import React, { useState } from 'react';

import { Container } from 'ui';
import { useFetch } from 'utilities';

export default function GuestDirectoryAnalyticsPage() {
  const [apps, setApps] = useState([]);
  useFetch('/api/gd-analytics', setApps);

  return (
    <Container>
      <h1>Analytics</h1>

      {apps.length > 0 ? null : "You don't have apps with analytics."}
    </Container>
  );
}
