import * as React from 'react';

import { Label, Input } from 'ui';
import { BlockEditorProps } from './common';
import { WebBlock } from '../contentHelpers';

class State {
  url = '';
}

export class Web extends React.Component<BlockEditorProps<WebBlock>, State> {
  state = new State();

  render() {
    return (
      <>
        <Label htmlFor="url">
          URL
          <Input
            name="url"
            placeholder="http://..."
            value={this.state.url}
            onChange={this.set}
          />
        </Label>
      </>
    );
  }

  componentDidMount() {
    this.setState({ url: this.props.data.url });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ url: nextProps.data.url });
  }

  set = e => {
    this.setState({ [e.target.name]: e.target.value } as any, () => {
      this.props.handleUpdate({
        ...this.props.data,
        url: this.state.url,
      });
    });
  };
}
