import React, { Component, ReactNode } from 'react';
import axios from 'axios';
import moment from 'moment';

import EditSchedule from './EditSchedule';
import SchedulePlaylist from './SchedulePlaylist';
import SchedulePlaylistItem from './SchedulePlaylistItem';

class Schedule extends Component<any, any> {
  state: any = {
    isLoading: false,
    channelId: 0,
    schedule: {
      items: [],
    },

    showEditScheduleDialog: false,
    editScheduleId: 0,
    editSchedule: null,

    showCreateScheduleDialog: false,
  };

  componentDidMount() {
    if (this.props.channelId) this.loadSchedule(this.props.channelId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.channelId !== this.props.channelId)
      this.loadSchedule(nextProps.channelId);
  }

  loadSchedule = id => {
    this.setState({ isLoading: true });
    axios
      .get(`/api/schedules/channel/${id}`)
      .then(r => {
        this.setState({
          isLoading: false,
          schedule: r.data,
        });
      })
      .catch(e => {
        this.setState({
          isLoading: false,
        });
        this.props.addDangerNotification(
          `Cannot load schedule for channel ${id}`
        );
      });
  };

  removeSchedule = id => {
    if (confirm('Are you sure you want to remove this schedule?')) {
      axios
        .delete(`/api/schedules/${id}`)
        .then(r => {
          this.loadSchedule(this.props.channelId);
          this.props.addInfoNotification(
            `Removed schedule ${id} for channel ${this.props.channelId}`
          );
        })
        .catch(e => {
          this.props.addDangerNotification(
            `Cannot delete schedule for channel ${id}`
          );
        });
    }
  };

  handleEditSchedule = (id, data) => {
    let { startTime, endTime } = data;
    if (startTime.length > 5) startTime = moment.utc(startTime).format('HH:mm');
    if (endTime.length > 5) endTime = moment.utc(endTime).format('HH:mm');

    var promise = axios.put(`/api/schedules/${id}`, {
      startDate: Math.round(new Date(data.startDate).getTime() / 1000),
      endDate: Math.round(new Date(data.endDate).getTime() / 1000),
      days: data.days,
      startTime: startTime,
      endTime: endTime,
      variables: data.variables,
    });

    promise
      .then(r => {
        console.log('success');
        this.props.addSuccessNotification('Schedule saved');
        this.loadSchedule(this.props.channelId);
      })
      .catch(e => {
        console.log('error');
        this.props.addDangerNotification(
          'Unable to save changes for the schedule!'
        );
      });

    return promise;
  };

  handleCreateSchedule = (selectedPlaylistId, scheduleObj) => {
    let { startTime, endTime } = scheduleObj;
    if (startTime.length > 5) startTime = moment.utc(startTime).format('HH:mm');
    if (endTime.length > 5) endTime = moment.utc(endTime).format('HH:mm');

    var promise = axios.post('/api/schedules', {
      accountId: window['userState'].accountId,
      channelId: this.props.match.params.id,
      playlistId: selectedPlaylistId,
      startDate: Math.round(new Date(scheduleObj.startDate).getTime() / 1000),
      endDate: Math.round(new Date(scheduleObj.endDate).getTime() / 1000),
      days: scheduleObj.days,
      startTime: startTime,
      endTime: endTime,
    });

    promise
      .then(r => {
        console.log('success');
        this.loadSchedule(this.props.match.params.id);
        this.props.addSuccessNotification('Schedule created');
      })
      .catch(e => {
        console.log('error');
        this.props.addDangerNotification('Unable to create schedule!');
      });

    return promise;
  };

  editSchedule = (e, s) => {
    this.setState(
      {
        showEditScheduleDialog: true,
        editScheduleId: s.id,
        editSchedule: s.scheduleData,
      },
      () => {
        $('#editSchedule').modal();
        $('#editSchedule').on('hidden.bs.modal', () => {
          this.setState({ showEditScheduleDialog: false });
        });
      }
    );
  };

  createSchedule = () => {
    this.setState({ showCreateScheduleDialog: true }, () => {
      $('#createSchedule').modal();
      $('#createSchedule').on('hidden.bs.modal', () => {
        this.setState({ showCreateScheduleDialog: false });
      });
    });
  };

  render() {
    let schedule: ReactNode = null;
    if (this.state.schedule && this.state.schedule.items) {
      if (this.state.schedule.items.length > 0) {
        schedule = (
          <div>
            <div className="pull-right">
              <button className="btn btn-fancy" onClick={this.createSchedule}>
                + Add playlist
              </button>
            </div>

            <div className="clearfix" />

            <br />

            <table className="table table-shadow table-hover table-playlist">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Playlist name</th>
                  <th>Duration</th>
                  <th>Items</th>
                  <th>Scheduled on</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.schedule.items.map((s, index) => (
                  <SchedulePlaylistItem
                    key={index}
                    index={index}
                    s={s}
                    removeSchedule={this.removeSchedule.bind(this, s.id)}
                    editSchedule={e => this.editSchedule(e, s)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        );
      } else {
        schedule = <p>This channel has no scheduled playlists.</p>;
      }
    }

    return (
      <div>
        {schedule}

        {this.state.showEditScheduleDialog ? (
          <EditSchedule
            name="editSchedule"
            handleAction={this.handleEditSchedule}
            scheduleId={this.state.editScheduleId}
            schedule={this.state.editSchedule}
          />
        ) : null}

        {this.state.showCreateScheduleDialog ? (
          <SchedulePlaylist
            name="createSchedule"
            channelId={this.props.match.params.id}
            handleAction={this.handleCreateSchedule}
          />
        ) : null}
      </div>
    );
  }
}

export default Schedule;
