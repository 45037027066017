import React, { Component } from 'react';

class TimelineScale extends Component<any, any> {
  render() {
    let ticks: number[] = [];

    for (var i = 0; i < Math.max(this.props.total + 4, 20); ++i) {
      const left = i * this.props.pxPerSec - this.props.offset;
      if (left >= 0) ticks.push(i);
    }

    return (
      <div className="timeline-scale">
        {ticks.map((tick, index) => {
          const left = tick * this.props.pxPerSec - this.props.offset;
          return (
            <div key={index} className="tick" style={{ left: `${left}px` }}>
              {tick}
            </div>
          );
        })}
        <div
          className="timeline-axis"
          style={{ paddingLeft: `${3 - this.props.offset}px` }}
        />
      </div>
    );
  }
}

export default TimelineScale;
