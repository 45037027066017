import axios from 'axios';

import {
  AppDTO,
  NewAppDTO,
  AppDetails,
  AppPageForCloneDto,
  UpdateAppDTO,
} from './types';

export const getApps = () => {
  return axios.get<AppDTO[]>('/api/apps/version/2');
};

export const getApp = (id: number) => {
  return axios.get<AppDetails>(`/api/apps/${id}`);
};

export const createApp = (data: NewAppDTO) => {
  return axios.post<AppDTO>('/api/apps', data);
};

export const getPagesForClone = (appId: number) => {
  return axios.get<AppPageForCloneDto[]>(`/api/apps/${appId}/pagesforclone`);
};

export const getPagesForLink = (appId: number) => {
  return axios.get<AppPageForCloneDto[]>(`/api/apps/${appId}/pagesforlink`);
};

export const copySourcePageUnderPage = (
  pageId: number,
  sourcePageId: number
) => {
  return axios.post(`/api/pages/${pageId}/link/${sourcePageId}/copy`);
};

export const linkSourcePageUnderPage = (
  pageId: number,
  sourcePageId: number
) => {
  return axios.post(`/api/pages/${pageId}/link/${sourcePageId}/link`);
};

export const deleteApp = (appId: number) => {
  return axios.delete(`/api/apps/${appId}`);
};

export const updateApp = (appId: number, data: UpdateAppDTO) => {
  return axios.put(`/api/apps/${appId}`, data);
};
