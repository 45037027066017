import React from 'react';
import ObjectActions from './ObjectActions';

export default class LineObj extends React.Component<any, any> {
  render() {
    let objProps = this.props.lineStateObj;
    let objActions = {
      handleClickClear: this.props.handleClickClear,
      handleClickEvent: this.props.handleClickEvent,
    };

    return (
      <div className="bottom-controls">
        <ObjectActions objActions={objActions} />

        <p>Position X1 || Y1</p>
        <input
          type="number"
          value={objProps.x1}
          name="x1"
          onChange={this.props.handleChangeLine}
        />
        <input
          type="number"
          value={objProps.y1}
          name="y1"
          onChange={this.props.handleChangeLine}
        />

        <p>Position X2 || Y2</p>
        <input
          type="number"
          value={objProps.x2}
          name="x2"
          onChange={this.props.handleChangeLine}
        />
        <input
          type="number"
          value={objProps.y2}
          name="y2"
          onChange={this.props.handleChangeLine}
        />

        <p>Length</p>
        <input
          type="number"
          value={objProps.length}
          name="length"
          onChange={this.props.handleChangeLine}
        />

        <p>Color</p>
        <select
          name="fillColor"
          className="form-control"
          value={objProps.fillColor}
          onChange={this.props.handleChangeLine}
        >
          <option value="black">Black</option>
          <option value="blue">Blue</option>
          <option value="red">Red</option>
          <option value="green">Green</option>
          <option value="yellow">Yellow</option>
          <option value="tomato">Tomato</option>
          <option value="white">White</option>
          <option value="purple">Purple</option>
        </select>

        <p>Opacity</p>
        <input
          type="number"
          placeholder="Opacity"
          name="opacity"
          className="form-control"
          value={objProps.opacity}
          onChange={this.props.handleChangeLine}
        />

        <p>Thickness</p>
        <input
          type="number"
          placeholder="Thickness"
          name="thickness"
          className="form-control"
          value={objProps.lineThickness}
          onChange={this.props.handleChangeLine}
        />
      </div>
    );
  }
}
