import * as React from 'react';

import './style.css';

export interface LabelProps {
  htmlFor?: string;
  className?: string;
  state?: 'error';
}

export const Label: React.SFC<LabelProps> = ({
  children,
  className,
  state,
  ...attributes
}) => {
  const classes = ['ui-label', className];
  if (state === 'error') {
    classes.push('has-error');
  }
  return (
    <label className={classes.join(' ')} {...attributes}>
      {children}
    </label>
  );
};
