import { useState } from 'react';

/**
 * A version of useState that is compatible with old style setState which
 * supported partial updates.
 *
 * This will help porting class components to hooks.
 *
 * @template T
 * @param {T} initial
 * @returns {[T, (t: Partial<T>) => void]}
 */
export function useStateLegacy<T = undefined>(
  initial: T
): [T, (t: Partial<T> | ((prev: T) => Partial<T>)) => void] {
  const [state, setState] = useState<T>(initial);

  function setPartial(partial: Partial<T> | ((prev: T) => Partial<T>)) {
    setState((prev) => {
      if (typeof partial === 'function') {
        return {
          ...prev,
          ...partial(prev),
        };
      }

      return {
        ...prev,
        ...partial,
      };
    });
  }

  return [state, setPartial];
}
