import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import EditSchedule from './EditSchedule';
import formatDuration from '../../utilities/formatDuration';

const SchedulePlaylistItem = ({ index, s, removeSchedule, editSchedule }) => {
  const schedule = s.scheduleData;
  const startDate = moment.utc(schedule.startDate);
  const endDate = moment.utc(schedule.endDate);
  const startTime = moment.utc(schedule.startTime).format('HH:mm');
  const endTime = moment.utc(schedule.endTime).format('HH:mm');

  let scheduledOn: any = null;
  if (schedule.startDate === schedule.endDate) {
    scheduledOn = `${startDate.format('DD.MM.YYYY')}`;
    if (startTime !== endTime)
      scheduledOn = (
        <div>
          <p>{`${startDate.format('DD.MM.YYYY')}`}</p>
          <p>{`from ${startTime} to ${endTime}`}</p>
        </div>
      );
  } else if (startDate.year() < 1990 && endDate.year() > 2030) {
    scheduledOn = 'always';
  } else {
    scheduledOn = (
      <div>
        <p>
          {startDate.format('DD.MM.YYYY')} - {endDate.format('DD.MM.YYYY')}
        </p>
        <p>
          <small>
            {startTime} - {endTime}
          </small>
        </p>
        <p>
          <span
            className={
              'schedule-day ' +
              (schedule.days.findIndex(a => a === 1) > -1 ? 'selected' : null)
            }
          >
            M
          </span>
          <span
            className={
              'schedule-day ' +
              (schedule.days.findIndex(a => a === 2) > -1 ? 'selected' : null)
            }
          >
            T
          </span>
          <span
            className={
              'schedule-day ' +
              (schedule.days.findIndex(a => a === 3) > -1 ? 'selected' : null)
            }
          >
            W
          </span>
          <span
            className={
              'schedule-day ' +
              (schedule.days.findIndex(a => a === 4) > -1 ? 'selected' : null)
            }
          >
            T
          </span>
          <span
            className={
              'schedule-day ' +
              (schedule.days.findIndex(a => a === 5) > -1 ? 'selected' : null)
            }
          >
            F
          </span>
          <span
            className={
              'schedule-day ' +
              (schedule.days.findIndex(a => a === 6) > -1 ? 'selected' : null)
            }
          >
            S
          </span>
          <span
            className={
              'schedule-day ' +
              (schedule.days.findIndex(a => a === 0) > -1 ? 'selected' : null)
            }
          >
            S
          </span>
        </p>
      </div>
    );
  }

  return (
    <tr>
      <td>{s.id}</td>
      <td>
        <Link to={`/digitalsignage/playlists/${s.playlistCacheData.id}`}>
          Playlist {s.playlistCacheData.name}
        </Link>
      </td>
      <td>{formatDuration(s.playlistCacheData.duration)}</td>
      <td>{s.playlistCacheData.items.length}</td>
      <td>{scheduledOn}</td>
      <td>
        <button
          className="btn btn-fancy"
          style={{ marginLeft: '12px' }}
          onClick={editSchedule}
        >
          Edit
        </button>
        <button
          className="btn btn-fancy"
          style={{ color: 'red', marginLeft: '12px' }}
          onClick={removeSchedule}
        >
          Unschedule
        </button>
      </td>
    </tr>
  );
};
export default SchedulePlaylistItem;
