import axios from 'axios';

import {
  BaseListDTO,
  AdminMobileDeviceDto,
  PushRegisterDeviceDto,
  AdminAccountDto,
  AdminAccountDetailsDto,
  EditAdminAccountDto,
  EditAdminAccountOptionsDto,
  AdminAccountOptionsDto,
  AdminAppDto,
} from './types';

export const getMobileDevices = (page: number = 1, pageSize: number = 20) => {
  return axios.get<BaseListDTO<AdminMobileDeviceDto>>(
    `/api/admin/push/${page}/${pageSize}`
  );
};

export const registerDevice = (data: PushRegisterDeviceDto) => {
  return axios.post(`/api/admin/push/register`, data);
};

export const getAccounts = () =>
  axios.get<AdminAccountDto[]>('/api/adminaccounts');

export const getAccount = (accountId: number) =>
  axios.get<AdminAccountDetailsDto>(`/api/adminaccounts/${accountId}`);

export const editAccount = (accountId: number, data: EditAdminAccountDto) =>
  axios.put(`/api/adminaccounts/${accountId}`, data);

export const getAccountOptions = (accountId: number) =>
  axios.get<AdminAccountOptionsDto>(`/api/adminaccounts/${accountId}/options`);

export const editAccountOptions = (
  accountId: number,
  data: EditAdminAccountOptionsDto
) => axios.put(`/api/adminaccounts/${accountId}/options`, data);

export const getAdminApp = (appId: number) =>
  axios.get<AdminAppDto>(`/api/adminapps/apps/${appId}`);

export const setAppAccount = (appId: number, accountId: number) =>
  axios.post<boolean>(`/api/adminapps/${appId}/setaccount/${accountId}`);

export const setUserAsAdmin = (userId: string) => {
  return axios.post(`/api/adminaccounts/setadmin/${userId}`);
};

export const setVar = (key: string, value: string) => {
  return axios.post<boolean>(`/api/adminvars`, { key, value });
};

export const deleteVar = (key: string) => {
  return axios.delete(`/api/adminvars/${key}`);
};

export const runDeviceStatsForDate = (date: string) => {
  return axios.post(`/api/admindevicestats/run/${date}`);
};
