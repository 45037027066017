import React, { Component } from 'react';

class ImagesHolder extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  render() {
    let { media, changeImage, type } = this.props;
    let filter = this.state.filter.toLowerCase();

    // for (var i = 0; i < 4; ++i)
    //     media = media.concat(media)

    let images = media
      .filter(image => !filter || image.name.toLowerCase().indexOf(filter) >= 0)
      .filter(i => i && i.mediaItemType === 2)
      .map((image, index) => {
        let uri = image.thumbUri;
        if (image.meta) {
          try {
            var meta = JSON.parse(image.meta);
            if (meta.uri) uri = meta.uri;
          } catch (e) {}
        }

        let imageObj = {
          target: {
            name: type === 'object' ? 'url' : 'bgImage',
            value: uri,
          },
        };

        return (
          <div className="pull-left card-bg addmediathumb" key={index}>
            <img
              src={image.thumbUri}
              onClick={changeImage.bind(this, imageObj)}
            />
            <p style={{ textAlign: 'center' }}>{image.name}</p>
          </div>
        );
      });

    return (
      <div className="media-images-holder clearfix">
        <form action="" className="form-inline">
          <div className="form-group">
            <label htmlFor="filter" className="control-label">
              Filter&nbsp;
            </label>
            <input
              type="text"
              className="form-control"
              name="filter"
              value={this.state.filter}
              onChange={e => this.setState({ filter: e.target.value })}
            />
          </div>
        </form>

        <br />

        {images.length > 0 ? images : 'No images found'}
      </div>
    );
  }
}

export { ImagesHolder };
