import React, { Component } from 'react';
import { connect } from 'react-redux';
import JSONTree from 'react-json-tree';

const AppDev = ({ appData }) => (
  <div className="container">
    {window['features'].dev ? (
      <JSONTree data={appData} shouldExpandNode={() => true} className="test" />
    ) : null}
  </div>
);

export default connect(state => ({
  appData: state.App,
}))(AppDev);
