import { combineReducers } from 'redux';

import { TimelineReducer } from './Components/Builder/Timeline/TimelineReducer';
import NotificationsReducer from './Components/NotificationsReducer';
import { MediaReducer } from './Components/Builder/ObjectTypes/MediaReducer';
import { AppReducer, PageReducer } from './Components/MobileApp/reducer';
import { AdminFormReducers } from './Components/Admin/Forms';
import accountReducer from './Components/Account/reducer';

export default combineReducers({
  Timeline: TimelineReducer,
  Notifications: NotificationsReducer,
  Media: MediaReducer,
  App: AppReducer,
  Page: PageReducer,
  AdminForms: AdminFormReducers.FormsReducer,
  AdminForm: AdminFormReducers.FormReducer,
  account: accountReducer,
});
