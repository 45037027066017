import * as React from 'react';

export interface InputProps {
  type?: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  defaultValue?: any;
  value?: any;
  onChange?: (e?: any) => any;
  onBlur?: (e?: any) => any;
  onFocus?: (e?: any) => any;
}

export const Input: React.SFC<InputProps> = ({ type, ...attributes }) => {
  const classes = ['ui-input', 'form-control'];
  return (
    <input
      type={type || 'text'}
      className={classes.join(' ')}
      {...attributes}
    />
  );
};
