const formatByteSize = size => {
  size = Number(size);
  if (size < 1024) return size + ' B';
  if (size < 1048576) return Math.floor(size / 1024) + ' KB';
  if (size < 1073741824) return Math.floor(size / 1048576) + ' MB';
  if (size < 4294967296) return (size / 1073741824).toFixed(2) + ' GB';

  return '-';
};

export default formatByteSize;
