import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { Loading, hasFeatureFlag } from 'Components/shared';
import * as Actions from './actions';
import PageTemplateSelectorModal from './PageTemplateSelectorModal';
import {
  createContent,
  defaultData,
  convertData,
  createChild,
  createBlock,
  BlockType,
  DataGridBlock,
} from './contentHelpers';
import PageContent from './PageContent';
import PageStyle from './Style/PageStyle';
import SubpagesStyle from './Style/SubpagesStyle';
import PageSubpages from './PageSubpages';
import PageTranslation from './PageTranslation';
import PageSettings from './PageSettings';
import ChildrenTranslation from './Localization/ChildrenTranslation';
import PageDev from './Dev/PageDev';
import { copySourcePageUnderPage, linkSourcePageUnderPage } from 'api';
import { AddTablePropertiesModal } from './AddTablePropertiesModal';

interface Props {
  id: string;
  page: any;
  app: any;
  templates: any;
  languages: any;
  dispatch: (action: any) => any;
  addSuccessNotification: (text: string) => any;
  addDangerNotification: (text: string) => any;
}

class State {
  isLoaded = false;
  isSaving = false;

  page: any = null;
  name = '';
  data: any = defaultData();
  content: any[] = []; // from data.content
  children: any[] = []; // from data.children
  template = {
    name: '',
    layout: [],
    alias: '',
  };
  redirectTo = '';
  hasError = false;
  showAddTableProperties = false;
}

class PageEditor extends React.Component<
  RouteComponentProps<{ id: string; pageid: string }> & Props,
  State
> {
  state = new State();

  render() {
    if (this.state.hasError)
      return <p className="alert alert-danger">Error loading editor.</p>;

    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    const { page, languages } = this.props;
    const hasLanguages = this.props.languages.length > 1;

    const { content } = page;

    const showAdd =
      page &&
      page.template &&
      page.template.alias !== 'page-rss' &&
      page.template.alias !== 'page-web' &&
      page.template.alias !== 'page-list';

    const availableBlocks = [
      {
        text: 'Title block',
        blockName: BlockType.title,
      },
      {
        text: 'Text block with formatting',
        blockName: BlockType.text,
      },
      {
        text: 'Button block',
        blockName: BlockType.button,
      },
      {
        text: 'Youtube link',
        blockName: BlockType.youtube,
      },
    ];

    // TODO: fix this (Toni)
    // if (hasFeatureFlag('mobileapps-block-table')) {
    //   availableBlocks.push({
    //     text: 'Table',
    //     blockName: BlockType.dataGrid,
    //   });
    // }

    if (showAdd) {
      const content = this.props.page.content;
      if (
        content.findIndex(
          (b) => b.type === BlockType.map || b.type === BlockType.gallery
        ) === -1
      ) {
        availableBlocks.push({
          text: 'Map block',
          blockName: BlockType.map,
        });
        availableBlocks.push({
          text: 'Gallery',
          blockName: BlockType.gallery,
        });
      }

      if (content.findIndex((b) => b.type === BlockType.social) === -1) {
        availableBlocks.push({
          text: 'Social links',
          blockName: BlockType.social,
        });
      }
    }

    return (
      <>
        <div className={'page__actions' + (page.isLoaded ? ' show' : ' hide')}>
          <button
            className="btn btn-fancy"
            title="Save page"
            onClick={this.handleSave}
          >
            <i className="fa fa-save" />
          </button>

          {showAdd ? (
            <div className="dropdown pull-right">
              <button
                className="btn btn-fancy dropdown-toggle"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i className="fa fa-plus" title="Add new element" />
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                {availableBlocks.map((blockName, index) => (
                  <li key={index} onClick={this.addBlock}>
                    <a data-type={blockName.blockName}>{blockName.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>

        <div className="col-xs-12">
          {page.template.alias ? (
            <div className="page-name-holder">
              <input
                type="text"
                className="page-name"
                value={page.name}
                onChange={this.handlePageNameChange}
                onBlur={this.checkName}
              />
              <small className="page-template">
                (template '{page.template.name}')
              </small>
            </div>
          ) : null}
        </div>

        <ul
          id="pageEditorTabs"
          className={
            'nav nav-tabs ' +
            (!page.isLoaded || !page.template.alias ? 'hide' : 'show')
          }
          role="tablist"
        >
          <li role="presentation" className="active">
            <a
              href="#page-content"
              aria-controls="content"
              role="tab"
              data-toggle="tab"
            >
              Content
            </a>
          </li>
          {hasLanguages ? (
            <li role="presentation">
              <a
                href="#page-translations"
                aria-controls="translations"
                role="tab"
                data-toggle="tab"
              >
                Translations
              </a>
            </li>
          ) : null}
          <li role="presentation">
            <a
              href="#page-style"
              aria-controls="style"
              role="tab"
              data-toggle="tab"
            >
              Style
            </a>
          </li>
          <li role="presentation">
            <a
              href="#page-settings"
              aria-controls="settings"
              role="tab"
              data-toggle="tab"
            >
              Settings
            </a>
          </li>
          {window['features'].dev ? (
            <li role="presentation">
              <a
                href="#page-dev"
                aria-controls="dev"
                role="tab"
                data-toggle="tab"
              >
                DEV
              </a>
            </li>
          ) : null}
        </ul>

        <div
          className={
            'tab-content ' +
            (page.isLoaded && !page.template.alias ? 'hide' : '')
          }
        >
          <div role="tabpanel" className="tab-pane active" id="page-content">
            <PageContent
              content={this.state.content}
              template={this.state.template}
              setContent={this.setContent}
              moveBlockUp={this.moveBlockUp}
              moveBlockDown={this.moveBlockDown}
              removeBlock={this.removeBlock}
              {...this.props}
            />
            <PageSubpages
              page={this.state.page}
              template={this.state.template}
              handleAddSubpage={this.handleAddSubpage}
              handleRemoveSubpage={this.handleRemoveSubpage}
              onLink={this.linkPage}
              onCopy={this.clonePage}
            />
          </div>

          <div role="tabpanel" className="tab-pane" id="page-style">
            <PageStyle
              background={this.props.page.data.background}
              content={this.props.page.content}
              setContent={this.setContent}
              setBackground={this.setBackground}
              {...this.props}
            />
            <SubpagesStyle setSubpage={this.setSubpage} />
          </div>

          {hasLanguages ? (
            <div role="tabpanel" className="tab-pane" id="page-translations">
              <PageTranslation
                content={content}
                languages={languages}
                setContent={this.setContent}
              />
              <ChildrenTranslation
                setSubpages={this.setSubpages}
                {...this.props}
              />
            </div>
          ) : null}

          <div role="tabpanel" className="tab-pane" id="page-settings">
            <PageSettings
              template={this.state.template}
              handleChangeTemplate={this.handleChangeTemplate}
              {...this.props}
            />
          </div>
          <div role="tabpanel" className="tab-pane" id="page-dev">
            <PageDev />
          </div>
        </div>

        {page.isLoaded && !page.template.alias ? (
          <div
            className="center-box"
            style={{ height: '400px', marginTop: '-200px', lineHeight: '50px' }}
          >
            <button
              className="btn btn-fancy"
              data-toggle="modal"
              data-target="#selectTemplate"
            >
              Select page template
            </button>
            <br />
            or
            <br />
            <button className="btn btn-fancy" onClick={this.handleDeletePage}>
              Delete page
            </button>
          </div>
        ) : null}

        {this.state.isSaving ? <Loading /> : null}

        <PageTemplateSelectorModal
          name="selectTemplate"
          currentTemplateAlias={this.state.template.alias}
          handleCreate={this.handleSelectTemplate}
        />

        <AddTablePropertiesModal
          show={this.state.showAddTableProperties}
          onAdd={this.addTableBlock}
          onClose={this.closeAddTableProperties}
        />
      </>
    );
  }

  componentDidMount() {
    if (this.props.id && this.props.templates) this.loadPage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.id !== this.props.id ||
      nextProps.templates !== this.props.templates
    ) {
      this.loadPage(nextProps);
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  loadPage = (props) => {
    const { id, templates, languages } = props;
    if (
      !id ||
      !templates ||
      templates.length === 0 ||
      !languages ||
      languages.length === 0
    ) {
      return;
    }

    this.props.dispatch(
      Actions.loadPage(id, templates, languages, props.app.pages)
    );
  };

  handleSave = () => {
    // if name has changed, reload entire app since tree needs to be updated
    // FIXME: work around this
    const page = this.props.page;
    const pages = this.props.app.pages;
    const nameChanged = page.name !== page.page.name;

    const data = {
      name: page.name,
      template: page.template.alias,
      rawData: Object.assign({}, page.data, {
        content: page.content,
        template: page.template.alias,
        children: page.subpages
          .map((child) => {
            // find this page elsewhere and use its title
            const page = pages.find((page) => page.id === child.id);
            if (!page) return null;

            // if the title is the same as page name, then save null
            // this way the picker on this page will properly update
            // if the child page changes name
            // if the name is overriden, then use that override
            const title = child.title;
            for (let i = 0; i < title.length; i += 1) {
              if (title[i].text === page.name) title[i].text = null;
            }

            return { ...child, title };
          })
          .filter((child) => child),
      }),
    };

    this.setState({ isSaving: true });

    return axios
      .put(`/api/pages/${this.props.page.id}`, data)
      .then((r) => {
        this.setState({ isSaving: false });
        this.props.addSuccessNotification('Page saved');
        if (nameChanged) {
          this.props.dispatch(Actions.load_app(this.props.app.data.id));
        }
      })
      .catch((e) => {
        this.setState({ isSaving: false });
        this.props.addDangerNotification('Unable to save page');
      });
  };

  handleAddSubpage = (data) => {
    let promise: any = null;
    if (this.props.page.level === 0) {
      promise = axios.post(`/api/pages/app/${this.props.app.data.id}`, {
        name: data.name,
      });
    } else {
      promise = axios.post(
        `/api/pages/app/${this.props.app.data.id}/parent/${this.props.page.id}`,
        {
          name: data.name,
        }
      );
    }

    promise
      .then((r) => {
        this.props.addSuccessNotification('Sub page added');
        const children = this.state.children.map((i) => i);
        children.push(createChild(r.data, data.name));
        this.setState({ children });
        this.props.dispatch(Actions.load_app(this.props.app.data.id));

        this.handleSave().then(() => {
          this.props.history.push(
            `/app/${this.props.app.data.id}/page/${r.data.id}`
          );
        });
      })
      .catch((e) => {
        this.props.addDangerNotification('Unable to add subpage');
      });

    return promise;
  };

  linkPage = (pageId: number, sourcePageId: number, name: string) => {
    return linkSourcePageUnderPage(pageId, sourcePageId)
      .then((r) => {
        this.props.addSuccessNotification(`Page ${name} linked`);
        const children = this.state.children.map((i) => i);
        children.push(createChild({ id: r.data }, name));
        this.setState({ children });
        this.props.dispatch(Actions.load_app(this.props.app.data.id));

        return r;
      })
      .catch(() => {
        this.props.addDangerNotification(`Unable to link page ${name}`);
      });
  };

  clonePage = (pageId: number, sourcePageId: number, name: string) => {
    return copySourcePageUnderPage(pageId, sourcePageId)
      .then((r) => {
        this.props.addSuccessNotification(`Page ${name} copied`);
        const children = this.state.children.map((i) => i);
        children.push(createChild({ id: r.data }, name));
        this.setState({ children });
        this.props.dispatch(Actions.load_app(this.props.app.data.id));
      })
      .catch(() => {
        this.props.addDangerNotification(`Unable to clone page ${name}`);
      });
  };

  handleRemoveSubpage = (subpage: { id: number }) => {
    const { pages } = this.props.app;
    const page = (pages || []).find((p) => p.id === subpage.id);

    if (
      page &&
      confirm(`Are you sure you want to unlink page '${page.name}'`)
    ) {
      this.props.dispatch(Actions.deletePage(subpage.id, null));
      if (parseInt(this.props.match.params.pageid, 10) === subpage.id) {
        const { history } = this.props;
        history.replace(`/app/${this.props.match.params.id}`);
      }
    }
  };

  set = (name, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, { [name]: value }),
    });
  };

  setContent = (data, autoSave = false) => {
    const index = this.props.page.content.findIndex((b) => b.id === data.id);
    if (index === -1) {
      this.props.addDangerNotification('Error saving content...');
      return;
    }

    this.props.dispatch(Actions.setPageContent(index, data));

    this.setState((state, props) => {
      return {
        content: state.content
          .slice(0, index)
          .concat(data)
          .concat(state.content.slice(index + 1)),
      };
    });
  };

  setSubpage = (subpage) => {
    this.props.dispatch(Actions.setSubpage(subpage));
  };

  setSubpages = (subpages) => {
    this.props.dispatch(Actions.setPageSubpages(subpages));
  };

  setBackground = (background) => {
    this.props.dispatch(Actions.setPageBackground(background));
  };

  handleSelectTemplate = (data) => {
    const template = data.template;
    const name = this.props.page.page.name;
    this.props.dispatch(
      Actions.changePageTemplate(name, this.props.languages, template)
    );
    return true;
  };

  handleChangeTemplate = (e) => {
    e.preventDefault();
    if (
      confirm(
        'Are you sure you want to change template? You will lose all data for this page.'
      )
    ) {
      $('#selectTemplate').modal('show');
    }
  };

  handleDeletePage = () => {
    this.props.dispatch(
      Actions.deletePage(
        this.props.match.params.pageid,
        this.props.match.params.id
      )
    );
  };

  addBlock = (e: any) => {
    const block = e.target.getAttribute('data-type');
    if (block === BlockType.dataGrid) {
      this.setState({ showAddTableProperties: true });
      return;
    }

    const newElement = createBlock(
      block,
      this.props.page.name,
      this.props.languages
    );
    newElement.id = this.props.page.content.length + 1;
    this.props.dispatch(Actions.addBlock(newElement));

    this.setState((state) => ({
      content: [...state.content, newElement],
    }));
  };

  addTableBlock = (columns: number) => {
    const columnStyles = [
      {
        width: '*',
        horizontalTextAlignment: 0,
      },
    ];
    for (let i = 0; i < columns - 1; ++i) {
      columnStyles.push({ width: 'Auto', horizontalTextAlignment: 2 });
    }

    const block: DataGridBlock = {
      columns,
      columnStyles,
      id: this.props.page.content.length + 1,
      type: BlockType.dataGrid,
      rows: [],
    };
    this.props.dispatch(Actions.addBlock(block));

    this.setState((state) => ({
      content: [...state.content, block],
      showAddTableProperties: false,
    }));
  };

  moveBlockUp = (id: number) => {
    this.props.dispatch(Actions.moveBlockUp(id));
  };

  moveBlockDown = (id: number) => {
    this.props.dispatch(Actions.moveBlockDown(id));
  };

  removeBlock = (id: number) => {
    if (confirm('Are you sure you want to remove this block?')) {
      this.props.dispatch(Actions.removeBlock(id));
    }
  };

  checkName = () => this.props.dispatch(Actions.pageCheckName);

  handlePageNameChange = (e) =>
    this.props.dispatch(Actions.pageSetName(e.target.value));

  closeAddTableProperties = () =>
    this.setState({ showAddTableProperties: false });
}

export default connect((state) => ({
  page: state.Page,
  theme: state.App.theme,
  templates: state.App.templates,
  languages: state.App.languages
    ? state.App.languages.map((l) => l.shortCode)
    : [],
}))(PageEditor);
