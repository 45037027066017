import * as React from 'react';
import TinyMCE from 'react-tinymce';

import { Button } from 'ui';

import { isoLangs } from '../../../languages';
import { LocalizedString, strip } from '../contentHelpers';
import { EditBlock } from '../Editors/common';

declare global {
  const tinyMCE: any;
}

interface Props {
  title: string;
  text: LocalizedString[];
  languages: string[];
  setText: (text: LocalizedString[]) => any;
}

class State {
  showModal = false;

  // which block are we editing
  index = -1;
  temp = '';

  // all blocks
  text: LocalizedString[] = [];

  editText = '';
}

class TextPreview extends React.Component<
  {
    index: number;
    block: LocalizedString;
    languages: string[];
    onOpenModal: (index: number) => any;
  },
  {}
> {
  openModal = () => this.props.onOpenModal(this.props.index);

  render() {
    const { block, languages } = this.props;
    const code = block.lang;
    const language = isoLangs.hasOwnProperty(code) ? isoLangs[code] : null;
    if (!language) return null;

    const text = strip(block.text);

    return (
      <tr>
        <td>{language.name}</td>
        <td>{text}</td>
        <td>
          <Button type="default" onClick={this.openModal}>
            Edit
          </Button>
        </td>
      </tr>
    );
  }
}

class LocalizeText extends React.Component<Props, State> {
  state = new State();

  render() {
    const { languages } = this.props;
    const rows = this.state.text
      .map((block, index) => (
        <TextPreview
          key={index}
          block={block}
          languages={languages}
          index={index}
          onOpenModal={this.handleEdit}
        />
      ))
      .filter((x) => x);

    return (
      <EditBlock title={this.props.title}>
        <table className="table table-shadow">
          <thead>
            <tr>
              <th>Language</th>
              <th>Content preview</th>
              <th />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>

        {this.state.showModal ? (
          <div
            className="modal fade"
            id="editLocalizedText"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="editTextLabel"
          >
            <div className="modal-dialog modal-lg modal-fancy" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="editTextLabel">
                    Edit text
                  </h4>
                </div>

                <div className="modal-body">
                  <TinyMCE
                    content={this.state.editText}
                    config={{
                      menubar: false,
                      plugins: 'paste autoresize lists',
                      statusbar: false,
                      toolbar:
                        'bold italic undo redo | alignleft aligncenter alignright | fontsizeselect | bullist numlist',
                      fontsize_formats:
                        '8pt 10pt 12pt 14pt 18pt 22pt 24pt 36pt',
                      paste_as_text: true,
                      min_height: 100,
                    }}
                    onChange={this.handleTextChange}
                  />
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-fancy"
                    onClick={this.handleTextSave}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </EditBlock>
    );
  }

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.text !== this.props.text ||
      nextProps.languages !== this.props.languages
    ) {
      this.init(nextProps);
    }
  }

  init = (props: Props) => {
    const { text, languages } = props;

    if (text && languages) {
      this.setState({
        text: languages.map((code) => {
          const block = text.find((d) => d.lang === code);

          return {
            lang: code,
            text: block ? block.text : '',
          };
        }),
      });
    }
  };

  handleEdit = (index) => {
    this.setState(
      {
        index,
        showModal: true,
        editText: this.state.text[index].text,
        temp: this.state.text[index].text,
      },
      () => {
        $('#editLocalizedText').modal('show');
        tinyMCE.activeEditor.setContent(this.state.text[index].text);
      }
    );
  };

  handleTextChange = (e) => {
    const temp = e.target.getContent();
    this.setState({ temp });
  };

  handleTextSave = () => {
    $('#editLocalizedText').modal('hide');

    this.setState(
      (state, props) => {
        const { text, temp, index } = state;
        return {
          text: [
            ...text.slice(0, index),
            {
              ...text[index],
              text: temp,
            },
            ...text.slice(index + 1),
          ],
          temp: '',
          showModal: false,
        };
      },
      () => {
        this.props.setText(this.state.text);
      }
    );
  };
}

export default LocalizeText;
