import React, { Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AddNewPlaylist from './AddNewPlaylist';
import { Loading, WithDialog } from 'Components/shared';
import formatDuration from '../../utilities/formatDuration';
import { formatPlaylistOrientation, PlaylistTransitions } from '../../enums';
import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';
import { Redirect } from 'react-router';
import { RenamePlaylistDialog } from './rename-playlist-modal';
import { UpdatePlaylistRequest, GetPlaylistResponse } from 'api';

function groupBy<T, U>(arr: T[], getKey: (t: T) => U) {
  const groups: { key: U; values: T[] }[] = [];

  for (let i = 0; i < arr.length; ++i) {
    const el = arr[i];
    const key = getKey(el);
    const group = groups.find((g) => g.key === key);

    if (!group) {
      groups.push({ key, values: [el] });
    } else {
      group.values.push(el);
    }
  }

  return groups;
}

const PlaylistRow = ({
  playlist,
  handleClickDeletePlaylist,
  onRename,
}: {
  playlist: GetPlaylistResponse;
  handleClickDeletePlaylist: (e: any, id: number) => void;
  onRename: (name: string) => Promise<any>;
}) => {
  return (
    <tr>
      <th>{playlist.id}</th>
      <td>
        <Link to={`/digitalsignage/playlists/${playlist.id}`} key={playlist.id}>
          {playlist.name}
        </Link>
      </td>
      <td>{formatPlaylistOrientation(playlist.orientation)}</td>
      <td>{formatDuration(playlist.duration)}</td>
      <td>{playlist.itemsCount !== 0 ? playlist.itemsCount : ''}</td>
      <td>
        <WithDialog>
          {(isOpen, show, hide) => (
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  show();
                }}
              >
                <i className="glyphicon glyphicon-pencil" />
              </a>

              <RenamePlaylistDialog
                name={playlist.name}
                onRename={onRename}
                onHide={hide}
                show={isOpen}
              />
            </>
          )}
        </WithDialog>

        {playlist.canDelete ? (
          <a
            onClick={(e) => handleClickDeletePlaylist(e, playlist.id)}
            title="Delete this playlist"
          >
            <span
              className="delete-media glyphicon glyphicon-trash"
              style={{ color: 'red' }}
            />
          </a>
        ) : null}
      </td>
    </tr>
  );
};

type State = {
  isFetching: boolean;
  playlists: GetPlaylistResponse[];
  redirectTo: string;
};

class DigitalSignagePlaylists extends React.Component<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      playlists: [],
      redirectTo: '',
    };
  }

  componentDidMount() {
    this.getPlaylists();
  }

  getPlaylists = () => {
    this.setState({ isFetching: true });
    return axios
      .get('/api/playlists')
      .then((res) => {
        this.setState({
          isFetching: false,
          playlists: res.data,
        });
        this.props.addInfoNotification('Playlists loaded.');
      })
      .catch((err) => {
        this.setState({ isFetching: false });
        this.props.addDangerNotification(
          'Unable to load playlists, please try refreshing the page'
        );
      });
  };

  handleClickCreatePlaylist = (data) => {
    const promise = axios.post('/api/playlists', {
      name: data.name.trim(),
      meta: JSON.stringify({
        duration: data.duration,
        transition: PlaylistTransitions.SlideLeft,
      }),
      accountId: window['userState'].accountId,
    });

    promise
      .then((res) => {
        $('#newPlaylist').modal('hide');
        this.setState({ redirectTo: `/digitalsignage/playlists/${res.data}` });
        this.props.addSuccessNotification('Playlist created');
      })
      .catch((err) => {
        this.props.addDangerNotification('Unable to create playlist');
      });
    return promise;
  };

  renamePlaylist = async (id: number, name: string) => {
    const playlist = this.state.playlists.find((p) => p.id === id);
    if (!!playlist) {
      const data: UpdatePlaylistRequest = {
        name,
        orientation: playlist.orientation,
        meta: JSON.stringify({
          duration: playlist.duration,
          transition: PlaylistTransitions.SlideLeft,
        }),
      };
      await axios.put(`/api/playlists/${id}`, data);
      await this.getPlaylists();
    }
  };

  handleClickDeletePlaylist = (e, id: number) => {
    e.preventDefault();

    if (confirm('Are you sure you want to delete this playlist?')) {
      axios
        .delete(`/api/playlists/${id}`)
        .then((res) => {
          this.props.dispatch(addSuccessNotification('Playlist deleted'));
          this.getPlaylists();
        })
        .catch((err) => {
          this.props.dispatch(
            addDangerNotification('Unable to delete playlist')
          );
          this.getPlaylists();
        });
    }
  };

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    if (this.state.isFetching) return <Loading />;

    let playlists: React.ReactNode = <></>;

    if (this.state.playlists.length !== 0) {
      const groups = groupBy(this.state.playlists, (p) => p.accountId);

      playlists = (
        <table className="table table-shadow table-striped table-hover">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Orientation</th>
              <th>Duration</th>
              <th>Items</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {groups.map((group) => (
              <Fragment key={group.key}>
                {groups.length > 1 && (
                  <tr>
                    <td colSpan={6}>{group.values[0].accountName}</td>
                  </tr>
                )}
                {group.values.map((playlist, index) => (
                  <PlaylistRow
                    key={index}
                    playlist={playlist}
                    handleClickDeletePlaylist={this.handleClickDeletePlaylist}
                    onRename={(name) => this.renamePlaylist(playlist.id, name)}
                  />
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      );
    } else {
      playlists = <p>There are no playlists currently.</p>;
    }

    return (
      <div className="container playlists-container">
        <div className="playlists-top-options clearfix">
          <div className="col-md-2">
            {false ? (
              <input
                type="text"
                placeholder="Filter media"
                className="form-control"
              />
            ) : null}
          </div>

          <div className="col-md-10">
            <button
              className="btn btn-fancy pull-right"
              data-toggle="modal"
              data-target="#newPlaylist"
            >
              + New Playlist
            </button>
          </div>
        </div>

        <AddNewPlaylist
          name="newPlaylist"
          handleCreate={this.handleClickCreatePlaylist}
        />

        {playlists}
      </div>
    );
  }
}

export default connect(null, {
  addDangerNotification,
  addInfoNotification,
  addSuccessNotification,
})(DigitalSignagePlaylists);
