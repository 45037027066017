import { DeviceLogLevel } from 'api';

export function getClassNameFromLevel(level: DeviceLogLevel) {
  switch (level) {
    case DeviceLogLevel.Error:
    case DeviceLogLevel.Critical:
      return 'danger';

    case DeviceLogLevel.Warning:
      return 'warning';

    case DeviceLogLevel.Information:
      return 'info';

    default:
      return 'debug';
  }
}
