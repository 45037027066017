import React from 'react';
import axios from 'axios';
import PresentationEditor from './PresentationEditor';

var oldCanvas;

export default class DigitalSignageDraftBuilder extends React.Component<
  any,
  any
> {
  canvas: any;

  constructor(props) {
    super(props);

    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClearCanvas = this.handleClearCanvas.bind(this);
    this.handleCanvasCreated = this.handleCanvasCreated.bind(this);

    this.state = {
      oldCanvas: null,
      isFetching: false,
    };
  }

  handleCanvasCreated(canvas) {
    this.canvas = canvas;
  }

  componentDidMount() {
    this.setState({
      isFetching: true,
    });
    axios
      .get('/api/devplayer/data')
      .then((response) => {
        oldCanvas = JSON.parse(response.data.data);
        this.setState({
          oldCanvas: JSON.parse(response.data.data),
          isFetching: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isFetching: false,
        });
      });
  }

  handleClickSave(canvas) {
    this.canvas.dimensions = {
      width: canvas.width,
      height: canvas.height,
    };

    axios.post(
      '/api/devplayer/data',
      JSON.stringify(this.canvas.toJSON(['dimensions']))
    );
  }

  handleClearCanvas() {
    axios
      .delete('/api/devplayer/data')
      .then((r) => {
        console.log('cleared');
      })
      .catch((e) => {
        console.log('error clearing');
      });
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <div className="col-md-12 clearfix name-and-save">
          <button
            onClick={this.handleClickSave}
            className="btn btn-fancy save-button pull-right"
          >
            <span
              className="glyphicon glyphicon glyphicon-floppy-disk"
              aria-hidden="true"
            />{' '}
            Save
          </button>
        </div>
        <PresentationEditor
          save={this.handleClickSave}
          oldCanvas={this.state.oldCanvas}
          handleClearCanvas={this.handleClearCanvas}
          handleCanvasCreated={this.handleCanvasCreated}
        />
        );
        {this.state.isFetching ? <h2>Loading draft builder...</h2> : null}
      </div>
    );
  }
}
