import React, { Component } from 'react';
import DatePicker from 'react-bootstrap-date-picker';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';

class ScheduleControl extends Component<any, any> {
  state: any = {
    errors: {
      _all: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
    },
    data: {
      recurring: false,
      playAlways: false,
      startDate: moment().format(),
      startTime: moment().startOf('day'),
      endTime: moment().startOf('day'),
      endDate: moment().format(),
      days: [],
    },
  };

  componentDidMount() {
    if (this.props.schedule) this.init(this.props.schedule);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.schedule !== nextProps.schedule)
      this.init(nextProps.schedule);
  }

  init = schedule => {
    const startDate = moment.utc(schedule.startDate);
    const endDate = moment.utc(schedule.endDate);
    const startTime =
      schedule.startTime.length === 5
        ? moment.utc(schedule.startTime, 'HH:mm')
        : moment.utc(schedule.startTime);
    const endTime =
      schedule.endTime.length === 5
        ? moment.utc(schedule.endTime, 'HH:mm')
        : moment.utc(schedule.endTime);

    this.setState({
      data: {
        recurring: schedule.startDate !== schedule.endDate,
        playAlways: startDate.year() < 1990 && endDate.year() > 2030,
        startDate: startDate.format(),
        endDate: endDate.format(),
        startTime: startTime,
        endTime: endTime,
        days: schedule.days,
        isValid: true,
      },
    });
  };

  handleChange = e => {
    const propName = e.target.name.split('-')[1];
    const propVal =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setDataValue(propName, propVal);
  };

  togglePlayAlways = e => {
    let newData = null;
    if (e.target.checked) {
      newData = {
        ...this.state.data,
        playAlways: true,
      };
    } else {
      newData = {
        ...this.state.data,
        playAlways: false,
        startDate: moment().format(),
        endDate: moment().format(),
        days: [],
        recurring: false,
      };
    }

    this.validateAndSet(newData);
  };

  toggleRecurring = e => {
    let newData = null;
    if (e.target.checked)
      newData = {
        ...this.state.data,
        recurring: true,
        endDate: moment
          .utc(this.state.startDate)
          .add(1, 'd')
          .format(),
      };
    else {
      newData = {
        ...this.state.data,
        recurring: false,
        endDate: this.state.startDate,
      };
    }

    this.validateAndSet(newData);
  };

  setDataValue = (name, value) => {
    var newData = {
      ...this.state.data,
      [name]: value,
    };

    this.validateAndSet(newData);
  };

  validateAndSet = newData => {
    var { errors, isValid } = this.validate(newData);

    this.setState({
      ...this.state,
      errors: errors,
      data: newData,
    });

    if (this.props.onChange)
      this.props.onChange(this.toSchedule(newData), isValid);
  };

  validate = data => {
    let errors = {
      _all: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
    };
    let isValid = true;

    if (!data.playAlways) {
      if (!data.startDate) {
        errors.startDate = 'Start Date is required';
      }
      if (!data.startTime) {
        errors.startTime = 'Start time is required';
      }
      if (!data.endTime) {
        errors.endTime = 'End time is required';
      }

      if (data.recurring) {
        if (!data.endDate) {
          errors.endDate = 'End date is required';
        }
        if (data.endDate < data.startDate) {
          errors._all = 'End date must be after the start date';
        }
        if (data.days.length === 0) {
          errors._all = 'Must select at least one day';
        }
      }
    }

    return { errors: errors, isValid: isValid };
  };

  // recreate final schedule from internal respresentation
  toSchedule = data => {
    if (data.playAlways) {
      return {
        startTime: '00:00',
        endTime: '00:00',
        startDate: '1970-01-01',
        // in case you are reading this after this date... hi!
        endDate: '2069-01-01',
        days: [0, 1, 2, 3, 4, 5, 6],
      };
    } else {
      return {
        startTime: data.startTime.format('HH:mm'),
        endTime: data.endTime.format('HH:mm'),
        startDate: data.startDate,
        endDate: data.recurring ? data.endDate : data.startDate,
        days: data.days,
      };
    }
  };

  handleClickDays = e => {
    e.preventDefault();

    const day = Number(e.target.getAttribute('data-key'));
    let days = this.state.data.days.map(x => x);

    if (days.indexOf(day) === -1) {
      days.push(day);
    } else {
      const indexRm = days.indexOf(day);
      days = days
        .slice(0, indexRm)
        .concat(days.slice(indexRm + 1, days.length));
    }

    this.setDataValue('days', days);
  };

  hasDay = day => {
    return this.state.data.days.findIndex(d => Number(d) === Number(day)) > -1;
  };

  render() {
    return (
      <div id={this.props.name + 'Form'}>
        <div className="form-group clearfix">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                name="data-playAlways"
                checked={this.state.data.playAlways}
                onChange={this.togglePlayAlways}
              />
              Play always
            </label>
          </div>

          <div className={this.state.data.playAlways ? 'hide' : ''}>
            <div
              className={
                'col-xs-3 padding0 ' + (this.state.data.recurring ? 'hide' : '')
              }
            >
              <label className="control-label" htmlFor="data-startDate">
                Schedule for
              </label>
              <DatePicker
                className="col-xs-12"
                name="data-startDate"
                value={this.state.data.startDate}
                onChange={v => this.setDataValue('startDate', v)}
                showClearButton={false}
                weekStartsOn={1}
                showTodayButton={true}
              />
              <div className="help-block with-errors">
                {this.state.errors.startDate}
              </div>
            </div>

            <div className="col-xs-3">
              <label className="control-label" htmlFor="data-startTime">
                Start time
              </label>
              <TimePicker
                showSecond={false}
                name="data-startTime"
                value={this.state.data.startTime}
                onChange={v => this.setDataValue('startTime', v)}
                className="form-control"
              />
              <div className="help-block with-errors">
                {this.state.errors.startTime}
              </div>
            </div>

            <div className="col-xs-3">
              <label className="control-label" htmlFor="data-endTime">
                End time
              </label>
              <TimePicker
                showSecond={false}
                name="data-endTime"
                value={this.state.data.endTime}
                onChange={v => this.setDataValue('endTime', v)}
                className="form-control"
              />
              <div className="help-block with-errors">
                {this.state.errors.endTime}
              </div>
            </div>

            <div
              className="col-xs-12 recurring-schedule-holder padding0"
              style={styles.holder}
            >
              <fieldset style={styles.fieldSet}>
                <legend style={styles.legend}>
                  <input
                    type="checkbox"
                    name="data-recurring"
                    checked={this.state.data.recurring}
                    onChange={this.toggleRecurring}
                  />{' '}
                  Recurring schedule
                </legend>

                <div className={this.state.data.recurring ? '' : 'hide'}>
                  <div className="col-xs-4">
                    <label className="control-label">Start date</label>
                    <DatePicker
                      className="col-xs-12"
                      name="data-startDate"
                      value={this.state.data.startDate}
                      onChange={v => this.setDataValue('startDate', v)}
                      showClearButton={false}
                      weekStartsOn={1}
                      showTodayButton={true}
                    />
                  </div>

                  <div className="col-xs-4">
                    <label className="control-label" htmlFor="data-endDate">
                      End date
                    </label>
                    <DatePicker
                      className="col-xs-12"
                      name="data-endDate"
                      value={this.state.data.endDate}
                      onChange={v => this.setDataValue('endDate', v)}
                      disabled={this.state.data.recurring ? false : true}
                      showClearButton={false}
                      weekStartsOn={1}
                      showTodayButton={true}
                    />
                    <div className="help-block with-errors">
                      {this.props.endDateError}
                    </div>
                  </div>

                  <div className="col-xs-12" style={styles.row}>
                    <label className="control-label" htmlFor="channel">
                      Days of the week
                    </label>
                    <br />
                    <button
                      data-key="1"
                      className={this.hasDay(1) ? 'pressed' : ''}
                      onClick={this.handleClickDays}
                    >
                      Mon
                    </button>
                    <button
                      data-key="2"
                      className={this.hasDay(2) ? 'pressed' : ''}
                      onClick={this.handleClickDays}
                    >
                      Tue
                    </button>
                    <button
                      data-key="3"
                      className={this.hasDay(3) ? 'pressed' : ''}
                      onClick={this.handleClickDays}
                    >
                      Wed
                    </button>
                    <button
                      data-key="4"
                      className={this.hasDay(4) ? 'pressed' : ''}
                      onClick={this.handleClickDays}
                    >
                      Thu
                    </button>
                    <button
                      data-key="5"
                      className={this.hasDay(5) ? 'pressed' : ''}
                      onClick={this.handleClickDays}
                    >
                      Fri
                    </button>
                    <button
                      data-key="6"
                      className={this.hasDay(6) ? 'pressed' : ''}
                      onClick={this.handleClickDays}
                    >
                      Sat
                    </button>
                    <button
                      data-key="0"
                      className={this.hasDay(0) ? 'pressed' : ''}
                      onClick={this.handleClickDays}
                    >
                      Sun
                    </button>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="has-error">
          <div className="help-block with-errors">{this.state.errors._all}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  legend: {
    paddingLeft: '15px',
    paddingRight: '15px',
    border: 'none',
    display: 'inline-block',
    width: 'auto',
    fontSize: '14px',
    color: '#929D9D',
    margin: '0',
  },
  fieldSet: {
    border: '1px solid #cecece',
    borderRadius: '3px',
    padding: '20px',
  },
  holder: {
    marginTop: '40px',
  },
  row: {
    marginTop: '20px',
  },
};

export default ScheduleControl;
