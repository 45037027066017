import React, {
  createContext,
  useState,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import axios from 'axios';
import { MeDTO } from 'api/types';

type UserState = {
  isFetching: boolean;
  isLoggedIn: boolean;
  name: string;
  accountId: number;
  accountName: string;
  isAdministrator: boolean;
  features: string[];
};

type UserActions = {
  login: () => Promise<any>;
  logout: () => void;
};
export type UserContextProps = UserState & UserActions;

const UserContext = createContext<UserContextProps | undefined>(undefined);

const userKey = 'user';

const initial = (function () {
  try {
    const s = localStorage.getItem(userKey);
    return JSON.parse(s!) as UserState;
  } catch (error) {
    return null;
  }
})();

function UserProvider({ children }: PropsWithChildren<{}>) {
  const [state, setState] = useState<UserState>(
    initial ||
      ({
        isFetching: true,
      } as UserState)
  );

  function setData(me: MeDTO) {
    const accounts = me.accounts;
    const features =
      accounts.length > 0 ? accounts[0].features.split(',') || [] : [];
    const accountId = accounts.length > 0 ? accounts[0].id : 0;

    setState({
      isLoggedIn: true,
      isFetching: false,
      isAdministrator: false,
      name: me.user?.userName ?? '',
      accountId,
      accountName: accounts.length > 0 ? accounts[0].name : 'unknown account',
      features,
    });

    window['features'] = features;
    window['userState'] = {
      accountId,
    };
  }

  useEffect(() => {
    window['features'] = {};
    window['userState'] = {};

    if (!initial || initial.isFetching) {
      axios
        .get<MeDTO>('/api/me')
        .then(response => {
          setData(response.data);
        })
        .catch(() => {
          setState({
            isFetching: false,
            isLoggedIn: false,
            name: '',
            accountId: 0,
            accountName: '',
            isAdministrator: false,
            features: [],
          });
        });
    }
  }, []);

  const value: UserContextProps = {
    ...state,
    login: async () => {
      await axios
        .get('/api/me')
        .then(response => {
          setData(response.data);
        })
        .catch(() => {
          console.log('Error logging in!');
          setState({
            isFetching: false,
            isLoggedIn: false,
            name: '',
            accountId: 0,
            accountName: '',
            isAdministrator: false,
            features: [],
          });
        });
    },
    logout: () => {
      setState({
        isFetching: false,
        isLoggedIn: false,
        name: '',
        accountId: 0,
        accountName: '',
        isAdministrator: false,
        features: [],
      });
      localStorage.removeItem(userKey);
    },
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('This component is not under UserProvider');
  }

  return context;
}

export { UserProvider, useUser };
