let initialState = {
  isFetchingMedia: false,
  fetchedMedia: null,
  fetchingMediaError: null,
};

export const MediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MEDIA_STARTED':
      return Object.assign({}, state, {
        isFetchingMedia: action.isFetchingMedia,
      });

    case 'GET_MEDIA_SUCCESS':
      return Object.assign({}, state, {
        isFetchingMedia: action.isFetchingMedia,
        fetchedMedia: action.fetchedMedia,
      });

    case 'GET_MEDIA_ERROR':
      return Object.assign({}, state, {
        isFetchingMedia: action.isFetchingMedia,
        fetchingMediaError: action.fetchingMediaError,
      });

    default:
      return state;
  }
};
