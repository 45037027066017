import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ColorPicker from '../../Builder/ColorPicker/ColorPicker';
import SubpageBackgroundImage from './SubpageBackgroundImage';

const ColorPreview = ({ color }) => {
  return (
    <span
      style={{
        borderBottomWidth: '3px',
        borderBottomStyle: 'solid',
        borderBottomColor: color,
        textTransform: 'uppercase',
      }}
    >
      {color}
    </span>
  );
};
class SubpageBackground extends Component<any, any> {
  constructor(props) {
    super(props);

    this.init = this.init.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleColorChangeComplete = this.handleColorChangeComplete.bind(this);
    this.handleClearColor = this.handleClearColor.bind(this);
    this.handleSetCustomColor = this.handleSetCustomColor.bind(this);

    this.state = {
      color: null,
      landscape: '',
      portrait: '',
    };
  }

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.subpage !== nextProps.subpage ||
      this.props.theme !== nextProps.theme
    )
      this.init(nextProps);
  }

  init(props) {
    let { subpage } = props;
    this.setState({
      color: subpage.background.color,
      landscape: subpage.background.landscape,
      portrait: subpage.background.portrait,
    });
  }

  handleColorChange(color, event) {
    this.setState({ color: color });
  }

  handleColorChangeComplete(color, event) {
    this.props.handleUpdate(
      Object.assign({}, this.props.subpage.background, {
        color: color.hex,
        landscape: this.state.landscape,
        portrait: this.state.portrait,
      })
    );
  }

  handleClearColor(e) {
    this.setState({ color: null });
  }

  handleSetCustomColor(e) {
    this.setState({ color: '#000000' });
  }

  render() {
    return (
      <div>
        {this.state.color ? (
          <div>
            <ColorPicker
              handleChange={this.handleColorChange}
              handleChangeComplete={this.handleColorChangeComplete}
              currentVal={this.state.color}
            />
            <ColorPreview color={this.state.color} />
            <button className="btn btn-default" onClick={this.handleClearColor}>
              Remove custom color
            </button>
          </div>
        ) : (
          <div>
            <span className="subtle-label">Theme: </span>
            <ColorPreview color={this.props.theme.backgroundColor} />
            <br />
            <button
              className="btn btn-default"
              onClick={this.handleSetCustomColor}
            >
              Change
            </button>
          </div>
        )}
      </div>
    );
  }
}

class SubpagesStyle extends Component<any, any> {
  constructor(props) {
    super(props);

    this.init = this.init.bind(this);
    this.handleUpdateBackground = this.handleUpdateBackground.bind(this);

    this.state = {
      subpages: [],
    };
  }

  componentDidMount() {
    if (this.props.page) this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.page !== nextProps.page ||
      this.props.pages !== nextProps.pages ||
      this.props.theme !== nextProps.theme ||
      this.props.subpages !== nextProps.subpages
    )
      this.init(nextProps);
  }

  init(props) {
    let { template } = props.page;
    let { pages, theme, subpages } = props;
    if (
      !template ||
      (template.alias !== 'page-home' && template.alias !== 'page-list')
    )
      return;

    this.setState({
      subpages: subpages.map((subpage, index) => {
        let page = pages.find(p => p.id === subpage.id);
        let name = page ? page.name : null;
        return {
          id: subpage.id,
          name: name,
          background: subpage.background,
        };
      }),
    });
  }

  handleUpdateBackground(index, background) {
    let subpage = this.props.subpages[index];
    this.props.setSubpage(
      Object.assign({}, subpage, {
        background: background,
      })
    );
  }

  render() {
    let { template, subpages } = this.props.page;
    let { pages, theme } = this.props;
    if (
      !template ||
      (template.alias !== 'page-home' && template.alias !== 'page-list')
    )
      return null;

    let rows = subpages.map((subpage, index) => {
      let page = pages.find(p => p.id === subpage.id);
      let title = page ? page.name : null;
      let color = null;

      if (!subpage.background.color) color = null;

      return (
        <tr key={index}>
          <td>
            <Link to={''}>{title}</Link>
          </td>
          <td>{color}</td>
        </tr>
      );
    });

    return (
      <div className="edit-block">
        <h4 className="edit-block-title">
          {this.props.page.page.level === 0 ? 'picker pages' : 'Subpages'}
        </h4>

        <table className="table table-shadow">
          <thead>
            <tr>
              <th>Name</th>
              <th>Color</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {this.state.subpages.map((subpage, index) => (
              <tr key={index}>
                <td>{subpage.name}</td>
                <td>
                  <SubpageBackground
                    subpage={subpage}
                    theme={this.props.theme}
                    handleUpdate={bkg =>
                      this.handleUpdateBackground(index, bkg)
                    }
                  />
                </td>
                <td>
                  <SubpageBackgroundImage
                    subpage={subpage}
                    theme={this.props.theme}
                    handleUpdate={bkg =>
                      this.handleUpdateBackground(index, bkg)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(state => ({
  subpages: state.Page.subpages,
  page: state.Page,
  pages: state.App.pages,
  theme: state.App.theme,
}))(SubpagesStyle);
