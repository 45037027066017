import React from 'react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withFetch<T>(loader: (props: any) => Promise<T>) {
  return WrappedComponent => {
    class WithFetch extends React.Component {
      state = { data: null, isLoading: false, isLoaded: false };

      componentDidMount() {
        if (loader) {
          loader(this.props)
            .then(data =>
              this.setState({ data, isLoading: false, isLoaded: true })
            )
            .catch(() => this.setState({ isLoading: false }));
        }
      }

      render() {
        const { data, isLoaded } = this.state;
        if (isLoaded) return <WrappedComponent {...this.props} {...data} />;
        return null;
      }
    }

    WithFetch['displayName'] = `WithFetch(${getDisplayName(WrappedComponent)})`;

    return WithFetch;
  };
}
