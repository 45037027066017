import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle,
} from 'react-sortable-hoc';

import {
  ImageTarget,
  UploadedImageModel,
  removePageImage,
  getAccountImages,
  getPageImages,
} from 'api';

import ImagePicker from '../../ImagePicker';

const DragHandle = SortableHandle(() => (
  <span className="drag-handle fa fa-bars" />
));

interface GalleryImageProps {
  image: any;
  onRemoveImage: (image: any) => any;
}

const SortableList = SortableContainer<any>(({ items, handleDelete }) => {
  return (
    <div>
      {items.map((image, index) => (
        <SortableItem
          key={`item-${index}`}
          image={image}
          index={index}
          handleRemove={handleDelete}
        />
      ))}
    </div>
  );
});

const SortableItem = SortableElement<any>(({ key, image, handleRemove }) => {
  return (
    <div style={{ display: 'inline-flex' }}>
      <GalleryImage key={key} image={image} onRemoveImage={handleRemove} />
    </div>
  );
});

export class GalleryImage extends React.PureComponent<GalleryImageProps, {}> {
  handleRemoveImage = () => {
    this.props.onRemoveImage(this.props.image);
  };

  render() {
    const {
      image: { thumbUri },
    } = this.props;
    return (
      <div>
        <DragHandle />
        <div
          className="gallery-image"
          style={{ backgroundImage: `url(${thumbUri})` }}
        >
          <button title="Remove this image" onClick={this.handleRemoveImage}>
            <i className="fa fa-trash" />
          </button>
        </div>
      </div>
    );
  }
}

interface Props {
  data: any;
  max?: number;
  handleUpdate: (data: any) => any;
  addSuccessNotification: (text: string) => any;
  addDangerNotification: (text: string) => any;
}

class State {
  // images from page.data
  images: any[] = [];
}

export class Gallery extends React.Component<
  Props & RouteComponentProps<{ pageid: string }>,
  State
> {
  state = new State();

  componentDidMount() {
    this.setState({ images: this.props.data.images });

    if (this.props.match.params.pageid) {
      this.loadGallery(this.props.match.params.pageid);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ images: nextProps.data.images });

    if (nextProps.match.params.pageid !== this.props.match.params.pageid) {
      this.loadGallery(nextProps.match.params.pageid);
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        images: arrayMove(this.state.images, oldIndex, newIndex),
      },
      () => {
        const images = this.state.images;
        this.props.handleUpdate({ ...this.props.data, images });
      }
    );
  };

  loadGallery = (pageid: string) => {
    getPageImages(parseInt(pageid, 10))
      .then((response) => {
        this.setState((state, props) => {
          const gallery = response.data;
          const images = state.images.slice(0, state.images.length);

          for (let i = 0; i < gallery.length; i += 1) {
            if (
              !images.find(
                (image) =>
                  (image.id && image.id === gallery[i].id) ||
                  (!image.id && image.thumbUri === gallery[i].thumbUri)
              )
            ) {
              images.push(gallery[i]);
            }
          }

          if (state.images.length !== images.length) {
            this.props.handleUpdate({ ...this.props.data, images });
          }

          return { images };
        });
      })
      .catch((e) => {});
  };

  handleRemoveImage = (image: UploadedImageModel) => {
    if (image.id) {
      removePageImage(parseInt(this.props.match.params.pageid, 10), image.id)
        .then(() => this.props.addSuccessNotification('Image removed'))
        .catch(() =>
          this.props.addDangerNotification('Unable to remove image')
        );
    }

    this.setState((state, props) => {
      let images = state.images;
      const index = images.indexOf(image);
      if (index >= 0) {
        images = images.slice(0, index).concat(images.slice(index + 1));
        this.props.handleUpdate({ ...this.props.data, images });
        return { images };
      }

      return { images };
    });
  };

  addUploadedImage = (image: UploadedImageModel) => {
    this.setState((state) => {
      const images = [
        ...state.images,
        {
          id: image.id,
          uri: image.uri,
          thumbUri: image.thumbnailUri,
        },
      ];
      this.props.handleUpdate({ ...this.props.data, images });
      return { images };
    });
  };

  showPicker = () => {
    $('#galleryImagePicker').modal({
      backdrop: 'static',
      keyboard: false,
    });
  };
  closePicker = () => $('#galleryImagePicker').modal('hide');

  render() {
    const { images } = this.state;
    if (!images) return <span>No images</span>;

    return (
      <>
        {!this.props.max || images.length < this.props.max ? (
          <>
            <button
              className="btn btn-transparent text-info pull-right"
              onClick={this.showPicker}
            >
              + Add image
            </button>
            <div className="clearfix" />
          </>
        ) : null}

        <SortableList
          items={images}
          handleDelete={this.handleRemoveImage}
          onSortEnd={this.onSortEnd}
          useDragHandle={true}
          axis="xy"
        />

        <ImagePicker
          name="galleryImagePicker"
          mustCropImage={false}
          target={ImageTarget.Gallery}
          backgroundType="gallery"
          pageId={parseInt(this.props.match.params.pageid, 10)}
          handleClose={this.closePicker}
          handleCreate={this.addUploadedImage}
          targetWidth={0}
          targetHeight={0}
        />
      </>
    );
  }
}
