import React, { useState } from 'react';
import DatePicker from 'react-bootstrap-date-picker';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';

import {
  SimpleModal,
  Header,
  Footer,
  Content,
  Loading,
} from 'Components/shared';
import { Button } from 'ui';

type Props = {
  mediaItemId: number;
  isOpen: boolean;
  hide: () => void;
  onSet: (type: string, date: number) => Promise<void>;
};

function toUnixTime(date: any) {
  return Math.floor(
    moment
      .utc(date)
      .toDate()
      .getTime() / 1000
  );
}

export function MediaItemScheduleModal(props: Props) {
  const { isOpen, hide } = props;
  const [isWorking, setIsWorking] = useState(false);
  const [date, setDate] = useState(moment().format());
  const [type, setType] = useState('activate');

  const set = () => {
    setIsWorking(true);

    props
      .onSet(type, toUnixTime(date))
      .then(() => {
        setIsWorking(false);
        props.hide();
      })
      .catch(() => {
        setIsWorking(false);
      });
  };

  return (
    <SimpleModal show={isOpen} hide={hide}>
      <Header>Set activation/deactivation date</Header>
      <Content>
        {isWorking && <Loading />}
        <section className="flex flex-row w-full items-center">
          <section className="mr-4">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="type"
                  value="activate"
                  checked={type === 'activate'}
                  onChange={() => setType('activate')}
                />
                Activate
              </label>
            </div>
          </section>

          <section>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="type"
                  value="deactivate"
                  checked={type === 'deactivate'}
                  onChange={() => setType('deactivate')}
                />
                Deactivate
              </label>
            </div>
          </section>
        </section>
        <div>
          <label className="control-label" htmlFor="date">
            Date:
          </label>
          <DatePicker
            value={date}
            onChange={date => setDate(date)}
            showClearButton={false}
            weekStartsOn={1}
            showTodayButton={true}
          />
        </div>

        <div>
          <label className="control-label" htmlFor="time">
            Time:
          </label>
          <TimePicker
            showSecond={false}
            value={moment(date)}
            onChange={date => setDate(date.format())}
            className="form-control"
          />
        </div>
      </Content>
      <Footer>
        <Button type="primary" onClick={set} disabled={isWorking}>
          Set
        </Button>
        <Button onClick={hide}>Cancel</Button>
      </Footer>
    </SimpleModal>
  );
}
