import axios from 'axios';
import useSWR from 'swr';

import {
  DevicesForAccount,
  CreateDeviceCommandCommand,
  DeviceLogDTO,
  BaseListDTO,
  AccountDeviceStats,
  AccountDeviceError,
  DashboardDeviceInfoDto,
} from './types';

export const getDashboard = (accountId: number) => {
  return axios.get<DevicesForAccount>(`/api/dashboard/${accountId}`);
};

export const removeDevice = (deviceId: number) => {
  return axios.delete(`/api/dashboard/device/${deviceId}`);
};

export const addDeviceCommand = (
  deviceId: number,
  command: string,
  isQuery: boolean
) => {
  const data: CreateDeviceCommandCommand = {
    deviceId,
    command,
    isQuery,
  };
  return axios.post<number>(`/api/devicecommands/${deviceId}`, data);
};

export const useDeviceLog = (
  deviceId: number,
  page: number,
  pageSize: number = 20
) => {
  return useSWR<BaseListDTO<DeviceLogDTO>>(
    `/api/dashboard/device/${deviceId}/log/${page}/${pageSize}`
  );
};

export const getStats = (accountId: number) => {
  return axios.get<AccountDeviceStats[]>(`/api/dashboard/stats/${accountId}`);
};

export const getErrors = (
  accountId: number,
  page: number,
  pageSize: number = 20
) => {
  return axios.get<BaseListDTO<AccountDeviceError>>(
    `/api/dashboard/errors/${accountId}/${page}/${pageSize}`
  );
};

export const useDeviceInfo = (deviceId: number) => {
  return useSWR<DashboardDeviceInfoDto>(
    `/api/dashboard/device/${deviceId}/info`
  );
};
