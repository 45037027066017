import React, { Component } from 'react';

class ModalBase extends Component<any, any> {
  fields: any;

  constructor(props, fields, data: any = null) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.set = this.set.bind(this);
    this.reset = this.reset.bind(this);

    this.fields = fields;

    let state = {
      data: data || {},
      errors: {
        _all: '',
      },
    };

    for (var i = 0; i < fields.length; ++i) {
      if (!state.data[fields[i]]) {
        state.data[fields[i]] = '';
      }
      state.errors[fields[i]] = '';
    }

    this.state = state;
  }

  handleChange(e) {
    this.set(e.target.name, e.target.value);
  }

  set(name, value) {
    this.setState({
      data: Object.assign({}, this.state.data, { [name]: value }),
    });
  }

  clearErrors() {
    console.log('clearing errors...');
    let errors = {};
    for (var i in this.state.errors) {
      if (this.state.errors.hasOwnProperty(i)) {
        errors[i] = '';
      }
    }
    this.setState({
      errors: errors,
    });
  }

  handleCreate() {
    this.clearErrors();

    var errors = (this as any).validate(this.state.data);
    let hasErrors = false;
    for (var i in errors) {
      if (errors.hasOwnProperty(i)) {
        if (errors[i]) hasErrors = true;
      }
    }

    if (hasErrors) {
      this.setState({
        errors: errors,
      });
    } else {
      let result = this.props.handleCreate(this.state.data);

      if (result === true || !result) {
        $(`#${this.props.name}`).modal('hide');
        this.reset();
      } else {
        // result is a promise
        result
          .catch((e) => {
            var errors = e.response.data;
            if (errors) {
              if (errors.error && typeof errors.error === 'string') {
                this.setState({
                  errors: {
                    _all: errors.error,
                  },
                });
              } else {
                var newState = this.state.errors;
                for (var fieldName in errors) {
                  errors[fieldName] = false;
                }

                this.setState({
                  errors: newState,
                });
              }
            }
          })
          .then((r) => {
            if (r) {
              var form = document.getElementById(this.props.name + 'Form');
              try {
                $(`#${this.props.name}`).modal('hide');
              } catch (e) {}
              this.reset();
            }
          });
      }
    }
  }

  reset() {
    for (var i = 0; i < this.fields.length; ++i) {
      this.state.data[this.fields[i]] = '';
      this.state.errors[this.fields[i]] = '';
    }
  }
}

export default ModalBase;
