import React, { ReactNode, FunctionComponent } from 'react';

import './loading.css';

export interface LoadingProps {
  show?: boolean;
  children?: ReactNode;
}

export const Loading = ({ show = true, children = null }: LoadingProps) => {
  if (!show) return null;
  return (
    <div className="loading">
      {children}
      <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1" />
        <div className="sk-cube sk-cube2" />
        <div className="sk-cube sk-cube3" />
        <div className="sk-cube sk-cube4" />
        <div className="sk-cube sk-cube5" />
        <div className="sk-cube sk-cube6" />
        <div className="sk-cube sk-cube7" />
        <div className="sk-cube sk-cube8" />
        <div className="sk-cube sk-cube9" />
      </div>
    </div>
  );
};

export const LoadingOverlay: FunctionComponent<LoadingProps> = ({
  show = true,
}) => {
  if (!show) return null;
  return (
    <div className="loading-overlay">
      <Loading />
    </div>
  );
};
