import * as React from 'react';

import { SocialBlock, BlockType } from '../contentHelpers';
import { BlockEditorProps } from './common';
import { Label, Input } from 'ui';

type Props = BlockEditorProps<SocialBlock>;
type State = SocialBlock;

export class Social extends React.Component<Props, State> {
  state: State = {
    id: 0,
    twitter: '',
    facebook: '',
    instagram: '',
    type: BlockType.social,
  };

  render() {
    return (
      <>
        <Label htmlFor="facebook">
          Facebook
          <Input
            name="facebook"
            value={this.state.facebook}
            onChange={this.set}
          />
        </Label>

        <Label htmlFor="twitter">
          Twitter
          <Input
            name="twitter"
            value={this.state.twitter}
            onChange={this.set}
          />
        </Label>

        <Label htmlFor="instagram">
          Instagram
          <Input
            name="instagram"
            value={this.state.instagram}
            onChange={this.set}
          />
        </Label>
      </>
    );
  }

  componentDidMount() {
    this.setState(this.props.data);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.data !== nextProps.data) {
      this.setState(nextProps.data);
    }
  }

  set = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as any, () => {
      this.props.handleUpdate({
        ...this.props.data,
        facebook: this.state.facebook,
        twitter: this.state.twitter,
        instagram: this.state.instagram,
      });
    });
  };
}
