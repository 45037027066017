import React from 'react';
import { NavLink } from 'react-router-dom';
import * as c from 'classnames';

import './pages-tree.css';

type Props = {
  appId: number;
  pages: any[];
};

export default function PagesTree({ appId, pages }: Props) {
  return (
    <ul className="pages-tree">
      {pages.map((page, index) => {
        const hasChildren = pages.find(p => p.parentPageId === page.id);
        return (
          <li
            key={index}
            className={`level-${page.level} ${page.isDraft ? 'draft' : ''} ${
              page.isNew ? 'new' : ''
            }`}
            style={{ marginLeft: `${page.level * 10}px` }}
          >
            <NavLink
              to={`/app/${appId}/page/${page.id}`}
              className={c(
                'page',
                { 'page-edited': page.isNew || page.isDraft },
                { 'page-hidden': page.isHidden }
              )}
              activeClassName="selected"
            >
              <i
                className={
                  page.level === 0
                    ? 'fa fa-home'
                    : hasChildren
                    ? 'fa fa-folder-o'
                    : 'fa fa-file-o'
                }
              />
              {page.name}
              {page.isLink && <i className="fa fa-link" />}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}
