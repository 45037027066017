import React from 'react';

export type ToggleFilterButtonProps = {
  active: boolean;
  onClick: () => void;
};
export function ToggleFilterButton({
  active,
  onClick,
  children,
}: React.PropsWithChildren<ToggleFilterButtonProps>) {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 border rounded-lg cursor-pointer ${
        active ? 'bg-blue-500 text-white' : 'text-blue-500 border-blue-500'
      }`}
    >
      {children}
    </button>
  );
}
