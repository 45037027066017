import 'react-hot-loader/patch';

import $ from 'jquery';

import 'bootstrap';
import './index.css';

import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { AppContainer } from 'react-hot-loader';
import axiosMiddleware from 'redux-axios-middleware';
import * as Sentry from '@sentry/browser';
import Modal from 'react-modal';
import { SWRConfig } from 'swr';

import mainReducer from './MainReducer';
import * as RoutesModule from './routes';
import { UserProvider, useUser } from 'utilities';
import ToastProvider from './ui/toasts/toast-provider';

import 'bootstrap/dist/css/bootstrap.css';
import './css/app.css';

window['jQuery'] = $;

// IE polyfill
// const Promise = require('es6-promise').Promise;
// require('es6-promise').polyfill();
// require('es6-object-assign').polyfill();

///////////////////////////////////////////////////////////////
//                      REDUX
///////////////////////////////////////////////////////////////
const logger = createLogger();
const autoSave = (store) => (next) => (action) => {
  const result = next(action);
  localStorage.setItem(
    'store:autoSave',
    JSON.stringify({
      Timeline: {
        collapsed: store.getState().Timeline.collapsed,
      },
    })
  );
  return result;
};

const client = axios.create({
  // all axios can be used, shown in axios documentation
  baseURL: '/api',
  responseType: 'json',
});

const redirects = (store) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case 'DELETE_PAGE_SUCCESS':
      const appid = store.getState().App.data.id;
      // TODO: Fix
      //   browserHistory.push(`/app/${appid}`)
      break;
  }
};
const initialStoreState = JSON.parse(
  localStorage.getItem('store:autoSave') || '{}'
);
const composeEnhancers =
  window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

const store = createStore(
  mainReducer,
  initialStoreState,
  composeEnhancers(
    applyMiddleware(thunk, axiosMiddleware(client), logger, autoSave, redirects)
  )
);

///////////////////////////////////////////////////////////////
//                      COMPONENTS
///////////////////////////////////////////////////////////////

$.fn.hideModal = function () {
  this.modal('hide');
  $('body').removeClass('modal-open');
  $('.modal-backdrop').remove();
};

declare global {
  interface JQuery {
    hideModal: () => void;
  }
}

///////////////////////////////////////////////////////////////
//                      LINQ
///////////////////////////////////////////////////////////////

declare global {
  interface Array<T> {
    firstOrDefault(predicate: (element: T) => boolean): T | null;
    selectMany<U>(predicate: (element: T) => U[]): U[];
  }
}

if (!Array.prototype.firstOrDefault) {
  Array.prototype.firstOrDefault = function <T>(
    predicate: (element: T) => boolean
  ): T | null {
    if (!predicate) {
      if (this.length === 0) return null;
      return this[0];
    }

    for (let i = 0; i < this.length; ++i) {
      if (predicate(this[i])) return this[i];
    }

    return null;
  };
}

if (!Array.prototype.selectMany) {
  Array.prototype.selectMany = function <T, U>(
    predicate: (element: T) => U[]
  ): U[] {
    const result: U[] = [];

    for (let i = 0; i < this.length; ++i) {
      const projection = predicate(this[i]);
      for (let j = 0; j < projection.length; ++j) {
        result.push(projection[j]);
      }
    }

    return result;
  };
}

///////////////////////////////////////////////////////////////
//                      Sentry
///////////////////////////////////////////////////////////////

Sentry.init({
  dsn: 'https://4e7d15cfc8c04e98aa5e41b459bb238e@sentry.io/1359314',
});

///////////////////////////////////////////////////////////////
//                      APP
///////////////////////////////////////////////////////////////

// Use Croatian locale
moment.locale('hr');

let routes = RoutesModule.routes;
const fetcher = (url) => axios.get(url).then((r) => r.data);

function Root() {
  const user = useUser();

  const baseUrl = useMemo(() => {
    return document.getElementsByTagName('base')[0].getAttribute('href')!;
  }, []);

  if (user.isFetching) {
    return <main className="flex flex-row items-center">Loading app...</main>;
  }
  if (!user) {
    return <>...</>;
  }

  if (user.isLoggedIn) {
    return (
      <Provider store={store}>
        <ToastProvider>
          <BrowserRouter children={routes} basename={baseUrl} />
        </ToastProvider>
      </Provider>
    );
  }

  return (
    <BrowserRouter children={RoutesModule.anonymousRoutes} basename={baseUrl} />
  );
}

function renderApp() {
  ReactDOM.render(
    <AppContainer>
      <UserProvider>
        <SWRConfig value={{ fetcher }}>
          <Root />
        </SWRConfig>
      </UserProvider>
    </AppContainer>,
    document.getElementById('root')
  );
}

renderApp();

if (module.hot) {
  module.hot.accept('./routes', () => {
    routes = require<typeof RoutesModule>('./routes').routes;
    renderApp();
  });
}

Modal.setAppElement('#root');
