import React, { Component } from 'react';

class FormInput extends Component<any, any> {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value: '',
      error: '',
    };
  }

  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
    this.props.onChange(e);
  }

  render() {
    var propName = this.props.propName;

    return (
      <div className="form-group">
        <label htmlFor={propName} className="control-label">
          {this.props.title}
        </label>
        <input
          className="form-control"
          type="text"
          name={propName}
          value={this.state.value}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default FormInput;
