import axios from 'axios';

import { FormRequestState } from '../../../enums';

export const formsLoad = (page = 1, count = 20) => dispatch => {
  dispatch(formsLoadStart());

  let accountId = window['userState'].accountId;
  axios
    .get(`/api/forms/account/${accountId}`)
    .then(r => {
      dispatch(formsLoaded(r.data));
    })
    .catch(e => {
      dispatch(formsLoadError(e));
    });
};

export const formsLoadStart = () => ({ type: 'FORMS_LOAD_START' });
export const formsLoaded = forms => ({
  type: 'FORMS_LOAD_SUCCESS',
  forms: forms,
});
export const formsLoadError = error => ({
  type: 'FORMS_LOAD_ERROR',
  error: error,
});

export const loadFormInfo = formId => dispatch => {
  dispatch(formInfoLoadStart());
  axios
    .get(`/api/forms/${formId}`)
    .then(r => {
      dispatch(formInfoLoaded(r.data));
    })
    .catch(e => {
      dispatch(formInfoLoadError(e));
    });
};

export const formRequestsLoad = formId => dispatch => {
  loadFormInfo(formId)(dispatch);
  dispatch(formRequestsLoadStart());
  axios
    .get(`/api/formrequests/form/${formId}`)
    .then(r => {
      dispatch(formRequestsLoaded(r.data));
    })
    .catch(e => {
      dispatch(formRequestsLoadError(e));
    });
};

export const formInfoLoadStart = () => ({ type: 'FORMINFO_LOAD_START' });
export const formInfoLoaded = data => ({
  type: 'FORMINFO_LOAD_SUCCESS',
  data: data,
});
export const formInfoLoadError = error => ({
  type: 'FORMINFO_LOAD_ERROR',
  error: error,
});

export const formRequestsLoadStart = () => ({
  type: 'FORMREQUESTS_LOAD_START',
});
export const formRequestsLoaded = data => ({
  type: 'FORMREQUESTS_LOAD_SUCCESS',
  data: data,
});
export const formRequestsLoadError = error => ({
  type: 'FORMREQUESTS_LOAD_ERROR',
  error: error,
});

export const formRequestAck = (request, response) => (dispatch, getState) => {
  axios
    .put(`/api/formrequests/${request.id}`, {
      state: FormRequestState.Acknowledged,
      response: response,
    })
    .then(r => {
      const formId = getState().AdminForm.info.id;
      dispatch(formRequestsLoad(formId));
    })
    .catch(e => {});
};

export const formRequestComplete = (request, response) => (
  dispatch,
  getState
) => {
  axios
    .put(`/api/formrequests/${request.id}`, {
      state: FormRequestState.Completed,
      response: response,
    })
    .then(r => {
      const formId = getState().AdminForm.info.id;
      dispatch(formRequestsLoad(formId));
    })
    .catch(e => {});
};

export const formRequestEditResponse = (request, response) => (
  dispatch,
  getState
) => {
  axios
    .put(`/api/formrequests/${request.id}`, {
      response: response,
    })
    .then(r => {
      const formId = getState().AdminForm.info.id;
      dispatch(formRequestsLoad(formId));
    })
    .catch(e => {});
};

export const createNewFormRequest = (alias, deviceId, data, pageId) => (
  dispatch,
  getState
) => {
  axios
    .post(`/api/formrequests/form/${alias}`, {
      deviceId: deviceId,
      data: data,
      request: data,
      pageId,
    })
    .then(r => {
      const formId = getState().AdminForm.info.id;
      dispatch(formRequestsLoad(formId));
    })
    .catch(e => {});
};

export const showFormEdit = () => ({ type: 'FORM_EDIT_SHOW' });

export const editFormData = data => (dispatch, getState) => {
  dispatch(formEditStart());

  axios
    .put(`/api/forms/${data.id}`, data)
    .then(r => {
      dispatch(formEdited(r.data));
      loadFormInfo(data.id)(dispatch);
    })
    .catch(e => dispatch(formEditError(e)));
};

export const formEditStart = () => ({ type: 'FORM_EDIT_START' });
export const formEdited = data => ({ type: 'FORM_EDIT_SUCCESS', data: data });
export const formEditError = error => ({
  type: 'FORM_EDIT_ERROR',
  error: error,
});
