import React from 'react';

const Select = ({ name, className, value, values, onChange }) => {
  if (!values) return null;

  let arr = values.constructor === Array ? values : Object.keys(values);

  return (
    <select name={name} className={className} value={value} onChange={onChange}>
      {arr.map((element, index) => {
        return (
          <option key={index} value={values[element]}>
            {element}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
