import React, { Component } from 'react';
import { connect } from 'react-redux';
import JSONTree from 'react-json-tree';

const PageDev = ({ pageData }) => (
  <div>
    {window['features'].dev ? (
      <JSONTree
        data={pageData}
        shouldExpandNode={() => true}
        className="test"
      />
    ) : null}
  </div>
);

export default connect(state => ({
  pageData: state.Page.data,
}))(PageDev);
