import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { connect } from 'react-redux';

import {
  PlaylistDetails,
  PlaylistDetailsMediaItem,
  PlaylistSchedules,
} from 'api';
import { Loading } from 'Components/shared';

import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';

import ApiError from '../ApiError';
import CreateSchedule from './CreateSchedule';
import { PlaylistOrientation, PlaylistTransitions } from '../../enums';
import formatDuration from '../../utilities/formatDuration';
import MediaItemDialog from '../modals/media-item-picker';
import Select from '../FormHelpers/Select';

import { PlaylistMediaItemRow } from './playlist-media-item-row';

import './playlists.css';
import { UserContextProps, useUser } from 'utilities';

const SortableItem = SortableElement(PlaylistMediaItemRow);

const SortableList = SortableContainer<any>(
  ({
    items,
    handleDelete,
    handleAvailability,
    handleSaveDurationChange,
    handleClearDuration,
    onChangePosition,
  }) => {
    return (
      <table className="table table-shadow table-hover table-playlist">
        <thead>
          <tr>
            <th>#</th>
            <th>Preview</th>
            <th>Name</th>
            <th>Type</th>
            <th>Dimensions</th>
            <th>Duration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <SortableItem
              key={item.id}
              index={index}
              item={item}
              itemsCount={items.length}
              onDelete={handleDelete}
              onAvailability={handleAvailability}
              onSaveDurationChange={handleSaveDurationChange}
              onClearDuration={handleClearDuration}
              onChangePosition={onChangePosition}
            />
          ))}
        </tbody>
      </table>
    );
  }
);

const Durations = (function () {
  const arr: number[] = [];
  for (let i = 1; i < 40; ++i) {
    arr[i] = i;
  }
  return arr;
})();

type Props = {
  addInfoNotification: (text: string) => void;
  addSuccessNotification: (text: string) => void;
  addDangerNotification: (text: string) => void;
  user: UserContextProps;
} & RouteComponentProps<{ id: string }>;

class State {
  isLoading = false;
  playlist: PlaylistDetails = new PlaylistDetails();
  items: PlaylistDetailsMediaItem[] = [];
  schedules: PlaylistSchedules[] = [];
  error: any = null;
  orientation = PlaylistOrientation.Landscape;
  transition = 0;
  duration = 0;

  showAddMediaItemDialog = false;
}

class Playlist extends React.Component<Props, State> {
  state = new State();

  componentDidMount() {
    this.getPlaylist();
  }

  getPlaylist = () => {
    this.setState({ isLoading: true });
    axios
      .get<PlaylistDetails>(`/api/playlists/${this.props.match.params.id}`)
      .then(res => {
        let meta = {
          transition: 1,
          duration: 5,
        };

        try {
          meta = Object.assign({}, meta, JSON.parse(res.data.meta));
        } catch (e) {}

        this.setState({
          isLoading: false,
          playlist: res.data,
          items: res.data.items.map((item, index) => ({
            ...item,
            order: index + 1,
          })),
          schedules: res.data.schedules,
          transition: meta.transition,
          duration: meta.duration,
          orientation: res.data.orientation,
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false, error: err.response });
        this.props.addDangerNotification(
          'Could not load playlist details, please try refreshing the page'
        );
      });
  };

  handleRemoveMediaItem = item => {
    if (confirm(`Are you sure you want to remove item '${item.mediaName}'?`)) {
      axios
        .delete(`/api/playlists/${this.state.playlist.id}/media/${item.id}`)
        .then(r => {
          this.props.addSuccessNotification('Item successfully removed');
          this.getPlaylist();
        })
        .catch(e => {
          this.props.addDangerNotification(
            'Unable to remove item, please try refreshing the page'
          );
        });
    }
  };

  handleAddMediaItem2 = (e, item) => {
    const promise = axios.post(
      `/api/playlists/${this.state.playlist.id}/media`,
      {
        mediaItemId: item.id,
      }
    );
    promise
      .then(r => {
        this.props.addSuccessNotification('Item added');
        this.getPlaylist();
      })
      .catch(e => {
        console.dir(e);
        this.props.addDangerNotification(
          'Unable to add playlist, please try again'
        );
      });

    return promise;
  };

  handleCreateSchedule = (selectedChannelId, scheduleObj) => {
    let { startTime, endTime } = scheduleObj;
    if (startTime.length > 5) startTime = moment.utc(startTime).format('HH:mm');
    if (endTime.length > 5) endTime = moment.utc(endTime).format('HH:mm');

    const promise = axios.post('/api/schedules', {
      startTime,
      endTime,
      accountId: window['userState'].accountId,
      channelId: selectedChannelId,
      playlistId: this.state.playlist.id,
      startDate: Math.round(new Date(scheduleObj.startDate).getTime() / 1000),
      endDate: Math.round(new Date(scheduleObj.endDate).getTime() / 1000),
      days: scheduleObj.days,
    });

    promise
      .then(r => {
        console.log('success');
        this.props.addSuccessNotification('Schedule created');
      })
      .catch(e => {
        console.log('error');
        this.props.addDangerNotification('Unable to create schedule!');
      });

    return promise;
  };

  handleAvailability = item => {
    if (item.isDisabled) {
      this.enableItem(item);
    } else {
      this.disableItem(item);
    }
  };

  handleClearDuration = item => {
    axios
      .post(`/api/playlists/${this.props.match.params.id}/media/${item.id}`, {
        duration: 0,
      })
      .then(r => {
        this.getPlaylist();
      })
      .catch(e => {
        console.log('unable to change duration: ');
      });
  };

  changePosition = (from: number, to: number) => {
    if (from !== to) {
      // from and to are based on 1, internal indices are 0 based
      this.onSortEnd({ oldIndex: from - 1, newIndex: to - 1 });
    }
  };

  handleSaveDurationChange = (
    item: PlaylistDetailsMediaItem,
    duration: number
  ) => {
    axios
      .post(`/api/playlists/${this.props.match.params.id}/media/${item.id}`, {
        duration,
      })
      .then(() => this.getPlaylist())
      .catch(() => {
        console.log('unable to change duration: ');
      });
  };

  enableItem = item => {
    axios
      .post(
        `/api/playlists/${this.props.match.params.id}/media/${item.id}/enable`
      )
      .then(res => {
        this.getPlaylist();
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: err.response });
        this.props.addDangerNotification(
          'There was an error, please try refreshing the page'
        );
      });
  };

  disableItem = item => {
    axios
      .post(
        `/api/playlists/${this.props.match.params.id}/media/${item.id}/disable`
      )
      .then(res => {
        this.getPlaylist();
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: err.response });
        this.props.addDangerNotification(
          'There was an error, please try refreshing the page'
        );
      });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    const { playlist } = this.state;
    const items = arrayMove(this.state.items, oldIndex, newIndex).map(
      (item, i) => ({
        ...item,
        order: i + 1,
      })
    );

    this.updateOrder(items);

    this.setState({
      items,
      playlist: { ...playlist, items },
    });
  };

  updateOrder = items =>
    axios
      .put(`/api/playlists/${this.state.playlist.id}/order`, { items })
      .then(() => {
        this.props.addSuccessNotification('Order changed');
      })
      .catch(e => {
        this.props.addDangerNotification(
          'Unable to change order, please try again'
        );
        return Promise.reject(e);
      });

  handleAddMediaItem = () => {
    this.setState({ showAddMediaItemDialog: true }, () => {
      $('#addMediaItemDialog').modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('#addMediaItemDialog').on('hidden.bs.modal', () => {
        this.setState({ showAddMediaItemDialog: false });
      });
    });
  };

  handleAddMediaItemDialogClose = () => {
    this.setState({ showAddMediaItemDialog: false });
  };

  handleImageUploaded = mediaItem => {
    axios
      .post(`/api/playlists/${this.state.playlist.id}/media`, {
        mediaItemId: mediaItem.id,
      })
      .then(r => {
        this.props.addSuccessNotification('Item added');
        this.getPlaylist();
      })
      .catch(e => {
        console.dir(e);
        this.props.addDangerNotification(
          'Unable to add playlist, please try again'
        );
      });
  };

  set = e => {
    const temp: any = {
      [e.target.name]:
        e.target.type === 'number'
          ? Number(e.target.value)
          : e.target.type === 'checkbox'
          ? e.target.checked
          : e.target.value,
    };
    this.setState(temp);
  };

  handleSave = e => {
    e.preventDefault();

    axios
      .put(`/api/playlists/${this.state.playlist.id}`, {
        meta: JSON.stringify({
          // FIXME: save meta in state and use Object.assign for other properties
          transition: this.state.transition,
          duration: this.state.duration,
        }),
        orientation: this.state.orientation,
      })
      .then(r => {
        this.props.addSuccessNotification('Playlist changes saved');
        this.getPlaylist();
      })
      .catch(e => {
        console.log('Unable to save changes');
      });
  };

  render() {
    if (!this.state.playlist.items) return null;

    return (
      <div className="container">
        <div className="playlists-top-options clearfix">
          <div className="col-xs-12">
            <div className="pull-right" style={{ marginTop: '20px' }}>
              <button
                className="pull-right btn btn-fancy"
                style={{ marginLeft: '12px' }}
                // disabled={true}
                onClick={this.handleSave}
              >
                <i className="fa fa-save" /> Save
              </button>
              <button
                className="pull-right btn btn-fancy"
                style={{ marginLeft: '12px' }}
                data-toggle="modal"
                data-target="#createSchedule"
              >
                Schedule
              </button>
            </div>

            <h3 className="playlist-name">{this.state.playlist.name}</h3>
          </div>
        </div>

        <CreateSchedule
          name="createSchedule"
          handleAction={this.handleCreateSchedule}
          addInfoNotification={this.props.addInfoNotification}
          addDangerNotification={this.props.addDangerNotification}
        />

        {this.state.playlist.items.length > 0 ? (
          <div className="col-xs-12">
            <div className="table-shadow">
              <div className="playlist-info-cell">
                <label htmlFor="orientation" className="subtle-label">
                  Canvas orientation
                </label>
                <Select
                  name="orientation"
                  className="form-control"
                  value={this.state.orientation}
                  values={PlaylistOrientation}
                  onChange={this.set}
                />
              </div>

              <div className="playlist-info-cell">
                <label htmlFor="transition" className="subtle-label">
                  Default transition
                </label>
                <Select
                  name="transition"
                  className="form-control"
                  value={this.state.transition}
                  values={PlaylistTransitions}
                  onChange={this.set}
                />
              </div>

              <div className="playlist-info-cell">
                <label htmlFor="duration" className="subtle-label">
                  Default duration
                </label>
                <Select
                  name="duration"
                  className="form-control"
                  value={this.state.duration}
                  values={Durations}
                  onChange={this.set}
                />
              </div>

              <div className="playlist-info-cell">
                <label className="subtle-label">Duration</label>
                <span className="value">
                  {formatDuration(this.state.playlist.duration)}
                </span>
              </div>

              <div className="playlist-info-cell">
                <label className="subtle-label">Last updated on</label>
                <span className="value">
                  {moment(this.state.playlist.modifiedOn).fromNow()}
                </span>
              </div>

              <div className="clearfix" />
            </div>
          </div>
        ) : null}

        <div className="clearfix" />
        <br />
        <div className="col-md-12">
          <button
            className="pull-right btn btn-transparent"
            style={{ marginLeft: '12px', marginBottom: '12px' }}
            onClick={this.handleAddMediaItem}
          >
            + Add media item
          </button>
          {this.state.playlist.items.length === 0 ? (
            <div className="center-box">
              <br />
              <br />
              Playlist is empty
            </div>
          ) : (
            <SortableList
              items={this.state.items}
              handleDelete={this.handleRemoveMediaItem}
              handleAvailability={this.handleAvailability}
              handleSaveDurationChange={this.handleSaveDurationChange}
              handleClearDuration={this.handleClearDuration}
              onChangePosition={this.changePosition}
              onSortEnd={this.onSortEnd}
              useDragHandle={true}
              distance={10}
              helperClass="sorting-row"
              lockToContainerEdges={true}
            />
          )}
        </div>

        {this.state.schedules.length > 0 ? (
          <div className="col-md-12">
            <h2 className="text-3xl">Scheduled on channels</h2>
            <section className="table-shadow px-8 py-4">
              <ul className="flex flex-row flex-wrap items-center mb-0">
                {this.state.schedules.map((schedule, index) => {
                  return (
                    <li
                      key={index}
                      className="px-4 py-2 border rounded-lg cursor-pointer text-[#2d7e9a] border-[#2d7e9a]"
                    >
                      <Link
                        to={`/digitalsignage/channels/${schedule.channelId}`}
                      >
                        {schedule.channelName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </section>
          </div>
        ) : null}

        {this.state.playlist.devices.length > 0 ? (
          <div className="col-md-12">
            <h2 className="text-3xl">Used by the following devices</h2>
            <section className="table-shadow px-8 py-4">
              <ul className="flex flex-row flex-wrap items-center mb-0 gap-4">
                {this.state.playlist.devices
                  .filter(device => {
                    return moment
                      .utc(device.lastHeartbeat)
                      .isAfter(moment(new Date()).add(-1, 'd'));
                  })
                  .map((device, index) => {
                    return (
                      <li
                        key={index}
                        className="px-4 py-2 border rounded-lg text-[#2d7e9a] border-[#2d7e9a]"
                      >
                        {this.props.user.isAdministrator ? (
                          <Link
                            to={`/digitalsignage/screens/${device.deviceId}/log`}
                            title={`${moment
                              .utc(device.lastHeartbeat)
                              .fromNow()}`}
                          >
                            {device.deviceName}
                          </Link>
                        ) : (
                          <span
                            title={`${moment
                              .utc(device.lastHeartbeat)
                              .fromNow()}`}
                          >
                            {device.deviceName}
                          </span>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </section>
          </div>
        ) : null}

        <div style={{ height: '72px' }} />

        {this.state.showAddMediaItemDialog ? (
          <MediaItemDialog
            name="addMediaItemDialog"
            orientation={this.state.orientation}
            handleClose={this.handleAddMediaItemDialogClose}
            handleImageUploaded={this.handleImageUploaded}
          />
        ) : null}

        {this.state.isLoading ? (
          <Loading />
        ) : this.state.error ? (
          <ApiError error={this.state.error} />
        ) : null}
      </div>
    );
  }
}

export default connect(null, {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
})(function (props) {
  const user = useUser();
  return <Playlist {...props} user={user} />;
});
