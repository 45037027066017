import React, { Component } from 'react';
import Dropzone, { ImageFile } from 'react-dropzone';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';

import { Loading } from 'Components/shared';
import { Pagination, Button, Checkbox, MultiProgress } from 'ui';

import 'react-image-crop/dist/ReactCrop.css';
import {
  getAccountImages,
  GalleryImageDto,
  BaseListDTO,
  CropProperties,
  setExistingImageForPage,
  uploadPageImage,
  ImageTarget,
  UploadedImageModel,
} from 'api';

interface ImageProps {
  image: GalleryImageDto;
  onClick: (image: GalleryImageDto) => any;
}

class Image extends React.Component<ImageProps, {}> {
  handleClick = () => {
    this.props.onClick(this.props.image);
  };

  render() {
    const { image } = this.props;
    return (
      <div
        className="pull-left card-bg addmediathumb"
        onClick={this.handleClick}
      >
        <div
          style={{
            height: '60px',
            backgroundImage: `url(${image.thumbUri})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        />
        <span className="subtle-label" style={{ display: 'block' }}>
          {image.width}x{image.height}
        </span>
        <p style={{ textAlign: 'center' }}>{image.name}</p>
      </div>
    );
  }
}

class State {
  isFetching = false;
  images: GalleryImageDto[] = [];
  image: any;
  info: any;
  data = new BaseListDTO<GalleryImageDto>();

  isWorking: boolean = false;
  isUploading: boolean = false;

  // for searching
  query: string = '';
  hideSmaller: boolean = false;
  showInvalidAspect: boolean = false;

  // when image is picked
  imagesToUpload: ImageFile[] = [];
  uploadProgress: number[] = [];

  imagePicked: any;
  imagePreview?: string;
  showCrop: boolean = false;
  crop: CropProperties | null = new CropProperties();
  pixelCrop = {};
}

interface Props {
  name: string;
  mustCropImage: boolean;
  targetWidth: number;
  targetHeight: number;
  backgroundType: string;
  target: ImageTarget;
  pageId: number;
  handleClose: () => any;
  handleCreate: (data: any) => any;
}

class ImagePicker extends Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    mustCropImage: true,
    targetWidth: 0,
    targetHeight: 0,
  };

  state = new State();

  loadPage = (page: number) => {
    this.setState({ isFetching: true });

    getAccountImages(page, this.state.query)
      .then((data) => {
        this.setState({ data, isFetching: false, images: data.items });
      })
      .catch(() => this.setState({ isFetching: false }));
  };

  componentDidMount() {
    this.loadPage(1);

    $('#imagePickerTabs a').click((e: any) => {
      e.preventDefault();
      $(this).tab('show');
    });

    if (this.props.mustCropImage) {
      this.setState({
        info: `${this.props.targetWidth}x${this.props.targetHeight}`,
      });
    }
  }

  initialCrop = (width, height) => {
    const aspect = this.props.targetWidth / this.props.targetHeight;
    const s = Math.min(
      width / this.props.targetWidth,
      height / this.props.targetHeight
    );
    return {
      aspect,
      x: 0,
      y: 0,
      width: (100 * this.props.targetWidth * s) / width,
      height: (100 * this.props.targetHeight * s) / height,
    };
  };

  handlePickImage = (image: GalleryImageDto) => {
    let uri = image.thumbUri;

    try {
      const meta = JSON.parse(image.meta);
      uri = meta && meta.uri ? meta.uri : uri;
    } catch (e) {}

    if (this.props.mustCropImage) {
      this.setState({
        imagePicked: image,
        imagePreview: uri,
        showCrop: true,
        crop: this.initialCrop(image.width, image.height),
      });
    } else {
      this.setState(
        {
          imagePicked: image,
          imagePreview: uri,
          crop: null,
        },
        () => this.handleCreate()
      );
    }
  };

  handleUpload = (accepted, rejected) => {
    if (this.props.mustCropImage) {
      if (accepted.length !== 1 || rejected.length !== 0) {
        alert('Upload just one image!');
        return;
      }

      this.setState({
        imagesToUpload: accepted,
        imagePreview: accepted[0].preview,
        showCrop: true,
        crop: this.initialCrop(accepted[0].width, accepted[0].height),
      });
    } else {
      this.setState(
        {
          imagesToUpload: accepted,
          imagePreview: accepted[0].preview,
          crop: null,
        },
        () => this.handleCreate()
      );
    }
  };

  handleCropChange = (crop) => this.setState({ crop });
  handleCropComplete = (crop, pixelCrop) => this.setState({ crop, pixelCrop });

  handleCancel = () => {
    if (this.state.showCrop) {
      this.setState({
        image: null,
        imagePreview: undefined,
        showCrop: false,
      });
    } else {
      if (this.props.handleClose) this.props.handleClose();
      $(`#${this.props.name}`).modal('hide');
    }
  };

  handleCreate = async () => {
    const { imagesToUpload } = this.state;

    if (this.state.imagePicked) {
      this.setState({ isWorking: true });

      // this means user has picked an existing image, use that one!
      setExistingImageForPage(
        this.props.pageId,
        this.state.imagePicked.id,
        this.state.crop,
        3
      )
        .then((uploadedImage) => {
          const { uri } = uploadedImage;
          this.setState({ isWorking: false });
          $(`#${this.props.name}`).modal('hide');

          this.props.handleCreate({
            ...uploadedImage,
            type: this.props.backgroundType,
          });
        })
        .catch((e) => {
          alert('Unable to set image');
          this.setState({ isWorking: false });
        });
    } else if (imagesToUpload.length > 0) {
      this.setState({
        isUploading: true,
        uploadProgress: imagesToUpload.map((i) => 0),
      });
      for (let i = 0; i < imagesToUpload.length; ++i) {
        await this.uploadImage(i);
      }

      this.setState({ isUploading: false });
      $(`#${this.props.name}`).modal('hide');
    }
  };

  uploadImage = async (index: number) => {
    // now upload image
    try {
      const uploadedImage = await uploadPageImage(
        this.props.pageId,
        window['userState'].accountId,
        this.state.imagesToUpload[index],
        this.props.target,
        this.state.crop,
        (percentCompleted) =>
          this.setState((s) => ({
            uploadProgress: s.uploadProgress.map((p, i) =>
              i === index ? percentCompleted : p
            ),
          }))
      );

      const { uri } = uploadedImage;

      this.props.handleCreate({
        ...uploadedImage,
        type: this.props.backgroundType,
      });
    } catch (e) {
      console.error(`Unable to upload image ${index}`);
    }
  };

  handleQueryChange = (e: any) =>
    this.setState({ query: e.target.value }, () => this.loadPage(1));
  toggleInvalidAspect = (e) =>
    this.setState({ showInvalidAspect: e.target.checked });
  handleImageLoaded = (image) =>
    this.setState({ crop: this.initialCrop(image.width, image.height) });

  render() {
    const images = this.props.mustCropImage
      ? this.state.images
          .filter((image) => {
            if (!this.state.hideSmaller) return true;

            // remove smaller in either dimension
            if (
              image.width < this.props.targetWidth ||
              image.height < this.props.targetHeight
            ) {
              return false;
            }

            return true;
          })
          .filter((image) => {
            const imageAspect = image.width / image.height;
            const aspect = this.props.targetWidth / this.props.targetHeight;
            console.log(`${imageAspect} - ${aspect}`);
            return (
              this.state.showInvalidAspect ||
              Math.abs(imageAspect - aspect) < 0.1
            );
          })
      : this.state.images;

    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Image picker{' '}
                <small>
                  ({this.state.info}, {this.props.backgroundType})
                </small>
              </h4>
            </div>

            <div className="modal-body">
              {this.state.isWorking ? (
                <Loading />
              ) : this.state.isUploading ? (
                <div className="col-xs-12">
                  <h5>Uploading...</h5>
                  <MultiProgress values={this.state.uploadProgress} />
                  {/* <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={this.state.uploadProgress}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: `${this.state.uploadProgress}%` }}>
                      {this.state.uploadProgress}%
                    </div>
                  </div> */}
                </div>
              ) : (
                <>
                  <ul
                    id="imagePickerTabs"
                    className={
                      'nav nav-tabs ' + (this.state.showCrop ? 'hide' : '')
                    }
                    role="tablist"
                  >
                    <li role="presentation" className="active">
                      <a
                        href="#imagepicker-upload"
                        aria-controls="style"
                        role="tab"
                        data-toggle="tab"
                      >
                        Upload
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        href="#imagepicker-gallery"
                        aria-controls="style"
                        role="tab"
                        data-toggle="tab"
                      >
                        Gallery
                      </a>
                    </li>
                  </ul>

                  <div
                    className={
                      'tab-content ' + (this.state.showCrop ? 'hide' : '')
                    }
                    id={this.props.name + 'Form'}
                    style={{ maxWidth: 'inherit' }}
                  >
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="imagepicker-upload"
                    >
                      <h5 className="help-block">Upload new image:</h5>
                      <Dropzone
                        className="upload"
                        activeClassName="upload-accept"
                        rejectClassName="upload-reject"
                        accept="image/*"
                        onDrop={this.handleUpload}
                      >
                        <span>+</span>
                      </Dropzone>
                      <div className="clearfix" />
                    </div>

                    <div
                      role="tabpanel"
                      className="tab-pane"
                      id="imagepicker-gallery"
                    >
                      <div className="col-lg-6">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Filter by name..."
                            name="query"
                            value={this.state.query}
                            onChange={this.handleQueryChange}
                          />
                          <span className="input-group-btn">
                            <button className="btn btn-default" type="button">
                              Go!
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="clearfix" />
                      <br />
                      {images.map((image, index) => (
                        <Image
                          key={index}
                          image={image}
                          onClick={this.handlePickImage}
                        />
                      ))}
                      {images.length === 0 ? (
                        <span className="text-info">
                          No images match your criteria
                        </span>
                      ) : null}

                      {this.state.isFetching ? <Loading /> : null}

                      <div className="clearfix" />

                      <Pagination
                        page={this.state.data.page}
                        pageCount={this.state.data.pageCount}
                        onPage={this.loadPage}
                      />

                      <div className="clearfix" />
                    </div>
                  </div>

                  <div className={this.state.showCrop ? '' : 'hide'}>
                    {this.state.imagePreview ? (
                      <ReactCrop
                        src={this.state.imagePreview}
                        crop={this.state.crop}
                        onChange={this.handleCropChange}
                        onComplete={this.handleCropComplete}
                        onImageLoaded={this.handleImageLoaded}
                      />
                    ) : null}
                  </div>
                </>
              )}
            </div>

            <div className="modal-footer">
              {this.props.mustCropImage && (
                <div className="pull-left">
                  <Checkbox
                    name="showInvalidAspect"
                    checked={this.state.showInvalidAspect}
                    onChange={this.toggleInvalidAspect}
                  >
                    Show invalid aspect
                  </Checkbox>
                </div>
              )}

              <Button onClick={this.handleCancel}>Cancel</Button>

              <Button
                onClick={this.handleCreate}
                disabled={!this.state.showCrop}
              >
                Set
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImagePicker;
