import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'Components/shared';

const SketchPicker = Loadable({
  loader: () => import(/* webpackChunkName: "sketch-picker" */ 'react-color'),
  loading: () => <Loading />,
  render(loaded, props) {
    const Component = loaded.SketchPicker;
    return <Component {...props} />;
  },
});

// TODO: use colors defined in account
// bellevueColors
const defaultColors = [
  {
    color: 'rgb(62,42,32)',
    title: 'Bellevue smeđa',
  },
  {
    color: 'rgb(201,175,137)',
    title: 'Bellevue Svijetlo smeđa',
  },
  {
    color: 'rgb(35,31,32)',
    title: 'Bellevue Crna',
  },
  {
    color: 'rgb(181,183,183)',
    title: 'Bellevue Srebrna',
  },
];

export default class ColorPicker extends React.Component<any, any> {
  state: any = {
    displayColorPicker: false,
  };

  componentDidMount() {
    this.setState({
      color: this.props.currentVal,
    });
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.currentVal !== this.props.currentVal ||
      newProps.currentVal !== this.state.color
    ) {
      this.setState({
        color: newProps.currentVal,
      });
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color, event) => {
    this.setState({ color: color.hex });
    this.props.handleChange(this.state.color, event);
  };

  handleChangeComplete = (color, event) => {
    if (this.props.handleChangeComplete) {
      this.props.handleChangeComplete(color, event);
    }
  };

  render() {
    const style = {
      ...styles.color,
      backgroundColor: this.state.color,
    };

    return (
      <div style={styles.holderDiv} className={this.props.className}>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={style} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
              onChangeComplete={this.handleChangeComplete}
              presetColors={this.props.colors || defaultColors}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = {
  color: {
    height: '12px',
    borderRadius: '2px',
    background: '#333',
  },
  swatch: {
    padding: '3px',
    background: '#f5f5f5',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute' as 'absolute',
    zIndex: 2,
  },
  cover: {
    position: 'fixed' as 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  holderDiv: {
    display: 'inline-block',
    width: '70px',
    verticalAlign: 'middle',
    borderRadius: '2px',
    height: '20px',
    marginRight: '30px',
    boxShadow: 'inset 0 0 3px 0 rgba(0,0,0,0.18)',
    border: '1px solid #cecece',
  },
};
