import * as React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = ({ location }) => (
  <div className="container text-center">
    <h1>:(</h1>
    <h2>
      No page under <code>{location.pathname}</code> found.
    </h2>

    <Link to="/">Go back</Link>
  </div>
);
