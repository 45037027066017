import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loading, FeatureFlag } from 'Components/shared';
import { formatAppType, formatPlatforms } from '../../enums';
import { isoLangs } from '../../languages';
import * as Actions from './actions';
import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';
import { Button } from 'ui';
import { deleteApp, updateApp } from 'api';

import AppLanguages from './settings/AppLanguages';
import AppForms from './settings/AppForms';

class Settings extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      language: '',
      languages: [],

      data: {
        name: '',
        forwardPaymentEmails: '',
        thumbnail: '',
        languages: '',
        id: 0,
        appType: 0,
        platforms: 0,
        isPublished: false,
        alias: '',
      },
      meta: {},
    };
  }

  componentDidMount() {
    if (this.props.app.isLoaded) this.loadFromData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.app.isLoaded) this.loadFromData(nextProps);
  }

  loadFromData = props => {
    const { app, meta, languages } = props;
    if (!meta.cms_version) meta.cms_version = 2;

    this.setState({
      meta,
      languages,
      data: app ? app.data : {},
    });
  };

  handleChange = e => {
    if (e.target.name.indexOf('-') === -1)
      this.setState({ [e.target.name]: e.target.value });
    else {
      const name = e.target.name;
      this.setState({
        data: {
          ...this.state.data,
          [name.split('-')[1]]: e.target.value,
        },
      });
    }
  };

  handleSave = e => {
    e.preventDefault();

    this.setState({ isSaving: true });

    updateApp(this.state.data.id, {
      name: this.state.data.name,
      forwardPaymentEmails: this.state.data.forwardPaymentEmails,
      thumbnail: this.state.data.thumbnail,
      rawData: {
        name: this.state.data.name,
        forwardPaymentEmails: this.state.data.forwardPaymentEmails,
        thumbnail: this.state.data.thumbnail,
        appType: this.state.data.appType,
        platforms: this.state.data.platforms,
        languages: this.props.languages,
        theme: this.state.data.theme,
      },
    })
      .then(r => {
        this.setState({ isSaving: false });
        this.props.addSuccessNotification('Changes saved');
      })
      .catch(e => {
        this.setState({ isSaving: false });
        this.props.addDangerNotification(
          'Error saving changes, please try again'
        );
      });
  };

  handleAddLanguage = e => {
    e.preventDefault();
    const lang = isoLangs.hasOwnProperty(this.state.language)
      ? isoLangs[this.state.language]
      : null;
    if (lang) {
      lang.shortCode = this.state.language;
      lang.isDefault = false;
      lang.isEnabled = false;
      this.props.addLanguage(lang);
      this.setState({ language: '' });
    }
  };

  handleRemoveLanguage = (e, lang) => {
    e.preventDefault();
    this.props.removeLanguage(lang);
  };

  render() {
    const { languages } = this.props;
    const allLanguages: any[] = [];
    for (let i in isoLangs) {
      if (
        isoLangs.hasOwnProperty(i) &&
        !languages.find(l => l.shortCode == i)
      ) {
        allLanguages.push({
          code: i,
          name: isoLangs[i].name,
          nativeName: isoLangs[i].nativeName,
        });
      }
    }

    return (
      <div className="container">
        <div className="pull-right">
          <br />
          <input
            type="submit"
            value="Save"
            className="btn btn-fancy"
            onClick={this.handleSave}
          />
        </div>

        <h2>Settings</h2>

        <div>
          <h5 className="edit-block-title">Info</h5>

          <div className="row">
            <div className="form-group col-xs-4">
              <label htmlFor="name" className="control-Label subtle-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                value={this.state.data.name}
                name="data-name"
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group col-xs-4">
              <label htmlFor="name" className="control-Label subtle-label">
                Alias
              </label>
              <input
                type="text"
                className="form-control"
                value={this.state.data.alias}
                name="alias"
                readOnly={true}
              />
              {window['features'].dev ? (
                <div>
                  <a
                    target="_blank"
                    href={`/api/cache/${this.state.data.alias}`}
                  >
                    newest cache
                  </a>
                  <br />
                  <a
                    target="_blank"
                    href={`/api/cache/draft/${this.state.data.alias}`}
                  >
                    draft cache
                  </a>
                </div>
              ) : null}
            </div>

            <div className="col-xs-4">
              <label htmlFor="" className="control-Label subtle-label">
                Application type
              </label>
              <input
                type="text"
                className="form-control"
                value={formatAppType(this.state.data.appType)}
                readOnly
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-xs-4">
              <label
                htmlFor="forwardPaymentEmails"
                className="control-Label subtle-label"
              >
                Forward payment emails
              </label>
              <input
                type="text"
                className="form-control"
                value={this.state.data.forwardPaymentEmails || ''}
                name="data-forwardPaymentEmails"
                placeholder="Split emails with ; e.g. me@mail.com;you@mail.com"
                onChange={this.handleChange}
                data-toggle="tooltip"
                title="Split emails with ; e.g. me@mail.com;you@mail.com"
              />
            </div>

            <div className="form-group col-xs-4">
              <label className="control-Label subtle-label">Thumbnail</label>
              <img
                className="uploaded-thumb img-thumbnail"
                src={this.state.data.thumbnail}
                alt="App thumbnail"
              />
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={this.state.data.thumbnail}
              />
            </div>

            <div className="col-xs-4">
              <label htmlFor="" className="control-Label subtle-label">
                Platforms
              </label>
              <input
                type="text"
                className="form-control"
                value={formatPlatforms(this.state.data.platforms)}
                readOnly
              />
            </div>
          </div>

          <FeatureFlag feature="mobileapps-languages">
            <>
              <AppLanguages
                languages={this.props.languages}
                language={this.state.language}
                handleRemoveLanguage={this.handleRemoveLanguage}
                handleEnableLanguage={(e, language) => {
                  e.preventDefault();
                  this.props.enableLanguage(language);
                }}
                handleDisableLanguage={(e, language) => {
                  e.preventDefault();
                  this.props.disableLanguage(language);
                }}
              />

              {allLanguages.length > 0 ? (
                <div className="form-group">
                  <div className="pull-right form-inline">
                    <select
                      name="language"
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value="">Select a language to add...</option>
                      {allLanguages.map((language, index) => {
                        return (
                          <option key={index} value={language.code}>
                            {language.name}
                          </option>
                        );
                      })}
                    </select>
                    &nbsp;
                    <button
                      className="btn-transparent"
                      disabled={!this.state.language}
                      onClick={this.handleAddLanguage}
                    >
                      + Add language
                    </button>
                  </div>
                </div>
              ) : null}
            </>
          </FeatureFlag>

          <div className="clearfix" />

          <AppForms
            forms={this.props.forms}
            meta={this.props.meta}
            enableForm={this.props.enableForm}
            disableForm={this.props.disableForm}
          />

          <hr />

          <Button type="danger" onClick={this.handleDeleteApp}>
            Delete app
          </Button>
          <div style={{ height: '100px' }} />
        </div>

        {this.state.isSaving ? <Loading /> : null}
      </div>
    );
  }

  handleDeleteApp = () => {
    if (confirm('Are you sure you want to delete this app?')) {
      deleteApp(parseInt(this.props.match.params.id))
        .then(() => this.props.history.push('/apps'))
        .catch(() => {
          alert('Unable to delete app. Please contact support.');
        });
    }
  };
}

export default connect(
  state => ({
    app: state.App,
    meta: state.App.meta,
    languages: state.App.languages,
    forms: state.App.forms,
  }),
  {
    addInfoNotification,
    addSuccessNotification,
    addDangerNotification,
    addLanguage: Actions.addLanguage,
    removeLanguage: Actions.removeLanguage,
    enableLanguage: Actions.enableLanguage,
    disableLanguage: Actions.disableLanguage,
    enableForm: Actions.enableForm,
    disableForm: Actions.disableForm,
  }
)(Settings);
