import React, { ReactNode, SFC } from 'react';

import { isoLangs } from '../../languages';

import LocalizeTitle from './Localization/LocalizeTitle';
import LocalizeText from './Localization/LocalizeText';
import { ContentBlock, BlockType, LocalizedString } from './contentHelpers';

interface Props {
  content: ContentBlock[];
  languages: string[];
  setContent: (data: any) => any;
}

interface PageTranslationBlockProps {
  data: ContentBlock;
  setContent: (data: any) => any;
  languages: string[];
}

class PageTranslationBlock extends React.Component<
  PageTranslationBlockProps,
  {}
> {
  setText = (text: LocalizedString[]) =>
    this.props.setContent({ ...this.props.data, text });

  render() {
    const { data, languages } = this.props;
    switch (data.type) {
      case BlockType.title:
        return (
          <LocalizeTitle
            title="Title"
            text={data.text}
            languages={languages}
            setText={this.setText}
          />
        );

      case BlockType.text:
        return (
          <LocalizeText
            title="Description"
            text={data.text}
            languages={languages}
            setText={this.setText}
          />
        );

      case BlockType.button:
        return (
          <LocalizeTitle
            title="Button"
            text={data.text}
            languages={languages}
            setText={this.setText}
          />
        );

      case BlockType.youtube:
        return (
          <LocalizeTitle
            title="Youtube link"
            text={data.text}
            languages={languages}
            setText={this.setText}
          />
        );

      case BlockType.alfredPayment1:
        /* tslint:disable */
        return (
          <>
            <LocalizeTitle
              title="Title"
              text={data.title}
              languages={languages}
              setText={text =>
                this.props.setContent({ ...this.props.data, title: text })
              }
            />

            <LocalizeText
              title="Description"
              text={data.text}
              languages={languages}
              setText={text =>
                this.props.setContent({ ...this.props.data, text: text })
              }
            />

            <LocalizeTitle
              title="Title"
              text={data.buttonTitle}
              languages={languages}
              setText={text =>
                this.props.setContent({ ...this.props.data, buttonTitle: text })
              }
            />
          </>
        );
      /* tslint:enable */
    }

    return null;
  }
}

const PageTranslation: SFC<Props> = ({ content, languages, setContent }) => {
  if ((languages || []).length < 1) return <>'Missing languages...'</>;

  return (
    <>
      {(content || []).map((data, index) => (
        <PageTranslationBlock
          key={data.id}
          data={data}
          setContent={setContent}
          languages={languages}
        />
      ))}
    </>
  );
};

export default PageTranslation;
