import {
  addDeviceCommand,
  DeviceForAccount,
  DevicesForAccount,
  removeDevice,
} from 'api';
import { Loading } from 'Components/shared';
import moment from 'moment';
import React, { useMemo } from 'react';
import Tooltip from 'react-tooltip-lite';
import useSWR from 'swr';
import { Button, Container, useToast } from 'ui';
import { useStateLegacy, useUser } from 'utilities';

import { useAdminVar } from '../../../utilities/useAdminVar';
import { DeviceStats } from './DeviceStats';
import { Screen } from './Screen';
import { ToggleFilterButton } from './toggle-filter-button';

type FacetLastSeen = 'all' | 'red' | 'yellow';

class State {
  showOldDevices = false;
  filterVersion: string | undefined = undefined;
  filterLastSeen: FacetLastSeen = 'all';
}

const LastSeenFacets: { key: FacetLastSeen; label: string }[] = [
  { key: 'all', label: 'All devices' },
  { key: 'yellow', label: 'More than an hour' },
  { key: 'red', label: 'More than a week ago' },
];

function Screens() {
  const [state, setState] = useStateLegacy(new State());
  const { success, danger } = useToast();

  const { accountId } = useUser();
  const { data, revalidate } = useSWR<DevicesForAccount>(
    `/api/dashboard/${accountId}`,
    {
      refreshInterval: 5000,
      onSuccess: () => {
        success('Screens loaded');
      },
      onError: () => {
        danger('Unable to load screens, please refresh the page');
      },
    }
  );
  const { value: version } = useAdminVar('digital-signage-android-version');

  const handleClickDelete = (deviceId: number) => {
    if (!confirm('Are you sure you want to delete device?')) return;

    removeDevice(deviceId)
      .then(() => {
        revalidate();
        success('Screen deleted');
      })
      .catch(() => {
        danger('Unable to delete a screen, please refresh the page');
      });
  };

  const addPingCommand = (deviceId: number) => sendCommand(deviceId, 'ping');
  const addCrashCommand = (deviceId: number) =>
    sendCommand(deviceId, '__crash');
  const addRestartCommand = (deviceId: number) =>
    sendCommand(deviceId, 'restart');
  const addUpdateCommand = (deviceId: number) =>
    sendCommand(deviceId, 'update');

  const renameDevice = (deviceId: number, deviceName: string) => {
    sendCommand(deviceId, `rename ${deviceName}`);
  };

  const sendCommand = (
    deviceId: number,
    command: string,
    isQuery: boolean = false
  ) => {
    addDeviceCommand(deviceId, command, isQuery)
      .then(() => success('Command sent'))
      .catch(() => danger('Unable to send notification'));
  };

  function toggleOldDevices() {
    setState(({ showOldDevices }) => ({
      showOldDevices: !showOldDevices,
    }));
  }

  const screens = data ? data.devices : [];

  const endTime = moment();
  const filtered = screens
    .filter((screen) => {
      if (!state.filterVersion) {
        return true;
      }
      return screen.playerVersion == state.filterVersion;
    })
    .filter((screen) => {
      if (state.filterLastSeen === 'red') {
        const hours = endTime.diff(screen.lastHeartbeat, 'hours');
        return hours > 24;
      }

      if (state.filterLastSeen === 'yellow') {
        const hours = endTime.diff(screen.lastHeartbeat, 'hours');
        return hours > 1 && hours <= 24;
      }

      return true;
    });

  const isLoading = !data;
  const { showOldDevices } = state;

  if (!screens || !screens.length) {
    if (!isLoading) {
      return <h3>No screens have been registered for this account yet.</h3>;
    }

    return <Loading />;
  }

  // filter out too old devices
  // probably not used anymore
  const aWeekAgo = moment().add(-7, 'days');

  const oldScreens = filtered.filter(
    (s) => moment(s.lastHeartbeat) <= aWeekAgo
  );
  const groups = filtered
    .filter((s) => moment(s.lastHeartbeat) > aWeekAgo)
    .reduce(
      (groups: { name: string; screens: DeviceForAccount[] }[], screen) => {
        const name = screen.channelGroupName || 'Other';
        const group = groups.find((g) => g.name === name);
        if (!group) {
          groups.push({ name, screens: [screen] });
        } else {
          group.screens.push(screen);
        }
        return groups;
      },
      []
    );

  groups.sort((l, r) => l.name.localeCompare(r.name));

  const versions = screens
    .filter((s) => moment(s.lastHeartbeat) > aWeekAgo)
    .reduce(
      (
        versions: { version: string; count: number }[],
        screen: DeviceForAccount
      ) => {
        const version = versions.find(
          (v) => v.version === screen.playerVersion
        );
        if (!version) {
          return [
            ...versions,
            {
              version: screen.playerVersion,
              count: 1,
            },
          ];
        } else {
          return versions.map((v) => {
            if (v.version === screen.playerVersion) {
              return { ...v, count: v.count + 1 };
            }
            return v;
          });
        }
      },
      []
    );

  // this should be sorted using some kind of semantic sort
  versions.sort((l, r) => (l.version || '').localeCompare(r.version || ''));

  return (
    <>
      {/* <ul className="nav nav-tabs" role="tablist">
          <li role="presentation" className="active">
            <a
              href="#devices"
              aria-controls="home"
              role="tab"
              data-toggle="tab"
            >
              Devices
            </a>
          </li>

          <li role="presentation">
            <a href="#stats" aria-controls="home" role="tab" data-toggle="tab">
              Stats
            </a>
          </li>
        </ul> */}

      <div className="tab-content">
        <div role="tabpanel" className="tab-pane active" id="devices">
          <div className="flex flex-row items-center my-4 space-x-4">
            <span className="uppercase font-light">Versions:</span>
            {versions.map((v) => (
              <ToggleFilterButton
                key={v.version}
                active={state.filterVersion === v.version}
                onClick={() => {
                  setState(({ filterVersion: oldVersion }) => ({
                    filterVersion:
                      oldVersion === v.version ? undefined : v.version,
                  }));
                }}
              >
                {v.version} - {v.count} {v.count === 1 ? 'device' : 'devices'}
              </ToggleFilterButton>
            ))}
            <Tooltip
              content={
                <>
                  <h2 className="text-3xl mt-0">Changelog</h2>
                  <p>
                    View changelog for Android version at{' '}
                    <a
                      href="https://docs.emguest.com/digital-signage/android-player-changelog"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Android Player Changelog
                    </a>
                  </p>
                  {!!version ? (
                    <p>
                      The latest version of Android player is{' '}
                      <code>{version.value}</code>.
                    </p>
                  ) : null}
                </>
              }
            >
              <i className="fa fa-info" />
            </Tooltip>
          </div>
          <div className="flex flex-row items-center my-4 space-x-4">
            <span className="uppercase font-light">Last seen:</span>
            {LastSeenFacets.map((facet) => (
              <ToggleFilterButton
                key={facet.key}
                active={facet.key === state.filterLastSeen}
                onClick={() => {
                  setState(({ filterLastSeen: oldFilterLastSeen }) => ({
                    filterLastSeen:
                      oldFilterLastSeen === facet.key ? 'all' : facet.key,
                  }));
                }}
              >
                {facet.label}
              </ToggleFilterButton>
            ))}
          </div>

          {groups.map((group, index) => {
            return (
              <div className="clearfix" key={index}>
                <h5>{group.name === null ? 'Other' : group.name}</h5>
                {group.screens.map((screen) => {
                  return (
                    <Screen
                      key={screen.deviceId}
                      screen={screen}
                      version={version?.value ?? ''}
                      deleteScreen={handleClickDelete}
                      addPingCommand={addPingCommand}
                      addCrashCommand={addCrashCommand}
                      addRestartCommand={addRestartCommand}
                      addUpdateCommand={addUpdateCommand}
                      sendCommand={sendCommand}
                      renameDevice={renameDevice}
                    />
                  );
                })}
              </div>
            );
          })}

          {groups.length === 0 ? (
            <>No devices found for currently selected filters</>
          ) : null}
          {oldScreens.length > 0 && (
            <>
              <hr />
              <h5>{oldScreens.length} devices not reporting in last 7 days</h5>
              <Button onClick={toggleOldDevices}>
                {showOldDevices ? 'Hide devices' : 'Show devices'}
              </Button>
              <div className="clearfix" />
              {showOldDevices &&
                oldScreens.map((screen) => {
                  return (
                    <Screen
                      key={screen.deviceId}
                      screen={screen}
                      version={version.value}
                      deleteScreen={handleClickDelete}
                      addPingCommand={addPingCommand}
                      addCrashCommand={addCrashCommand}
                      addRestartCommand={addRestartCommand}
                      addUpdateCommand={addUpdateCommand}
                      sendCommand={sendCommand}
                      renameDevice={renameDevice}
                    />
                  );
                })}
            </>
          )}
        </div>
        <div role="tabpanel" className="tab-pane" id="stats">
          <DeviceStats stats={data?.stats} />
        </div>
      </div>

      {/* {state.isLoading ? <div className="line-loader" /> : null} */}
    </>
  );
}

export default function ScreensHolder() {
  return (
    <Container className="channels-holder pt-8">
      <Screens />
    </Container>
  );
}
