import * as React from 'react';
import { pagesRange } from 'utilities';

export interface PageLinkProps {
  currentPage: number;
  page: number;
  onPage: (page: number) => void;
}

export class PageLink extends React.PureComponent<PageLinkProps, {}> {
  onPage = () => this.props.onPage(this.props.page);

  render() {
    const { page, currentPage } = this.props;
    return (
      <li className={currentPage === page ? 'active' : ''}>
        <a onClick={this.onPage}>{page}</a>
      </li>
    );
  }
}

export interface PaginationProps {
  page: number;
  pageCount: number;
  onPage: (page: number) => void;
}

export class Pagination extends React.PureComponent<PaginationProps, {}> {
  previousPage = () => this.props.onPage(this.props.page - 1);
  nextPage = () => this.props.onPage(this.props.page + 1);

  render() {
    const { page, pageCount, onPage } = this.props;
    return (
      <nav className="pull-right clearfix" aria-label="Page navigation">
        <ul className="pagination pagination-sm">
          <li className={page > 1 ? '' : 'disabled'}>
            <a aria-label="Previous" onClick={this.previousPage}>
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>

          {pagesRange(1, pageCount).map((p, index) => (
            <PageLink key={index} currentPage={page} page={p} onPage={onPage} />
          ))}

          <li className={page !== pageCount ? '' : 'disabled'}>
            <a aria-label="Next" onClick={this.nextPage}>
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
