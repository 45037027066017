import React from 'react';
import ColorPicker from '../ColorPicker/ColorPicker';
import { ImagesHolder } from './ImagesHolder';
import { connect } from 'react-redux';

class CanvasProperties extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(imageObj) {
    this.props.handleChangeCanvas(imageObj);
    $('#browseImagesCanvas').modal('hide');
  }

  render() {
    let objProps = this.props.objProps;

    return (
      <div className="bottom-controls">
        <div className="single-control-holder canvas-properties">
          <label style={canvasPropsStyle.labelStyle}>Background</label>
          <ColorPicker
            handleChange={this.props.handleChangeCanvas}
            currentVal={this.props.canvasProps.bgColor}
          />
          <div className="bottom-properties clearfix">
            <div>
              <p>&nbsp;</p>
            </div>
            <div className="property-info">
              <p>Color</p>
            </div>
          </div>
        </div>

        <div className="single-control-holder canvas-properties">
          <label style={canvasPropsStyle.labelStyle}>Image</label>
          <input
            type="text"
            placeholder="Image URL"
            name="bgImage"
            value={this.props.canvasProps.bgImage}
            style={{ width: '150px' }}
            onChange={this.props.handleChangeCanvas}
          />
          <button
            className="btn btn-default btn-picker"
            data-toggle="modal"
            data-target="#browseImagesCanvas"
          >
            <span className="fa fa-file-o" />
          </button>

          <div className="bottom-properties clearfix">
            <div>
              <p>&nbsp;</p>
            </div>
            <div className="property-info" style={{ width: '140px' }}>
              Link
            </div>
          </div>
        </div>

        <div className="single-control-holder canvas-properties">
          <label style={canvasPropsStyle.labelStyle} />
          {this.props.canvasProps.bgImage ? (
            <button
              className="btn btn-warning"
              name="canvas-background-remove"
              onClick={this.props.handleClickEvent}
            >
              Remove background image
            </button>
          ) : null}
        </div>

        <div
          className="modal modal-fancy fade"
          id="browseImagesCanvas"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="browseImagesCanvasLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="browseImagesCanvasLabel">
                  Browse images
                </h4>
              </div>
              <div className="modal-body">
                {this.props.isFetchingMedia ? (
                  <p>Loading</p>
                ) : this.props.fetchedMedia !== null &&
                  this.props.fetchedMedia.length !== 0 ? (
                  <ImagesHolder
                    media={this.props.fetchedMedia}
                    changeImage={this.handleClick}
                    type="canvas"
                  />
                ) : (
                  <p>You have no media</p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-fancy"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const canvasPropsStyle = {
  labelStyle: {
    width: '90px',
  },
  selectStyle: {
    width: '150px',
  },
  firstDivStyle: {
    width: '100px',
  },
  middleDivStyle: {
    width: '150px',
  },
};

const mapStateToProps = (state) => {
  return {
    isFetchingMedia: state.Media.isFetchingMedia,
    fetchedMedia: state.Media.fetchedMedia,
    fetchingMediaError: state.Media.fetchingMediaError,
  };
};

export default connect(mapStateToProps)(CanvasProperties);
