import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { connect } from 'react-redux';

import {
  addDangerNotification,
  addInfoNotification,
} from '../../Components/NotificationActions';

type ToastsContextType = {
  success: (text: string) => any;
  danger: (text: string) => any;
};

const ToastsContext = createContext<ToastsContextType | undefined>(undefined);

interface Props {
  addInfoNotification: (text: string) => any;
  addDangerNotification: (text: string) => any;
}

function ToastProvider({
  children,
  addInfoNotification,
  addDangerNotification,
}: PropsWithChildren<Props>) {
  const value: ToastsContextType = useMemo(() => {
    return {
      success: (text: string) => addInfoNotification(text),
      danger: (text: string) => addDangerNotification(text),
    };
  }, [addInfoNotification]);

  return (
    <ToastsContext.Provider value={value}>{children}</ToastsContext.Provider>
  );
}

export function useToast() {
  const context = useContext(ToastsContext);
  if (!context) {
    throw new Error('Must be under ToastProvider');
  }

  return context;
}

export default connect(null, {
  addInfoNotification,
  addDangerNotification,
})(ToastProvider);
