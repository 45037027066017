import React from 'react';
import PresentationEditor from './PresentationEditor';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { Loading } from 'Components/shared';

export default class DigitalSignageBuilder extends React.Component<any, any> {
  handleClearCanvas = () => {};
  handleChangePresName = () => {};

  render() {
    return (
      <div style={{ height: '100%' }}>
        {false ? (
          <div className="col-md-12 clearfix name-and-save">
            <div
              className={
                'presentation-name-holder ' +
                (this.state.name ? '' : 'has-error')
              }
            >
              <input
                type="text"
                placeholder=""
                value={this.state.name}
                onChange={this.handleChangePresName}
              />
              <span
                className="glyphicon glyphicon-pencil edit-icon"
                aria-hidden="true"
              />
            </div>
          </div>
        ) : null}

        <PresentationEditor
          save={this.props.save}
          oldCanvas={this.props.oldCanvas}
          isPortrait={
            this.props.presentation.width < this.props.presentation.height
          }
          handleClearCanvas={this.handleClearCanvas}
          handleCanvasCreated={this.props.handleCanvasCreated}
          handleDurationChange={this.props.handleDurationChange}
        />

        {this.props.match.params.action === 'readonly' ? (
          <div className="readonly-overlay">
            <div className="center-box">
              <Link
                to={`/digitalsignage/builder/${this.props.match.params.id}`}
                className="btn btn-primary"
              >
                Edit presentation
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
