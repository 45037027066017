import { useEffect, useReducer, Reducer } from 'react';
import axios from 'axios';

type State<T> =
  | { state: 'initialized' }
  | { state: 'loading' }
  | { state: 'done'; data: T }
  | { state: 'error'; error: any };

type Action<T> =
  | { type: 'start' }
  | { type: 'done'; payload: T }
  | { type: 'error'; payload: any };

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case 'start':
      return {
        state: 'loading',
      };

    case 'done': {
      return { state: 'done', data: action.payload };
    }

    case 'error': {
      return { state: 'error', error: action.payload };
    }

    default:
      throw new Error(
        `Invalid action ${JSON.stringify(action)} for state ${
          state ? state.state : 'unknown state'
        }`
      );
  }
}

export function useQuery<T extends any>(url: string) {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(reducer, {
    state: 'initialized',
  });

  useEffect(
    () => {
      if (!url) {
        return;
      }

      dispatch({ type: 'start' });

      axios
        .get(url)
        .then(r => dispatch({ type: 'done', payload: r.data }))
        .catch(e => {
          dispatch({ type: 'error', payload: e });
        });
    },
    [url]
  );

  return {
    loading: state.state === 'loading',
    data: state.state === 'done' ? state.data : undefined,
    error: state.state === 'error' ? state.error : undefined,
  };
}
