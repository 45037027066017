import * as React from 'react';

import { ImageTarget } from 'api';

import ColorPicker from '../../Builder/ColorPicker/ColorPicker';
import { BackgroundType } from '../../../enums';
import ImagePicker from '../../ImagePicker';
import PageBackgroundImage from './PageBackgroundImage';

class State {
  data = {
    color: null,
    landscape: null,
    portrait: null,
  };

  themeColor: string | null = null;

  showPicker = false;
  backgroundType = 'landscape';
  targetWidth = 0;
  targetHeight = 0;
}

class PageBackground extends React.Component<any, State> {
  state = new State();

  render() {
    const data = this.state.data;
    const theme = this.props.app.theme;

    return (
      <div className="edit-block clearfix">
        <h4 className="edit-block-title">Page background</h4>

        <div className="form-group">
          <div className="col-xs-4">
            <label
              htmlFor=""
              className="control-label subtle-label"
              style={{ marginTop: '15px' }}
            >
              Solid color
            </label>
            <br />
          </div>
          {data.color ? (
            <div className="col-xs-8">
              <ColorPicker
                handleChange={this.handleColorChange}
                handleChangeComplete={this.handleColorChangeComplete}
                currentVal={data.color}
              />
              <button className="btn btn-default" onClick={this.removeColor}>
                Remove custom color
              </button>
              <br />
              <span className="help-block">
                Default app background color is{' '}
                <span
                  style={{
                    borderBottomWidth: '3px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: theme.backgroundColor,
                    textTransform: 'uppercase',
                  }}
                >
                  {theme.backgroundColor}
                </span>
              </span>
            </div>
          ) : (
            <button className="btn btn-default" onClick={this.setCustomColor}>
              Change
            </button>
          )}

          <div className="clearfix" />
        </div>

        <div className="form-group">
          <PageBackgroundImage
            appType={this.props.app.data.appType}
            background={this.props.background}
            handleSet={this.chooseBackgroundImage}
            handleRemove={this.removeBackgroundImage}
          />
          <div className="clearfix" />
        </div>

        {this.state.showPicker ? (
          <ImagePicker
            name="imagePicker"
            mustCropImage={true}
            pageId={this.props.page.id}
            backgroundType={this.state.backgroundType}
            target={ImageTarget.Background}
            targetWidth={this.state.targetWidth}
            targetHeight={this.state.targetHeight}
            handleCreate={this.handleImagePicked}
            handleClose={this.handleImagePickerClosed}
          />
        ) : null}
      </div>
    );
  }

  componentDidMount() {
    if (this.props.background) this.init(this.props.background);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.background !== this.props.background)
      this.init(nextProps.background);
  }

  set = (name, value) => {
    const newData = { ...this.state.data, [name]: value };
    this.setState({ data: newData });
  };

  handleColorChange = color => this.set('color', color);
  handleColorChangeComplete = (color, event) => {
    if (this.props.handleUpdate) {
      this.props.handleUpdate(
        Object.assign({}, this.state.data, { color: color.hex })
      );
    }
  };

  setCustomColor = e => this.set('color', '#000000');
  removeColor = e => this.set('color', null);

  init = background => {
    if (background && background.color === 'inherit') background.color = null;
    this.setState({ data: background });
  };

  chooseBackgroundImage = (e, type) => {
    let targetWidth = 0;
    let targetHeight = 0;
    let backgroundType = 'portrait';

    switch (type) {
      case BackgroundType.Landscape_GuestDirectory:
        targetWidth = 2048;
        targetHeight = 1536;
        backgroundType = 'landscape';
        break;

      case BackgroundType.Portrait_GuestDirectory:
        targetWidth = 1536;
        targetHeight = 2048;
        break;

      case BackgroundType.Portrait_Mobile:
        targetWidth = 750;
        targetHeight = 1334;
        break;
    }

    this.setState(
      {
        backgroundType,
        targetWidth,
        targetHeight,
        showPicker: true,
      },
      () => {
        $('#imagePicker').modal({
          backdrop: 'static',
          keyboard: false,
        });
      }
    );
  };

  removeBackgroundImage = (e, type) => {
    switch (type) {
      case BackgroundType.Landscape_GuestDirectory:
        this.props.handleUpdate(
          Object.assign({}, this.state.data, { landscape: null })
        );
        break;

      case BackgroundType.Portrait_GuestDirectory:
      case BackgroundType.Portrait_Mobile:
        this.props.handleUpdate(
          Object.assign({}, this.state.data, { portrait: null })
        );
        break;
    }
  };

  handleImagePicked = data => {
    this.props.handleUpdate(
      Object.assign({}, this.props.background, {
        [data.type]: data.uri,
      })
    );
    this.setState({ showPicker: false });
  };

  handleImagePickerClosed = () => {
    this.setState({ showPicker: false });
  };
}

export default PageBackground;
