enum NotificationTypes {
  danger = 'danger',
  success = 'success',
  info = 'info',
}

const addNotification = (type: NotificationTypes, text: string) => {
  return {
    type: 'ADD_NOTIFICATION',
    notification: {
      type: type,
      text: text,
    },
  };
};

const addSuccessNotification = (text: string) => {
  return addNotification(NotificationTypes.success, text);
};

const addInfoNotification = (text: string) => {
  return addNotification(NotificationTypes.info, text);
};

const addDangerNotification = (text: string) => {
  return addNotification(NotificationTypes.danger, text);
};

const hideNotification = (id: number) => {
  return {
    type: 'HIDE_NOTIFICATION',
    id: id,
  };
};

export {
  addNotification,
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
  hideNotification,
};

export type LegacyReduxNotificationProps = {
  addInfoNotification: (text: string) => any;
  addDangerNotification: (text: string) => any;
  addSuccessNotification: (text: string) => any;
};
