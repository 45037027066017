import React from 'react';

import { formatAccountUserRole } from '../../enums';

const AccountUser = ({ user }) => (
  <tr>
    <td>{user.userName}</td>
    <td>{user.email}</td>
    <td>{formatAccountUserRole(user.role)}</td>
  </tr>
);

const AccountUsers = ({ users }) => {
  if (users.length === 0) return <p>No users found for this account</p>;

  return (
    <table className="table table-striped" style={{ color: 'black' }}>
      <thead>
        <tr>
          <th>User name (login)</th>
          <th>Email</th>
          <th>Role</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <AccountUser key={user.email} user={user} />
        ))}
      </tbody>
    </table>
  );
};

export default AccountUsers;
