import React from 'react';

import ObjectActions from './ObjectActions';
import ColorPicker from '../ColorPicker/ColorPicker';
import * as CommonProperties from './CommonProperties';

export default class RectObj extends React.Component<any, any> {
  render() {
    return (
      <div className="bottom-controls">
        <ObjectActions {...this.props} />

        <div className="single-control-holder">
          <label>Fill</label>
          <ColorPicker
            handleChange={this.props.handleChangeRect}
            currentVal={this.props.rect.getFill()}
          />
          <div className="bottom-properties clearfix">
            <div>
              <p>&nbsp;</p>
            </div>
            <div>
              <p>Color</p>
            </div>
            <div>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>

        <CommonProperties.OpacityProperty {...this.props} />

        <hr />

        <CommonProperties.PositionProperties {...this.props} />
        <CommonProperties.SizeProperties {...this.props} />
        <CommonProperties.RotationProperties {...this.props} />
      </div>
    );
  }
}
