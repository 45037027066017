import { HorizontalTextAlignment } from '../../xamarin';

export enum BlockType {
  title = 'title',
  text = 'text',
  rss = 'rss',
  button = 'button',
  gallery = 'gallery',
  map = 'map',
  social = 'social',
  web = 'web',
  youtube = 'youtube',
  dataGrid = 'data-grid',
  weather = 'weather',
  alfredPayment1 = 'alfred-payment-1',
}

export interface BaseBlock {
  id: number;
}

export interface LocalizedString {
  text: string;
  lang: string;
}

export interface TitleBlockStyle {}

export interface TitleBlock extends BaseBlock {
  type: BlockType.title;
  // style: TitleBlockStyle;
  text: LocalizedString[];
}

export interface TextBlock extends BaseBlock {
  type: BlockType.text;
  // style: TextBlockStyle;
  text: LocalizedString[];
}

export interface TextBlockStyle {}

export interface RSSBlock extends BaseBlock {
  type: BlockType.rss;
  url: string;
}

export interface ButtonBlock extends BaseBlock {
  type: BlockType.button;
  target: string;
  href: string;
  style: {};
  text: LocalizedString[];
}

export interface GalleryBlock extends BaseBlock {
  type: BlockType.gallery;
  images: any[];
}

export interface MapBlock extends BaseBlock {
  type: BlockType.map;
  lat: number;
  lng: number;
  radius: number;
  label: string;
}

export interface SocialBlock extends BaseBlock {
  facebook: string;
  twitter: string;
  instagram: string;
  type: BlockType.social;
}

export interface WebBlock extends BaseBlock {
  type: BlockType.web;
  url: string;
}

export interface DataGridBlock extends BaseBlock {
  type: BlockType.dataGrid;
  columns: number;
  columnStyles: { width: string; horizontalTextAlignment: number }[];
  rows: any[];
}

export interface WeatherBlock extends BaseBlock {
  type: BlockType.weather;
}

export interface YoutubeBlock extends BaseBlock {
  url: string;
  text: LocalizedString[];
  type: BlockType.youtube;
}

export interface AlfredPayment1Block extends BaseBlock {
  title: LocalizedString[];
  text: LocalizedString[];
  images: any[];
  price: number;
  currency: string;
  buttonTitle: LocalizedString[];
  type: BlockType.alfredPayment1;
}

export type ContentBlock =
  | TitleBlock
  | TextBlock
  | RSSBlock
  | ButtonBlock
  | GalleryBlock
  | YoutubeBlock
  | MapBlock
  | SocialBlock
  | WebBlock
  | DataGridBlock
  | WeatherBlock
  | AlfredPayment1Block;

function defaultTitleStyle(): TitleBlockStyle {
  return {};
}

function defaultTitleBlock(languages: string[]): TitleBlock {
  return {
    id: 0,
    // style: defaultTitleStyle(),
    text: languages.map((lang) => ({ lang, text: '' })),
    type: BlockType.title,
  };
}

export function defaultTextStyle(): TextBlockStyle {
  return {
    horizontalTextAlignment: HorizontalTextAlignment.center,
  };
}

function defaultTextBlock(languages: string[]): TextBlock {
  return {
    id: 0,
    // style: defaultTextStyle(),
    text: languages.map((language) => ({ text: '', lang: language })),
    type: BlockType.text,
  };
}

function createBlock(
  block: BlockType,
  name: string,
  languages: string[]
): ContentBlock {
  switch (block) {
    case BlockType.title:
      const title = defaultTitleBlock(languages);
      if (name) title.text[0].text = name;
      return title;

    case BlockType.text:
      return defaultTextBlock(languages);

    case BlockType.button:
      return {
        href: '',
        target: '',
        style: {},
        text: languages.map((language) => ({
          text: '',
          lang: language,
        })),
        type: BlockType.button,
      } as ButtonBlock;

    case BlockType.gallery:
      return { id: 0, images: [], type: BlockType.gallery } as GalleryBlock;

    case BlockType.map:
      return {
        lat: 0,
        lng: 0,
        radius: 10,
        label: '',
        type: BlockType.map,
      } as MapBlock;

    case BlockType.social:
      return {
        facebook: '',
        twitter: '',
        instagram: '',
        type: BlockType.social,
      } as SocialBlock;

    case BlockType.youtube:
      return {
        url: '',
        text: languages.map((language) => ({
          text: '',
          lang: language,
        })),
        type: BlockType.youtube,
      } as YoutubeBlock;

    case BlockType.rss:
      return { url: '', type: BlockType.rss } as RSSBlock;

    case BlockType.web:
      return { url: '', type: BlockType.web } as WebBlock;

    case BlockType.dataGrid:
      return {
        id: 0,
        type: BlockType.dataGrid,
        columns: 2,
        columnStyles: [
          { width: '*', horizontalTextAlignment: 0 },
          { width: 'Auto', horizontalTextAlignment: 2 },
        ],
        rows: [],
      } as DataGridBlock;

    case BlockType.weather:
      // TOOD: improve!
      return { type: BlockType.weather } as WeatherBlock;

    case BlockType.alfredPayment1:
      return {
        id: 0,
        type: BlockType.alfredPayment1,
        title: languages.map((lang) => ({
          lang,
          text: '',
        })),
        text: languages.map((lang) => ({
          lang,
          text: '',
        })),
        images: [],
        price: 0,
        currency: 'EUR',
        buttonTitle: languages.map((lang) => ({
          lang,
          text: '',
        })),
      } as AlfredPayment1Block;
  }

  throw new Error(`Invalid block type: '${block}'`);
}

function createContent(
  layout: BlockType[],
  name: string,
  languages: string[]
): ContentBlock[] {
  return layout
    .map((type) => createBlock(type, name, languages))
    .map((block, index) => {
      block.id = index + 1;
      return block;
    });
}

const pageListStyle = {
  default: 0,
  button: 1,
};

function defaultData() {
  return {
    cms_version: 2,
    background: {
      color: null,
      landscape: null,
      portrait: null,
    },
    style: [],
    children: [],
    content: [],
    'list-style': pageListStyle.default,
    settings: {
      ga_event: '',
    },
    template: '',
  };
}

function convertData(old) {
  // TODO: implement converting old templates into new one
  return defaultData();
}

function createChild(
  data: { id: number },
  name: string,
  languages: string[] = []
) {
  return {
    background: {
      color: null,
      landscape: null,
      portrait: null,
    },
    id: data.id,
    title: (languages || ['en']).map((lang) => ({
      lang,
      text: name,
    })),
  };
}

function strip(html: string) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

/**
 * Returns if the block type is fixed i.e. always at the bottom.
 * Other blocks cannot be moved past this one.
 *
 * @param {BlockType} type The block type to inspect.
 * @returns True if the block is static, false otherwise.
 */
export function isStaticBlock(type: BlockType) {
  switch (type) {
    case BlockType.map:
    case BlockType.gallery:
    case BlockType.rss:
    case BlockType.web:
      return true;
  }
  return false;
}

export {
  createContent,
  pageListStyle,
  defaultData,
  convertData,
  createChild,
  strip,
  createBlock,
};
