import * as React from 'react';
import { NavLink } from 'react-router-dom';

import NavBar from './NavBar';
import { FeatureFlag } from './shared';

const DigitalSignage: React.SFC<{}> = ({ children }) => (
  <div style={{ height: '100%' }}>
    <NavBar title="EmGuest" link="/">
      <li>
        <NavLink to="/digitalsignage/screens" activeClassName="link-active">
          Screens
        </NavLink>
      </li>
      <li>
        <NavLink to="/digitalsignage/gallery" activeClassName="link-active">
          Media Gallery
        </NavLink>
      </li>
      <li>
        <NavLink to="/digitalsignage/playlists" activeClassName="link-active">
          Playlists
        </NavLink>
      </li>
      <li>
        <NavLink to="/digitalsignage/channels" activeClassName="link-active">
          Channels
        </NavLink>
      </li>

      <FeatureFlag feature="devbuilder">
        <li>
          <NavLink
            to="/digitalsignage/draftbuilder"
            activeClassName="link-active"
          >
            Dev editor
          </NavLink>
        </li>
      </FeatureFlag>

      <FeatureFlag feature="dev">
        <li>
          <NavLink to="/digitalsignage/dev" activeClassName="link-active">
            DEV
          </NavLink>
        </li>
      </FeatureFlag>
    </NavBar>

    <div className="digital-content-holder">{children}</div>
  </div>
);

export default DigitalSignage;
