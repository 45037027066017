import * as React from 'react';
import { connect } from 'react-redux';

import { ImageTarget } from 'api';

import { AppType } from '../../../enums';
import ImagePicker from '../../ImagePicker';

class State {
  showPicker = false;
  targetWidth = 0;
  targetHeight = 0;
  backgroundType = 'landscape';
}

class SubpageBackgroundImage extends React.Component<any, State> {
  state = new State();

  render() {
    const { subpage } = this.props;
    if (!subpage || !subpage.background) return null;

    const hasLandscape = true;
    const hasPortrait =
      this.props.page.page.level !== 0 &&
      this.props.app.data.appType === AppType.GuestDirectory;

    let landscapeDimensions: any = null;
    let portraitDimensions: any = null;
    switch (this.props.app.data.appType) {
      case AppType.GuestDirectory:
        if (this.props.page.page.level === 0) {
          landscapeDimensions = '512x256';
        } else {
          landscapeDimensions = '2048x512';
          portraitDimensions = '1536x512';
        }
        break;

      case AppType.Mobile:
        if (this.props.page.page.level === 0) {
          landscapeDimensions = '750x268';
        } else {
          landscapeDimensions = '750x333';
        }
        break;
    }

    return (
      <>
        {hasLandscape ? (
          <div className="col-xs-6">
            <h4 className="subtle-label">Landscape {landscapeDimensions}</h4>
            {!subpage.background.landscape ? (
              <button
                className="btn btn-default"
                onClick={this.setLandscapeImage}
              >
                Set
              </button>
            ) : (
              <div
                style={{
                  backgroundImage: `url(${subpage.background.landscape})`,
                }}
                className="thumbnail-image"
              >
                <button
                  title="Remove this image"
                  onClick={this.removeLandscapeImage}
                >
                  <i className="fa fa-trash" />
                </button>
              </div>
            )}
          </div>
        ) : null}

        {hasPortrait ? (
          <div className="col-xs-6">
            <h4 className="subtle-label">Portrait {portraitDimensions}</h4>
            {!subpage.background.portrait ? (
              <button
                className="btn btn-default"
                onClick={this.setPortraitImage}
              >
                Set
              </button>
            ) : (
              <div
                style={{
                  backgroundImage: `url(${subpage.background.portrait})`,
                }}
                className="thumbnail-image"
              >
                <button
                  title="Remove this image"
                  onClick={this.removePortraitImage}
                >
                  <i className="fa fa-trash" />
                </button>
              </div>
            )}
          </div>
        ) : null}

        {this.state.showPicker ? (
          <ImagePicker
            name="imagePicker"
            mustCropImage={true}
            pageId={this.props.page.id}
            backgroundType={this.state.backgroundType}
            target={ImageTarget.Picker}
            targetWidth={this.state.targetWidth}
            targetHeight={this.state.targetHeight}
            handleCreate={this.handleImagePicked}
            handleClose={this.handleImagePickerClosed}
          />
        ) : null}
      </>
    );
  }

  handleSetImage = (e, backgroundType) => {
    e.preventDefault();

    const appType = this.props.app.data.appType;
    const pageLevel = this.props.page.page.level;
    let targetWidth = 0;
    let targetHeight = 0;
    if (appType === AppType.GuestDirectory) {
      if (pageLevel === 0) {
        // this is root picker image
        // 512x256
        targetWidth = 512;
        targetHeight = 256;
      } else {
        // this is regular subpage image
        // 2048x512
        // 1536x512

        if (backgroundType === 'landscape') targetWidth = 2048;
        else targetWidth = 1536;
        targetHeight = 512;
      }
    } else if (appType === AppType.Mobile) {
      if (pageLevel === 0) {
        // 750x268
        targetWidth = 750;
        targetHeight = 268;
      } else {
        // 750x333
        targetWidth = 750;
        targetHeight = 333;
      }
    }

    this.setState(
      {
        backgroundType,
        targetWidth,
        targetHeight,
        showPicker: true,
      },
      () => {
        $('#imagePicker').modal({
          backdrop: 'static',
          keyboard: false,
        });
      }
    );
  };

  handleRemoveImage = (e, backgroundType) => {
    this.props.handleUpdate({
      ...this.props.subpage.background,
      [backgroundType]: '',
    });
  };

  handleImagePicked = data => {
    this.props.handleUpdate({
      ...this.props.subpage.background,
      [data.type]: data.uri,
    });
    this.setState({ showPicker: false });
  };

  handleImagePickerClosed = () => this.setState({ showPicker: false });

  setLandscapeImage = e => this.handleSetImage(e, 'landscape');
  removeLandscapeImage = e => this.handleRemoveImage(e, 'landscape');

  setPortraitImage = e => this.handleSetImage(e, 'portrait');
  removePortraitImage = e => this.handleRemoveImage(e, 'portrait');
}

export default connect(state => ({
  app: state.App,
  page: state.Page,
}))(SubpageBackgroundImage);
