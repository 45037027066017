import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';

import ChannelsToolbar from './ChannelsToolbar';
import AddNewChannel from './AddNewChannel';
import { Loading } from 'Components/shared';
import Schedule from './Schedule';
import EditChannelDialog from './EditChannelDialog';
import { UserContextProps, useUser } from 'utilities';

type Props = RouteComponentProps<{ id: string }> & {
  addInfoNotification: (text: string) => any;
  addSuccessNotification: (text: string) => any;
  addDangerNotification: (text: string) => any;
} & { user: UserContextProps };

class State {
  isLoadingChannels = false;
  selectedChannel = 0;
  channels: any[] = [];

  isSaving = false;

  schedule: any = null;
  isLoadingSchedule = false;

  showEditChannelDialog = false;
}

class Channels extends Component<Props, State> {
  state = new State();

  componentDidMount() {
    this.getChannels();
    if (this.props.match.params.id) {
      this.setState({
        selectedChannel: parseInt(this.props.match.params.id),
      });
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (+nextProps.match.params.id !== this.state.selectedChannel) {
      this.setState({
        selectedChannel: +nextProps.match.params.id,
      });
    }
  }

  getChannels = () => {
    this.setState({ isLoadingChannels: true });
    const accountId = this.props.user.accountId;
    axios
      .get(`/api/channels/account/${accountId}`)
      .then(res => {
        this.setState({
          isLoadingChannels: false,
          channels: res.data,
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoadingChannels: false });
        this.props.addDangerNotification('Unable to load channels');
      });
  };

  handleSelectChannel = e => {
    var channelId = e.target.selectedOptions[0].getAttribute('data-id');
    if (channelId) {
      this.setState({
        selectedChannel: channelId,
      });
      this.props.history.push(`/digitalsignage/channels/${channelId}`);
    } else {
      this.setState({
        selectedChannel: 0,
        schedule: null,
        isLoadingSchedule: false,
      });
      this.props.history.push('/digitalsignage/channels');
    }
  };

  handleCreateNewChannel = data => {
    var promise = axios.post(
      '/api/channels/' + window['userState'].accountId,
      data
    );
    promise
      .then(res => {
        this.props.addSuccessNotification('Channel created!');
        this.setState({
          selectedChannel: res.data,
        });
        this.getChannels();
      })
      .catch(err => {
        this.props.addDangerNotification(
          'Could not create channel. Please try again or contact support'
        );
        console.log(err);
      });
    return promise;
  };

  handleDeleteChannel = () => {
    if (!confirm('Are you sure you want to delete this channel?')) {
      return;
    }

    axios
      .delete(`/api/channels/${this.state.selectedChannel}`)
      .then(r => {
        this.props.addSuccessNotification('Channel successfully deleted!');
        this.getChannels();
        this.props.history.push('/digitalsignage/channels');
      })
      .catch(e => {
        if (e.response && e.response.data && e.response.data.error) {
          this.props.addDangerNotification(
            'Could not delete channel. ' + e.response.data.error
          );
        } else {
          this.props.addDangerNotification(
            'Could not delete channel. Please try again or contact support'
          );
        }
      });
  };

  handleEditChannel = e => {
    this.setState({ showEditChannelDialog: true }, () => {
      $('#editChannelDialog').modal();
      $('#editChannelDialog').on('hidden.bs.modal', () => {
        this.setState({ showEditChannelDialog: false });
      });
    });
  };

  handleEditChannelDialogClose = () => {
    this.setState({ showEditChannelDialog: false });
  };

  handleEditChannelOK = data => {
    if (data.name.trim() === '') {
      alert('Name should not be empty');
      return false;
    }

    this.setState({ isSaving: true });
    axios
      .put(`/api/channels/${this.state.selectedChannel}`, data)
      .then(r => {
        this.setState({ isSaving: false });
        this.props.addSuccessNotification('Channel saved!');
        this.getChannels();
      })
      .catch(e => {
        this.setState({ isSaving: true });
        this.props.addDangerNotification(
          'There was an error trying to save changes'
        );
      });

    return true;
  };

  render() {
    let selectedChannelName = null,
      selectedChannelGroupName = null;
    const selectedChannel = this.state.channels.find(
      c => +c.id === +this.state.selectedChannel
    );
    if (selectedChannel) {
      selectedChannelName = selectedChannel.name;
      selectedChannelGroupName = selectedChannel.groupName;
    }

    return (
      <div className="container channels-container">
        <ChannelsToolbar
          channels={this.state.channels}
          selectedChannel={this.state.selectedChannel}
          handleSelectChannel={this.handleSelectChannel}
        />

        {selectedChannel ? (
          <div className="channels-holder">
            <h4>
              Selected channel: {selectedChannelName}{' '}
              {selectedChannelGroupName ? (
                <small>(group: {selectedChannelGroupName})</small>
              ) : null}
              <button
                className="btn btn-small btn-transparent"
                title="Edit channel"
                onClick={this.handleEditChannel}
                style={{ marginLeft: '10px' }}
              >
                <i className="fa fa-pencil" />
              </button>
              {selectedChannel.canDelete ? (
                <button
                  className="btn btn-small remove-button"
                  aria-label="Left Align"
                  onClick={this.handleDeleteChannel}
                >
                  <span
                    className="glyphicon glyphicon-trash"
                    aria-hidden="true"
                  />
                </button>
              ) : null}
            </h4>

            <Schedule channelId={this.state.selectedChannel} {...this.props} />

            {this.state.isLoadingSchedule ? <Loading /> : null}
          </div>
        ) : null}

        {this.state.isLoadingChannels || this.state.isLoadingSchedule ? (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
              backgroundColor: 'rgba(0,0,0, 0.2)',
            }}
          >
            <Loading />
          </div>
        ) : null}

        <AddNewChannel
          name="newChannel"
          handleCreate={this.handleCreateNewChannel}
        />

        {this.state.showEditChannelDialog ? (
          <EditChannelDialog
            name="editChannelDialog"
            channel={selectedChannel}
            handleClose={this.handleEditChannelDialogClose}
            handleOk={this.handleEditChannelOK}
          />
        ) : null}

        {this.state.isSaving ? <Loading /> : null}
      </div>
    );
  }
}

export default connect(null, {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
})(function (props: Props) {
  const user = useUser();

  return <Channels {...props} user={user} />;
});
