let initialState = {
  collapsed: false,
};

export const TimelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_TIMELINE':
      return Object.assign({}, state, {
        collapsed: action.collapsed,
      });

    default:
      return state;
  }
};
