import React, { PureComponent } from 'react';
import moment from 'moment';

import { MediaItemType, mediaItemTypeString } from '../../enums';
import formatByteSize from '../../utilities/formatByteSize';

import { MediaItemThumb } from './media-item-thumb';
import formatDuration from '../../utilities/formatDuration';
import { MediaPreviewLink } from './media-preview-link';
import { ImagePreviewModal } from './image-preview-modal';
import { VideoPreviewModal } from './video-preview-modal';
import { MediaItemListItem } from 'api';
import { MediaItemScheduleModal } from './media-item-schedule-modal';
import { WithDialog } from 'Components/shared';
import { Dropdown } from 'ui/atoms/dropdown';
import AddToPlaylistsModal from './add-to-playlists-modal';

type Props = {
  mediaItem: MediaItemListItem;
  search?: string;
  onDelete: (mediaItem: MediaItemListItem) => void;
  onVideoUpdate: (mediaItem: MediaItemListItem) => void;
  onVideoInfo: (mediaItem: MediaItemListItem) => void;
  onViewPlaylists: (mediaItem: MediaItemListItem) => void;
  onClearSchedule: (mediaItem: MediaItemListItem) => void;
  onSetSchedule: (
    mediaItem: MediaItemListItem,
    type: string,
    date: number
  ) => Promise<void>;
  onEnable: (mediaItem: MediaItemListItem) => void;
  onUpdateVideoMetadata: (mediaItem: MediaItemListItem) => void;
  disableMediaItem: (mediaItem: MediaItemListItem) => void;
  onAddToPlaylists: (id: number, playlists: number[]) => Promise<any>;
};

class State {
  uri = '';
  showPreview: boolean = false;
}

export class MediaItemRow extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    let uri = '';
    try {
      const meta = props.mediaItem.meta;
      uri = meta ? JSON.parse(meta).uri : null;
    } catch (e) {}

    this.state = { uri, showPreview: false };
  }

  showPreview = () => this.setState({ showPreview: true });
  hidePreview = () => this.setState({ showPreview: false });

  delete = () => this.props.onDelete(this.props.mediaItem);
  videoUpdate = () => this.props.onVideoUpdate(this.props.mediaItem);
  videoInfo = () => this.props.onVideoInfo(this.props.mediaItem);
  viewPlaylists = () => this.props.onViewPlaylists(this.props.mediaItem);
  clearSchedule = () => this.props.onClearSchedule(this.props.mediaItem);

  setSchedule = (type: string, date: number) =>
    this.props.onSetSchedule(this.props.mediaItem, type, date);
  enableMediaItem = () => this.props.onEnable(this.props.mediaItem);
  updateVideoMetadata = () =>
    this.props.onUpdateVideoMetadata(this.props.mediaItem);
  disableMediaItem = () => this.props.disableMediaItem(this.props.mediaItem);

  render() {
    const { showPreview, uri } = this.state;
    const { mediaItem } = this.props;

    return (
      <>
        <tr>
          <th scope="row">{mediaItem.id}</th>
          <td>
            <MediaItemThumb
              thumbUri={mediaItem.thumbUri}
              type={mediaItem.mediaItemType}
              mediaItemId={mediaItem.id}
              onClick={this.showPreview}
            />
          </td>
          <td>
            <section className="flex flex-col">
              <section className="flex flex-row items-center">
                <MediaPreviewLink
                  id={mediaItem.id}
                  name={mediaItem.name}
                  type={mediaItem.mediaItemType}
                  onClick={this.showPreview}
                />{' '}
                {mediaItem.isDraft ? (
                  <span className="media-draft">DRAFT</span>
                ) : null}
              </section>
              <span className="text-xs">
                <HighlightText target={this.props.search}>
                  {mediaItem.reference}
                </HighlightText>
              </span>
            </section>
          </td>
          <td>
            {mediaItemTypeString(mediaItem.mediaItemType)}
            {mediaItem.mediaItemType === MediaItemType.Video && (
              <>
                <br />
                {formatDuration(mediaItem.duration)}
              </>
            )}
          </td>
          <td>{`${mediaItem.width} x ${mediaItem.height}`}</td>
          <td>
            {mediaItem.mediaItemType !== MediaItemType.Presentation &&
              formatByteSize(mediaItem.size)}
          </td>
          <td>{moment(mediaItem.lastModifiedOn).fromNow()}</td>
          <td>
            <a
              onClick={this.viewPlaylists}
              title="Delete this media item"
              data-toggle="tooltip"
              data-placement="left"
            >
              {mediaItem.used ? `${mediaItem.used} times` : '-'}
            </a>
          </td>
          <td>
            {!mediaItem.deactivateOn && !mediaItem.activateOn && (
              <WithDialog>
                {(isOpen, show, hide) => (
                  <>
                    <a onClick={show} title="Schedule activation/deactivation">
                      <span className="glyphicon glyphicon-time" />
                    </a>

                    <MediaItemScheduleModal
                      mediaItemId={mediaItem.id}
                      isOpen={isOpen}
                      hide={hide}
                      onSet={this.setSchedule}
                    />
                  </>
                )}
              </WithDialog>
            )}

            {mediaItem.mediaItemType === MediaItemType.Presentation ? (
              <div className="dropdown" style={{ float: 'left' }}>
                <span
                  className="glyphicon glyphicon-option-vertical"
                  id={`dropdown-image${mediaItem.id}`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  style={{}}
                />
                <ul
                  className="dropdown-menu"
                  aria-labelledby={`dropdown-image${mediaItem.id}`}
                >
                  <li>
                    <a onClick={this.disableMediaItem}>
                      Disable media item in all playlists
                    </a>
                  </li>
                </ul>
              </div>
            ) : null}

            <Dropdown
              id={`dropdown-image${mediaItem.id}`}
              className="float-left"
            >
              <a onClick={this.enableMediaItem}>
                <span className="delete-media glyphicon glyphicon-play" />
                Enable media item in all playlists
              </a>
              <a onClick={this.disableMediaItem}>
                <span className="delete-media glyphicon glyphicon-stop" />
                Disable media item in all playlists
              </a>
              <a
                onClick={this.delete}
                title="Delete this media item"
                data-toggle="tooltip"
                data-placement="left"
              >
                <span
                  className="delete-media glyphicon glyphicon-trash"
                  style={{ color: 'red' }}
                />
                Delete this item
              </a>
              {this.props.mediaItem.mediaItemType === MediaItemType.Video && (
                <a onClick={this.updateVideoMetadata}>Update video metadata</a>
              )}
            </Dropdown>

            <section>
              <WithDialog>
                {(isOpen, show, hide) => (
                  <>
                    <a onClick={show}>Add to playlists</a>

                    {isOpen && (
                      <AddToPlaylistsModal
                        show={isOpen}
                        hide={hide}
                        mediaItem={mediaItem}
                        onAdd={(playlists) =>
                          this.props.onAddToPlaylists(
                            this.props.mediaItem.id,
                            playlists
                          )
                        }
                      />
                    )}
                  </>
                )}
              </WithDialog>
            </section>

            {false && mediaItem.mediaItemType === MediaItemType.Video ? (
              <div
                className="col-md-1 col-xs-3 text-right padding0"
                style={{ width: '20px' }}
              >
                <div className="dropdown">
                  <span
                    className="glyphicon glyphicon-option-vertical"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    style={{}}
                  />
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li>
                      <a href="#" onClick={this.videoUpdate}>
                        Update info
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={this.videoInfo}>
                        Get info
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </td>
        </tr>

        {mediaItem.activateOn && (
          <tr>
            <td colSpan={2} />
            <td colSpan={12}>
              Media item scheduled for activation on{' '}
              {moment(mediaItem.activateOn).format('DD. MM. YYYY. HH:mm')}{' '}
              <a onClick={this.clearSchedule}>Reset</a>
            </td>
          </tr>
        )}
        {mediaItem.deactivateOn && (
          <tr>
            <td colSpan={2} />
            <td colSpan={12}>
              Media item scheduled for deactivation on{' '}
              {moment(mediaItem.deactivateOn).format('DD. MM. YYYY. HH:mm')}{' '}
              <a onClick={this.clearSchedule}>Reset</a>
            </td>
          </tr>
        )}

        {showPreview && mediaItem.mediaItemType === MediaItemType.Image && (
          <ImagePreviewModal
            show
            name={mediaItem.name}
            uri={uri}
            onClose={this.hidePreview}
          />
        )}
        {showPreview && mediaItem.mediaItemType === MediaItemType.Video && (
          <VideoPreviewModal
            show
            name={mediaItem.name}
            uri={uri}
            onClose={this.hidePreview}
          />
        )}
      </>
    );
  }
}

function HighlightText(props: {
  target?: string;
  children: string;
}): JSX.Element {
  const { target, children } = props;

  if (!target || !children) {
    return <>{children}</>;
  }

  const index = children.indexOf(target);
  if (index === -1) {
    return <>{children}</>;
  }

  const arr: React.ReactNode[] = [
    children.substr(0, index),
    <span style={{ backgroundColor: 'yellow' }}>
      {children.substr(index, target.length)}
    </span>,
    children.substr(index + target.length),
  ];

  return (
    <>
      {arr.map((el) => (
        <>{el}</>
      ))}
    </>
  );
}
