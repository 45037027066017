let initialForms = {
  isLoading: false,
  isLoaded: false,
  forms: [],
};

let initialForm = {
  isFormLoading: false,
  isFormLoaded: false,
  areRequestsLoading: false,
  areRequestsLoaded: false,
  info: {
    id: 0,
    name: '',
    alias: '',
  },
  requests: [],

  showFormEditModal: false,
  isFormSaving: false,
};

export const FormsReducer = (state = initialForms, action) => {
  switch (action.type) {
    case 'FORMS_LOAD_START':
      return {
        ...state,
        isLoading: true,
      };

    case 'FORMS_LOAD_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        forms: action.forms,
      };

    case 'FORMS_LOAD_ERROR':
      return {
        ...state,
        isLoading: false,
      };
  }

  return state;
};

export const FormReducer = (state = initialForm, action) => {
  switch (action.type) {
    case 'FORMINFO_LOAD_START':
      return {
        ...state,
        isFormLoading: true,
      };

    case 'FORMINFO_LOAD_SUCCESS':
      return {
        ...state,
        isFormLoading: false,
        isFormLoaded: true,
        info: action.data,
      };

    case 'FORMINFO_LOAD_ERROR':
      return {
        ...state,
        isFormLoading: false,
      };

    case 'FORMREQUESTS_LOAD_START':
      return {
        ...state,
        areRequestsLoading: true,
      };

    case 'FORMREQUESTS_LOAD_SUCCESS':
      return {
        ...state,
        areRequestsLoading: false,
        areRequestsLoaded: true,
        requests: action.data.items,
      };

    case 'FORMREQUESTS_LOAD_ERROR':
      return {
        ...state,
        areRequestsLoading: false,
      };

    case 'FORM_EDIT_SHOW':
      return {
        ...state,
        showFormEditModal: true,
      };

    case 'FORM_EDIT_START':
      return {
        ...state,
        isFormSaving: true,
      };

    case 'FORM_EDIT_SUCCESS':
      return {
        ...state,
        isFormSaving: false,
        showFormEditModal: false,
      };

    case 'FORM_EDIT_ERROR':
      return {
        ...state,
        isFormSaving: false,
      };
  }

  return state;
};
