import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';

import { StateComponent, Loading } from 'Components/shared';
import { Container, Button, Label, Input } from 'ui';
import { resetPassword } from 'api';

import './ResetPassword.css';
import { formatErrors } from 'utilities';

class State {
  static NoErrors = { '': '', email: '', password: '', confirmPassword: '' };

  isValid = true;
  isWorking = false;
  isDone = false;

  userId = '';
  code = '';

  email = '';
  password = '';
  confirmPassword = '';
  errors = State.NoErrors;
}

export default class ResetPassword extends StateComponent<
  RouteComponentProps<{}>,
  State
> {
  state = new State();

  render() {
    return (
      <Container>
        <div className="reset-password-container card-bg">
          <h3>Reset password</h3>

          {this.state.isWorking ? (
            <div style={{ height: '100px' }}>
              <Loading />
            </div>
          ) : this.state.isDone ? (
            <span className="help-block">Password was successfully reset!</span>
          ) : this.state.isValid ? (
            <>
              <Label state={this.state.errors.email ? 'error' : undefined}>
                Email
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={this.set}
                />
                <span className="help-block">{this.state.errors.email}</span>
              </Label>

              <Label state={this.state.errors.password ? 'error' : undefined}>
                Password
                <Input
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.set}
                />
                <span className="help-block">{this.state.errors.password}</span>
              </Label>

              <Label
                state={this.state.errors.confirmPassword ? 'error' : undefined}
              >
                Confirm password
                <Input
                  type="password"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.set}
                />
                <span className="help-block">
                  {this.state.errors.confirmPassword}
                </span>
              </Label>

              <Label state={this.state.errors[''] ? 'error' : undefined}>
                <span className="help-block">{this.state.errors['']}</span>
              </Label>

              <Button onClick={this.resetPassword}>Reset password</Button>
            </>
          ) : (
            <p>An error ocurred</p>
          )}

          <hr />

          <Link to="/account/login">Back to login</Link>
        </div>
      </Container>
    );
  }

  componentDidMount() {
    const { search } = this.props.location;
    console.dir(search);

    const matches = search.match(/userId=(.*)&code=(.*)/);
    if (!matches || matches.length < 3) {
      this.setState({ isValid: false });
      return;
    }

    const userId = matches[1];
    const code = decodeURIComponent(matches[2]);
    this.setState({ userId, code });
  }

  resetPassword = () => {
    this.setState({ errors: State.NoErrors });
    this.setState({ isWorking: true });

    resetPassword(
      this.state.email,
      this.state.password,
      this.state.confirmPassword,
      this.state.code
    )
      .then(r => this.setState({ isWorking: false, isDone: true, email: '' }))
      .catch(e =>
        this.setState(s => ({
          isWorking: false,
          errors: {
            ...s.errors,
            ...formatErrors(s.errors, e.response.data),
          },
        }))
      );
  };
}
