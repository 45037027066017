import React, { FunctionComponent, useState } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';

import { AppDTO, AppPageForCloneDto } from 'api';
import {
  ModalProps,
  SimpleModal,
  Header,
  Content,
  Footer,
  LoadingOverlay,
} from 'Components/shared';
import { Label, Button } from 'ui';
import { useFetch } from 'utilities';

type Props = {
  appType: number;
  onCopy: (pageId: number, name: string) => Promise<any>;
} & ModalProps;

const CopyPageDialog: FunctionComponent<Props> = ({
  appType,
  onCopy,
  show,
  onClose,
}) => {
  const [selectedApp, setSelectedApp] = useState<ValueType<any>>(null);
  const [selectedPage, setSelectedPage] = useState<ValueType<any>>(null);
  const [apps, setApps] = useState<AppDTO[]>([]);
  const [pages, setPages] = useState<AppPageForCloneDto[]>([]);
  const [isWorking, setIsWorking] = useState(false);

  useFetch(`/api/apps/version/2`, data =>
    setApps(data.filter(app => app.appType === appType))
  );

  useFetch(
    selectedApp ? `/api/apps/${selectedApp.value}/pagesforclone` : '',
    setPages
  );

  function copy() {
    setIsWorking(true);
    onCopy(selectedPage.value, selectedPage.label)
      .then(() => {
        onClose();
        setIsWorking(false);
      })
      .catch(() => {
        setIsWorking(false);
      });
  }

  return (
    <SimpleModal show={show} hide={onClose}>
      <Header>Copy existing page and add as child</Header>

      <Content>
        <Label>Choose an app:</Label>
        <Select
          value={selectedApp}
          onChange={value => setSelectedApp(value)}
          options={apps.map(app => ({
            value: app.id,
            label: `${app.name} (${app.accountName || ''})`,
          }))}
          autoFocus
        />

        {!!selectedApp && (
          <>
            <Label>Choose a page:</Label>
            <Select
              value={selectedPage}
              onChange={value => setSelectedPage(value)}
              options={pages.map(page => ({
                value: page.id,
                label: `${page.name} ${
                  page.descendants > 0 ? ` (+${page.descendants} pages)` : ''
                }`,
              }))}
              autoFocus
            />
          </>
        )}

        {isWorking && <LoadingOverlay />}
      </Content>

      <Footer>
        <Button onClick={onClose}>Dismiss</Button>
        <Button
          type="primary"
          onClick={copy}
          disabled={!selectedPage || !selectedPage.value || isWorking}
        >
          Add
        </Button>
      </Footer>
    </SimpleModal>
  );
};

export default CopyPageDialog;
