import * as React from 'react';
import TinyMCE from 'react-tinymce';

import { strip, TextBlock } from '../contentHelpers';

interface Props {
  data: TextBlock;
  handleUpdate: (data: any) => any;
}

class State {
  text = '';
  temp = '';
  showModal = false;
}

export class Text extends React.Component<Props, State> {
  state = new State();

  render() {
    let text = strip(this.state.text);
    if (text.length > 70) text = text.substr(0, 70) + '...';

    return (
      <>
        <input
          type="button"
          className="btn btn-default pull-right"
          value="Edit"
          data-toggle="modal"
          data-target="#editText"
          onClick={this.handleShowDialog}
        />

        {text ? (
          <div>
            <label className="subtle-label">Preview:</label>
            <p>{text}</p>
          </div>
        ) : (
          <label className="subtle-label">
            Preview will appear here once text has been edited.
          </label>
        )}

        {this.state.showModal ? (
          <div
            className="modal fade"
            id="editText"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="editTextLabel"
          >
            <div className="modal-dialog modal-lg modal-fancy" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="editTextLabel">
                    Edit text
                  </h4>
                </div>
                <div className="modal-body">
                  <form id={'editText' + 'Form'}>
                    <TinyMCE
                      content={this.state.text}
                      config={
                        {
                          menubar: false,
                          plugins: 'paste autoresize lists',
                          statusbar: false,
                          toolbar:
                            'bold italic undo redo | alignleft aligncenter alignright | fontsizeselect | bullist numlist',
                          fontsize_formats:
                            '8pt 10pt 12pt 14pt 18pt 22pt 24pt 36pt',
                          paste_as_text: true,
                          min_height: 100,
                        } // Disable HTML tags when pasting
                      }
                      onChange={this.handleChange}
                    />
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-fancy"
                    onClick={this.handleSave}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  componentDidMount() {
    // always select default language
    const text = this.props.data.text[0].text;
    this.setState({ text, temp: text });
  }

  componentWillReceiveProps(nextProps) {
    const text = nextProps.data.text[0].text;
    this.setState({ text, temp: text });
  }

  handleShowDialog = (e) => {
    this.setState({ showModal: true }, () => {
      $('#editText').modal('show');
      tinyMCE.activeEditor.setContent(this.state.text);
    });
  };

  handleChange = (e) => {
    const html = e.target.getContent();
    this.setState({ temp: html });
  };

  handleSave = (e) => {
    $('#editText').modal('hide');

    this.setState(
      (state, props) => {
        return {
          text: state.temp,
          temp: '',
          showModal: false,
        };
      },
      () => {
        if (this.props.handleUpdate) {
          const newData = {
            ...this.props.data,
            text: [
              {
                lang: this.props.data.text[0].lang,
                text: this.state.text,
              },
            ].concat(this.props.data.text.slice(1)),
          };

          this.props.handleUpdate(newData);
        }
      }
    );
  };
}
