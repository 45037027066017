import React from 'react';
import { connect } from 'react-redux';

import TimelineObjModal from './TimelineObjModal';
import TimelineDuration from './TimelineDuration';
import TimelineScale from './TimelineScale';
import TimelineObject from './TimelineObject';
import { toggle_timeline } from './toggle_timeline';
import formatDuration from '../../../utilities/formatDuration';
import calculateTotalDuration from '../calculateTotalDuration';

const pxPerSec = 100;

class TimelineHolder extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.handleClickCollapse = this.handleClickCollapse.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      scaleOffsetLeft: 0,
      scaleOffsetTop: 0,
    };
  }

  handleClickCollapse() {
    this.props.dispatch(toggle_timeline(!this.props.collapsed));
  }

  handleScroll(e) {
    this.setState({
      scaleOffsetLeft: e.target.scrollLeft,
      scaleOffsetTop: e.target.scrollTop,
    });
  }

  render() {
    const total = calculateTotalDuration(this.props.timelineObjects);

    return (
      <div
        className={
          this.props.collapsed
            ? 'timeline-container collapsed clearfix'
            : 'timeline-container clearfix'
        }
      >
        <button className="btn collapse-btn" onClick={this.handleClickCollapse}>
          <span
            className={
              this.props.collapsed
                ? 'glyphicon glyphicon-collapse-up'
                : 'glyphicon glyphicon-collapse-down'
            }
          />
        </button>

        <div className="timeline-header">
          <div className="clearfix timeline-duration">
            <h5 className="timeline-duration-summ">
              Duration: {formatDuration(total)}
            </h5>
          </div>
          <TimelineScale
            objs={this.props.timelineObjects}
            offset={this.state.scaleOffsetLeft}
            total={total}
            pxPerSec={pxPerSec}
          />
        </div>

        <div className="timeline-objects-holder">
          <div
            className="timeline-objects-header"
            style={{ marginTop: `-${this.state.scaleOffsetTop}px` }}
          >
            {this.props.timelineObjects.map((object, index) => {
              let name = `${
                object.type === 'i-text' ? 'Text' : object.type
              } #${index}`;

              return (
                <div
                  className={
                    'timeline-object-name ' +
                    (this.props.selectedObj !== null &&
                    this.props.selectedObj === object.getZIndex()
                      ? 'selected'
                      : '')
                  }
                  key={index}
                  onClick={this.props.updateSelectedObj.bind(this, object)}
                >
                  <span className="name">{name}</span>
                  <button
                    type="button"
                    className="btn-transparent pull-right"
                    data-toggle="modal"
                    data-target={`#timelineModal-${index}`}
                    title="Edit animations"
                  >
                    <span className="glyphicon glyphicon-cog" />
                  </button>

                  <TimelineObjModal
                    object={object}
                    index={index}
                    changeAnimations={this.props.changeAnimations}
                    changeDuration={this.props.changeDuration}
                  />
                </div>
              );
            })}
          </div>

          <div className="timeline-graph" onScroll={this.handleScroll}>
            {this.props.timelineObjects.map((object, index) => {
              return (
                <TimelineObject
                  key={index}
                  index={index}
                  object={object}
                  isSelected={
                    this.props.selectedObj !== null &&
                    this.props.selectedObj === object.getZIndex()
                  }
                  presentationDuration={total}
                  pxPerSec={pxPerSec}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    collapsed: state.Timeline.collapsed,
  };
};

export default connect(mapStateToProps)(TimelineHolder);
