import React, { Component } from 'react';

import OpacitySlider from './OpacitySlider';

export const OpacityProperty = ({ objProps2, handleChangeEvent }: any) => (
  <div className="single-control-holder">
    <label>Opacity</label>
    <input
      type="number"
      placeholder="Opacity"
      name="opacity"
      value={objProps2.opacity * 100}
      onChange={handleChangeEvent}
      min="0"
      max="100"
      step="1"
    />
    <OpacitySlider
      opacity={objProps2.opacity}
      onChange={(e, value) => handleChangeEvent('opacity', value)}
      className="opacity-slider"
    />
    <div className="bottom-properties clearfix">
      <div>
        <p>&nbsp;</p>
      </div>
      <div>
        <p>Value</p>
      </div>
      <div>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
);

export const PositionProperties = ({ objProps, handleChangeEvent }: any) => (
  <div className="single-control-holder">
    <label>Position</label>
    <input
      type="number"
      value={objProps.position.x}
      name="position-x"
      onChange={handleChangeEvent}
    />
    <input
      type="number"
      value={objProps.position.y}
      name="position-y"
      onChange={handleChangeEvent}
    />
    <div className="bottom-properties clearfix">
      <div>
        <p>&nbsp;</p>
      </div>
      <div>
        <p>X</p>
      </div>
      <div>
        <p>Y</p>
      </div>
    </div>
  </div>
);

export const SizeProperties = ({ objProps, handleChangeEvent }: any) => (
  <div className="single-control-holder">
    <label>Size</label>
    <input
      type="number"
      value={objProps.dimensions.width}
      name="dimensions-width"
      onChange={handleChangeEvent}
    />
    <input
      type="number"
      value={objProps.dimensions.height}
      name="dimensions-height"
      onChange={handleChangeEvent}
    />
    <div className="bottom-properties clearfix">
      <div>
        <p>&nbsp;</p>
      </div>
      <div>
        <p>Width</p>
      </div>
      <div>
        <p>Height</p>
      </div>
    </div>
  </div>
);

export const RotationProperties = ({
  objProps,
  handleChangeEvent,
  handleClickEvent,
}: any) => {
  return (
    <div className="single-control-holder">
      <label>Rotation</label>
      <input
        type="number"
        placeholder="Rotation angle"
        name="rotation-angle"
        value={objProps.rotation.angle}
        onChange={handleChangeEvent}
      />
      <button
        className="btn btn-transparent"
        name="rotation-angle-left"
        onClick={handleClickEvent}
      >
        <i className="fa fa-rotate-left" />
        &nbsp;
      </button>
      <button
        className="btn btn-transparent"
        name="rotation-angle-right"
        onClick={handleClickEvent}
      >
        <i className="fa fa-rotate-right" />
        &nbsp;
      </button>
      {Number(objProps.rotation.angle) !== 0 ? (
        <button
          className="btn btn-transparent"
          name="rotation-angle-reset"
          onClick={handleClickEvent}
        >
          <i className="fa fa-times" />
          &nbsp;
        </button>
      ) : null}
      <div className="bottom-properties clearfix">
        <div>
          <p>&nbsp;</p>
        </div>
        <div>
          <p>Angle</p>
        </div>
        <div>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};
