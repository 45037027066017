import * as React from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import Loadable from 'react-loadable';

import { NotFound, Loading } from 'Components/shared';

import Main from './Components/Main';
import DigitalSignage from './Components/DigitalSignage';
import MediaItems from './Components/MediaItems/media-items';
import Playlists from './Components/Playlists/Playlists';
import Channels from './Components/Channels/Channels';
import DigitalSignageContainer from './Components/Builder/DigitalSignageContainer';
import DigitalSignageBuilder from './Components/Builder/DigitalSignageBuilder';
import DigitalSignageDraftBuilder from './Components/Builder/DigitalSignageDraftBuilder';
import Dev from './Components/Dev/Dev';
import Playlist from './Components/Playlists/Playlist';
import AppsList from './Components/MobileApps/AppsList';
import Container from './Components/MobileApp/Container';
import Pages from './Components/MobileApp/Pages';
import Settings from './Components/MobileApp/Settings';
import Theme from './Components/MobileApp/Theme';
import AppDev from './Components/MobileApp/Dev/AppDev';
import AccountContainer from './Components/Account/AccountContainer';
import Shell from './Components/Shell';
import Screens from './pages/digital-signage/screens/Screens';
import DeviceLog from './pages/digital-signage/device-log';
import AppForms from './Components/AppForms/AppForms';
import AppForm from './Components/AppForms/AppForm';
import PushNotifications from './Components/PushNotifications';
import AppFormsRequest from './Components/AppForms/AppFormsRequest';
import ScreensStats from './pages/digital-signage/screens/ScreensStats';
import DashboardErrors from './pages/digital-signage/screens/DashboardErrors';
import LoginPage from './Components/Account/login-page';
import ForgotPasswordPage from './Components/Account/forgot-password-page';
import ResetPasswordPage from './Components/Account/ResetPassword';
import DeveloperRoot from './pages/DeveloperRoot';
import AppsUsersPage from './pages/apps-users-page';
import GuestDirectoryDevicesPage from './pages/developer/GuestDirectoryDevices';
import GuestDirectoryAnalyticsPage from './pages/gd-analytics-page';
import GuestDirectoryMediaItemsPage from './pages/gd-media-items-page';
import NavBar from './Components/NavBar';
import { DeviceQueries } from './pages/digital-signage/device-queries';
import PrivacyPolicy from 'pages/privacy-policy';

const AdminRoute = Loadable({
  loader: () => import(/* webpackChunkName: "admin" */ './Components/Admin'),
  loading: () => <Loading />,
  render(loaded, props) {
    const Component = loaded.AdminRoute;
    return <Component {...props} />;
  },
});

const DigitalSignageRoute = () => (
  <DigitalSignage>
    <Route exact path="/digitalsignage/screens" component={Screens} />
    <Route
      exact
      path="/digitalsignage/screens/stats"
      component={ScreensStats}
    />
    <Route exact path="/digitalsignage/screens/:id/log" component={DeviceLog} />
    <Route
      exact
      path="/digitalsignage/screens/:id/queries"
      component={DeviceQueries}
    />
    <Route
      exact
      path="/digitalsignage/screens/errors"
      component={DashboardErrors}
    />
    <Route
      exact
      path="/digitalsignage/gallery/:filter?"
      component={MediaItems}
    />
    <Route exact path="/digitalsignage/playlists" component={Playlists} />
    <Route exact path="/digitalsignage/playlists/:id" component={Playlist} />
    <Route exact path="/digitalsignage/channels" component={Channels} />
    <Route exact path="/digitalsignage/channels/:id" component={Channels} />
    <Route
      exact
      path="/digitalsignage/draftbuilder/:id"
      component={DigitalSignageDraftBuilder}
    />
    <Route
      exact
      path="/digitalsignage/draftbuilder"
      component={DigitalSignageDraftBuilder}
    />
    <Route exact path="/digitalsignage/dev" component={Dev} />
  </DigitalSignage>
);

const AppRoute = props => (
  <Container {...props}>
    <Switch>
      <Route exact path="/app/:id" component={Pages} />
      <Route exact path="/app/:id/page/:pageid" component={Pages} />
      <Route exact path="/app/:id/settings" component={Settings} />
      <Route exact path="/app/:id/theme" component={Theme} />
      <Route exact path="/app/:id/dev" component={AppDev} />
    </Switch>
  </Container>
);

function GuestDirectoryContainer({ children }) {
  return (
    <div style={{ height: '100%' }}>
      <NavBar title="EmGuest" link="/">
        <li>
          <NavLink exact to="/apps" activeClassName="link-active">
            Applications
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/forms" activeClassName="link-active">
            Forms
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/apps-users" activeClassName="link-active">
            Apps & Users
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/gd/devices" activeClassName="link-active">
            Devices
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/gd/analytics" activeClassName="link-active">
            Analytics
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/gd/media-items" activeClassName="link-active">
            Media items
          </NavLink>
        </li>
      </NavBar>

      {children}
    </div>
  );
}

const GuestDirectoryRoute = () => (
  <GuestDirectoryContainer>
    <Route path="/apps" component={AppsList} />

    <Route exact path="/forms" component={AppForms} />
    <Route exact path="/forms/requests/all" component={AppFormsRequest} />
    <Route exact path="/forms/:id" component={AppForm} />

    <Route path="/apps-users" component={AppsUsersPage} />

    <Route path="/gd/devices" component={GuestDirectoryDevicesPage} />
    <Route path="/gd/analytics" component={GuestDirectoryAnalyticsPage} />
    <Route path="/gd/media-items" component={GuestDirectoryMediaItemsPage} />
  </GuestDirectoryContainer>
);

const AppRoutes = () => (
  <Shell>
    <Switch>
      <Route exact path="/account/login" component={LoginPage} />
      <Route
        exact
        path="/account/forgotpassword"
        component={ForgotPasswordPage}
      />
      <Route
        exact
        path="/account/resetpassword"
        component={ResetPasswordPage}
      />
      <Route exact path="/" component={Main} />
      <Route path="/accounts/:id" component={AccountContainer} />

      <Route path="/apps" component={GuestDirectoryRoute} />
      <Route path="/apps-users" component={GuestDirectoryRoute} />
      <Route path="/app/:id" component={AppRoute} />
      <Route path="/gd" component={GuestDirectoryRoute} />
      <Route path="/forms" component={GuestDirectoryRoute} />

      <Route
        exact
        path="/digitalsignage/builder/:id"
        component={DigitalSignageContainer}
      />
      <Route
        exact
        path="/digitalsignage/builder/:id/:action"
        component={DigitalSignageContainer}
      />
      <Route path="/digitalsignage" component={DigitalSignageRoute} />

      <Route path="/pushnotifications" component={PushNotifications} />

      <Route path="/admin" component={AdminRoute} />

      {/* For testing purposes */}
      <Route path="/loading" component={Loading} />

      <Route path="/dev" component={DeveloperRoot} />

      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route component={NotFound} />
    </Switch>
  </Shell>
);

export const routes = (
  <Switch>
    <Route exact path="/account/login" component={LoginPage} />
    <Route
      exact
      path="/account/forgotpassword"
      component={ForgotPasswordPage}
    />
    <Route exact path="/account/resetpassword" component={ResetPasswordPage} />
    <Route component={AppRoutes} />
  </Switch>
);

export const anonymousRoutes = (
  <Switch>
    <Route exact path="/" component={LoginPage} />
    <Route exact path="/account/login" component={LoginPage} />

    <Route path="/privacy-policy" component={PrivacyPolicy} />

    <Route component={NotFound} />
  </Switch>
);
