import * as React from 'react';

interface Props {
  values: number[];
}

export const MultiProgress: React.SFC<Props> = ({ values }) => {
  if (!values || values.length === 0) return null;

  return (
    <div className="progress">
      {values.map((value, index) => {
        if (value === 0) return null;

        const width = (value / 100) * (100 / values.length);
        const statusclass = value === 100 ? 'progress-bar-success' : '';
        return (
          <div
            key={index}
            className={'progress-bar ' + statusclass}
            style={{ width: `${width}%` }}
          >
            <span className="sr-only">{value}% Complete (success)</span> {value}
          </div>
        );
      })}
    </div>
  );
};
