import React, { PureComponent } from 'react';

import {
  SimpleModal,
  Header,
  Content,
  Footer,
  ModalProps,
  LoadingOverlay,
} from 'Components/shared';
import { Label, Input, Button } from 'ui';
import { AppType } from 'api';
import { formatAlias } from './formatAlias';
import { cx, css } from 'emotion';

type Props = ModalProps & {
  create: (
    name: string,
    email: string,
    appType: AppType,
    password: string
  ) => Promise<any>;
};

type State = {
  isCreating: boolean;
  name: string;
  email: string;
  appType: AppType;
  password: '';
  error: string;
};

function isValidEmail(email: string) {
  return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
}

class CreateAppUserModal extends PureComponent<Props, State> {
  state: State = {
    isCreating: false,
    name: '',
    email: '',
    password: '',
    appType: AppType.GuestDirectory,
    error: '',
  };

  render() {
    const { show, onClose } = this.props;
    const { name, email, isCreating, password, error } = this.state;
    const canCreate = !!name && isValidEmail(email) && !isCreating && password;

    return (
      <SimpleModal show={show} hide={onClose}>
        <Header>Create new application and/for user</Header>

        <Content>
          <Label>
            Application name
            <Input
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
            />
          </Label>

          <Label>
            Email
            <Input
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
            />
          </Label>

          <Label>
            Password
            <Input
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
            />
          </Label>

          <Label>
            Alias
            <Input value={formatAlias(name)} readOnly />
          </Label>

          {error && (
            <p
              className={cx(
                'bg-danger',
                css`
                  padding: 10px;
                `
              )}
            >
              {error}
            </p>
          )}
          {isCreating && <LoadingOverlay />}
        </Content>

        <Footer>
          <Button onClick={onClose}>Close</Button>
          <Button type="primary" onClick={this.create} disabled={!canCreate}>
            Create
          </Button>
        </Footer>
      </SimpleModal>
    );
  }

  create = async () => {
    const { name, email, password, appType } = this.state;
    this.setState({ isCreating: true });

    try {
      await this.props.create(name, email, appType, password);
      this.setState({
        name: '',
        email: '',
        appType: AppType.GuestDirectory,
        password: '',
        error: '',
      });
      this.props.onClose();
    } catch (e) {
      if (e && e.response && e.response.data) {
        this.setState({ error: e.response.data });
      }
    }

    this.setState({ isCreating: false });
  };
}

export default CreateAppUserModal;
