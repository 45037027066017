import React, { PureComponent } from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import { PlaylistDetailsMediaItem } from 'api';

import { formatMediaType, MediaItemType } from '../../enums';
import formatDuration from '../../utilities/formatDuration';

import { MediaItemThumb } from '../MediaItems/media-item-thumb';
import { MediaPreviewLink } from '../MediaItems/media-preview-link';
import { ImagePreviewModal } from '../MediaItems/image-preview-modal';
import { VideoPreviewModal } from '../MediaItems/video-preview-modal';
import { WithDialog } from 'Components/shared';
import { MovePlaylistItemModal } from './move-playlist-item-modal';
import formatByteSize from 'utilities/formatByteSize';

const DragHandle = SortableHandle(() => (
  <span className="drag-handle fa fa-bars" />
));

export type PlaylistMediaItemRowProps = {
  item: PlaylistDetailsMediaItem;
  itemsCount: number;
  onDelete: (item: PlaylistDetailsMediaItem) => void;
  onAvailability: (item: PlaylistDetailsMediaItem) => void;
  onSaveDurationChange: (
    item: PlaylistDetailsMediaItem,
    duration: number
  ) => void;
  onClearDuration: (item: PlaylistDetailsMediaItem) => void;
  onChangePosition: (currentPosition: number, newPosition: number) => void;
};

export class PlaylistMediaItemRow extends PureComponent<
  PlaylistMediaItemRowProps,
  { uri: string; duration: number; showPreview: boolean }
> {
  constructor(props: PlaylistMediaItemRowProps) {
    super(props);

    let uri = '';
    try {
      const meta = props.item.meta;
      uri = meta ? JSON.parse(meta).uri : null;
    } catch (e) {}

    this.state = { uri, duration: props.item.duration, showPreview: false };
  }

  showPreview = () => this.setState({ showPreview: true });
  hidePreview = () => this.setState({ showPreview: false });

  delete = () => this.props.onDelete(this.props.item);
  availability = () => this.props.onAvailability(this.props.item);
  saveDurationChange = () =>
    this.props.onSaveDurationChange(this.props.item, this.state.duration);
  clearDuration = () => this.props.onClearDuration(this.props.item);

  changePosition = (newPosition: number) => {
    this.props.onChangePosition(this.props.item.order, newPosition);
  };

  render() {
    const { item, itemsCount } = this.props;
    const { showPreview, uri, duration } = this.state;

    return (
      <>
        <tr className={item.isDisabled ? 'disabled' : ''}>
          <td>
            <DragHandle /> {item.order}
          </td>
          <td>
            <MediaItemThumb
              mediaItemId={item.mediaItemId}
              thumbUri={item.mediaThumbUri}
              type={item.mediaItemType}
              className="pull-left"
              onClick={this.showPreview}
            />
          </td>
          <td>
            <MediaPreviewLink
              id={item.mediaItemId}
              name={item.mediaName}
              type={item.mediaItemType}
              onClick={this.showPreview}
            />
          </td>
          <td>{formatMediaType(item.mediaItemType)}</td>
          <td>
            <section className="flex flex-col">
              <span>
                {item.width}x{item.height}
              </span>
              <span>
                {item.mediaItemType !== MediaItemType.Presentation &&
                  formatByteSize(item.size)}
              </span>
            </section>
          </td>
          {item.mediaItemType === MediaItemType.Image ? (
            <td className="duration-override-cell">
              <span className="duration">{formatDuration(item.duration)}</span>
              <div className="duration-override-holder">
                <input
                  type="number"
                  className="duration-override"
                  value={duration}
                  name="duration"
                  onChange={(e) =>
                    this.setState({ duration: parseInt(e.target.value, 10) })
                  }
                  onBlur={this.saveDurationChange}
                />
                <button
                  type="button"
                  className="close"
                  aria-label="Reset"
                  data-title="Reset to default value"
                  onClick={this.clearDuration}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </td>
          ) : (
            <td>
              <span className="duration">{formatDuration(item.duration)}</span>
            </td>
          )}
          <td>
            <div className="">
              <a onClick={this.availability}>
                {item.isDisabled ? 'Enable ' : 'Disable '}
              </a>
              <a onClick={this.delete} title="Remove this media item">
                <span
                  className="glyphicon glyphicon-trash"
                  style={{ color: 'red', lineHeight: '70px' }}
                />
              </a>{' '}
              <WithDialog>
                {(isOpen, show, hide) => (
                  <>
                    <a title="Move this item" onClick={show}>
                      <span className="glyphicon glyphicon-menu-right" />
                    </a>

                    <MovePlaylistItemModal
                      show={isOpen}
                      onClose={hide}
                      current={item.order}
                      places={itemsCount}
                      onChangePosition={this.changePosition}
                    />
                  </>
                )}
              </WithDialog>
            </div>
          </td>
        </tr>

        {showPreview && item.mediaItemType === MediaItemType.Image && (
          <ImagePreviewModal
            show
            name={item.mediaName}
            uri={uri}
            onClose={this.hidePreview}
          />
        )}
        {showPreview && item.mediaItemType === MediaItemType.Video && (
          <VideoPreviewModal
            show
            name={item.mediaName}
            uri={uri}
            onClose={this.hidePreview}
          />
        )}
      </>
    );
  }
}
