import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavBar from '../NavBar';
import Account, { AccountProps } from './Account';
import { loadAccountInfo, editAccount, accountCreateUser } from './actions';
import { addDangerNotification } from '../NotificationActions';

class AccountContainer extends Component<AccountProps> {
  componentDidMount() {
    let user = window['userState'];
    if (!this.props.account) this.props.loadAccountInfo(user.accountId);
  }

  render() {
    return (
      <div>
        <NavBar title="Account" />
        <Account {...this.props} user={window['userState']} />
      </div>
    );
  }
}

export default connect(
  state => ({
    isLoading: state.account.isLoading,
    isSaving: state.account.isSaving,
    account: state.account.data,
  }),
  {
    loadAccountInfo,
    editAccount,
    addDangerNotification,
    accountCreateUser,
  }
)(AccountContainer);
