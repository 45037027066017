import React, { useState } from 'react';

import {
  SimpleModal,
  ModalProps,
  Footer,
  Header,
  Content,
} from 'Components/shared';
import { Button, Label, Input } from 'ui';
import { cx, css } from 'emotion';

type Props = {
  name: string;
  onSetPassword: (password: string) => Promise<any>;
} & ModalProps;

export default function SetPasswordModal({
  name,
  show,
  onClose,
  onSetPassword,
}: Props) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const set = () =>
    onSetPassword(password)
      .then(() => {
        setPassword('');
        setError('');
      })
      .catch(e => {
        setError(e.response.data);
      });

  return (
    <SimpleModal show={show} hide={onClose}>
      <Header>Set new password for {name}</Header>
      <Content>
        <Label>
          New passowrd
          <Input value={password} onChange={e => setPassword(e.target.value)} />
        </Label>
        {error && (
          <p
            className={cx(
              'bg-danger',
              css`
                padding: 10px;
              `
            )}
          >
            {error}
          </p>
        )}
      </Content>
      <Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="primary" onClick={set}>
          Set
        </Button>
      </Footer>
    </SimpleModal>
  );
}
