import React from 'react';

import { AppType, BackgroundType } from '../../../enums';

function toThumbUri(str) {
  let last = str.lastIndexOf('.');
  if (last >= 0) {
    return str.substr(0, last) + '_thumb' + str.substr(last);
  }
  return str;
}

const SingleImage = ({ image, handleSet, handleRemove }) => {
  if (!image)
    return (
      <button className="btn btn-default" onClick={handleSet}>
        Set
      </button>
    );

  return (
    <div
      style={{ backgroundImage: `url(${toThumbUri(image)})` }}
      className="thumbnail-image"
    >
      <button title="Remove this image" onClick={handleRemove}>
        <i className="fa fa-trash" />
      </button>
    </div>
  );
};

const PageBackgroundImage = ({
  appType,
  background,
  handleSet,
  handleRemove,
}) => {
  return (
    <div>
      <div className="col-xs-4">
        <label
          htmlFor=""
          className="subtle-label"
          style={{ marginTop: '15px' }}
        >
          Background image
        </label>
      </div>

      {appType === AppType.GuestDirectory ? (
        <div className="col-xs-8">
          <div className="col-xs-6">
            <h5 className="subtle-label">Landscape (2048x1536)</h5>
            <SingleImage
              image={background.landscape}
              handleSet={e =>
                handleSet(e, BackgroundType.Landscape_GuestDirectory)
              }
              handleRemove={e =>
                handleRemove(e, BackgroundType.Landscape_GuestDirectory)
              }
            />
          </div>

          <div className="col-xs-6">
            <h5 className="subtle-label">Portrait (1536x2048)</h5>
            <SingleImage
              image={background.portrait}
              handleSet={e =>
                handleSet(e, BackgroundType.Portrait_GuestDirectory)
              }
              handleRemove={e =>
                handleRemove(e, BackgroundType.Portrait_GuestDirectory)
              }
            />
          </div>
        </div>
      ) : (
        <div className="form-group">
          <h5>Portrait (750x1334)</h5>
          <div>
            <SingleImage
              image={background.portrait}
              handleSet={e => handleSet(e, BackgroundType.Portrait_Mobile)}
              handleRemove={e =>
                handleRemove(e, BackgroundType.Portrait_Mobile)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageBackgroundImage;
