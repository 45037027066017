import React from 'react';
import { defaultTextStyle } from '../contentHelpers';

import Select from '../../FormHelpers/Select';

import { HorizontalTextAlignment } from '../../../xamarin';

const TextStyle = ({ data, theme, handleUpdate }) => {
  return null;
  /*let style = Object.assign({}, defaultTextStyle, data.style || {})

    // if missing, apply immediately
    if (!data.style) {
        handleUpdate(Object.assign({}, data, { style: style }))
    }

    return (
        <div className="edit-block">
            <h4 className="edit-block-title">Text</h4>
            <Select className="form-control" name="style-horizontalTextAlignment" value={style.horizontalTextAlignment} values={HorizontalTextAlignment}
                onChange={e => handleUpdate(set(e, data))} />
        </div>
    )*/
};

export default TextStyle;
