import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import ScheduleControl from '../Channels/ScheduleControl';

class CreateSchedule extends Component<any, any> {
  state: any = {
    isLoadingChannels: false,
    channels: [],
    selectedChannel: 0,

    schedule: {
      days: [],
      startDate: moment.utc().format(),
      startTime: moment.utc().startOf('day').format('HH:mm'),
      endTime: moment.utc().startOf('day').format('HH:mm'),
      endDate: moment.utc().format(),
      variables: {},
    },
    isValid: true,
  };

  componentDidMount() {
    this.setState({ isLoadingChannels: true });
    axios
      .get(`/api/channels/account/${window['userState'].accountId}`)
      .then(res => {
        this.setState({
          isLoadingChannels: false,
          channels: res.data,
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoadingChannels: false });
        this.props.addDangerNotification('Unable to load channels');
      });
  }

  handleSelectChannel = e => {
    var channelId = e.target.selectedOptions[0].getAttribute('value');
    this.setState({
      selectedChannel: channelId ? Number(channelId) : 0,
    });
  };

  handleCreate = e => {
    this.props
      .handleAction(this.state.selectedChannel, this.state.schedule)
      .then(r => {
        if (r) $(`#${this.props.name}`).modal('hide');
        this.reset();
      })
      .catch(e => {
        var errors = e.response.data;
        if (errors) {
          if (errors.error && typeof errors.error === 'string') {
            this.setState({
              errors: {
                _all: errors.error,
              },
            });
          } else {
            var newState = this.state.errors;
            for (var fieldName in errors) {
              errors[fieldName] = false;
            }

            this.setState({
              errors: newState,
            });
          }
        }
      });
  };

  handleScheduleChange = (schedule, isValid) => {
    this.setState({ schedule: schedule, isValid: isValid });
  };

  reset = () => {
    this.setState({ selectedChannel: 0 });
  };

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Create schedule for channel
              </h4>
            </div>
            <div className="modal-body">
              <form id={this.props.name + 'Form'}>
                <div className="form-group clearfix">
                  <label className="control-label" htmlFor="channel">
                    Channel name
                  </label>
                  <select
                    className="form-control"
                    onChange={this.handleSelectChannel}
                    value={this.state.selectedChannel}
                    name="channel"
                    style={styles.channelPick}
                  >
                    <option value="0">Select channel...</option>
                    {this.state.channels.map((channel, index) => (
                      <option key={channel.id} value={channel.id}>
                        {channel.name}
                      </option>
                    ))}
                  </select>
                </div>

                <ScheduleControl
                  schedule={this.state.schedule}
                  onChange={this.handleScheduleChange}
                />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-fancy"
                onClick={this.handleCreate}
                disabled={
                  !this.state.isValid || this.state.selectedChannel === 0
                }
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  channelPick: {
    marginBottom: '30px',
  },
};

export default CreateSchedule;
