import axios from 'axios';

import {
  defaultData,
  createChild,
  createContent,
  convertData,
} from './contentHelpers';
import defaultAppTheme from './defaultAppTheme';
import { getApp } from 'api';

export const load_app = id => (dispatch, getState) => {
  const state = getState();
  if (state && state.App && state.App.isLoading) {
    console.log('already loading...');
    return;
  }

  dispatch({ type: 'LOAD_APP' });

  getApp(id)
    .then(r => {
      const data = r.data;
      let meta: any = null;
      let theme = defaultAppTheme(r.data.appType, r.data.platforms);

      if (data) {
        if (data.draftMeta && data.draftMeta.data) {
          meta = data.draftMeta.data;
        } else if (data.publishedMeta && data.publishedMeta.data) {
          meta = data.publishedMeta.data;
        }

        if (meta && meta.theme) {
          // FIXME: when introducing new properties, correctly merge theme styles
          theme = Object.assign(theme, meta.theme);
        }
      }

      let languages = meta ? meta.languages : null;
      if (languages && languages.constructor === String) languages = null;
      languages = languages || [
        {
          name: 'English',
          nativeName: 'English',
          shortCode: 'en',
          isDefault: true,
        },
      ];

      // ensure there is at least one default language
      const hasDefault = languages.find(l => l.isDefault);
      if (!hasDefault) {
        for (var i = 0; i < languages.length; ++i) {
          if (languages[i].shortCode === 'en') {
            languages[i].isDefault = true;
            languages[i].isEnabled = true;
            break;
          }
        }
      }
      // also ensure that non-default languages are disabled if not enabled
      for (var i = 0; i < languages.length; ++i) {
        let language = languages[i];
        if (!language.isDefault && !language.hasOwnProperty('isEnabled'))
          language.isEnabled = false;
      }

      dispatch({
        type: 'LOAD_APP_SUCCESS',
        data: data,
        languages: languages,
        meta: meta,
        theme: theme,
      });
    })
    .catch(e => {
      dispatch({ type: 'LOAD_APP_ERROR', error: e.response.data });
    });
};

export const addLanguage = language => ({
  type: 'APP_LANGUAGE_ADD',
  language: language,
});
export const removeLanguage = language => ({
  type: 'APP_LANGUAGE_REMOVE',
  language: language,
});
export const enableLanguage = language => ({
  type: 'APP_LANGUAGE_ENABLE',
  language: language,
});
export const disableLanguage = language => ({
  type: 'APP_LANGUAGE_DISABLE',
  language: language,
});

export const enableForm = formId => ({
  type: 'APP_FORM_ENABLE',
  formId: formId,
});
export const disableForm = formId => ({
  type: 'APP_FORM_DISABLE',
  formId: formId,
});

export const deletePage = (pageid, appId) => (dispatch, getState) => {
  appId = appId || getState().App.data.id;
  dispatch({ type: 'DELETE_PAGE_STARTED' });

  axios
    .delete(`/api/pages/${pageid}`)
    .then(r => {
      dispatch({ type: 'DELETE_PAGE_SUCCESS', data: r.data });
      dispatch(load_app(appId));
    })
    .catch(e => {
      dispatch({ type: 'DELETE_PAGE_ERROR', error: e.response.data });
    });
};

export const updatePlatformStyle = (platform, style, name, value) => ({
  type: 'APP_UPDATE_PLATFORM_STYLE',
  platform: platform,
  style: style,
  name: name,
  value: value,
});

export const publishApp = appId => dispatch => {
  if (confirm('Are you sure you want to publish app?')) {
    dispatch(appPublishStart());

    axios
      .post(`/api/publish/app/${appId}`)
      .then(r => {
        dispatch(appPublished());
        dispatch(load_app(appId));
      })
      .catch(e => {
        dispatch(appPublishError(e));
      });
  }
};

export const appPublishStart = () => ({ type: 'APP_PUBLISH_START' });
export const appPublished = () => ({ type: 'APP_PUBLISH_SUCCESS' });
export const appPublishError = error => ({
  type: 'APP_PUBLISH_ERROR',
  error: error,
});

export const loadPage = (pageid, templates, languages, pages) => (
  dispatch,
  getState
) => {
  const state = getState();
  if (state && state.Page && state.Page.isLoading && state.Page.id === pageid) {
    console.log('already loading...');
    return;
  }

  dispatch({ type: 'LOAD_PAGE_STARTED', data: { id: pageid } });

  axios
    .get(`/api/pages/${pageid}`)
    .then(r => {
      // FIXME: this should be easier on backend
      let data =
        r.data && r.data.versions && r.data.versions.length > 0
          ? r.data.versions[0].rawData
          : null;
      if (!data) data = defaultData();
      else if (!data.cms_version) data = convertData(data);

      let templateAlias = data.template;
      let content: any[] = data.content || [];
      let template = templates.find(x => x.alias === templateAlias);
      let layout = template ? template.layout.split(',') : [];

      if (templateAlias === 'blank' || !templateAlias) {
        if (r.data.level === 0) {
          // default override for root pages
          // for other blank pages we will ask user to pick a template
          templateAlias = 'page-home';
          template = templates.find(x => x.alias === templateAlias);
          layout = template.layout.split(',');
          content = createContent(layout, r.data.name, languages);
        }
      }

      // if unsaved, page might think it doesn't have children, but in fact it does
      // find all pages that claim this page as parent
      let children = data.children || [];
      let allSubpages = pages.filter(page => page.parentPageId === r.data.id);

      for (var i = 0; i < allSubpages.length; ++i) {
        const subpage = allSubpages[i];
        if (!children.find(child => child.id === subpage.id)) {
          // missing child, add it with defaults
          children.push(createChild(subpage, subpage.name, languages));
        }
      }

      const home = pages.find(p => p.level === 0);

      if (home) {
        // it is also possible that page has been deleted, but parent has no idea
        // let's remove such pages
        children = children
          .filter(
            child =>
              allSubpages.find(x => x.id === child.id) || child.id === home.id
          )
          // update children with order information
          .map(child => {
            var page = allSubpages.find(c => c.id === child.id);
            child.order = page ? page.order : 1;
            return child;
          });

        // if this is a home page, ensure itself is added to the list of children
        if (
          Number(r.data.id) === Number(home.id) &&
          children.findIndex(p => Number(p.id) === Number(home.id)) === -1
        ) {
          children.splice(0, 0, createChild(home, home.name, languages));
        }
      }
      // finally sort children
      children.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });

      // fix ids after load
      content.forEach((block, index) => (block.id = index + 1));

      dispatch({
        type: 'LOAD_PAGE_SUCCESS',
        data: {
          id: r.data.id,
          name: r.data.name,

          page: r.data,
          data: data,
          content: content,
          subpages: children,
          template: {
            name: template ? template.name : '',
            alias: templateAlias,
            layout: layout,
          },
        },
      });
    })
    .catch(e => {
      dispatch({ type: 'LOAD_PAGE_ERROR', error: e.response.data });
    });
};

export const setPageContent = (index, data) => {
  return {
    type: 'PAGE_SET_CONTENT',
    index: index,
    data: data,
  };
};

export const addBlock = data => ({
  type: 'PAGE_BLOCK_ADD',
  payload: data,
});

export const moveBlockUp = (id: number) => ({
  type: 'PAGE_BLOCK_MOVEUP',
  payload: id,
});
export const moveBlockDown = (id: number) => ({
  type: 'PAGE_BLOCK_MOVEDOWN',
  payload: id,
});
export const removeBlock = (id: number) => ({
  type: 'PAGE_BLOCK_REMOVE',
  payload: id,
});

export const setPageSubpages = subpages => {
  return {
    type: 'PAGE_SET_SUBPAGES',
    subpages: subpages,
  };
};

export const setSubpage = subpage => {
  return {
    type: 'PAGE_SET_SUBPAGE',
    subpage: subpage,
  };
};

export const changePageTemplate = (name, languages, template) => {
  const layout = template.layout.split(',');
  return {
    type: 'PAGE_SET_TEMPLATE',
    content: createContent(layout, name, languages),
    template: {
      name: template.name,
      alias: template.alias,
      layout: layout,
    },
  };
};

export const setPageBackground = background => ({
  type: 'PAGE_SET_BACKGROUND',
  background: background,
});

export const pageSortSubpages = order => (dispatch, getState) => {
  const state = getState();
  let appid = state.App.data.id;
  let pageid = state.Page.id;

  axios
    .post(`/api/pages/reorder`, {
      pages: order,
    })
    .then(r => {
      dispatch(load_app(appid));
    })
    .catch(e => {});
};

export const hidePage = pageId => (dispatch, getState) => {
  if (confirm('Are you sure you want to hide the page?')) {
    dispatch(hidePageStart(pageId));
    axios
      .post(`/api/pages/${pageId}/hide`)
      .then(r => {
        dispatch(hidePageStarted());
        let store = getState();
        let { templates, languages, pages } = store.App;
        dispatch(loadPage(pageId, templates, languages, pages));
      })
      .catch(e => {
        dispatch(hidePageError(e));
      });
  }
};

export const hidePageStart = pageId => ({
  type: 'PAGE_HIDE_START',
  pageId: pageId,
});
export const hidePageStarted = () => ({ type: 'PAGE_HIDE_SUCCESS' });
export const hidePageError = error => ({
  type: 'PAGE_HIDE_ERROR',
  error: error,
});

export const unhidePage = pageId => (dispatch, getState) => {
  if (confirm('Are you sure you want to show the page?')) {
    dispatch(unhidePageStart(pageId));
    axios
      .post(`/api/pages/${pageId}/show`)
      .then(r => {
        dispatch(unhidePageStarted());
        let store = getState();
        let { templates, languages, pages } = store.App;
        dispatch(loadPage(pageId, templates, languages, pages));
      })
      .catch(e => {
        dispatch(unhidePageError(e));
      });
  }
};

export const unhidePageStart = pageId => ({
  type: 'PAGE_UNHIDE_START',
  pageId: pageId,
});
export const unhidePageStarted = () => ({ type: 'PAGE_UNHIDE_SUCCESS' });
export const unhidePageError = error => ({
  type: 'PAGE_UNHIDE_ERROR',
  error: error,
});

export const saveSettings = settings => ({
  type: 'PAGE_SAVE_SETTINGS',
  settings: settings,
});

export const pageCheckName = () => ({ type: 'PAGE_CHECK_NAME' });

export const pageSetName = name => ({
  name,
  type: 'PAGE_SET_NAME',
});
