import React, { Component } from 'react';

class EditChannelDialog extends Component<any, any> {
  state: any = {
    name: '',
    groupName: '',
  };

  componentDidMount() {
    this.setState({
      name: this.props.channel.name,
      groupName: this.props.channel.groupName,
    });
  }

  handleCancel = () => {
    this.close();
  };

  handleOk = () => {
    if (
      this.props.handleOk({
        name: this.state.name,
        groupName: this.state.groupName,
      }) === true
    ) {
      this.close();
    }
  };

  close = () => {
    this.props.handleClose();
    $(`#${this.props.name}`).modal('hide');
  };

  set = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() });
  };

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Edit channel
              </h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="name" className="subtle-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.name}
                  name="name"
                  onChange={this.set}
                />
              </div>

              <div className="form-group">
                <label htmlFor="groupName" className="subtle-label">
                  Group name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.groupName}
                  name="groupName"
                  onChange={this.set}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-fancy"
                onClick={this.handleOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditChannelDialog;
