import React, { Component } from 'react';
import TinyMCE from 'react-tinymce';

import { SimpleModal, Header, Footer, Content } from 'Components/shared';
import { Button } from 'ui';

type Props = {
  isOpen: boolean;
  text: string;
  onHide: () => void;
  onSave: (text: string) => void;
};

class State {
  text = '';
  temp = '';
}

class RichTextModal extends Component<Props, State> {
  state = new State();

  constructor(props) {
    super(props);

    this.state = {
      ...new State(),
      text: props.text || '',
    };
  }

  componentDidMount() {
    if (tinyMCE.activeEditor) {
      tinyMCE.activeEditor.setContent(this.state.text);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.text !== nextProps.text) {
      this.setState({ text: nextProps.text });
    }
  }

  handleChange = e => {
    const html = e.target.getContent();
    this.setState({ temp: html });
  };

  handleSave = () => {
    this.props.onSave(this.state.temp);
  };

  render() {
    return (
      <SimpleModal show={this.props.isOpen} hide={this.props.onHide}>
        <Header>Edit text</Header>
        <Content>
          <TinyMCE
            content={this.state.text}
            config={{
              menubar: false,
              // Disable HTML tags when pasting
              plugins: 'paste autoresize',
              statusbar: false,
              toolbar: 'bold italic undo redo',
              paste_as_text: true,
              min_height: 100,
            }}
            onChange={this.handleChange}
          />
        </Content>
        <Footer>
          <div className="modal-footer">
            <Button onClick={this.props.onHide}>Cancel</Button>
            <Button onClick={this.handleSave}>Save</Button>
          </div>
        </Footer>
      </SimpleModal>
    );
  }
}

export default RichTextModal;
