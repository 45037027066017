import React from 'react';
import { Link } from 'react-router-dom';

import NavBar from './NavBar';
import { FeatureFlag } from 'Components/shared';
import { Container } from 'ui';

const Main = () => (
  <Container>
    <div className="col-md-11 col-md-offset-1">
      <NavBar title="EmGuest" />
      <div className="">
        <h2>Guest directory</h2>

        {/* Mobile applications */}
        <FeatureFlag feature="mobileapps">
          <Link to="/apps" className="main-section card-bg">
            Applications
          </Link>

          <Link to="/forms" className="main-section card-bg">
            Forms
          </Link>

          <FeatureFlag feature="mobileapps-pushnotifications">
            <Link to="/pushnotifications" className="main-section card-bg">
              Push notifications
            </Link>
          </FeatureFlag>

          <FeatureFlag feature="mobileapps-appusers">
            <Link to="/apps-users" className="main-section card-bg">
              Apps & Users
            </Link>
          </FeatureFlag>

          <Link to="/gd/devices" className="main-section card-bg">
            Devices
          </Link>

          <Link to="/gd/analytics" className="main-section card-bg">
            Analytics
          </Link>

          <Link to="/gd/media-items" className="main-section card-bg">
            Media items
          </Link>

          <div className="clearfix" />
          <hr />
        </FeatureFlag>

        {/* Digital signage */}
        <FeatureFlag feature="digitalsignage">
          <h2>Digital signage</h2>

          <Link to="/digitalsignage/screens" className="main-section card-bg">
            Screens
          </Link>
          <Link to="/digitalsignage/gallery" className="main-section card-bg">
            Gallery
          </Link>
          <Link to="/digitalsignage/playlists" className="main-section card-bg">
            Playlists
          </Link>
          <Link to="/digitalsignage/channels" className="main-section card-bg">
            Channels
          </Link>

          <div className="clearfix" />
          <hr />
        </FeatureFlag>

        <FeatureFlag feature="admin">
          <h2>Admin</h2>

          <Link to="/admin" className="main-section card-bg">
            Admin
          </Link>
        </FeatureFlag>
      </div>
    </div>
  </Container>
);

export default Main;
