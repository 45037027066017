import React, { SFC } from 'react';
import { Link } from 'react-router-dom';

import { MediaItemType } from 'api';

export type MediaPreviewLinkProps = {
  id: number;
  name: string;
  type: MediaItemType;
  onClick: () => void;
};

export const MediaPreviewLink: SFC<MediaPreviewLinkProps> = ({
  id,
  type,
  name,
  onClick,
}) => {
  switch (type) {
    case MediaItemType.Presentation:
      return <Link to={`/digitalsignage/builder/${id}/readonly`}>{name}</Link>;

    case MediaItemType.Video:
      return <a onClick={onClick}>{name}</a>;

    case MediaItemType.Image:
      return <a onClick={onClick}>{name}</a>;
  }

  return <span>{name}</span>;
};
