import React, { Component } from 'react';
import axios from 'axios';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';
import NavBar from '../NavBar';
import { Loading } from 'Components/shared';
import DigitalSignageBuilder from './DigitalSignageBuilder';

class DigitalSignageContainer extends Component<any, any> {
  state: any = {
    presentation: {
      isDraft: false,
    },
    name: '',
    duration: 0,
    isLoading: false,
    isSaving: false,
    isPublishing: false,
    oldCanvas: null,

    undoStack: [],
  };

  canvas: any;
  saveTimeout: any = null;

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });
      this.loadPresentation(this.props.match.params.id);
    }
  }

  handleDurationChange = (duration, offset) => {
    this.setState({
      duration: duration + offset,
    });
  };

  handleCanvasCreated = canvas => {
    this.canvas = canvas;
  };

  loadPresentation = id => {
    axios
      .get(`/api/presentations/${id}`)
      .then(r => {
        //if (localStorage.getItem('canvas') !== undefined && localStorage.getItem('canvas') !== '') {
        this.props.addInfoNotification('Presentation loaded.');
        this.setState({
          isLoading: false,
          presentation: r.data,
          name: r.data.name,
          oldCanvas: r.data.meta ? JSON.parse(r.data.meta) : null,
          undoStack: [r.data.meta || '{}'],
        });
      })
      .catch(e => {
        this.props.addDangerNotification('Unable to load presentation');
      });
  };

  publish = () => {
    if (this.state.isPublishing) return;

    if (!confirm('Are you sure you want to publish the presentation?')) return;

    this.setState({
      isPublishing: true,
      undoStack: [],
    });

    axios
      .post(`/api/presentations/${this.state.presentation.id}/publish`)
      .then(r => {
        this.setState({ isPublishing: false });
        this.loadPresentation(this.state.presentation.id);
        this.props.addSuccessNotification('Presentation is published!');
      })
      .catch(e => {
        this.setState({ isPublishing: false });
        this.props.addDangerNotification(
          'Unable to publish, please try again or if the error persists, contact support'
        );
      });
  };

  delaySave = data => {
    clearTimeout(this.saveTimeout);
    this.saveTimeout = null;
    console.log('saving...');

    this.setState({ isSaving: true });
    axios
      .put(`/api/presentations/${this.state.presentation.id}`, data)
      .then(r => {
        this.props.addSuccessNotification('Presentation saved');
        let undoStack = this.state.undoStack.slice(
          0,
          this.state.undoStack.length
        );
        undoStack.push(data.meta);
        this.setState({
          isSaving: false,
          presentation: {
            ...this.state.presentation,
            isDraft: true,
          },
          name: this.state.presentation.name,
          undoStack: undoStack,
        });
      })
      .catch(e => {
        console.dir(e);
        this.setState({ isSaving: false });
        this.props.addDangerNotification(
          'Unable to save, please try again or if the error persists, contact support'
        );
      });
  };

  save = data => {
    if (this.state.isSaving) return;

    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
      console.log('skipping...');
    }

    this.saveTimeout = setTimeout(() => this.delaySave(data), 250);
  };

  handleSave = () => {
    if (!this.canvas) return;

    this.canvas.dimensions = {
      width: this.canvas.getWidth(),
      height: this.canvas.getHeight(),
    };

    // Added [dimensions] to preserve it while saving to JSON and loading from it
    //localStorage.setItem('canvas', JSON.stringify(canvas.toJSON(['dimensions'])));
    var meta = JSON.stringify(this.canvas.toJSON(['dimensions']));

    this.save({
      duration: this.state.duration,
      meta: meta,
    });
  };

  resetUntilSave = () => {
    axios
      .post(`/api/presentations/${this.state.presentation.id}/undo`)
      .then(r => {
        this.loadPresentation(this.state.presentation.id);
        this.props.addSuccessNotification('Presentation reverted');
      })
      .catch(e => {
        this.props.addDangerNotification(
          'Unable to undo, please try again or if the error persists, contact support'
        );
      });
  };

  undo = () => {
    if (this.state.undoStack.length > 1) {
      const undoStack = this.state.undoStack.slice(
        0,
        this.state.undoStack.length - 1
      );
      const data = undoStack[undoStack.length - 1];

      this.setState({
        oldCanvas: data ? JSON.parse(data) : null,
        undoStack: undoStack,
      });
    }
  };

  handleRename = e => {
    var name = prompt(
      'Enter new presentation name',
      this.state.presentation.name
    );
    if (name) {
      name = name.trim();
      if (name !== this.state.presentation.name) {
        axios
          .put(`/api/presentations/${this.state.presentation.id}`, {
            name: name,
          })
          .then(r => {
            this.props.addSuccessNotification('Presentation renamed');
            this.setState({
              name: name,
              presentation: {
                ...this.state.presentation,
                name: name,
              },
            });
          })
          .catch(e => {
            this.props.addDangerNotification(
              'Unable to save, please try again or if the error persists, contact support'
            );
          });
      }
    }
  };
  resetPresentation = () => {
    // TODO: wtf is this?
  };

  render() {
    let buttons = (
      <div>
        <span style={{ display: 'inline-block', width: '30px' }} />
        <button
          className="btn"
          onClick={this.undo}
          disabled={this.state.undoStack.length <= 1}
        >
          {' '}
          <i className="fa fa-undo" /> Undo
        </button>

        <button
          onClick={this.handleSave}
          className={`btn ${
            this.state.isSaving ? 'btn-green-animation' : null
          }`}
          disabled={this.state.isSaving}
        >
          <i className="fa fa-save" />{' '}
          {this.state.isSaving ? 'Saving...' : 'Save'}
        </button>

        <div className="dropdown" style={{ display: 'inline' }}>
          <button
            className="btn"
            title="Additional options"
            id="dropdownPresentationBuilderOptions"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <i className="glyphicon glyphicon-option-vertical" />
          </button>

          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownPresentationBuilderOptions"
          >
            <li className="hide">
              <a onClick={this.resetPresentation} style={{ color: 'red' }}>
                Reset presentation
              </a>
            </li>
            <li>
              <a onClick={this.resetUntilSave}>Undo changes until last save</a>
            </li>
            <li>
              <a onClick={this.handleRename}>Rename presentation</a>
            </li>
          </ul>
        </div>
        <button
          onClick={this.publish}
          className={`btn ${
            this.state.isPublishing ? 'btn-green-animation' : null
          }`}
          disabled={!this.state.presentation.isDraft}
        >
          {' '}
          <i className="fa fa-send" /> Publish
        </button>
      </div>
    );

    return (
      <div style={{ height: '100%' }}>
        <NavBar
          title="Presentation editor"
          link="/digitalsignage/gallery"
          special={buttons}
        >
          <li style={{ lineHeight: '50px', fontSize: '13px', float: 'left' }}>
            {this.state.presentation.name}
          </li>
        </NavBar>

        <div className="builder-holder" style={{ height: '100%' }}>
          <DigitalSignageBuilder
            match={this.props.match}
            presentation={this.state.presentation}
            oldCanvas={this.state.oldCanvas}
            save={this.handleSave}
            handleCanvasCreated={this.handleCanvasCreated}
            handleDurationChange={this.handleDurationChange}
          />

          {this.state.isLoading ? <Loading /> : null}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    addInfoNotification,
    addSuccessNotification,
    addDangerNotification,
  }
)(DigitalSignageContainer);
