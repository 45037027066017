import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from './Alert';
import { hideNotification } from './NotificationActions';

// TODO: also use same type in actions
// TODO: move type to actions
export interface NotificationProp {
  id: number;
  type: string;
  text: string;
  expiresAfter: number;
}

export interface NotificationsProps {
  notifications: NotificationProp[];
  hideNotification: (id: number) => void;
}

class Notifications extends Component<NotificationsProps> {
  timerId: any = null;

  componentDidMount() {
    if (this.props.notifications.length > 0) this.startTimer();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications.length === 0) {
      this.stopTimer();
    } else if (!this.timerId) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer = () => {
    console.log('starting timer for notifications');
    this.timerId = setInterval(this.checkNotifications, 1000);
  };

  stopTimer = () => {
    console.log('stopping timer');
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  };

  checkNotifications = () => {
    let now = Math.floor(Date.now() / 1000);
    for (let i = 0; i < this.props.notifications.length; ++i) {
      if (this.props.notifications[i].expiresAfter < now) {
        this.props.hideNotification(this.props.notifications[i].id);
      }
    }
  };

  render() {
    return (
      <div className="notifications">
        {this.props.notifications.map(notification => (
          <Alert
            key={notification.id}
            type={notification.type}
            handleClose={() => this.props.hideNotification(notification.id)}
          >
            {notification.text}
          </Alert>
        ))}
      </div>
    );
  }
}

export default connect(
  state => ({
    notifications: state.Notifications.notifications,
  }),
  {
    hideNotification,
  }
)(Notifications);
