import React from 'react';
import cn from 'classnames';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

export function Container({ children, className }: Props) {
  return <div className={cn('container', className)}>{children}</div>;
}
