import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';
import { FormRequestState, formatFormRequestState } from '../../enums';
import { Loading } from 'Components/shared';

class EditResponse extends Component<any, any> {
  state: any = {
    errors: {
      _all: '',
    },
    response: '',
  };

  componentDidMount() {
    this.setState({ response: this.props.response });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.response !== nextProps.response)
      this.setState({ response: nextProps.response });
  }

  set = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSend = (e) => {
    this.props.handleSend(this.state.response);
  };

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Edit response
              </h4>
            </div>
            <div className="modal-body">
              <form id={this.props.name + 'Form'}>
                <div className="form-group">
                  <label
                    htmlFor="response"
                    className="control-label subtle-label"
                  >
                    Response
                  </label>
                  <input
                    type="text"
                    name="response"
                    className="form-control"
                    value={this.state.response}
                    onChange={this.set}
                  />
                </div>

                <div className="has-error">
                  <div className="help-block with-errors">
                    {this.state.errors._all}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-fancy"
                onClick={this.handleSend}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Request = ({
  request,
  handleEditResponse,
  handleAcknowledge,
  handleComplete,
}) => {
  let rowClass =
    request.state === FormRequestState.Completed ? 'info' : 'warning';

  let title =
    request.state === FormRequestState.Completed ? 'Completed' : 'Pending';

  return (
    <tr className={`${rowClass}`} title={title}>
      <td>{request.id}</td>
      <td>{request.formName}</td>
      <td>{request.request}</td>
      {request.state === FormRequestState.Completed ? (
        <td>
          <span className="subtle-label">Completed: '{request.response}'</span>
          <br />
          <button className="btn btn-fancy" onClick={handleEditResponse}>
            Edit response
          </button>
        </td>
      ) : request.state === FormRequestState.Acknowledged ? (
        <td>
          <span className="subtle-label">
            Acknowledged: '{request.response}'
          </span>
          <br />
          <button className="btn btn-fancy" onClick={handleComplete}>
            Complete
          </button>
        </td>
      ) : (
        <td>
          <span className="subtle-label">New request</span>
          <br />
          <button className="btn btn-fancy" onClick={handleAcknowledge}>
            Acknowledge
          </button>
        </td>
      )}
      <td>{moment(request.createdOn).format('DD.MM HH:mm')}</td>
      <td>
        {request.state === FormRequestState.Sent
          ? null
          : moment(request.modifiedOn).format('DD.MM HH:mm')}
      </td>
    </tr>
  );
};

class AppFormsRequest extends Component<any, any> {
  handle: any = null;

  state: any = {
    isFetchingInfo: false,
    isFetched: false,
    isLoading: false,
    lastLoadAt: null,
    info: { id: 0, name: '' },
    page: 1,
    pageSize: 100,
    pageCount: 0,
    requests: [],

    // for modal
    request: { response: '' },
    showResponseModal: false,
    type: FormRequestState.Sent,
  };

  componentDidMount() {
    this.load(window['userState'].accountId);

    this.handle = setInterval(() => {
      this.load(window['userState'].accountId);
    }, 5000);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.params.id)
      this.load(window['userState'].accountId);
  }

  componentWillUnmount() {
    if (this.handle) clearInterval(this.handle);
  }

  load = (accountId) => {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      axios
        .get(
          `/api/formrequests/account/${accountId}/${this.state.page}/${this.state.pageSize}`
        )
        .then((r) => {
          this.setState({
            isLoading: false,
            requests: r.data.items,
            lastLoadAt: moment().format('HH:mm:ss'),
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          this.props.addDangerNotification('Unable to load forms');
        });
    }
  };

  handleEditResponse = (e, request) => {
    this.setState(
      {
        request: request,
        showResponseModal: true,
        type: null,
      },
      () => {
        $('#responseModal').on('hidden.bs.modal', (e) => {
          this.setState({ showResponseModal: false });
        });
        $('#responseModal').modal();
      }
    );
  };

  handleAcknowledge = (e, request) => {
    this.setState(
      {
        request: request,
        showResponseModal: true,
        type: FormRequestState.Acknowledged,
      },
      () => {
        $('#responseModal').on('hidden.bs.modal', (e) => {
          this.setState({ showResponseModal: false });
        });
        $('#responseModal').modal();
      }
    );
  };

  handleComplete = (e, request) => {
    this.setState(
      {
        request: request,
        showResponseModal: true,
        type: FormRequestState.Completed,
      },
      () => {
        $('#responseModal').on('hidden.bs.modal', (e) => {
          this.setState({ showResponseModal: false });
        });
        $('#responseModal').modal();
      }
    );
  };

  handleSend = (response) => {
    const { request, type } = this.state;
    axios
      .put(`/api/formrequests/${request.id}`, {
        response: response,
        state: type,
      })
      .then((r) => {
        $('#responseModal').modal('hide');
        this.setState({ showResponseModal: false });
        this.props.addSuccessNotification('Request updated!');
      })
      .catch((e) => {
        this.props.addDangerNotification('Unable to edit response!');
      });
  };

  render() {
    return (
      <div className="container">
        <h2>Viewing all forms requests</h2>
        <small>Last load at {this.state.lastLoadAt}</small>
        <br />

        <table className="table table-shadow">
          <thead>
            <tr>
              <th>Id</th>
              <th>Form name</th>
              <th>Request</th>
              <th>Status</th>
              <th>Sent on</th>
              <th>Last modification</th>
            </tr>
          </thead>
          <tbody>
            {this.state.requests.map((request, index) => (
              <Request
                key={index}
                request={request}
                handleEditResponse={(e) => this.handleEditResponse(e, request)}
                handleAcknowledge={(e) => this.handleAcknowledge(e, request)}
                handleComplete={(e) => this.handleComplete(e, request)}
              />
            ))}
          </tbody>
        </table>

        <Loading show={this.state.isLoading} />

        {this.state.showResponseModal ? (
          <EditResponse
            response={this.state.request.response}
            name="responseModal"
            handleSend={this.handleSend}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(null, {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
})(AppFormsRequest);
