import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';

import { useUser } from '../utilities/user-context';

const Anonymous = () => (
  <ul className="nav navbar-nav navbar-right">
    <li>
      <a href="/Account/Login">Log in</a>
    </li>
  </ul>
);

export interface LoggedInProps {
  token: string;
  userState: {
    name: string;
    accountId: number;
    accountName: string;
  };
}

function LoggedIn({ userState, token }: LoggedInProps) {
  const user = useUser();

  return (
    <div className="nav navbar-nav navbar-right">
      <div id="user-actions" className="dropdown">
        <button
          id="dLabel"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span>Welcome {user?.name ?? ''}</span>
          <span className="caret" />
        </button>

        <div
          className="dropdown-menu user-menu"
          aria-labelledby="dLabel"
          style={{ width: '240px', marginRight: '0px' }}
        >
          <span style={{ paddingLeft: '5px' }}>Your accounts</span>
          <ul style={{ listStyleType: 'none' }}>
            <li
              className={css`
                line-height: 15px;
                margin-left: 5px;
                font-size: 13px;
              `}
            >
              <Link
                to={`/account/${userState.accountId}`}
                style={{ color: '#DEDEDE' }}
              >
                {userState.accountName}
              </Link>
            </li>
          </ul>

          <hr />
          <form
            action="/Account/LogOff"
            method="post"
            id="logoutForm"
            className="navbar-right"
          >
            <input
              name="__RequestVerificationToken"
              type="hidden"
              value={token}
            />
            <button
              type="submit"
              className="btn btn-link navbar-btn navbar-link"
            >
              Logout
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default function Login() {
  const [token, setToken] = useState('');
  const user = useUser();

  useEffect(() => {
    setToken(`${$('input[name="__RequestVerificationToken"]').val() || ''}`);
  }, []);

  if (user.isLoggedIn) {
    return <LoggedIn token={token} userState={user} />;
  }

  return <Anonymous />;
}
