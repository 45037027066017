import React from 'react';

import './add-user.css';

type Props = {
  onAddUser: () => any;
};

export default function AddUser({ onAddUser }: Props) {
  return (
    <span
      className="add-user glyphicon glyphicon-plus"
      title="Add new user to this application"
      onClick={onAddUser}
    />
  );
}
