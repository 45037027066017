import { useDeviceInfo, useDeviceLog } from 'api';
import { Loading } from 'Components/shared';
import moment from 'moment';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, DefinitionItem, Pagination, Section } from 'ui';

import { formatDeviceLogLevel } from './formatDeviceLogLevel';
import { getClassNameFromLevel } from './getClassNameFromLevel';

function DeviceLog(props: RouteComponentProps<{ id: string }>) {
  const [page, setPage] = useState(1);

  const deviceId = parseInt(props.match.params.id, 10);

  const { data: info } = useDeviceInfo(deviceId);

  function loadPage(newPage: number) {
    setPage(newPage);
  }

  const { data: response } = useDeviceLog(deviceId, page, 20);

  const log = !!response ? response.items : [];

  let deviceLog;
  if (!response) {
    deviceLog = <p>Device log is empty.</p>;
  } else {
    if (log.length !== 0) {
      const { pageCount } = response;
      deviceLog = (
        <>
          <table className="table table-shadow table-striped table-hover">
            <thead>
              <tr>
                <th>Level</th>
                <th>Created</th>
                <th>Message</th>
                <th>Exception</th>
              </tr>
            </thead>

            <tbody>
              {log.map((log, index) => (
                <tr key={index} className={getClassNameFromLevel(log.level)}>
                  <td>{formatDeviceLogLevel(log.level)}</td>
                  <td>{moment(log.createdOn).fromNow()}</td>
                  <td>{log.message}</td>
                  <td>{log.exception}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination page={page} pageCount={pageCount} onPage={loadPage} />
        </>
      );
    }
  }

  return (
    <Container className="space-y-4">
      <h1>Device log for ID: {props.match.params.id}</h1>

      {info ? (
        <Section>
          <dl className="m-0">
            <DefinitionItem term="Device" description={info.name} />
            <DefinitionItem term="Version" description={info.playerVersion} />
            <DefinitionItem term="Info" description={info.deviceInfo} />
          </dl>
        </Section>
      ) : null}

      {!response ? <Loading /> : null}
      {deviceLog}
    </Container>
  );
}

export default DeviceLog;
