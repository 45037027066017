import React, { PureComponent } from 'react';

import {
  SimpleModal,
  Header,
  ModalProps,
  Footer,
  Content,
} from 'Components/shared';
import { Button } from 'ui';

import './move-playlist-item-modal.css';

type Props = {
  current: number;
  places: number;
  onChangePosition: (position: number) => void;
} & ModalProps;

class State {}

const range = (start = 0, count = 0, step = 1) => {
  const arr: number[] = [];
  for (let i = 0; i < count; ++i) {
    arr.push(start + step * i);
  }
  return arr;
};

export class MovePlaylistItemModal extends PureComponent<Props, State> {
  state = new State();
  render() {
    const { show, onClose, current, places } = this.props;
    return (
      <SimpleModal show={show} hide={onClose}>
        <Header>Move playlist item to another position</Header>
        <Content>
          <label htmlFor="position" className="control-label">
            Move from {current} to position:
          </label>
          <div className="playlist-position-holder">
            {range(1, places, 1).map(i => (
              <span
                key={i}
                className={i === current ? 'selected' : ''}
                onClick={() => {
                  this.props.onChangePosition(i);
                  this.props.onClose();
                }}
              >
                {i}
              </span>
            ))}
          </div>
        </Content>
        <Footer>
          <Button onClick={onClose}>Dismiss</Button>
        </Footer>
      </SimpleModal>
    );
  }
}
