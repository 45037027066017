import React, { PureComponent } from 'react';

import { SimpleModal, Header, Footer, Content } from 'Components/shared';
import { Button, Label, Input } from 'ui';

type Props = {
  show: boolean;
  onAdd: (columns: number) => void;
  onClose: () => void;
};

class State {
  columns = 2;
}

export class AddTablePropertiesModal extends PureComponent<Props, State> {
  state = new State();

  render() {
    return (
      <SimpleModal show={this.props.show} hide={this.props.onClose}>
        <Header>Add table block</Header>
        <Content>
          <Label>
            Columns count
            <Input
              value={this.state.columns}
              name="alias"
              type="number"
              onChange={e =>
                this.setState({ columns: parseInt(e.target.value, 10) })
              }
            />
          </Label>
        </Content>

        <Footer>
          <Button type="primary" onClick={this.add}>
            Add
          </Button>
          <Button onClick={this.props.onClose}>Close</Button>
        </Footer>
      </SimpleModal>
    );
  }

  add = () => {
    this.props.onAdd(this.state.columns);
  };
}
