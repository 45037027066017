import * as React from 'react';
import { useUser } from 'utilities';

interface Props {
  feature: string;
}

export const hasFeatureFlag = (feature: string, features: string[]) => {
  return (
    (feature || '')
      .split(',')
      .map((s) => s.trim())
      .filter((s) => !!s)
      .filter((s) => features.indexOf(s) !== -1).length > 0
  );
};

const FeatureFlag: React.SFC<Props> = ({ feature, children }) => {
  const { features } = useUser();

  if (hasFeatureFlag(feature, features)) {
    return <>{children}</>;
  }

  return null;
};

export { FeatureFlag };
