import React from 'react';

export interface CheckboxProps {
  name?: string;
  checked?: boolean;
  onChange?: (e: any) => any;
  disabled?: boolean;
}

export const Checkbox: React.SFC<CheckboxProps> = ({ children, ...props }) => {
  return (
    <div className="checkbox">
      <label>
        <input type="checkbox" name="showInvalidAspect" {...props} />
        {children}
      </label>
    </div>
  );
};
