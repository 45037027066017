import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { StateComponent } from 'Components/shared';

import NavBar from '../NavBar';
import { Loading } from 'Components/shared';
import * as NotificationActions from '../NotificationActions';
import { AccountUserRole } from '../../enums';
import AccountUsers from './AccountUsers';
import { loadAccountInfo, NewUserRequest } from './actions';

export interface AccountProps {
  account: any;
  user: {
    name: string;
    accountId: number;
    accountName: string;
    role: number;
  };
  isLoading: boolean;
  loadAccountInfo: (accountId: number) => void;
  editAccount: (accountId: number, data: any) => void;
  accountCreateUser: (accountId: number, data: NewUserRequest) => any;
  addDangerNotification: (text: string) => void;
}

export interface AccountState {
  id: number;
  name: string;
  alias: string;
  users: any[];
}

class Account extends StateComponent<AccountProps, AccountState> {
  state: AccountState = {
    id: 0,
    name: '',
    alias: '',
    users: [],
  };

  componentDidMount() {
    if (this.props.account) this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.account !== nextProps.account) this.init(nextProps);
  }

  init = props => {
    this.setState({
      id: props.account.id,
      name: props.account.name,
      alias: props.account.alias,
      users: props.account.users,
    });
  };

  handleCreateDeviceLogin = () => {
    if (!this.state.alias) {
      this.props.addDangerNotification('Must add valid alias first');
    }

    this.props
      .accountCreateUser(this.props.user.accountId, {
        userName: `${this.state.alias}player`.toLowerCase(),
        password: 'Password1!',
        email: `player@${this.state.alias}`.toLowerCase(),
        role: 2,
      })
      .then(r => {
        if (r.error) {
          this.props.addDangerNotification('Cannot create device login');
        } else {
          this.props.loadAccountInfo(this.props.user.accountId);
        }
      });
  };

  saveChanges = () => {
    if (!this.state.name) {
      alert('Name is required');
      return;
    }

    this.props.editAccount(this.state.id, {
      name: this.state.name,
      alias: this.props.account.alias || this.state.alias,
    });
  };

  render() {
    let hasDeviceUser = this.state.users.find(
      user => user.role === AccountUserRole.Device
    );

    return (
      <div className="container" style={{ paddingLeft: '20px' }}>
        <div>
          <div className="pull-right">
            <button className="btn btn-fancy" onClick={this.saveChanges}>
              <i className="fa fa-save" /> Save
            </button>
          </div>
          <h3>Account details</h3>

          <h5 className="edit-block-title">Basic info</h5>

          <div className="form-group">
            <label htmlFor="name" className="control-label subtle-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.set}
              maxLength={50}
            />
          </div>

          <div className="form-group">
            <label htmlFor="alias" className="control-label subtle-label">
              Alias
            </label>
            <input
              type="text"
              className="form-control"
              name="alias"
              value={this.state.alias}
              placeholder="Enter alias name"
              onChange={this.set}
              readOnly={this.props.account && this.props.account.alias}
            />
          </div>

          <div>
            <h5 className="edit-block-title">Users</h5>
            <AccountUsers users={this.state.users} />
          </div>

          {!hasDeviceUser ? (
            <div>
              <h5 className="edit-block-title">Device account</h5>
              <div className="form-group">
                <span className="help-block">
                  This account doesn't have a device specific user. Click the
                  button below to create such device user account.
                </span>
                <input
                  type="submit"
                  className="btn btn-default"
                  value="Create device login"
                  onClick={this.handleCreateDeviceLogin}
                />
              </div>
            </div>
          ) : null}
        </div>

        {this.props.isLoading ? <Loading /> : null}
      </div>
    );
  }
}

export default Account;
