import axios from 'axios';
import { useEffect } from 'react';

export const useFetch = (url: string, setResult: Function) => {
  useEffect(
    () => {
      if (url) {
        axios.get(url).then(r => setResult(r.data));
      }
    },
    [url]
  );
};
