import React, { Component } from 'react';
import axios from 'axios';
import FormInput from './FormInput';

const rootStyle = {
  position: 'relative' as 'relative',
  overflow: 'hidden' as 'hidden',
  marginBottom: '15px',
};
const formStyle = {
  padding: '10px 15px',
};
const outputWindowStyle = {
  height: '100%',
  position: 'absolute' as 'absolute',
  right: 0,
  overflow: 'auto' as 'auto',
};

class LogPlaylistForDevice extends Component<any, any> {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      output: '',
      data: {
        device: 0,
        playlist: '',
      },
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
        `/api/devicelog/device/${this.state.data.device}/playlist/${
          this.state.data.playlist
        }`
      )
      .then(response => {
        this.setState({ output: JSON.stringify(response.data, null, 2) });
      })
      .catch(error => {
        this.setState({ output: JSON.stringify(error.response, null, 2) });
      });
  }

  handleChange(e) {
    this.state.data[e.target.name] = e.target.value;
    this.setState({ data: this.state.data });
  }

  render() {
    return (
      <div className="well row" style={rootStyle}>
        <form
          onSubmit={this.handleSubmit}
          className="col-md-6"
          style={formStyle}
        >
          <h3>Log playlist for device</h3>

          <FormInput
            title="Device UID"
            propName="device"
            onChange={this.handleChange}
          />
          <FormInput
            title="Playlist ID"
            propName="playlist"
            onChange={this.handleChange}
          />

          <div className="form-group">
            <input type="submit" className="btn btn-default" />
          </div>
        </form>

        <div className="col-md-6" style={outputWindowStyle}>
          <pre>{this.state.output}</pre>
        </div>
      </div>
    );
  }
}

export default LogPlaylistForDevice;
