import * as React from 'react';
function transformChildren(
  children: React.ReactNode,
  apply: (child: React.ReactChild, index: number) => any
): any[] {
  return React.Children.map(
    children as any,
    (child: React.ReactChild, index) => {
      let newChild = apply(child, index);
      if (newChild.props && newChild.props.children)
        return React.cloneElement(newChild, {
          children: transformChildren(newChild.props.children, apply),
        });
      return newChild;
    }
  );
}

export interface BindingContextProps {
  data: any;
  set: (e: any) => void;
  children: React.ReactNode;
}

export const BindingContext = ({
  data,
  set,
  children,
}: BindingContextProps) => (
  <div>
    {transformChildren(children, (child: any, index) =>
      // skip built in types
      !!child && !!child.type && typeof child.type !== 'string'
        ? React.cloneElement(child, {
            data,
            set,
          })
        : child
    )}
  </div>
);

function dataValue(data: any, name: string) {
  return data ? data[name] : undefined;
}

export interface TextInputProps {
  name: string;
  data?: any;
  title?: string;
  set?: () => any;
}

export const TextInput = ({ data, title, name, set }: TextInputProps) => (
  <div className="form-group">
    <label htmlFor={name}>{title ? title : name}</label>
    <input
      type="text"
      className="form-control"
      name={name}
      value={dataValue(data, name)}
      onChange={set}
    />
  </div>
);

export const PasswordInput = ({ data, title, name, set }: TextInputProps) => (
  <div className="form-group">
    <label htmlFor={name}>{title ? title : name}</label>
    <input
      type="password"
      className="form-control"
      name={name}
      value={dataValue(data, name)}
      onChange={set}
    />
  </div>
);

export interface SelectProps extends TextInputProps {
  children?: React.ReactNode;
}

export const Select = ({ data, title, name, set, children }: SelectProps) => (
  <div className="form-group">
    <label htmlFor={name}>{title ? title : name}</label>
    <select
      name={name}
      value={dataValue(data, name)}
      className="form-control"
      onChange={set}
    >
      {children}
    </select>
  </div>
);

export interface SubmitProps {
  onClick: () => any;
  value?: string | string[] | number;
  disabled?: boolean;
}

export const Submit = ({
  onClick,
  value,
  disabled = false,
}: React.PropsWithChildren<SubmitProps>) => (
  <div className="form-group">
    <input
      type="submit"
      className="form-control btn btn-primary"
      value={value}
      disabled={disabled}
      onClick={onClick}
    />
  </div>
);

export const Checkbox = ({ data, title, name, set }: TextInputProps) => (
  <div className="checkbox">
    <label>
      <input
        type="checkbox"
        checked={dataValue(data, name)}
        name={name}
        onChange={set}
      />{' '}
      {title ? title : name}
    </label>
  </div>
);
