import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  MediaItemListItem,
  GetPlaylistResponse,
  AddMediaItemToPlaylistsCommand,
} from 'api';
import {
  Header,
  SimpleModal,
  ModalProps2,
  Content,
  Footer,
} from '../shared/SimpleModal';
import { Button, Checkbox } from 'ui';
import { useQuery } from '../../utilities/useQuery';
import { useMutation } from '../../utilities/useMutation';
import { Loading } from 'Components/shared';
import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
  LegacyReduxNotificationProps,
} from '../NotificationActions';

type Props = {
  mediaItem: MediaItemListItem;
  onAdd: (playlists: number[]) => Promise<any>;
} & ModalProps2 &
  LegacyReduxNotificationProps;

const addMediaItemToPlaylists = useMutation<
  AddMediaItemToPlaylistsCommand,
  boolean
>(`/api/playlists/media`);

export function AddToPlaylistsModal(props: Props) {
  const [selected, setSelected] = useState<{ id: number; selected: boolean }[]>(
    []
  );
  const { loading, data, error } = useQuery<GetPlaylistResponse[]>(
    `/api/playlists`
  );
  const [isWorking, setIsWorking] = useState(false);

  useEffect(
    () => {
      if (!data) {
        return;
      }
      setSelected(data.map(playlist => ({ id: playlist.id, selected: false })));
    },
    [data]
  );

  async function addToPlaylists() {
    setIsWorking(true);

    const playlists = selected.filter(s => s.selected).map(s => s.id);
    try {
      await props.onAdd(playlists);
      props.hide();
    } catch (error) {}

    setIsWorking(false);
  }

  function toggle(id: number) {
    setSelected(
      selected.map(s => {
        if (s.id !== id) {
          return s;
        }

        return { ...s, selected: !s.selected };
      })
    );
  }

  return (
    <SimpleModal show={props.show} hide={props.hide}>
      <Header>Add to playlists</Header>
      <Content>
        {(loading || isWorking) && <Loading />}
        Playlists
        {(data || []).map(playlist => {
          const s = selected.find(s => s.id === playlist.id);
          const checked = s ? s.selected : false;

          return (
            <Fragment key={playlist.id}>
              <Checkbox
                name={`playlist${playlist.id}`}
                checked={checked}
                onChange={() => toggle(playlist.id)}
                disabled={!s}
              >
                {playlist.name}
              </Checkbox>
            </Fragment>
          );
        })}
      </Content>
      <Footer>
        <Button type="default" onClick={props.hide}>
          Close
        </Button>
        <Button
          type="primary"
          onClick={addToPlaylists}
          disabled={selected.filter(s => s.selected).length === 0}
        >
          Add to playlists
        </Button>
      </Footer>
    </SimpleModal>
  );
}

export default connect(
  null,
  {
    addInfoNotification,
    addDangerNotification,
    addSuccessNotification,
  }
)(AddToPlaylistsModal);
