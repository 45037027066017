import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';

import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';

import PageEditor from './PageEditor';
import { load_app } from './actions';
import PagePreview from './PagePreview';
import { FeatureFlag } from 'Components/shared';
import { link } from 'fs';
import PagesTree from './pages-tree';

const NoPageSelected = () => {
  return <div className="center-box">Select a page to start editing</div>;
};

class Pages extends Component<any, any> {
  handleAddHomePage = () => {
    axios
      .post(`/api/pages/app/${this.props.app.data.id}`, {
        name: 'Home',
      })
      .then(r => {
        this.props.addSuccessNotification('Home page created');
        this.props.load_app(this.props.app.data.id);
      })
      .catch(e => {
        this.props.addDangerNotification('Unable to create home page');
      });
  };

  render() {
    const pages = this.props.app ? this.props.app.pages : [];
    return (
      <div className="root-container">
        <div className="pages card-bg">
          <h3 className="col-xs-12 pages-title">Pages</h3>
          <PagesTree appId={this.props.match.params.id} pages={pages} />
        </div>

        <div className="page-editor">
          {this.props.match.params.pageid ? (
            <PageEditor id={this.props.match.params.pageid} {...this.props} />
          ) : this.props.app.pages.length > 0 ? (
            <NoPageSelected />
          ) : null}

          {pages.length === 0 && this.props.app.isLoaded ? (
            <div className="center-box">
              <button
                className="btn btn-fancy"
                onClick={this.handleAddHomePage}
              >
                + Add Home page
              </button>
            </div>
          ) : null}
        </div>

        <FeatureFlag feature="page-preview">
          <PagePreview />
        </FeatureFlag>
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
  }),
  {
    addInfoNotification,
    addSuccessNotification,
    addDangerNotification,
    load_app,
  }
)(Pages);
