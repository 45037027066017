import React, { Component, ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import NavBar from '../NavBar';
import { load_app, publishApp } from './actions';
import { Loading } from 'Components/shared';

class Container extends Component<any, any> {
  componentDidMount() {
    if (
      !this.props.app.isLoaded ||
      Number(this.props.match.params.id) !== this.props.app.data.id
    ) {
      this.props.load_app(this.props.match.params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id)
      this.props.load_app(nextProps.params.id);
  }

  render() {
    let isSubpage =
      this.props.location &&
      this.props.location.pathname.indexOf('/page/') !== -1;

    let special: ReactNode = null;
    if (this.props.match.params.id)
      special = (
        <button
          className="btn btn-primary btn-publish"
          onClick={() => this.props.publishApp(this.props.match.params.id)}
        >
          Publish
        </button>
      );

    return (
      <div style={{ height: '100%' }}>
        <NavBar
          title={`App ${this.props.app.data.name}`}
          link="/apps"
          special={special}
        >
          <li>
            <NavLink
              to={`/app/${this.props.match.params.id}`}
              className={isSubpage ? 'link-active' : ''}
            >
              Content
            </NavLink>
          </li>
          <li>
            <NavLink to={`/app/${this.props.match.params.id}/settings`}>
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink to={`/app/${this.props.match.params.id}/theme`}>
              Theme
            </NavLink>
          </li>
          {window['features'].dev ? (
            <li>
              <NavLink to={`/app/${this.props.match.params.id}/dev`}>
                Dev
              </NavLink>
            </li>
          ) : null}
        </NavBar>

        {this.props.children}

        {this.props.app.isLoading || this.props.isPublishing ? (
          <Loading />
        ) : null}
      </div>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    isPublishing: state.App.isPublishing,
  }),
  {
    publishApp,
    load_app,
  }
)(Container);
