import React from 'react';

import { DevicePlatform } from 'api';

export const PlatformIcon = ({
  platformId,
}: {
  platformId: DevicePlatform;
}) => {
  let platform;
  switch (platformId) {
    case DevicePlatform.WPF:
      platform = <i className="fa fa-desktop" aria-hidden="true" />;
      break;

    case DevicePlatform.Android:
      platform = <i className="fa fa-android" aria-hidden="true" />;
      break;

    default:
      platform = <i className="fa fa-exclamation" aria-hidden="true" />;
      break;
  }

  return <p className="screens-platform-icon">{platform}</p>;
};
