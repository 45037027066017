import React from 'react';

const formatType = type => {
  switch (type) {
    case 'i-text':
      return 'text';
    case 'rect':
      return 'box';
    case 'image':
      return 'image';
  }

  return null;
};

const ObjectActions = props => (
  <div className="object-actions">
    <p style={{ fontSize: '1.4em' }}>{formatType(props.selectedObject.type)}</p>

    <button onClick={props.handleClickClear} className="btn btn-danger btn-sm">
      <span className="glyphicon glyphicon-trash" aria-hidden="true" /> Delete
    </button>

    <div className="dropdown" style={{ display: 'inline-block' }}>
      <span
        className="btn glyphicon glyphicon-object-align-top"
        id="dropdownObjectAlign"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        style={{}}
      />

      <ul className="dropdown-menu" aria-labelledby="dropdownObjectAlign">
        <li className="dropdown-header">Side</li>

        <li>
          <a
            data-name="align-top"
            title="Align to top"
            onClick={props.handleClickEvent}
          >
            <i className="glyphicon glyphicon-object-align-top" /> Align to the
            top
          </a>
        </li>
        <li>
          <a
            data-name="align-bottom"
            title="Align to bottom"
            onClick={props.handleClickEvent}
          >
            <i className="glyphicon glyphicon-object-align-bottom" /> Align to
            the bottom
          </a>
        </li>
        <li>
          <a
            data-name="align-left"
            title="Align to left"
            onClick={props.handleClickEvent}
          >
            <i className="glyphicon glyphicon-object-align-left" /> Align to the
            left
          </a>
        </li>
        <li>
          <a
            data-name="align-right"
            title="Align to right"
            onClick={props.handleClickEvent}
          >
            <i className="glyphicon glyphicon-object-align-right" /> Align to
            the right
          </a>
        </li>

        <li className="dropdown-header">Center</li>
        <li>
          <a data-name="align-horizontal" onClick={props.handleClickEvent}>
            <i className="glyphicon glyphicon-object-align-horizontal" /> Center
            horizontally
          </a>
        </li>
        <li>
          <a data-name="align-vertical" onClick={props.handleClickEvent}>
            <i className="glyphicon glyphicon-object-align-vertical" /> Center
            vertically
          </a>
        </li>
      </ul>
    </div>

    <button
      className="btn btn-default btn-sm"
      name="back-btn"
      onClick={props.handleClickZIndex}
    >
      <span className="glyphicon glyphicon-chevron-up" aria-hidden="true" />{' '}
      Back
    </button>
    <button
      className="btn btn-default btn-sm"
      name="front-btn"
      onClick={props.handleClickZIndex}
    >
      <span className="glyphicon glyphicon-chevron-down" aria-hidden="true" />{' '}
      Front
    </button>

    <hr />
  </div>
);

export default ObjectActions;
