import React, { PureComponent, useState, useEffect } from 'react';
import Modal from 'react-modal';
import c from 'classnames';

interface P {
  children: React.ReactNode;
}

export const Header: React.FunctionComponent<P> = ({ children }: P) =>
  !children ? null : <>{children}</>;
export const Content: React.FunctionComponent<P> = ({ children }: P) =>
  !children ? null : <>{children}</>;
export const Footer: React.FunctionComponent<P> = ({ children }: P) =>
  !children ? null : <>{children}</>;

export type Props = {
  className?: string;
  show?: boolean;
  hide: () => void;
  children?: React.ReactNode;
};

export type ModalProps = {
  show?: boolean;
  onClose: () => void;
};

// Use when typing props for SimpleModal wrapper
export type ModalProps2 = {
  show?: boolean;
  hide: () => void;
};

export const SimpleModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.show === true);

  useEffect(
    () => {
      setIsOpen(props.show === true);
    },
    [props.show]
  );

  let header: React.ReactNode;
  let content: React.ReactNode;
  let footer: React.ReactNode;
  React.Children.map(props.children, (child: any) => {
    if (child.type === Header) header = React.cloneElement(child);
    if (child.type === Content) content = child;
    if (child.type === Footer) footer = child;
  });

  const { className } = props;

  return (
    <Modal
      isOpen={isOpen}
      className={c('modal-dialog modal-fancy', className)}
      onRequestClose={props.hide}
    >
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.hide}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="modal-title">{header}</h4>
        </div>
        <div className="modal-body">{content}</div>
        <div className="modal-footer">{footer}</div>
      </div>
    </Modal>
  );
};

/**
 * @example
 * {(isOpen, show, hide) => (<>  </>)}
 *
 * @export
 * @class WithDialog
 * @extends {PureComponent<{}, { isOpen: boolean }>}
 */
export class WithDialog extends PureComponent<{}, { isOpen: boolean }> {
  state = {
    isOpen: false,
  };

  show = () => this.setState({ isOpen: true });
  hide = () => this.setState({ isOpen: false });

  render() {
    const children: any = this.props.children;
    return children(this.state.isOpen, this.show, this.hide);
  }
}
