import { AxiosError } from 'axios';

export const parseBadRequest = (error: AxiosError) => {
  if (!error) return 'Unknown error';

  if (error.response) {
    switch (error.response.status) {
      case 400:
        let errors: string[] = [];

        const data = error.response.data;
        for (const i in data) {
          if (data.hasOwnProperty(i)) {
            errors = errors.concat(data[i] as string[]);
          }
        }
        return errors.reduce((p, c) => `${p} ${c}`, '');

      default:
        return `Error ${error.response.status}`;
    }
  }

  return error.message || 'Unknown error';
};
