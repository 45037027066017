import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { css, cx } from 'emotion';
import moment from 'moment';

import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';

import { Loading, FeatureFlag } from 'Components/shared';
import { AppType } from '../../enums';
import CreateApp from './CreateApp';
import { getApps, createApp, NewAppDTO, AppDTO } from 'api';
import { Button, Container } from 'ui';

require('../../css/apps.css');

moment.locale('en');

interface AppsListProps {
  addInfoNotification: (text: string) => void;
  addSuccessNotification: (text: string) => void;
  addDangerNotification: (text: string) => void;
}

class AppsListState {
  isLoading = false;
  isLoaded = false;
  apps: any[] = [];
  showCreateApp = false;
}

const mapMap = function<Key, Value>(map: Map<Key, Value>) {
  const toReturn: { key: Key; value: Value }[] = [];
  map.forEach((value, key) => {
    toReturn.push({ value, key });
  });
  return toReturn;
};

class AppsList extends Component<
  AppsListProps & RouteComponentProps<{}>,
  AppsListState
> {
  state = new AppsListState();

  componentDidMount() {
    this.load();
  }

  load = () => {
    this.setState({ isLoading: true });

    getApps()
      .then(r => {
        this.setState({
          isLoading: false,
          apps: r.data,
        });
        this.props.addInfoNotification('Apps loaded');
      })
      .catch(e => {
        this.props.addDangerNotification('Unable to load apps');
      });
  };

  handleCreateApp = (data: NewAppDTO) => {
    data.accountId = window['userState'].accountId;
    const promise = createApp(data);

    promise
      .then(r => {
        this.setState({ showCreateApp: false });
        this.props.addSuccessNotification('Application created!');
        this.props.history.push(`/app/${r.data.id}`);
      })
      .catch(() => {
        this.props.addDangerNotification('Unable to create application');
      });

    return promise;
  };

  render() {
    const groups = new Map<number, { name: string; apps: AppDTO[] }>();
    const { apps } = this.state;

    if (apps) {
      apps.forEach(a => {
        if (groups.has(a.accountId)) {
          groups.get(a.accountId)!.apps.push(a);
        } else {
          groups.set(a.accountId, {
            name: a.accountName || '',
            apps: [a],
          });
        }
      });
      mapMap(groups).forEach(group => {
        group.value.apps.sort((l, r) => {
          return l.name.localeCompare(r.name);
        });
      });
    }

    return (
      <Container>
        <div className="top-options clearfix">
          <FeatureFlag feature="mobileapps-create">
            <Button className="pull-right" onClick={this.showCreateApp}>
              + Create app
            </Button>
          </FeatureFlag>

          <h1>Applications</h1>
        </div>

        {this.state.isLoading ? (
          <Loading />
        ) : this.state.apps.length === 0 ? (
          <h4>No applications created yet</h4>
        ) : (
          <div className="apps-list">
            {mapMap(groups).map(({ key, value: { name, apps } }) => (
              <div key={key || 0} className="app-group">
                {name ? <h3>{name}</h3> : null}
                {apps.map((app, index) => {
                  return (
                    <div key={index} className="card-bg app">
                      {/* <div
                      className="background"
                      style={{ backgroundImage: `url(${app.thumbnail})` }}
                    /> */}

                      <Link to={`/app/${app.id}`}>
                        <div className="title">
                          <span className="app-name">{app.name}</span>
                        </div>

                        {app.activeDevices > 0 ? (
                          <div className="app-devices">
                            <span className="bullet">&#8226;</span>{' '}
                            {app.activeDevices} active devices
                          </div>
                        ) : null}

                        {/* {app.isPublished ? (
                        <span>Published on {moment(app.publishedOn).fromNow()}</span>
                      ) : null} */}

                        {!!app.publishedBy && (
                          <div
                            className={css`
                              font-size: 0.8em;
                              align-self: end;
                            `}
                          >
                            Published {moment(app.publishedOn).fromNow()}
                          </div>
                        )}

                        <div className="app-status">
                          {!app.isPublished && (
                            <span className="edit-status">EDIT</span>
                          )}

                          <span className="app-type-icon">
                            {app.appType === AppType.GuestDirectory ? (
                              <>
                                Guest Directory{' '}
                                <i
                                  className="fa fa-tablet"
                                  title="Guest Directory"
                                />
                              </>
                            ) : app.appType === AppType.Mobile ? (
                              <>
                                Mobile{' '}
                                <i
                                  className="fa fa-mobile"
                                  title="Mobile app"
                                />
                              </>
                            ) : (
                              <span>unknown</span>
                            )}
                          </span>
                        </div>
                      </Link>
                    </div>
                  );
                })}

                <div className="clearfix" />
              </div>
            ))}
          </div>
        )}

        <CreateApp
          isOpen={this.state.showCreateApp}
          handleCreate={this.handleCreateApp}
          hide={this.hideCreateApp}
        />

        {this.state.isLoading ? <Loading /> : false}
      </Container>
    );
  }

  showCreateApp = () => this.setState({ showCreateApp: true });
  hideCreateApp = () => this.setState({ showCreateApp: false });
}

export default connect(
  null,
  {
    addInfoNotification,
    addSuccessNotification,
    addDangerNotification,
  }
)(AppsList);
