import React from 'react';
import RegisterDeviceForm from './RegisterDeviceForm';
import DeviceLogForm from './DeviceLogForm';
import AssignChannelToDeviceForm from './AssignChannelToDeviceForm';
import LogPlaylistForDevice from './LogPlaylistForDevice';

class Dev extends React.Component<any, any> {
  render() {
    return (
      <div className="container">
        <br />

        <RegisterDeviceForm />
        <DeviceLogForm />
        <AssignChannelToDeviceForm />
        <LogPlaylistForDevice />

        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">Environment variables</h3>
          </div>
          <div className="panel-body">
            <pre>{JSON.stringify(process.env, null, 2)}</pre>
          </div>
        </div>

        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">Features</h3>
          </div>
          <div className="panel-body">
            <pre>{JSON.stringify(window['features'], null, 2)}</pre>
          </div>
        </div>

        <div className="well">
          <a href="/swagger/index.html" title="Swagger">
            Open Swagger
          </a>
        </div>
      </div>
    );
  }
}

export default Dev;
