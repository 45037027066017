import React from 'react';
import { connect } from 'react-redux';

import ObjectActions from './ObjectActions';
import { Loading } from 'Components/shared';
import { ImagesHolder } from './ImagesHolder';
import * as CommonProperties from './CommonProperties';

class ImageObj extends React.Component<any, any> {
  render() {
    return (
      <div className="bottom-controls">
        <ObjectActions {...this.props} />

        <div className="single-control-holder">
          <label>Image</label>
          <input
            type="text"
            placeholder="Image URL"
            name="url"
            value={this.props.imageStateObj.url}
            style={{ width: '150px' }}
            onChange={this.props.handleChangeImage}
          />

          <div className="bottom-properties clearfix">
            <div>
              <p>&nbsp;</p>
            </div>
            <div style={{ width: '150px', float: 'left', marginLeft: '0' }}>
              <p>Link</p>
            </div>
          </div>
        </div>

        <CommonProperties.OpacityProperty {...this.props} />

        <hr />

        <CommonProperties.PositionProperties {...this.props} />
        <CommonProperties.SizeProperties {...this.props} />
        <CommonProperties.RotationProperties {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetchingMedia: state.Media.isFetchingMedia,
    fetchedMedia: state.Media.fetchedMedia,
    fetchingMediaError: state.Media.fetchingMediaError,
  };
};

export default connect(mapStateToProps)(ImageObj);
