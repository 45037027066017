import React from 'react';

class AddNewPlaylist extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.reset = this.reset.bind(this);

    this.state = {
      data: {
        name: '',
        effect: '',
        duration: 5,
      },
      errors: {
        _all: '',
        name: '',
        effect: '',
        duration: '',
      },
    };
  }

  handleChange(e) {
    var oldData = this.state.data,
      propName,
      propVal;
    propName = e.target.name.split('-')[1];
    propVal = e.target.value;

    oldData[propName] = propVal;

    this.setState({
      data: oldData,
    });
  }

  handleCreate(e) {
    e.preventDefault();
    this.setState({
      errors: {
        name: '',
        duration: '',
      },
    });

    if (!this.state.data.name) {
      this.setState({
        errors: {
          name: 'Name is required',
        },
      });
      return;
    }
    if (!this.state.data.duration || Number(this.state.data.duration) < 0) {
      this.setState({
        errors: {
          duration: 'Duration should be larger than 0',
        },
      });
      return;
    }

    this.props
      .handleCreate(this.state.data)
      .catch((e) => {
        var errors = e.response.data;
        if (errors) {
          if (errors.error && typeof errors.error === 'string') {
            this.setState({
              errors: {
                _all: errors.error,
              },
            });
          } else {
            var newState = this.state.errors;
            for (var fieldName in errors) {
              errors[fieldName] = false;
            }

            this.setState({
              errors: newState,
            });
          }
        }
      })
      .then((r) => {
        if (r) {
          var form = document.getElementById(this.props.name + 'Form');
          $(`#${this.props.name}`).modal('hide');
          this.reset();
        }
      });
  }

  componentDidMount() {
    this.reset();
  }

  reset() {
    this.setState({
      data: {
        name: '',
        effect: '',
        duration: 5,
      },
      errors: {
        _all: '',
        name: '',
        effect: '',
        duration: '',
      },
    });
  }

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Create new playlist
              </h4>
            </div>
            <div className="modal-body">
              <form id={this.props.name + 'Form'}>
                <div
                  className={
                    'form-group ' + (this.state.errors.name ? 'has-error' : '')
                  }
                >
                  <label htmlFor="playlist-name" className="control-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Playlist name..."
                    name="playlist-name"
                    value={this.state.data.name}
                    onChange={this.handleChange}
                    required
                  />
                  <div className="help-block with-errors">
                    {this.state.errors.name}
                  </div>
                </div>

                <div
                  className={
                    'form-group ' +
                    (this.state.errors.duration ? 'has-error' : '')
                  }
                >
                  <label htmlFor="playlist-duration" className="control-label">
                    Default item duration
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Duration..."
                    name="playlist-duration"
                    value={this.state.data.duration}
                    onChange={this.handleChange}
                    required
                  />
                  <div className="help-block with-errors">
                    {this.state.errors.duration}
                  </div>
                </div>
                <div className="has-error">
                  <div className="help-block with-errors">
                    {this.state.errors._all}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-fancy"
                onClick={this.handleCreate}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddNewPlaylist;
