import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

export interface LineChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

export function DeviceStats({ stats }) {
  const data: LineChartData = {
    labels: [],
    datasets: [
      {
        label: 'Active devices',
        data: [],
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(255,99,132,1)'],
        borderWidth: 1,
      },
    ],
  };

  // find out how many devices per day have been shown
  const groups = stats.reduce((prev, current) => {
    if (!prev[current.date]) {
      prev[current.date] = 1;
    } else {
      prev[current.date]++;
    }
    return prev;
  }, {});

  // fill any gaps in dates
  const allDates = Object.keys(groups).reduce<string[]>((previous, current) => {
    if (
      previous.length === 0 ||
      moment
        .utc(previous[previous.length - 1])
        .add(1, 'day')
        .isSame(moment.utc(current))
    ) {
      previous.push(current);
    } else {
      for (
        let date = moment.utc(previous[previous.length - 1]).add(1, 'day');
        date.isBefore(moment.utc(current));
        date = date.add(1, 'day')
      ) {
        previous.push(date.format().replace('Z', ''));
      }
    }
    return previous;
  }, []);

  // construct dataset
  allDates.map((g) => {
    data.labels.push(moment(g).format('YYYY-MM-DD'));
    data.datasets[0].data.push(groups[g] || 0);
  });

  return <Line data={data} />;
}
