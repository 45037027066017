function padZero(s) {
  if (s === 0) s = '00';
  else if (s < 10) s = `0${s}`;

  return s;
}

const formatDuration = duration => {
  duration = Number(duration);
  if (duration <= 0) return '-';

  const ms = Math.floor((duration * 1000) % 1000);
  const s = Math.floor(duration) % 60;
  const m = Math.floor((duration / 60) % 60);
  const h = Math.floor(duration / 3600);

  const sec = padZero(s);
  const min = padZero(m);

  if (ms === 0) {
    if (h === 0) {
      return `${m}:${sec}`;
    }

    return `${h}:${min}:${sec}`;
  }

  if (h === 0) {
    if (m === 0) {
      return `${s}.${ms} s`;
    }

    return `${m}:${sec}.${ms}`;
  }
  return `${h}:${min}:${sec}.${ms}`;
};

export default formatDuration;
