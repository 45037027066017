import React from 'react';
import { isoLangs } from '../../../languages';

const AppLanguages = ({
  language,
  languages,
  handleRemoveLanguage,
  handleEnableLanguage,
  handleDisableLanguage,
}) => {
  return (
    <div className="form-group">
      <h5 className="edit-block-title">Languages</h5>
      <table className="table table-shadow">
        <thead>
          <tr>
            <th>Language</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {languages.map((language, index) => {
            const lang = isoLangs[language.shortCode];

            if (!lang) return null;

            return (
              <tr key={index}>
                <td>{lang.name}</td>
                <td>
                  {languages.length > 1 && !language.isDefault ? (
                    <button
                      className="btn btn-danger"
                      onClick={e => handleRemoveLanguage(e, language)}
                    >
                      Remove
                    </button>
                  ) : null}
                  &nbsp;
                  {language.isDefault ? (
                    <span className="text-info">Default</span>
                  ) : language.isEnabled ? (
                    <button
                      className="btn btn-warning"
                      onClick={e => handleDisableLanguage(e, language)}
                    >
                      Disable language
                    </button>
                  ) : (
                    <button
                      className="btn btn-info"
                      onClick={e => handleEnableLanguage(e, language)}
                    >
                      Enable language
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AppLanguages;
