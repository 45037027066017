import * as React from 'react';

import { BlockEditorProps } from './common';
import { RSSBlock, BlockType } from '../contentHelpers';
import { Label, Input } from 'ui';

type Props = BlockEditorProps<RSSBlock>;
type State = RSSBlock;

export class RSS extends React.Component<Props, State> {
  state: State = { id: 0, url: '', type: BlockType.rss };

  render() {
    return (
      <>
        <Label htmlFor="url">
          URL
          <Input
            name="url"
            value={this.state.url}
            onChange={this.set}
            placeholder="http://"
          />
        </Label>
      </>
    );
  }

  componentDidMount() {
    this.setState({ ...this.props.data });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps.data });
  }

  set = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as any, () => {
      this.props.handleUpdate(this.state);
    });
  };
}
