import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';
import { Loading } from 'Components/shared';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

class Form extends Component<any, any> {
  handleOpenForm = () => {
    this.props.handleOpenForm(this.props.form.id);
  };

  render() {
    let { form } = this.props;
    return (
      <tr onClick={this.handleOpenForm}>
        <td>{form.id}</td>
        <td>{form.name}</td>
        <td>
          <span className="text-info">
            {form.incompleteRequests == 0
              ? 'All requests processed'
              : `${form.incompleteRequests} pending requests`}{' '}
            of {form.totalRequests}
          </span>
        </td>
      </tr>
    );
  }
}

class AppForms extends Component<any, any> {
  state: any = {
    isLoading: false,
    forms: [],
    redirectTo: '',
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get(`/api/forms/account/${window['userState'].accountId}`)
      .then(r => {
        this.setState({ isLoading: false, forms: r.data });
        this.props.addInfoNotification('Forms loaded');
      })
      .catch(e => {
        this.setState({ isLoading: false });
        this.props.addDangerNotification('Cannot load forms');
      });
  }

  handleOpenForm = (formId: number) => {
    this.setState({ redirectTo: `/forms/${formId}` });
  };

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <div className="container">
        <div className="col-md-12">
          <Link to="/forms/requests/all">
            <button className="btn btn-fancy pull-right mt-20">
              View all requests
            </button>
          </Link>
        </div>
        <h1>Forms</h1>

        <table className="table table-shadow">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Requests</th>
            </tr>
          </thead>
          <tbody>
            {this.state.forms.map((form, index) => (
              <Form
                key={index}
                form={form}
                handleOpenForm={this.handleOpenForm}
              />
            ))}
          </tbody>
        </table>

        {this.state.isLoading ? <Loading /> : null}
      </div>
    );
  }
}

export default connect(
  null,
  {
    addInfoNotification,
    addSuccessNotification,
    addDangerNotification,
  }
)(AppForms);
