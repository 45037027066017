import React from 'react';

export default function EmguestLogo(props) {
  return (
    <svg
      width="146px"
      height="37px"
      viewBox="0 0 146 37"
      version="1.1"
      {...props}
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon
          id="path-1"
          points="145.0063 0.379 0.0003 0.379 0.0003 36.8579 145.0063 36.8579 145.0063 0.379"
        />
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Desktop-HD" transform="translate(-135.000000, -1697.000000)">
          <g id="Group-11" transform="translate(135.000000, 1696.000000)">
            <g id="section__signage">
              <g id="signage-logo">
                <g id="Group-18" transform="translate(0.000000, 0.621000)">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g id="Clip-17" />
                  <polygon
                    id="Fill-16"
                    fill="#00CACA"
                    mask="url(#mask-2)"
                    points="145.0063 0.3789 0.0003 0.3789 0.0003 13.4699 9.1063 18.7269 0.0003 23.9839 0.0003 36.8579 145.0063 36.8579 145.0063 23.5939 136.5773 18.7269 145.0063 13.8599"
                  />
                </g>
                <path
                  d="M33.0532,21.5858 C34.0112,21.5858 34.4422,21.2128 34.4422,20.4718 C34.4422,18.2008 34.0312,17.9638 31.7062,17.9638 C29.2512,17.9638 28.2702,18.0088 28.2782,21.5858 L33.0532,21.5858 Z M31.6362,15.9728 C34.8212,15.9728 36.6492,16.2968 36.6492,20.5188 C36.6492,22.4808 35.5942,23.5168 33.2912,23.5168 L28.2782,23.5168 C28.2782,26.3238 28.9152,26.9018 30.7722,26.9018 C32.9072,26.9018 34.6102,26.9228 36.4092,26.6588 L36.4092,28.2538 C34.5062,28.8378 32.2592,28.9258 30.2682,28.9258 C27.0302,28.9258 26.0712,27.2798 26.0712,22.3768 C26.0712,16.7498 27.6732,15.9728 31.6362,15.9728 L31.6362,15.9728 Z"
                  id="Fill-19"
                  fill="#FFFFFF"
                />
                <path
                  d="M38.8887,16.0683 L40.5317,16.0683 L41.0957,17.5603 C42.4687,16.7283 43.5727,15.9143 45.4737,15.9143 C47.0517,15.9143 48.3947,16.5883 48.9387,17.7233 C50.1577,16.7463 51.8297,15.9143 53.9217,15.9143 C56.2937,15.9143 57.8187,17.6373 57.8187,20.1203 L57.8187,28.8053 L55.6127,28.8053 L55.6127,20.1053 C55.6127,18.7523 54.7977,17.9403 53.6587,17.9403 C51.9297,17.9403 50.7847,18.2343 49.3887,19.1903 C49.3887,19.2193 49.3927,20.0473 49.3927,20.0793 L49.3927,28.8053 L47.1857,28.8053 L47.1857,20.1053 C47.1857,18.7523 46.3507,17.9403 45.2117,17.9403 C43.5087,17.9403 42.7087,18.3643 41.0957,19.2153 L41.0957,28.8053 L38.8887,28.8053 L38.8887,16.0683 Z"
                  id="Fill-21"
                  fill="#FFFFFF"
                />
                <path
                  d="M65.793,11.0556 C67.425,11.0556 70.152,11.3406 72.065,11.7396 L72.065,13.4786 C71.921,13.4786 68.484,13.1546 66.009,13.1546 C62.983,13.1546 62.355,15.2736 62.355,19.9786 C62.355,25.0946 63.212,26.7556 66.036,26.7966 C67.809,26.8176 70.21,25.6936 70.21,25.6936 L70.21,21.3456 L66.608,21.3456 L66.608,19.8946 C67.586,19.5836 68.897,19.2946 70.786,19.2946 C71.29,19.2946 71.841,19.3196 72.417,19.3676 L72.417,28.8056 L70.782,28.8056 L70.258,27.6296 C69.382,28.1916 67.979,28.9076 65.856,28.9256 C60.891,29.0126 60.147,24.9936 60.147,19.9306 C60.147,15.0506 61.02,11.0556 65.793,11.0556"
                  id="Fill-23"
                  fill="#FFFFFF"
                />
                <path
                  d="M74.7622,16.0683 L76.9692,16.0683 L76.9692,24.8503 C76.9692,26.3463 77.8042,26.9953 78.9232,26.9953 C80.6872,26.9953 81.5682,26.5503 83.1812,25.6993 L83.1812,16.0683 L85.3882,16.0683 L85.3882,28.8053 L83.7452,28.8053 L83.1812,27.3543 C81.7722,28.1723 80.6032,29.0213 78.6602,29.0213 C76.0022,29.0213 74.7622,27.0333 74.7622,24.8363 L74.7622,16.0683 Z"
                  id="Fill-25"
                  fill="#FFFFFF"
                />
                <path
                  d="M94.8169,21.5858 C95.7749,21.5858 96.2059,21.2128 96.2059,20.4718 C96.2059,18.2008 95.7949,17.9638 93.4699,17.9638 C91.0149,17.9638 90.0339,18.0088 90.0419,21.5858 L94.8169,21.5858 Z M93.3999,15.9728 C96.5849,15.9728 98.4129,16.2968 98.4129,20.5188 C98.4129,22.4808 97.3579,23.5168 95.0549,23.5168 L90.0419,23.5168 C90.0419,26.3238 90.6789,26.9018 92.5359,26.9018 C94.6709,26.9018 96.3739,26.9228 98.1729,26.6588 L98.1729,28.2538 C96.2699,28.8378 94.0229,28.9258 92.0319,28.9258 C88.7939,28.9258 87.8349,27.2798 87.8349,22.3768 C87.8349,16.7498 89.4369,15.9728 93.3999,15.9728 L93.3999,15.9728 Z"
                  id="Fill-27"
                  fill="#FFFFFF"
                />
                <path
                  d="M100.2012,19.1864 C100.2012,17.2194 101.0412,15.9494 103.5122,15.9494 C105.0862,15.9494 108.7442,16.1624 109.9192,16.4044 L109.9192,17.9764 L103.7922,17.9764 C102.4402,17.9764 102.4082,18.4864 102.4082,19.2444 L102.4082,20.2044 C102.4082,21.3824 102.9942,21.4234 103.7922,21.4234 L107.3732,21.4234 C109.5542,21.4234 110.5632,22.7584 110.5632,24.5724 L110.5632,25.7834 C110.5632,28.3974 108.9802,28.9254 107.4452,28.9254 C105.9312,28.9254 101.6332,28.6334 100.7292,28.4054 L100.7292,26.8734 L107.1502,26.8734 C107.2112,26.8734 108.3572,26.9704 108.3572,25.7104 L108.3572,24.6944 C108.3572,23.9374 108.1052,23.5074 107.1502,23.5074 L103.5832,23.5074 C101.3282,23.5074 100.2012,22.7604 100.2012,20.2904 L100.2012,19.1864 Z"
                  id="Fill-29"
                  fill="#FFFFFF"
                />
                <path
                  d="M111.4111,16.5487 L113.7001,16.0447 L114.1671,12.4937 L115.9061,12.4937 L115.9061,16.0447 L119.3871,16.0447 L119.3871,18.0347 L115.9061,18.0347 L115.9061,24.1047 C115.9061,26.1337 116.4261,26.5917 117.1981,26.7977 C117.1981,26.7977 119.0211,27.3017 119.1891,27.3017 L119.1891,28.7887 L116.8551,28.7887 C114.9971,28.7887 113.7001,27.6297 113.7001,24.2477 L113.7001,18.0347 L111.4111,18.0347 L111.4111,16.5487 Z"
                  id="Fill-31"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
