import axios from 'axios';

export const get_media = () => {
  return function(dispatch) {
    dispatch(get_media_started());

    axios
      .get(`/api/mediaitems/account/${window['userState'].accountId}`)
      .then(r => {
        dispatch(get_media_success(r.data.items));
      })
      .catch(e => {
        dispatch(get_media_error(e));
      });
  };
};

const get_media_started = () => {
  return {
    type: 'GET_MEDIA_STARTED',
    isFetchingMedia: true,
  };
};

const get_media_success = media => {
  return {
    type: 'GET_MEDIA_SUCCESS',
    isFetchingMedia: false,
    fetchedMedia: media,
  };
};

const get_media_error = err => {
  return {
    type: 'GET_MEDIA_ERROR',
    isFetchingMedia: false,
    fetchingMediaError: err,
  };
};
