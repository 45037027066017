let initialState: any = {
  channels: [],
  notifications: [],
};

let newId = 1;

export default function NotificationsReducer(state = initialState, action) {
  //console.log('action: ' + action.type + ": " + JSON.stringify(action));

  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return {
        notifications: [
          Object.assign({}, action.notification, {
            id: newId++,
            expiresAfter: Math.floor(Date.now() / 1000) + 3,
          }),
          ...state.notifications,
        ],
      };

    case 'HIDE_NOTIFICATION':
      return {
        notifications: state.notifications.filter(n => n.id !== action.id),
      };

    case 'SET_CHANNELS':
      return Object.assign(state, {
        channels: action.channels,
      });
  }
  return state;
}
