import React, { MouseEvent } from 'react';
import RectObj from './ObjectTypes/RectObj';
import LineObj from './ObjectTypes/LineObj';
import TextObj from './ObjectTypes/TextObj';
import ImageObj from './ObjectTypes/ImageObj';
import CanvasProperties from './ObjectTypes/CanvasProperties';
import TimelineHolder from './Timeline/TimelineHolder';
import calculateTotalDuration from './calculateTotalDuration';
import MediaItemDialog from '../modals/media-item-picker';
import { connect } from 'react-redux';
import { get_media } from './ObjectTypes/get_media';

import '../../css/editor.css';

import image from '../../Images/signage/icon_image-btn.png';
import rect from '../../Images/signage/icon_rectangle-btn.png';
import line from '../../Images/signage/icon_line-btn.png';

declare global {
  const fabric: any;
  const canvas: any;
}

// Import images
var imageObjects = {
  image: image,
  rect: rect,
  line: line,
};

class PresentationEditor extends React.Component<any, any> {
  initialState: any = {};
  canvas: any = null;

  constructor(props) {
    super(props);

    this.initialState = {
      canvas: {},
      controls: {},
      timelineObjects: [],
      canvasProps: {
        bgColor: '#fff',
        bgImage: '',
        dimensions: {
          width: 1920,
          height: 1080,
        },
      },
      objSelected: false,
      selObjCan: null,
      selectedText: {
        fontFamily: '',
        fontWeight: '',
        textAlign: '',
        textColor: '',
        fontSize: '',
        variable: '',
        isVariable: 'false',
        opacity: '1',
        text: '',
      },
      selectedRect: {
        fillColor: '',
        borderColor: '',
        borderThickness: '1',
        opacity: 1,
      },
      selectedLine: {
        fillColor: '',
        lineThickness: 1,
        opacity: '1',
        x1: '',
        y1: '',
        x2: '',
        y2: '',
        length: '',
      },
      selectedImage: {
        url: '',
        opacity: '1',
      },
      posSize: {
        position: {
          x: 0,
          y: 0,
        },
        dimensions: {
          width: 0,
          height: 0,
          radius: 0,
        },
        fill: {
          color: '',
        },
        rotation: {
          angle: 0,
        },
        opacity: 1,
      },
      showAddMediaItemDialog: false,
    };
    this.state = Object.assign({}, this.initialState);
  }

  componentWillUnmount() {
    this.canvas.clear();
    this.canvas.setBackgroundColor('white');
    this.canvas.renderAll();
    this.setState({
      state: Object.assign({}, this.initialState),
    });

    window.removeEventListener('resize', this.resizeWindow);
  }

  resizeWindow = props => {
    const isPortrait = props
      ? props.isPortrait !== undefined
        ? props.isPortrait
        : this.props.isPortrait
      : false;

    if (isPortrait) {
      // max fit of 9:16 box inside the parent element
      const padding = 20,
        halfPadding = padding / 2;
      var maxWidth = ($('.canvas-section-holder').width() || 0) - padding;
      var maxHeight = ($('.canvas-section-holder').height() || 0) - padding;
      var scaling = Math.min(maxWidth / 9, maxHeight / 16);

      this.canvas.setWidth(9 * scaling);
      this.canvas.setHeight(16 * scaling);
      this.canvas.renderAll();
      this.canvas.calcOffset();
      this.canvas.setZoom(this.canvas.getWidth() / 1080);

      if (maxHeight > 16 * scaling) {
        $('.canvas-container').css({
          'margin-top': (maxHeight - 16 * scaling) / 2 + halfPadding,
          'margin-left': halfPadding,
        });
      } else {
        $('.canvas-container').css({
          'margin-top': halfPadding,
          'margin-left': (maxWidth - 9 * scaling) / 2 + halfPadding,
        });
      }
    } else {
      // max fit of 16:9 box inside the parent element
      const padding = 20,
        halfPadding = padding / 2;
      var maxWidth = ($('.canvas-section-holder').width() || 0) - padding;
      var maxHeight = ($('.canvas-section-holder').height() || 0) - padding;
      var scaling = Math.min(maxWidth / 16, maxHeight / 9);

      this.canvas.setWidth(16 * scaling);
      this.canvas.setHeight(9 * scaling);
      this.canvas.renderAll();
      this.canvas.calcOffset();
      this.canvas.setZoom(this.canvas.getWidth() / 1920);

      if (maxHeight > 9 * scaling) {
        $('.canvas-container').css({
          'margin-top': (maxHeight - 9 * scaling) / 2 + halfPadding,
          'margin-left': halfPadding,
        });
      } else {
        $('.canvas-container').css({
          'margin-top': halfPadding,
          'margin-left': (maxWidth - 16 * scaling) / 2 + halfPadding,
        });
      }
    }

    console.log(`zoom level is ${this.canvas.getZoom()}`);
  };

  componentDidMount() {
    fabric.Object.prototype.toObject = (function(toObject) {
      return function(this: any) {
        return fabric.util.object.extend(toObject.call(this), {
          animations: this.getObjAnimations(),
          objDuration: this.getObjDuration(),
          zIndex: this.getZIndex(),
          text: this.text,
          fontFamily: this.fontFamily,
          fontWeight: this.fontWeight,
          fontSize: this.fontSize,
          variable: this.getTextVariable(),
        });
      };
    })(fabric.Object.prototype.toObject);

    var self = this;

    fabric.Object.prototype.getZIndex = function() {
      return self.canvas.getObjects().indexOf(this);
    };

    // Get custom fields before saving the objects
    fabric.Object.prototype.getObjAnimations = function() {
      var objs = self.canvas.getObjects();
      var indexIs = objs.indexOf(this);

      if (indexIs !== -1) {
        return objs[indexIs].get('animations');
      }
    };

    fabric.Object.prototype.getObjDuration = function() {
      var objs = self.canvas.getObjects();
      var indexIs = objs.indexOf(this);

      if (indexIs !== -1) {
        return objs[indexIs].get('objDuration');
      }
    };

    fabric.Object.prototype.getTextVariable = function() {
      var objs = self.canvas.getObjects();
      var indexIs = objs.indexOf(this);

      if (indexIs !== -1 && objs[indexIs].type === 'i-text') {
        return objs[indexIs].get('variable');
      }

      return null;
    };

    this.canvas = new fabric.Canvas('c', {
      width: this.props.isPortrait ? 108 : 192,
      height: this.props.isPortrait ? 192 : 108,
      backgroundColor: this.state.canvasProps.bgColor,
      preserveObjectStacking: true,
    });

    this.props.handleCanvasCreated(this.canvas);

    this.resizeWindow(null);
    window.addEventListener('resize', this.resizeWindow, false);

    this.canvas.loadFromJSON(this.props.oldCanvas);
    this.canvas.renderAll();

    // Update background property
    if (this.props.oldCanvas) {
      if (this.props.oldCanvas.background) {
        var canBg = this.state.canvasProps;
        canBg.bgColor = this.props.oldCanvas.background;
        this.setState({
          canvasProps: canBg,
        });
      }
    }

    this.canvas.on('object:added', e => {
      this.setState({
        timelineObjects: this.canvas._objects.map(i => i),
      });
      if (this.props.handleDurationChange)
        this.props.handleDurationChange(
          calculateTotalDuration(this.canvas._objects),
          0
        );
    });

    // If the object is modified, change the values in the UI
    this.canvas.on('object:modified', e => {
      this.updateSelectedObj(e.target);
      window['selectedObject'] = e.target;

      // Auto save
      this.props.save();
    });

    // If the object is selected, change the values in the UI
    this.canvas.on('object:selected', this.handleSelectObj.bind(this));

    // If no objects are selected, clear the UI with properties
    this.canvas.on('selection:cleared', this.handleUnselectObj.bind(this));

    this.canvas.on('text:changed', e => {
      if (this.state.selectedText) {
        this.setState({
          selectedText: Object.assign({}, this.state.selectedText, {
            text: e.target.text,
          }),
        });
      }
    });

    // Keep stroke width 0 at all times
    this.canvas.on('object:scaling', function(e) {
      var obj = e.target;
      if (obj.type !== 'line') {
        obj.set('strokeWidth', 0);

        const w = obj.width * obj.scaleX;
        const h = obj.height * obj.scaleY;
        const s = obj.strokeWidth;

        obj.set({
          height: h,
          width: w,
          scaleX: 1,
          scaleY: 1,
        });
      }
    });

    // Update the UI with canvas info after canvas is loaded
    this.canvas.on('after:render', e => {
      var oldCan = this.state.canvasProps;
      oldCan.dimensions.width = this.canvas.getWidth();
      oldCan.dimensions.height = this.canvas.getHeight();
      this.setState({
        canvasProps: oldCan,
      });
    });

    this.setState({
      canvas: this.canvas,
      timelineObjects: this.canvas._objects,
    });

    if (this.props.handleDurationChange)
      this.props.handleDurationChange(
        calculateTotalDuration(this.canvas._objects),
        0
      );

    window['canvas'] = this.canvas;

    this.props.dispatch(get_media());
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.oldCanvas !== this.props.oldCanvas &&
      newProps.oldCanvas !== null
    ) {
      this.state.canvas.loadFromJSON(newProps.oldCanvas);

      // Update background property
      var canBg = this.state.canvasProps;

      if (newProps.oldCanvas && newProps.oldCanvas.background) {
        canBg.bgColor = newProps.oldCanvas.background;
      }

      // correctly init
      if (
        newProps.oldCanvas &&
        newProps.oldCanvas.backgroundImage &&
        newProps.oldCanvas.backgroundImage.src
      ) {
        canBg.bgImage = newProps.oldCanvas.backgroundImage.src;
      }

      this.setState({
        canvasProps: canBg,
      });
    }

    if (this.props.isPortrait !== newProps.isPortrait) {
      this.resizeWindow(newProps);
    }

    if (this.props.collapsed != newProps.collapsed) {
      setTimeout(() => {
        this.resizeWindow(null);
      }, 500);
    }
  }

  handleSelectObj = e => {
    this.updateSelectedObj(e.target !== undefined ? e.target : e);
    window['selectedObject'] = e.target !== undefined ? e.target : e;

    if (this.state.selObjCan && this.state.selObjCan.type === 'i-text') {
      this.state.selObjCan.setBackgroundColor('');
    }

    if (e.target === undefined) {
      this.canvas.setActiveObject(e);
    }

    if (e.target && e.target.type === 'i-text') {
      e.target.setBackgroundColor('red');
    }

    this.setState({
      objSelected: true,
      selObjCan: e.target !== undefined ? e.target : e,
    });
  };

  handleUnselectObj = e => {
    this.updateSelectedObj(null);

    if (this.state.selObjCan && this.state.selObjCan.type === 'i-text') {
      this.state.selObjCan.setBackgroundColor('');
    }

    this.setState({
      objSelected: false,
      selObjCan: null,
    });
  };

  handleDeselect(e) {
    if (e.target.id === 'canvas-section-holder') {
      this.canvas.discardActiveObject();
      this.canvas.renderAll();
    }
  }

  handleChangeEvent = (e, value) => {
    let oldSelectedObj = this.state.posSize;

    let groupName, propName, propVal;

    if (typeof e === 'string') {
      propName = e;
      propVal = value;
    } else {
      const name = e.target.name;
      propVal = e.target.value;
      if (name.indexOf('-') === -1) {
        propName = name;
      } else {
        groupName = name.split('-')[0];
        propName = name.split('-')[1];
      }
    }

    switch (propName) {
      case 'x': {
        const n = parseFloat(propVal);
        oldSelectedObj[groupName][propName] = n;
        this.canvas.getActiveObject().setLeft(n);
        this.canvas.renderAll();
        break;
      }

      case 'y': {
        const n = parseFloat(propVal);
        oldSelectedObj[groupName][propName] = n;
        this.canvas.getActiveObject().setTop(n);
        this.canvas.renderAll();
        break;
      }

      case 'width': {
        const n = parseFloat(propVal);
        oldSelectedObj[groupName][propName] = n;
        this.canvas.getActiveObject().setWidth(n);
        this.canvas.renderAll();
        break;
      }

      case 'height': {
        const n = parseFloat(propVal);
        oldSelectedObj[groupName][propName] = n;
        this.canvas.getActiveObject().setHeight(n);
        this.canvas.renderAll();
        break;
      }

      case 'angle': {
        const n = parseFloat(propVal);
        oldSelectedObj[groupName][propName] = n;
        this.canvas.getActiveObject().setAngle(n);
        this.canvas.renderAll();
        break;
      }

      case 'color': {
        this.canvas.getActiveObject().setFill(propVal);
        this.canvas.renderAll();
        break;
      }

      case 'opacity': {
        let opacity = parseFloat(propVal);
        if (opacity > 1) opacity = opacity / 100;

        // temporary workaround
        switch (this.state.selObjCan.type) {
          case 'rect':
            this.state.selectedRect.opacity = opacity;
            break;

          case 'i-text':
            this.state.selectedText.opacity = opacity;
            break;

          case 'image':
            this.state.selectedImage.opacity = opacity;
            break;

          case 'line':
            this.state.selectedLine.opacity = opacity;
            break;
        }

        this.canvas.getActiveObject().setOpacity(opacity);
        this.canvas.renderAll();
        break;
      }

      case 'fontSize': {
        let fontSize = parseFloat(propVal);
        this.state.selectedText.fontSize = fontSize;
        this.canvas.getActiveObject().setFontSize(fontSize);
        this.state.canvas.renderAll();
        break;
      }

      default:
        console.log(
          `Cannot change prop: ${groupName} ${propName} to ${propVal}.`
        );
        return;
    }

    this.setState({
      posSize: Object.assign({}, oldSelectedObj),
    });

    // Auto save
    this.props.save();
  };

  handleAddMediaItemDialogClose = () => {
    this.setState({ showAddMediaItemDialog: false });
  };

  handleImageUploaded = mediaItem => {
    let uri = mediaItem.uri || mediaItem.thumbUri;
    uri = uri.replace('_thumb', '');
    let obj = new fabric.Image.fromURL(uri, imgLoaded => {
      let s = 1,
        W = 0,
        H = 0;
      if (this.props.isPortrait) {
        s = Math.min(1080 / imgLoaded.width, 1920 / imgLoaded.height);
        W = 1080;
        H = 1920;
      } else {
        s = Math.min(1920 / imgLoaded.width, 1080 / imgLoaded.height);
        W = 1920;
        H = 1080;
      }

      const w = s * imgLoaded.width;
      const h = s * imgLoaded.height;

      imgLoaded.animations = {
        entry: {
          name: 'none',
          duration: 0,
          delay: 0,
          properties: {
            direction: 'left',
            distance: 0,
            easing: 'none',
            easingSpecial: 'none',
            reverse: false,
            repeat: 0,
          },
        },
        exit: {
          name: 'none',
          duration: 0,
          delay: 0,
          properties: {
            direction: 'left',
            distance: 0,
            easing: 'none',
            easingSpecial: 'none',
            reverse: false,
            repeat: 0,
          },
        },
      };
      imgLoaded.objDuration = {
        delay: 0,
        duration: 0,
      };

      this.canvas.add(imgLoaded);

      imgLoaded.setCoords();
      imgLoaded.setWidth(w);
      imgLoaded.setHeight(h);
      imgLoaded.setLeft((W - w) / 2);
      imgLoaded.setTop((H - h) / 2);
      this.canvas.renderAll();

      // Auto save
      this.props.save();
    });

    // this.setState({ showAddMediaItemDialog: false })
  };

  handleClickEvent = e => {
    e.preventDefault();

    let name =
      e.target.getAttribute('data-name') ||
      e.target.name ||
      e.target.parentElement.name;

    switch (name) {
      case 'rect-btn':
        this.initAndAddNewObject(
          new fabric.Rect({
            width: 100,
            height: 100,
            fill: 'black',
          })
        );
        break;

      case 'circ-btn':
        this.initAndAddNewObject(
          new fabric.Circle({
            radius: 50,
            fill: 'green',
          })
        );
        break;

      case 'line-btn':
        this.initAndAddNewObject(
          new fabric.Line([50, 50, 100, 100], {
            stroke: 'black',
            strokeWidth: 2,
          })
        );
        break;

      case 'text-btn':
        this.initAndAddNewObject(
          new fabric.IText('Text', {
            fontSize: 120,
            fontFamily: 'bellevue-stencil',
          })
        );
        break;

      case 'image-btn':
        this.setState({ showAddMediaItemDialog: true }, () => {
          $('#addMediaItemDialog').modal({
            backdrop: 'static',
            keyboard: false,
          });
          $('#addMediaItemDialog').on('hidden.bs.modal', () => {
            this.setState({ showAddMediaItemDialog: false });
          });
        });
        break;

      case 'rotation-angle-reset': {
        let oldSelectedObj = this.state.posSize;
        const n = 0;
        oldSelectedObj.rotation.angle = n;
        this.canvas.getActiveObject().setAngle(n);
        this.canvas.renderAll();
        break;
      }

      case 'rotation-angle-left': {
        let oldSelectedObj = this.state.posSize;
        const n = oldSelectedObj.rotation.angle - 45;
        oldSelectedObj.rotation.angle = n;
        this.canvas.getActiveObject().setAngle(n);
        this.canvas.renderAll();
        break;
      }

      case 'rotation-angle-right': {
        let oldSelectedObj = this.state.posSize;
        const n = oldSelectedObj.rotation.angle + 45;
        oldSelectedObj.rotation.angle = n;
        this.canvas.getActiveObject().setAngle(n);
        this.canvas.renderAll();
        break;
      }

      case 'font-size-dec': {
        let fontSize = 0.9 * this.state.selectedText.fontSize;
        this.state.selectedText.fontSize = fontSize;
        this.canvas.getActiveObject().setFontSize(fontSize);
        this.state.canvas.renderAll();
        break;
      }

      case 'font-size-inc': {
        let fontSize = 1.1 * this.state.selectedText.fontSize;
        this.state.selectedText.fontSize = fontSize;
        this.canvas.getActiveObject().setFontSize(fontSize);
        this.state.canvas.renderAll();
        break;
      }

      case 'align-left': {
        this.state.posSize.position.x = 0;
        this.canvas.getActiveObject().setLeft(0);
        this.canvas.renderAll();
        break;
      }

      case 'align-top': {
        this.state.posSize.position.y = 0;
        this.canvas.getActiveObject().setTop(0);
        this.canvas.renderAll();
        break;
      }

      case 'align-right': {
        const left =
          window['canvas'].width / window['canvas'].getZoom() -
          this.state.posSize.dimensions.width;
        this.state.posSize.position.x = left;
        this.canvas.getActiveObject().setLeft(left);
        this.canvas.renderAll();
        break;
      }

      case 'align-bottom': {
        const top =
          window['canvas'].height / window['canvas'].getZoom() -
          this.state.posSize.dimensions.height;
        this.state.posSize.position.y = top;
        this.canvas.getActiveObject().setTop(top);
        this.canvas.renderAll();
        break;
      }

      case 'align-horizontal': {
        const left =
          (window['canvas'].width / window['canvas'].getZoom() -
            this.state.posSize.dimensions.width) /
          2;
        this.state.posSize.position.x = left;
        this.canvas.getActiveObject().setLeft(left);
        this.canvas.renderAll();
        break;
      }

      case 'align-vertical': {
        const top =
          (window['canvas'].height / window['canvas'].getZoom() -
            this.state.posSize.dimensions.height) /
          2;
        this.state.posSize.position.y = top;
        this.canvas.getActiveObject().setTop(top);
        this.canvas.renderAll();
        break;
      }

      case 'canvas-background-remove':
        {
          this.canvas.setBackgroundImage(null);
        }
        break;

      default:
        console.log(`Cannot handle click on ${name}`);
        return;
    }

    this.canvas.renderAll();
    // Auto save
    this.props.save();
  };

  initAndAddNewObject = obj => {
    if (obj) {
      // Add custom properties to newly created objects
      obj.animations = {
        entry: {
          name: 'none',
          duration: 0,
          delay: 0,
          properties: {
            direction: 'left',
            distance: 0,
            easing: 'none',
            easingSpecial: 'none',
            reverse: false,
            repeat: 0,
          },
        },
        exit: {
          name: 'none',
          duration: 0,
          delay: 0,
          properties: {
            direction: 'left',
            distance: 0,
            easing: 'none',
            easingSpecial: 'none',
            reverse: false,
            repeat: 0,
          },
        },
      };
      obj.objDuration = {
        delay: 0,
        duration: 0,
      };

      obj.variable = {
        name: '',
        value: '',
      };

      this.canvas.add(obj);
      this.canvas.centerObject(obj);
      obj.setCoords();
    }
  };

  handleClickClearCanvas = e => {
    e.preventDefault();
    if (!confirm('Are you sure you want to delete everything from the canvas?'))
      return;

    console.log('Clearing entire canvas...');

    this.canvas.clear();
    this.canvas.setBackgroundColor('white');
    this.canvas.renderAll();
    this.setState({
      canvasProps: Object.assign({}, this.state.canvasProps, {
        bgColor: '#fff',
        bgImage: '',
      }),
      timelineObjects: [],
    });

    if (this.props.handleDurationChange) this.props.handleDurationChange(0, 0);

    this.props.handleClearCanvas();
    this.props.save.bind(this, this.canvas)();
  };

  handleClickZIndex = e => {
    e.preventDefault();

    switch (e.target.name) {
      case 'back-btn':
        this.canvas.sendToBack(this.canvas.getActiveObject());
        this.canvas.renderAll();
        break;
      case 'front-btn':
        this.canvas.bringToFront(this.canvas.getActiveObject());
        this.canvas.renderAll();
        break;
    }
  };

  handleClickClear = () => {
    var x = this.state.canvas.getActiveObject();
    this.state.canvas.remove(x);

    // Auto save
    this.props.save.bind(this, this.canvas)();
  };

  updateSelectedObj = obj => {
    var oldSelectedObj = this.state.posSize,
      oldSelectedLine = this.state.selectedLine,
      oldSelectedRect = this.state.selectedRect,
      oldSelectedImage = Object.assign({}, this.state.selectedImage),
      oldSelectedText = this.state.selectedText;

    if (!obj) {
      oldSelectedObj.dimensions.height = 0;
      oldSelectedObj.position.x = 0;
      oldSelectedObj.position.y = 0;
      oldSelectedObj.fill.color = '';
      oldSelectedObj.rotation.angle = 0;

      oldSelectedText.isVariable = false;

      oldSelectedLine = {
        fillColor: '',
        lineThickness: 0,
        opacity: 1,
        x1: '',
        y1: '',
        x2: '',
        y2: '',
        length: '',
      };
    } else {
      if (obj.type === 'line') {
        oldSelectedLine.fillColor = obj.getFill();
        oldSelectedLine.lineThickness = obj.getStrokeWidth();
        oldSelectedLine.opacity = obj.getOpacity();
        oldSelectedLine.x1 = obj.get('x1');
        oldSelectedLine.y1 = obj.get('y1');
        oldSelectedLine.x2 = obj.get('x2');
        oldSelectedLine.y2 = obj.get('y2');
        //oldSelectedLine.length = obj.getLength();
      } else {
        // Common for all types except line
        oldSelectedObj.dimensions.width = Math.floor(obj.getWidth());
        oldSelectedObj.dimensions.height = Math.floor(obj.getHeight());
        oldSelectedObj.position.x = Math.floor(obj.getLeft());
        oldSelectedObj.position.y = Math.floor(obj.getTop());
        oldSelectedObj.fill.color = Math.floor(obj.getFill());
        oldSelectedObj.rotation.angle = Math.floor(obj.getAngle());

        // Switch through types and only update parts of each type
        switch (obj.type) {
          case 'rect':
            oldSelectedRect.opacity = obj.getOpacity();
            break;
          case 'i-text':
            oldSelectedText.opacity = obj.getOpacity();
            oldSelectedText.fontFamily = obj.getFontFamily();
            oldSelectedText.fontSize = obj.getFontSize();
            oldSelectedText.fontWeight = obj.getFontWeight();
            oldSelectedText.textColor = obj.getFill();
            oldSelectedText.textAlign = obj.getTextAlign();
            oldSelectedText.text = obj.text;
            oldSelectedText.variable = obj.get('variable');
            break;
          case 'image':
            oldSelectedImage.opacity = obj.getOpacity();
            oldSelectedImage.url = obj.getSrc();
            break;
        }
      }
    }

    this.setState({
      posSize: Object.assign({}, oldSelectedObj),
      selectedImage: oldSelectedImage,
      selectedRect: oldSelectedRect,
      selectedText: oldSelectedText,
      selectedLine: oldSelectedLine,
    });
  };

  handleChangeCanvas = e => {
    var groupName, propName, propVal, oldCanvasObj;
    oldCanvasObj = this.state.canvasProps;

    if (e.target === undefined) {
      if (typeof e === 'string') {
        oldCanvasObj.bgColor = e;
        this.canvas.setBackgroundColor(e);
        this.canvas.renderAll();
      }
    } else {
      propName = e.target.name;
      propVal = e.target.value;

      oldCanvasObj[propName] = propVal;

      switch (propName) {
        case 'bgImage':
          fabric.Image.fromURL(propVal, loadedImg => {
            var W = 1920,
              H = 1080;
            if (this.props.isPortrait) {
              W = 1080;
              H = 1920;
            }
            var scale = Math.max(W / loadedImg.width, H / loadedImg.height);
            var w = loadedImg.width * scale;
            var h = loadedImg.height * scale;

            loadedImg.setWidth(w);
            loadedImg.setHeight(h);
            this.canvas.setBackgroundImage(
              loadedImg,
              this.canvas.renderAll.bind(this.canvas),
              {
                originX: 'left',
                originY: 'top',
                left: -(w - W) / 2,
                top: -(h - H) / 2,
              }
            );
          });
          break;
        default:
          console.log('Something went wrong.');
          break;
      }
    }

    this.setState({
      canvasProps: oldCanvasObj,
    });
  };

  handleChangeRect = e => {
    var propName, propVal, oldSelectedRect;
    oldSelectedRect = this.state.selectedRect;

    if (e.target === undefined) {
      if (typeof e === 'string') {
        this.canvas.getActiveObject().setFill(e);
        this.canvas.renderAll();
      } else {
        oldSelectedRect.opacity = e;
        this.canvas.getActiveObject().setOpacity(e);
        this.canvas.renderAll();
      }
    } else {
      propName = e.target.name;
      propVal = e.target.value;

      oldSelectedRect[propName] = propVal;

      switch (propName) {
        case 'opacity':
          this.canvas.getActiveObject().setOpacity(propVal);
          this.canvas.renderAll();
          break;
        default:
          console.log('Something went wrong.');
          break;
      }
    }

    this.setState({
      selectedRect: oldSelectedRect,
    });
  };

  handleChangeText = e => {
    var propName, propVal, oldSelectedText;
    oldSelectedText = this.state.selectedText;

    if (e.target === undefined) {
      this.canvas.getActiveObject().setFill(e);
      this.canvas.renderAll();
    } else if (e.target.name === 'isVariable') {
      oldSelectedText[e.target.name] = e.target.checked;
    } else if (e.target.name === 'variableName') {
      let newVar = {
        name: e.target.value,
        value: '',
      };

      oldSelectedText.variable.name = e.target.value;

      this.canvas.getActiveObject().set('variable', newVar);
      this.canvas.renderAll();
    } else {
      propName = e.target.name;
      propVal = e.target.value;

      oldSelectedText[propName] = propVal;

      switch (propName) {
        case 'opacity':
          this.canvas.getActiveObject().setOpacity(parseFloat(propVal));
          this.state.canvas.renderAll();
          break;
        case 'fontFamily':
          this.canvas.getActiveObject().setFontFamily(propVal);
          this.state.canvas.renderAll();
          break;
        case 'fontWeight':
          this.canvas.getActiveObject().setFontWeight(propVal);
          this.state.canvas.renderAll();
          break;
        case 'fontSize':
          this.canvas.getActiveObject().setFontSize(parseFloat(propVal));
          this.state.canvas.renderAll();
          break;
        case 'textAlign':
          this.canvas.getActiveObject().setTextAlign(propVal);
          this.state.canvas.renderAll();
          break;

        case 'text':
          this.canvas.getActiveObject().setText(propVal);
          this.state.canvas.renderAll();
          break;

        default:
          console.log('Something went wrong.');
          break;
      }
    }

    this.setState({
      selectedText: oldSelectedText,
    });
  };

  handleChangeImage = e => {
    var propName, propVal, oldSelectedImg;

    oldSelectedImg = Object.assign({}, this.state.selectedImage);
    propName = e.target.name;
    propVal = e.target.value;

    oldSelectedImg[propName] = propVal;

    const img = this.canvas.getActiveObject();

    switch (propName) {
      case 'opacity':
        img.setOpacity(parseFloat(propVal));
        this.canvas.renderAll();
        break;

      case 'url':
        this.canvas.getActiveObject().setSrc(propVal, imgLoaded => {
          let s = 1,
            W = 0,
            H = 0;
          if (this.props.isPortrait) {
            s = Math.min(1080 / imgLoaded.width, 1920 / imgLoaded.height);
            W = 1080;
            H = 1920;
          } else {
            s = Math.min(1920 / imgLoaded.width, 1080 / imgLoaded.height);
            W = 1920;
            H = 1080;
          }

          const w = s * imgLoaded.width;
          const h = s * imgLoaded.height;

          img.setCoords();
          img.setWidth(w);
          img.setHeight(h);
          img.setLeft((W - w) / 2);
          img.setTop((H - h) / 2);
          this.canvas.renderAll();
        });
        break;

      default:
        console.log(`Invalid image property ${propName}.`);
        break;
    }

    this.setState({
      selectedImage: oldSelectedImg,
    });
  };

  handleChangeLine = e => {
    var propName, propVal, oldSelectedLine;

    oldSelectedLine = this.state.selectedLine;
    propName = e.target.name;
    propVal = e.target.value;

    oldSelectedLine[propName] = propVal;

    switch (propName) {
      case 'x1':
        this.canvas.getActiveObject().set('x1', propVal);
        this.canvas.renderAll();
        break;
      case 'y1':
        this.canvas.getActiveObject().set('y1', propVal);
        this.canvas.renderAll();
        break;
      case 'x2':
        this.canvas.getActiveObject().set('x2', propVal);
        this.canvas.renderAll();
        break;
      case 'y2':
        this.canvas.getActiveObject().set('y2', propVal);
        this.canvas.renderAll();
        break;
      case 'opacity':
        this.canvas.getActiveObject().setOpacity(propVal);
        this.canvas.renderAll();
        break;
      case 'fillColor':
        this.canvas.getActiveObject().setFill(propVal);
        this.canvas.renderAll();
        break;
      case 'thickness':
        this.canvas.getActiveObject().setStrokeWidth(propVal);
        this.canvas.renderAll();
        break;
      default:
        console.log('Something went wrong.');
        break;
    }

    this.setState({
      selectedLine: oldSelectedLine,
    });
  };

  changeAnimations = e => {
    const groupName = e.target.name.split('-')[0];
    let propName = e.target.name.split('-')[1];
    const propVal = e.target.value;

    const objIndex = e.target.getAttribute('data-index');
    const timelineObjects = this.state.timelineObjects;
    const targetObj = timelineObjects[objIndex];

    if (propName === 'properties') {
      propName = e.target.name.split('-')[2];
      targetObj.animations[groupName]['properties'][propName] = propVal;
    } else targetObj.animations[groupName][propName] = propVal;

    this.setState({
      timelineObjects: timelineObjects.map((a, i) => a),
    });

    if (this.props.handleDurationChange)
      this.props.handleDurationChange(
        calculateTotalDuration(timelineObjects),
        0
      );
  };

  changeDuration = e => {
    var groupName,
      propName,
      propVal,
      oldTimelineObj,
      objIndex,
      targetObj,
      targetObjDuration;

    objIndex = e.target.getAttribute('data-index');
    groupName = e.target.name.split('-')[0];
    oldTimelineObj = this.state.timelineObjects;
    propName = e.target.name.split('-')[1];
    propVal = e.target.value;
    targetObj = oldTimelineObj[objIndex];
    targetObjDuration = targetObj.objDuration;

    targetObjDuration[propName] = propVal;

    this.setState({
      timelineObjects: oldTimelineObj,
    });

    if (this.props.handleDurationChange)
      this.props.handleDurationChange(
        calculateTotalDuration(oldTimelineObj),
        0
      );
  };

  render() {
    var objectSelected;

    if (this.state.objSelected) {
      switch (this.state.selObjCan.type) {
        case 'rect':
          objectSelected = (
            <RectObj
              // temporary
              handleClickClear={this.handleClickClear}
              handleClickZIndex={this.handleClickZIndex}
              handleChangeRect={this.handleChangeRect}
              rectStateObj={this.state.selectedRect}
              rect={this.state.selObjCan}
              objProps2={this.state.selectedRect}
              selectedObject={this.state.selObjCan}
              handleClickEvent={this.handleClickEvent}
              handleChangeEvent={this.handleChangeEvent}
              objProps={this.state.posSize}
            />
          );
          break;
        case 'line':
          objectSelected = (
            <LineObj
              handleClickClear={this.handleClickClear}
              handleClickEvent={this.handleClickEvent}
              handleChangeEvent={this.handleChangeEvent}
              handleChangeLine={this.handleChangeLine}
              lineStateObj={this.state.selectedLine}
              line={this.state.selObjCan}
            />
          );
          break;
        case 'i-text':
          objectSelected = (
            <TextObj
              handleClickClear={this.handleClickClear}
              handleClickEvent={this.handleClickEvent}
              handleClickZIndex={this.handleClickZIndex}
              handleChangeEvent={this.handleChangeEvent}
              handleChangeText={this.handleChangeText}
              textStateObj={this.state.selectedText}
              text={this.state.selObjCan}
              selectedObject={this.state.selObjCan}
              objProps={this.state.posSize}
              objProps2={this.state.selectedText}
            />
          );
          break;
        case 'image':
          objectSelected = (
            <ImageObj
              handleClickClear={this.handleClickClear}
              handleClickEvent={this.handleClickEvent}
              handleClickZIndex={this.handleClickZIndex}
              handleChangeEvent={this.handleChangeEvent}
              handleChangeImage={this.handleChangeImage}
              imageStateObj={this.state.selectedImage}
              image={this.state.selObjCan}
              selectedObject={this.state.selObjCan}
              objProps={this.state.posSize}
              objProps2={this.state.selectedImage}
            />
          );
          break;
      }
    } else {
      objectSelected = (
        <CanvasProperties
          handleChangeCanvas={this.handleChangeCanvas}
          handleClickEvent={this.handleClickEvent}
          canvasProps={this.state.canvasProps}
        />
      );
    }

    return (
      <div className="builder-container">
        <div
          className={
            this.props.collapsed
              ? 'canvas-holder stretch clearfix'
              : 'canvas-holder clearfix'
          }
        >
          <div
            className="canvas-section-holder"
            id="canvas-section-holder"
            onClick={e => this.handleDeselect(e)}
          >
            <canvas id="c" />
          </div>

          <div className="canvas-controls">
            <div className="top-controls">
              <div className="clearfix">
                <button
                  className="btn image-btn"
                  name="image-btn"
                  title="Insert image"
                  onClick={this.handleClickEvent}
                />
                <button
                  className="btn text-btn"
                  name="text-btn"
                  title="Insert text"
                  onClick={this.handleClickEvent}
                />
                <span className="separator" />
                <button
                  className="btn rect-btn"
                  name="rect-btn"
                  title="Insert rectangle"
                  onClick={this.handleClickEvent}
                />

                <button
                  className="btn btn-danger btn-clear pull-right"
                  name="reset-btn"
                  onClick={this.handleClickClearCanvas}
                  title="Clear canvas"
                >
                  <span
                    className="glyphicon glyphicon-trash"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>

            {objectSelected}
          </div>
        </div>

        <TimelineHolder
          timelineObjects={this.state.timelineObjects}
          changeAnimations={this.changeAnimations}
          changeDuration={this.changeDuration}
          selectedObj={
            this.state.selObjCan !== null
              ? this.state.selObjCan.getZIndex()
              : null
          }
          updateSelectedObj={this.handleSelectObj}
        />

        {this.state.showAddMediaItemDialog ? (
          <MediaItemDialog
            name="addMediaItemDialog"
            orientation={128}
            type="image"
            handleClose={this.handleAddMediaItemDialogClose}
            handleImageUploaded={this.handleImageUploaded}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    collapsed: state.Timeline.collapsed,
  };
};

export default connect(mapStateToProps)(PresentationEditor);
