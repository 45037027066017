import { AppPlatforms } from 'api';

export function formatAppPlatforms(platform: AppPlatforms) {
  switch (platform) {
    case AppPlatforms.None:
      return 'none';
  }

  return 'unkown';
}
