import axios from 'axios';

import { CreateAppAndUserCommand, AddAppUserCommand } from './types';

export const createAppAndUsers = (
  accountId: number,
  data: CreateAppAndUserCommand
) => {
  return axios.post(`/api/mobile/appsusers/${accountId}`, data);
};

export function addUserToApp(
  accountId: number,
  appId: number,
  email: string,
  password: string
) {
  return axios.post<boolean>(`/api/mobile/appsusers/${accountId}/adduser`, {
    appId,
    email,
    password,
  });
}

export function removeUserFromApp(
  accountId: number,
  appId: number,
  userId: string
) {
  return axios.post<boolean>(`/api/mobile/appsusers/${accountId}/removeuser`, {
    appId,
    userId,
  });
}

export function setPasswordForUser(
  accountId: number,
  email: string,
  password: string
) {
  return axios.post<boolean>(`/api/mobile/appsusers/${accountId}/setpassword`, {
    email,
    password,
  });
}
