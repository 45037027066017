import React, { Component } from 'react';

import PageBackground from './PageBackground';
import TextStyle from './TextStyle';

class PageStyle extends Component<any, any> {
  render() {
    return (
      <div>
        <PageBackground
          background={this.props.background}
          handleUpdate={background => this.props.setBackground(background)}
          {...this.props}
        />

        {this.props.content.map((block, index) => {
          switch (block.type) {
            case 'text':
              return (
                <TextStyle
                  key={index}
                  data={block}
                  theme={this.props.theme}
                  handleUpdate={data => {
                    this.props.setContent(index, data);
                  }}
                />
              );
          }

          return null;
        })}
      </div>
    );
  }
}

export default PageStyle;
