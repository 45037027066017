import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import * as Editors from './Editors';
import { BlockType, ContentBlock } from './contentHelpers';
import { EditBlock, EditBlockCommands } from './Editors/common';
import { Label, Input } from 'ui';

interface Props {
  page: {
    content: any[];
  };
  setContent: (data: any) => any;
  addSuccessNotification: (text: string) => any;
  addDangerNotification: (text: string) => any;
  removeBlock: (id: number) => any;
  moveBlockUp: (id: number) => any;
  moveBlockDown: (id: number) => any;
}

class PageContent extends React.Component<
  Props & RouteComponentProps<{ pageid: string }>,
  {}
> {
  render() {
    const { content } = this.props.page;

    const editable = content.map((data: ContentBlock) => {
      const editorProps = {
        handleUpdate: this.props.setContent,
      };

      const blockProps = {
        id: data.id || -1,
        moveBlockUp: this.props.moveBlockUp,
        moveBlockDown: this.props.moveBlockDown,
        removeBlock: this.props.removeBlock,
      };

      switch (data.type) {
        case BlockType.title:
          return (
            <EditBlock title="title" key={data.id}>
              <EditBlockCommands {...blockProps} />
              <Editors.Title data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.text:
          return (
            <EditBlock title="text" key={data.id}>
              <EditBlockCommands {...blockProps} />
              <Editors.Text data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.gallery:
          return (
            <EditBlock title="gallery" key={data.id}>
              <EditBlockCommands {...blockProps} isFixed={true} />
              <Editors.Gallery data={data} {...editorProps} {...this.props} />
            </EditBlock>
          );

        case BlockType.youtube:
          return (
            <EditBlock title="youtube" key={data.id}>
              <EditBlockCommands {...blockProps} />
              <Editors.Youtube data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.button:
          return (
            <EditBlock title="button" key={data.id}>
              <EditBlockCommands {...blockProps} />
              <Editors.Button data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.rss:
          return (
            <EditBlock title="rss feed" key={data.id}>
              <Editors.RSS data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.web:
          return (
            <EditBlock title="web" key={data.id}>
              <Editors.Web data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.social:
          return (
            <EditBlock title="social" key={data.id}>
              <EditBlockCommands {...blockProps} />
              <Editors.Social data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.map:
          return (
            <EditBlock title="map detail" key={data.id}>
              <EditBlockCommands {...blockProps} isFixed={true} />
              <Editors.Map data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.dataGrid:
          return (
            <EditBlock title="data grid" key={data.id}>
              <EditBlockCommands {...blockProps} />
              <Editors.DataGrid data={data} {...editorProps} />
            </EditBlock>
          );

        case BlockType.weather:
          return (
            <EditBlock title="weather" key={data.id}>
              <Editors.Weather />
            </EditBlock>
          );

        case BlockType.alfredPayment1:
          /* eslint-disable */
          /* tslint:disable */
          return (
            <>
              <EditBlock title="title" key={data.id}>
                <Editors.Title
                  data={{
                    id: 0,
                    type: BlockType.title,
                    text: data.title,
                  }}
                  handleUpdate={part =>
                    this.props.setContent({
                      ...data,
                      title: part.text,
                    })
                  }
                />
              </EditBlock>

              <EditBlock title="description" key={data.id}>
                <Editors.Text
                  data={{
                    id: 0,
                    type: BlockType.text,
                    text: data.text,
                  }}
                  handleUpdate={part =>
                    this.props.setContent({
                      ...data,
                      text: part.text,
                    })
                  }
                />
              </EditBlock>

              <EditBlock title="photo" key={data.id}>
                <Editors.Gallery
                  data={{ images: data.images }}
                  max={1}
                  handleUpdate={({ images }) =>
                    this.props.setContent({
                      ...data,
                      images,
                    })
                  }
                  {...this.props}
                />
              </EditBlock>

              <EditBlock title="pricing" key={data.id}>
                <Label>
                  price
                  <Input
                    type="number"
                    value={data.price}
                    onChange={e =>
                      this.props.setContent({
                        ...data,
                        price: parseInt(e.target.value, 10),
                      })
                    }
                  />
                </Label>
                <Label>
                  currency
                  <Input readOnly value={data.currency} />
                </Label>
              </EditBlock>

              <EditBlock title="button ttitle" key={data.id}>
                <Editors.Title
                  data={{
                    id: 0,
                    type: BlockType.title,
                    text: data.buttonTitle,
                  }}
                  handleUpdate={part =>
                    this.props.setContent({
                      ...data,
                      buttonTitle: part.text,
                    })
                  }
                />
              </EditBlock>
            </>
          );
        /* tslint:enable */
        /* eslint-enable */

        default:
          console.log('Invalid block type');
          break;
      }

      return null;
    });

    return <div>{editable.filter(e => !!e)}</div>;
  }
}

export default connect(state => ({
  page: state.Page,
}))(PageContent);
