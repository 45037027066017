import * as React from 'react';

import { SimpleModal, Header, Footer, Content } from 'Components/shared';

interface SimpleModalButtonProps {
  title: string;
  footer?: React.ReactNode;
}

interface SimpleModalButtonState {}

export class SimpleModalButton extends React.Component<
  SimpleModalButtonProps,
  SimpleModalButtonState
> {
  state = {
    show: false,
  };

  onShow = () => this.setState({ show: true });

  onHide = () => this.setState({ show: false });

  render() {
    let { title, children } = this.props;
    let { show } = this.state;

    return (
      <div>
        <button className="btn btn-primary" onClick={this.onShow}>
          {title}
        </button>

        <SimpleModal show={show} hide={this.onHide}>
          <Header>Register device</Header>
          <Content>{children}</Content>
          <Footer>{this.props.footer}</Footer>
        </SimpleModal>
      </div>
    );
  }
}
