import React, { Component } from 'react';

const ChannelsToolbar = ({
  channels,
  selectedChannel,
  handleSelectChannel,
}) => {
  var chanList;
  if (channels.length !== 0) {
    chanList = channels.map((channel, index) => {
      return (
        <option key={index} data-id={channel.id} value={channel.id}>
          {channel.name}
        </option>
      );
    });
  }

  return (
    <div className="top-options clearfix">
      <div className="col-sm-9 form-horizontal">
        <div className="col-md-12 form-group">
          <label className="control-label col-xs-2" htmlFor="channels">
            Channel
          </label>
          <div className="col-xs-10">
            <select
              name="channels"
              className="form-control"
              onChange={handleSelectChannel}
              value={selectedChannel || 0}
            >
              <option value="0">Select a channel</option>
              {chanList}
            </select>
            {channels.length === 0 ? <h5>No channels</h5> : null}
          </div>
        </div>
      </div>
      <div className="col-m-3">
        <button
          type="button"
          className="btn btn-fancy pull-right"
          data-toggle="modal"
          data-target="#newChannel"
        >
          + New channel
        </button>
      </div>
    </div>
  );
};

export default ChannelsToolbar;
