function extractErrors(errors: any) {
  if (!errors) {
    return { '': 'Unknown error' };
  }

  if (errors.error && typeof errors.error === 'string') {
    return { '': errors.error };
  }

  if (!errors.succeeded && errors.errors) {
    return {
      '': errors.errors.map(e => e.description).join(' '),
    };
  }

  return {
    ...Object.keys(errors)
      .map(prop => [prop, errors[prop].join(' ')])
      .filter(p => p && p.length === 2)
      .reduce((p, c) => {
        p[c[0]] = c[1] || '';
        return p;
      }, {}),
  };
}

export function formatErrors<T extends object>(current: any, errors: any): T {
  return {
    ...current,
    ...extractErrors(errors),
  };
}
