import React, { Component } from 'react';

const errorBox = {
  height: '300px',
  overflow: 'hidden' as 'hidden',
  position: 'absolute' as 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
};

const errorResponseStyle = {
  overflow: 'auto' as 'auto',
  height: '100%',
  marginBottom: '10px',
};

class ApiError extends Component<any, any> {
  render() {
    return (
      <div>
        <div className="center-box">
          <h3>An error occurred :(</h3>
          {this.props.error.status === 404 ? (
            <h4>
              It seems you are trying to access a resource that doesn't exist.
            </h4>
          ) : (
            <h4>
              Please try refreshing the page and if the error persists, try
              contacting support.
            </h4>
          )}
        </div>

        {window['features'].dev ? (
          <div style={errorBox}>
            <hr />
            <h5 className="pull-left">Error</h5>
            <div className="clearfix" />
            <pre style={errorResponseStyle}>
              {JSON.stringify(this.props.error, null, 2)}
            </pre>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ApiError;
