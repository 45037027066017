import React, { PureComponent } from 'react';
import {
  RouteComponentProps,
  NavLink,
  Switch,
  Route,
  Link,
} from 'react-router-dom';

import { NotFound } from 'Components/shared';

import NavBar from '../Components/NavBar';
import AddGuestDirectoryDevice from './developer/AddGuestDirectoryDevice';
import GuestDirectoryDevices from './developer/GuestDirectoryDevices';

type Props = RouteComponentProps<{}>;

const DeveloperRootContent = () => (
  <div className="container">
    <h1>Developer tools</h1>

    <Link to="/dev/addguestdirectorydevices">Add guest directory device</Link>
  </div>
);
export default class DeveloperRoot extends PureComponent<Props, {}> {
  render() {
    return (
      <>
        <NavBar title="Developer" link="/dev">
          <li>
            <NavLink
              to="/dev/guestdirectorydevices"
              activeClassName="link-active"
            >
              GD Devices
            </NavLink>
          </li>
        </NavBar>

        <Switch>
          <Route exact path="/dev" component={DeveloperRootContent} />
          <Route
            exact
            path="/dev/guestdirectorydevices"
            component={GuestDirectoryDevices}
          />
          <Route
            exact
            path="/dev/addguestdirectorydevices"
            component={AddGuestDirectoryDevice}
          />
          <Route component={NotFound} />
        </Switch>
      </>
    );
  }
}
