import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Login from './Login';

export interface NavBarProps {
  link?: string;
  title: string;
  children?: ReactNode;
  special?: ReactNode;
}

const NavBar = ({
  link = '',
  title,
  children = null,
  special = null,
}: NavBarProps) => (
  <nav className="navbar navbar-inverse navbar-fixed-top">
    <div className="row" id="navigation-holder">
      <div className="navbar-header">
        <button
          type="button"
          className="navbar-toggle"
          data-toggle="collapse"
          data-target=".navbar-collapse"
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
        <Link className="navbar-brand" to={link ? link : '/'} title="Home">
          {title}
        </Link>
      </div>

      <div
        className="collapse navbar-collapse"
        id="bs-example-navbar-collapse-1"
      >
        <ul className="nav navbar-nav">{children}</ul>
        <Login />
        {special}
      </div>
    </div>
  </nav>
);

export default NavBar;
