import React, { Component } from 'react';

import { strip } from '../contentHelpers';
import RichTextModal from './RichTextModal';

const range = (start = 0, count = 0, step = 1) => {
  const arr: number[] = [];
  for (let i = start; arr.length < count; ++i) arr.push(start + step * i);
  return arr;
};

class State {
  showModal = false;
  temp = '';
  rowIndex = -1;
  elementIndex = -1;
}

export class DataGrid extends Component<any, State> {
  state = new State();

  addRow = () => {
    const rows = this.props.data.rows;
    rows.push(range(0, this.props.data.columns).map(() => ''));
    this.props.handleUpdate({
      ...this.props.data,
      rows,
    });
  };

  handleDeleteRow = (e, rowIndex) => {
    if (confirm('Are you sure you want to remove this row?')) {
      let rows = this.props.data.rows;
      rows = rows.slice(0, rowIndex).concat(rows.slice(rowIndex + 1));
      this.props.handleUpdate({
        ...this.props.data,
        rows,
      });
    }
  };

  handleSave = html => {
    if (this.state.rowIndex < 0 || this.state.elementIndex < -1) return;

    const rows = this.props.data.rows;
    let row = this.props.data.rows[this.state.rowIndex];

    row = row
      .slice(0, this.state.elementIndex)
      .concat(html || '')
      .concat(row.slice(this.state.elementIndex + 1));

    let newRows = rows.slice(0, this.state.rowIndex);
    newRows.push(row);
    newRows = newRows.concat(rows.slice(this.state.rowIndex + 1));

    this.props.handleUpdate({ ...this.props.data, rows: newRows });

    this.setState({ showModal: false });
  };

  editText = (e, element, rowIndex, elementIndex) => {
    this.setState({
      rowIndex,
      elementIndex,
      temp: element,
      showModal: true,
    });
  };

  render() {
    const rows = this.props.data.rows || [];
    return (
      <>
        <button
          className="btn btn-transparent pull-right"
          onClick={this.addRow}
        >
          + Add new row
        </button>

        <table className="table table-shadow">
          <thead>
            <tr>
              {range(0, this.props.data.columns).map(index => (
                <th key={index}>Col #{index + 1}</th>
              ))}
              <th />
            </tr>
          </thead>

          <tbody>
            {rows.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {row.map((element, elementIndex) => (
                    <td key={elementIndex}>
                      {element ? (
                        <span className="text-info">{strip(element)}</span>
                      ) : (
                        <span className="subtle-label">- empty -</span>
                      )}
                      <button
                        className="btn-transparent pull-right"
                        onClick={e =>
                          this.editText(e, element, rowIndex, elementIndex)
                        }
                      >
                        <i className="fa fa-pencil" />
                      </button>
                    </td>
                  ))}
                  <td>
                    <button
                      className="text-danger btn-transparent pull-right"
                      onClick={e => this.handleDeleteRow(e, rowIndex)}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {window['features'].dev ? (
          <pre>{JSON.stringify(this.props.data, null, 2)}</pre>
        ) : null}

        <RichTextModal
          isOpen={this.state.showModal}
          onHide={this.handleHide}
          onSave={this.handleSave}
          text={this.state.temp}
        />
      </>
    );
  }

  handleHide = () => this.setState({ showModal: false });
}
