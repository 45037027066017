import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Formik, FormikProps, FormikActions } from 'formik';

import { login } from 'api';
import { Loading } from 'Components/shared';
import { Container, Label, Input, Button } from 'ui';
import { formatErrors } from 'utilities';

import EmguestLogo from './emguest-logo';

import './login-page.css';

const loggedIn = Symbol();

type LoginFormValues = {
  email: string;
  password: string;
};

export default function LoginPage(props: RouteComponentProps<{}>) {
  function validate(values) {
    return {};
  }

  async function doLogin(values, actions: FormikActions<LoginFormValues>) {
    try {
      await login(values.email, values.password);
      actions.setStatus(loggedIn);

      const { search } = props.location;
      if (document.location) {
        document.location.href = '/';
      }
    } catch (e) {
      const errors = formatErrors({}, e.response.data);
      console.dir('errors', errors);
      actions.setErrors(errors);
    }
    actions.setSubmitting(false);
  }

  return (
    <Container>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validate={validate}
        onSubmit={doLogin}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          status,
        }: FormikProps<LoginFormValues>) => (
          <form
            className="login-container shadow-md rounded bg-gray-100"
            onSubmit={handleSubmit}
          >
            <EmguestLogo className="self-center" />

            {status === loggedIn ? (
              'Logged in, redirected...'
            ) : isSubmitting ? (
              <div style={{ height: '100px' }}>
                <Loading />
              </div>
            ) : (
              <>
                <h3>Login to EmGuest</h3>

                <Label
                  state={errors.email && touched.email ? 'error' : undefined}
                >
                  Email
                  <Input
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <span className="help-block">
                    {errors.email && touched.email && errors.email}
                  </span>
                </Label>

                <Label
                  state={
                    errors.password && touched.password ? 'error' : undefined
                  }
                >
                  Password
                  <Input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span className="help-block">
                    {errors.password && touched.password && errors.password}
                  </span>
                </Label>

                <Label state={errors[''] ? 'error' : undefined}>
                  <span className="help-block">{errors['']}</span>
                </Label>

                <Button disabled={isSubmitting}>Login</Button>

                <hr />

                <Link to="/Account/ForgotPassword">Forgot your password?</Link>
              </>
            )}
          </form>
        )}
      </Formik>
    </Container>
  );
}
