import axios from 'axios';

import {
  GalleryImageDto,
  BaseListDTO,
  ImageTarget,
  UploadedImageModel,
} from 'api';

export function getPageImages(pageId: number) {
  return axios.get(`/api/images/page/${pageId}`);
}

export function getAccountImages(page: number, query: string) {
  const searchQuery = query ? `?=${query}` : '';

  return axios
    .get<BaseListDTO<GalleryImageDto>>(
      `/api/images/gallery/${page}/20${searchQuery}`
    )
    .then(r => r.data);
}

export function removePageImage(pageId: number, imageId: number) {
  return axios.delete(`/api/images/page/${pageId}/image/${imageId}`);
}

export class CropProperties {
  x: number = 0;
  y: number = 0;
  width: number = 0;
  height: number = 0;
  aspect: number = 1;
}

export class ImageProperties {
  targetWidth: number = 0;
  targetHeight: number = 0;

  crop: CropProperties = new CropProperties();
  target = ImageTarget.None;
}

export function setExistingImageForPage(
  pageId: number,
  imageId: number,
  crop: CropProperties | null,
  target: ImageTarget
) {
  return axios
    .post<UploadedImageModel>(`/api/images/page/${pageId}/image/${imageId}`, {
      crop,
      target,
    })
    .then(r => r.data);
}

export function uploadPageImage(
  pageId: number,
  accountId: number,
  file: any,
  target: ImageTarget,
  crop: CropProperties | null,
  updateProgress: (percentCompleted: number) => void
) {
  const data = new FormData();
  data.append('accountId', window['userState'].accountId);
  data.append('file', file);
  data.append('target', target.toString());
  data.append('crop', JSON.stringify(crop));

  return axios
    .post<UploadedImageModel>(`/api/images/page/${pageId}`, data, {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        updateProgress(percentCompleted);
      },
    })
    .then(r => r.data);
}
