import React from 'react';

const TimelineObjectEntryAnimation = ({ animation, pxPerSec }) => {
  if (animation.name === 'none') return null;

  const duration = parseInt(animation.duration);
  const length = Math.max(1, duration) * pxPerSec;

  return (
    <span
      className="pull-left timeline-anim timeline-anim-entry"
      style={{ width: length }}
    >
      {animation.name}
    </span>
  );
};

const TimelineObjectExitAnimation = ({ animation, pxPerSec }) => {
  if (animation.name === 'none') return null;

  const duration = parseInt(animation.duration);
  const length = Math.max(1, duration) * pxPerSec;

  return (
    <span
      className="pull-right timeline-anim timeline-anim-exit"
      style={{ width: length }}
    >
      {animation.name}
    </span>
  );
};

const TimelineObject = ({
  index,
  object,
  presentationDuration,
  isSelected,
  pxPerSec,
}) => {
  let animations = object.get('animations');

  // delay from start in seconds
  let delay = 0;
  // total length of the object on screen
  let duration = 0;

  if (animations.entry.name !== 'none') {
    delay = parseFloat(animations.entry.delay);
    duration = parseFloat(animations.entry.duration);
  }

  if (animations.exit.name !== 'none') {
    duration += parseFloat(animations.exit.delay);
    duration += parseFloat(animations.exit.duration);
  } else {
    duration = Math.max(presentationDuration - delay, 5);
  }

  let name = `${object.type === 'i-text' ? 'Text' : object.type} #${index}`;

  return (
    <div
      className={`timeline-object ${isSelected ? 'selected-obj' : ''}`}
      style={{ width: `${(presentationDuration + 15) * pxPerSec}px` }}
    >
      <div
        className="timeline-right-object"
        style={{
          marginLeft: delay * pxPerSec,
          width: duration * pxPerSec,
        }}
      >
        <span className="names">{name}</span>
        <TimelineObjectEntryAnimation
          animation={animations.entry}
          pxPerSec={pxPerSec}
        />
        <TimelineObjectExitAnimation
          animation={animations.exit}
          pxPerSec={pxPerSec}
        />
      </div>
    </div>
  );
};

export default TimelineObject;
