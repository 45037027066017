import React, { SFC } from 'react';
import { Link } from 'react-router-dom';

import { MediaItemType } from 'api';

const defaultThumbnails = {
  presentation: require('../../Images/media_presentation_thumb.png'),
  image: require('../../Images/media_image_thumb.png'),
  video: require('../../Images/media_video_thumb.png'),
};

type Props = {
  thumbUri: string;
  type: MediaItemType;
  className?: string;
  mediaItemId: number;
  onClick: () => void;
};

export const MediaItemThumb: SFC<Props> = ({
  thumbUri,
  type,
  className = '',
  mediaItemId,
  onClick,
}) => {
  let uri = thumbUri;

  if (!thumbUri) {
    switch (type) {
      case MediaItemType.Presentation:
        uri = defaultThumbnails.presentation;
        break;

      case MediaItemType.Image:
        uri = defaultThumbnails.image;
        break;

      case MediaItemType.Video:
        uri = defaultThumbnails.video;
        break;
    }
  }

  return (
    <div className={`thumb ${className}`}>
      {type === MediaItemType.Video ? (
        <a onClick={onClick}>
          <div
            className="media-thumb"
            style={{ backgroundImage: `url(${uri})` }}
          />
        </a>
      ) : type === MediaItemType.Image ? (
        <a onClick={onClick}>
          <div
            className="media-thumb"
            style={{ backgroundImage: `url(${uri})` }}
          />
        </a>
      ) : type === MediaItemType.Presentation ? (
        <Link to={`/digitalsignage/builder/${mediaItemId}/readonly`}>
          <img src={uri} />
        </Link>
      ) : (
        <div
          className="media-thumb"
          style={{ backgroundImage: `url(${uri})` }}
        />
      )}
    </div>
  );
};
