import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getApps, AppDTO, pingGuestDirectoryDevice, AppPlatforms } from 'api';
import { Container, Label, Input, Select, Button } from 'ui';
import { withFetch, uuidv4 } from 'utilities';
import { formatAppType } from '../../enums';
import { addSuccessNotification } from '../../Components/NotificationActions';

type FetchProps = {
  apps: AppDTO[];
};

type Props = FetchProps & {
  addSuccessNotification: (text: string) => any;
};

class State {
  name = '';
  deviceId = uuidv4();
  appId = 0;
}

function bind(name: string, owner: any) {
  return {
    value: owner.state[name],
    onChange: e => {
      owner.setState({
        [name]: e.target.value,
      });
    },
  };
}

class AddGuestDirectoryDevice extends PureComponent<Props, State> {
  state = new State();

  render() {
    return (
      <Container>
        <h2>Add guest directory device</h2>

        <form onSubmit={this.add}>
          <Label>
            Name
            <Input {...bind('name', this)} />
          </Label>

          <Label>
            Device Id
            <Input {...bind('deviceId', this)} />
          </Label>

          <Label>
            Platform
            <Select {...bind('platform', this)}>
              <option value={AppPlatforms.iPad}>iPad</option>
            </Select>
          </Label>

          <Label>
            App
            <Select {...bind('appId', this)}>
              <option value="0">Select an app</option>
              {(this.props.apps || []).map(app => (
                <option key={app.id} value={app.id}>
                  {app.name} ({app.id}, {formatAppType(app.appType)})
                </option>
              ))}
            </Select>
          </Label>

          <Button type="primary" onClick={this.add}>
            Add
          </Button>
        </form>
      </Container>
    );
  }

  add = async e => {
    e.preventDefault();
    const app = this.props.apps.firstOrDefault(
      x => +x.id === +this.state.appId
    );
    if (!app) return;

    await pingGuestDirectoryDevice({
      appAlias: app.alias,
      deviceId: this.state.deviceId,
    });

    this.setState(new State());
    this.props.addSuccessNotification('Device created');
  };
}

export default connect(
  null,
  { addSuccessNotification }
)(
  withFetch<FetchProps>(async props => {
    return {
      apps: await getApps().then(r => r.data),
    };
  })(AddGuestDirectoryDevice)
);
