import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';

import ScheduleControl from './ScheduleControl';
import { PlaylistOrientation, formatPlaylistOrientation } from '../../enums';

class SchedulePlaylist extends Component<any, any> {
  state: any = {
    playlists: [],
    selectedPlaylist: 0,
    schedule: {
      days: [],
      startDate: moment.utc().format(),
      startTime: moment.utc().startOf('day'),
      endTime: moment.utc().startOf('day'),
      endDate: moment.utc().format(),
      variables: {},
    },
    isValid: true,
  };

  componentDidMount() {
    axios
      .get('/api/playlists')
      .then((r) => this.setState({ playlists: r.data }));
  }

  handleCreate = (e) => {
    this.props
      .handleAction(this.state.selectedPlaylist, this.state.schedule)
      .then((r) => {
        if (r) $(`#${this.props.name}`).modal('hide');
        this.reset();
      })
      .catch((e) => {
        var errors = e.response.data;
        if (errors) {
          if (errors.error && typeof errors.error === 'string') {
            this.setState({
              errors: {
                _all: errors.error,
              },
            });
          } else {
            var newState = this.state.errors;
            for (var fieldName in errors) {
              errors[fieldName] = false;
            }

            this.setState({
              errors: newState,
            });
          }
        }
      });
  };

  handleScheduleChange = (schedule, isValid) => {
    this.setState({ schedule: schedule, isValid: isValid });
  };

  reset = () => {
    this.setState({
      selectedPlaylist: 0,
      schedule: {
        days: [],
        startDate: moment.utc().format(),
        startTime: moment.utc().startOf('day'),
        endTime: moment.utc().startOf('day'),
        endDate: moment.utc().format(),
        variables: {},
      },
      isValid: false,
    });
  };

  handleSelectPlaylist = (e) => {
    var playlistId = e.target.selectedOptions[0].getAttribute('value');
    this.setState({
      selectedPlaylist: playlistId ? Number(playlistId) : 0,
    });
  };

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Schedule playlist
              </h4>
            </div>
            <div className="modal-body">
              <form id={this.props.name + 'Form'}>
                <label className="subtle-label" htmlFor="playlist">
                  Playlist
                </label>
                <select
                  className="form-control"
                  onChange={this.handleSelectPlaylist}
                  value={this.state.selectedPlaylist}
                  name="playlist"
                  style={styles.channelPick}
                >
                  <option value="0">Select playlist...</option>
                  {this.state.playlists.map((playlist, index) => (
                    <option key={playlist.id} value={playlist.id}>
                      {playlist.name} (
                      {formatPlaylistOrientation(playlist.orientation)})
                    </option>
                  ))}
                </select>

                <ScheduleControl
                  schedule={this.state.schedule}
                  onChange={this.handleScheduleChange}
                />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-fancy"
                disabled={
                  !this.state.isValid || this.state.selectedPlaylist === 0
                }
                onClick={this.handleCreate}
              >
                Schedule
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  channelPick: {
    marginBottom: '30px',
  },
};

export default SchedulePlaylist;
