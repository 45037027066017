import axios from 'axios';
import { NewFormDTO, FormRequestsList } from 'api';

export const createForm = (accountId: number, data: NewFormDTO) => {
  return axios.post<number>(`/api/forms/account/${accountId}`, data);
};

export const getFormRequests = (formId: number) => {
  return axios.get<FormRequestsList>(`/api/formrequests/form/${formId}`);
};

export const deleteFormRequest = (requestiId: number) => {
  return axios.delete(`/api/formrequests/${requestiId}`);
};
