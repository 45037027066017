import axios from 'axios';

export const login = (email: string, password: string) => {
  return axios.post('/account/loginuser', {
    password,
    email,
  });
};

export const forgotPassword = (email: string) => {
  return axios.post('/account/forgotpassword', { email });
};

export const resetPassword = (
  email: string,
  password: string,
  confirmPassword: string,
  code: string
) => {
  return axios.post('/account/resetpassword', {
    email,
    password,
    confirmPassword,
    code,
  });
};
