import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle,
} from 'react-sortable-hoc';

import * as Actions from './actions';
import AddSubpageModal from './AddSubpageModal';
import { StateComponent, WithDialog } from 'Components/shared';
import LinkPageDialog from './link-page-dialog';
import CopyPageDialog from './copy-page-dialog';

function templateHasChildren(name: string) {
  return name === 'page-home' || name === 'page-list';
}

const DragHandle = SortableHandle(() => (
  <span className="drag-handle fa fa-bars" />
)); // This can be any component you want

const SortableSubpage = SortableElement<any>(
  ({
    appid,
    child,
    title,
    handleRemove,
    canHighlight,
    isHidden,
    handleHighlight,
    handleRemoveHighlight,
  }) => {
    return (
      <tr>
        <td>
          <DragHandle />
        </td>
        <td>
          <Link
            to={`/app/${appid}/page/${child.id}`}
            className={isHidden ? 'page-hidden' : ''}
          >
            {title}
          </Link>
        </td>
        <td>
          {!child.background || !child.background.color ? (
            <span>
              <i>app theme</i>
            </span>
          ) : (
            <span
              style={{
                borderBottomStyle: 'solid',
                borderBottomWidth: '4px',
                borderBottomColor: child.background.color.toUpperCase(),
              }}
            >
              {child.background.color.toUpperCase()}
            </span>
          )}
          <button
            className="btn-transparent"
            style={{ marginLeft: '10px' }}
            onClick={e => {
              $('#pageEditorTabs a[href="#page-style"]').tab('show');
            }}
          >
            <i className="fa fa-edit" />
          </button>
        </td>
        <td>
          {child.level !== 0 && (
            <button
              className="btn-remove-page"
              title="Remove page"
              onClick={handleRemove}
            >
              <i className="fa fa-trash" />
            </button>
          )}
          {canHighlight ? (
            child.isHighlighted ? (
              <button
                className="btn-remove-highlight"
                title="Remove highlight"
                onClick={handleRemoveHighlight}
              >
                <i className="fa fa-star" />
              </button>
            ) : (
              <button
                className="btn-highlight"
                title="Highlight page"
                onClick={handleHighlight}
              >
                <i className="fa fa-star-o" />
              </button>
            )
          ) : null}
        </td>
        <td>
          {child.isLink && (
            <>
              <i title="This page is a link" className="fa fa-link" />
            </>
          )}
        </td>
      </tr>
    );
  }
);

const SortableSubpages = SortableContainer<any>(
  ({
    subpages,
    pages,
    appid,
    handleRemove,
    canHighlight,
    handleHighlight,
    handleRemoveHighlight,
  }) => {
    return (
      <table className="children table table-shadow ">
        <thead>
          <tr>
            <th />
            <th>Page name</th>
            <th>Style</th>
            <th>Actions</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {subpages.map((child, index) => {
            let title: React.ReactNode = null;
            const page = pages.find(p => p.id === child.id);
            // TODO: remove in future once prototype is over
            if (child.title.constructor === Array) {
              if (child.title.length > 0) {
                title = child.title[0].text;
              }
              if (
                (title === null && page) ||
                (title && page && page.name === title)
              ) {
                title = <i>{page.name}</i>;
              }
            }

            let additionalSubpageProps = {};
            if (page) {
              additionalSubpageProps = { level: page.level };
            }

            return (
              <SortableSubpage
                key={index}
                index={index}
                appid={appid}
                child={{ ...child, ...additionalSubpageProps }}
                title={title}
                handleRemove={e => handleRemove(child, e)}
                handleHighlight={e => handleHighlight(child, e)}
                handleRemoveHighlight={e => handleRemoveHighlight(child, e)}
                canHighlight={canHighlight}
                isHidden={page && page.isHidden ? true : false}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
);

type Props = {
  onLink: Function;
  onCopy: Function;
} & any;

class PageSubpagesState {
  subpages: any[] = [];
}

class PageSubpages extends StateComponent<Props, PageSubpagesState> {
  state = new PageSubpagesState();

  render() {
    let appid = 0;
    if (this.props.app) appid = this.props.app.data.id;

    const { pages, page, handleAddSubpage } = this.props;
    const { template } = page;

    if (!template || !templateHasChildren(template.alias)) {
      return <>{null}</>;
    }

    return (
      <div className="edit-block">
        <h4 className="edit-block-title">
          {page.page.level === 0 ? 'picker pages' : 'Subpages'}
        </h4>

        {page.page.level === 0 ? (
          <div className="form-group">
            <label className="subtle-label">
              These pages will appear in top level navigation
            </label>
          </div>
        ) : null}

        <div className="form-group hide">
          <label htmlFor="liststyle">List style</label>
          <select className="form-control" name="liststyle" disabled={true}>
            <option value="">List</option>
          </select>
        </div>

        <div className="form-group pull-right">
          <button
            className="btn btn-transparent text-info"
            data-toggle="modal"
            data-target="#addSubpage"
          >
            + Create new subpage
          </button>
          &nbsp;&nbsp;&nbsp;
          <WithDialog>
            {(isOpen, show, hide) => (
              <>
                <button
                  className="btn btn-transparent text-info"
                  onClick={show}
                >
                  + Copy existing page
                </button>

                <CopyPageDialog
                  appType={this.props.app.data.appType}
                  onCopy={this.copyPage}
                  show={isOpen}
                  onClose={hide}
                />
              </>
            )}
          </WithDialog>
          &nbsp;&nbsp;&nbsp;
          <WithDialog>
            {(isOpen, show, hide) => (
              <>
                <button
                  className="btn btn-transparent text-info"
                  onClick={show}
                >
                  + Link existing page
                </button>

                <LinkPageDialog
                  appId={this.props.app.data.id}
                  appType={this.props.app.data.appType}
                  show={isOpen}
                  onClose={hide}
                  onLink={this.linkPage}
                />
              </>
            )}
          </WithDialog>
        </div>

        <div className="form-group">
          <SortableSubpages
            pages={pages}
            subpages={this.state.subpages}
            appid={appid}
            handleRemove={this.props.handleRemoveSubpage}
            canHighlight={this.props.page.page.level === 0}
            handleHighlight={this.handleHighlight}
            handleRemoveHighlight={this.handleRemoveHighlight}
            onSortEnd={this.handleSortEnd}
            useDragHandle={true}
            distance={10}
          />
        </div>

        <AddSubpageModal name="addSubpage" handleCreate={handleAddSubpage} />
      </div>
    );
  }

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.init(nextProps);
    }
  }

  init = props => {
    const { template } = props.page;
    let { subpages } = props.page;

    // this.props.page is a Redux store
    // this.props.page.page is data returned by API

    // this.props.page.subpages are children collection inside the data and they will be used in mobile app
    // this.props.page.page.subpages is a list of subpages the way DB sees it
    // these two lists should match, but the latter one has order in it

    // most templates don't have support for subpages
    if (!template || !templateHasChildren(template.alias)) {
      subpages = [];
    }

    if (props.page.page && props.page.page.subpages) {
      subpages = subpages.map(s => {
        const subpage = props.page.page.subpages.find(x => x.id === s.id);
        if (subpage) {
          return { ...s, isLink: subpage.isLink };
        }
        return s;
      });
    }

    this.setState({ subpages });
  };

  handleSortEnd = draggedItem => {
    const { oldIndex, newIndex } = draggedItem;
    let subpages = this.props.page.subpages as any[];

    subpages = arrayMove(subpages, oldIndex, newIndex);
    for (let i = 0; i < subpages.length; i += 1) {
      subpages[i].order = i + 1;
    }

    // TODO: send message to sort
    // TODO: reload page and app
    this.props.dispatch(
      Actions.pageSortSubpages(
        subpages.map(subpage => ({
          pageId: subpage.id,
          order: subpage.order,
        }))
      )
    );
  };

  handleHighlight = (subpage, index) => {
    this.props.dispatch(
      Actions.setSubpage(
        Object.assign({}, subpage, {
          isHighlighted: true,
        })
      )
    );
  };

  handleRemoveHighlight = (subpage, index) => {
    this.props.dispatch(
      Actions.setSubpage(
        Object.assign({}, subpage, {
          isHighlighted: false,
        })
      )
    );
  };

  linkPage = (pageId: number, name: string) => {
    return this.props.onLink(this.props.page.id, pageId, name);
  };

  copyPage = (pageId: number, name: string) => {
    return this.props.onCopy(this.props.page.id, pageId, name);
  };
}

export default connect(state => ({
  app: state.App,
  pages: state.App.pages,
  page: state.Page,
  languages: state.App.languages,
}))(PageSubpages);
