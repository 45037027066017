import React from 'react';
import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';

export default class OpacitySlider extends React.Component<any, any> {
  onChange = (value, e) => {
    this.props.onChange(e, value / 100);
  };

  render() {
    return (
      <Slider
        value={parseFloat(this.props.opacity) * 100}
        orientation="horizontal"
        onChange={this.onChange}
        tooltip={false}
        min={0}
        max={100}
        step={1}
        className={this.props.className}
      />
    );
  }
}
