import * as React from 'react';

export interface ButtonProps {
  type?: 'primary' | 'default' | 'danger';
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onClick?: (e: any) => any;
  title?: string;
}

export const Button: React.SFC<ButtonProps> = ({
  className,
  children,
  type,
  ...attributes
}) => {
  const classes = ['btn', className || ''];
  switch (type) {
    case 'primary':
      classes.push('btn-primary');
      break;

    case 'danger':
      classes.push('btn-danger');
      break;

    case 'default':
      classes.push('btn-default');
      break;

    default:
      classes.push('btn-fancy');
      break;
  }

  return (
    <button {...attributes} className={classes.join(' ')}>
      {children}
    </button>
  );
};
