import React, { SFC, HTMLAttributes } from 'react';

import './icons.css';

export const EditIcon: SFC<HTMLAttributes<HTMLSpanElement>> = props => (
  <span {...props} className="ui-icon glyphicon glyphicon-pencil" />
);

export const TrashIcon: SFC<HTMLAttributes<HTMLSpanElement>> = props => (
  <span {...props} className="ui-icon glyphicon glyphicon-trash" />
);
