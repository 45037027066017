import * as React from 'react';
import axios from 'axios';
import { Loading } from 'Components/shared';

interface Props {
  from: string | (() => Promise<any>) | (string | (() => Promise<any>))[];
}

class AsyncState<T> {
  isFetching = false;
  hasFetched = false;
  hasError = false;
  data?: T;
}

export type FetchProps<T> = AsyncState<T> & {
  refresh: () => void;
};

export class Fetch extends React.Component<Props, {}> {
  state = new AsyncState<any>();

  load = () => {
    let { from } = this.props;

    if (!from) return;

    // if we get a single string, wrap it into an array and unwrap later
    if (typeof from === 'string' || typeof from === 'function') from = [from];
    else if (from.length === 0) return;

    this.setState({ isFetching: true });

    const promises: Promise<any>[] = [];
    from.forEach(f => {
      if (typeof f === 'string') {
        promises.push(axios.get(f));
      } else {
        promises.push(f());
      }
    });

    Promise.all(promises)
      .then(r => {
        this.setState({
          isFetching: false,
          hasFetched: true,
          data: r.length === 1 ? r[0] : r,
        });
      })
      .catch(e => this.setState({ isFetching: false, hasError: true }));
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const renderProp = this.props.children as any;
    const props: FetchProps<any> = {
      ...this.state,
      refresh: this.load,
    };

    if (typeof renderProp === 'function') {
      return renderProp(props);
    }

    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, props)
    );
  }
}

export interface FetchRenderProps<T> {
  data?: T;
  isFetching?: boolean;
  hasError?: boolean;
  refresh?: () => any;
}

export class FetchRender extends React.Component<FetchRenderProps<any>, {}> {
  render() {
    const { data, isFetching, hasError, refresh } = this.props;
    if (isFetching) return <Loading />;
    if (hasError) return 'error';
    if (!data) return '!data';

    return (this.props.children as any)({ data, refresh });
  }
}
