import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';

import ColorPicker from '../Builder/ColorPicker/ColorPicker';
import defaultAppTheme from './defaultAppTheme';

import { updatePlatformStyle } from './actions';
import { updateApp } from 'api';

type Props = {
  app: any;
  addSuccessNotification: (text: string) => any;
  addDangerNotification: (text: string) => any;
};

class State {
  isSaving = false;
  meta: any = {};
  theme = {
    foregroundColor: 'white',
    backgroundColor: 'black',
  };
}

class Theme extends Component<Props, State> {
  state = new State();

  componentDidMount() {
    if (this.props.app.isLoaded) {
      this.loadFromData(this.props.app.data, this.props.app.theme);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.app.isLoaded) {
      this.loadFromData(nextProps.app.data, nextProps.app.theme);
    }
  }

  loadFromData = (data, theme) => {
    // FIXME: fix this horrendeous mess
    let meta = data.draftMeta ? data.draftMeta : data.publishedMeta;
    if (meta) meta = meta.data;
    if (!meta.cms_version) meta.cms_version = 2;

    this.setState({
      meta,
      theme: theme || defaultAppTheme(),
    });
  };

  handleChange = e => this.set(e.target.name, e.target.value);

  set = (name, value) =>
    this.setState(state => ({
      theme: { ...state.theme, [name]: value },
    }));

  handleSave = e => {
    this.setState({ isSaving: true });

    updateApp(this.props.app.data.id, {
      name: this.props.app.data.name,
      forwardPaymentEmails: this.props.app.data.forwardPaymentEmails,
      thumbnail: this.props.app.data.thumbnail,
      rawData: {
        ...this.state.meta,
        theme: this.state.theme,
      },
    })
      .then(r => {
        this.setState({ isSaving: false });
        this.props.addSuccessNotification('Changes saved');
      })
      .catch(e => {
        this.setState({ isSaving: false });
        this.props.addDangerNotification(
          'Error saving changes, please try again'
        );
      });
  };

  render() {
    return (
      <div className="container">
        <h2>Theme</h2>

        <input
          type="submit"
          value="Save"
          className="btn btn-fancy pull-right"
          onClick={this.handleSave}
        />

        <h3>Colors</h3>

        <div className="form-group">
          <label
            htmlFor="foregroundColor"
            className="control-label subtle-label"
          >
            Foreground color
          </label>{' '}
          &nbsp;
          <ColorPicker
            handleChange={this.handleForegroundColorChange}
            currentVal={this.state.theme.foregroundColor}
          />
          <input
            type="text"
            name="foregroundColor"
            className="form-control"
            value={this.state.theme.foregroundColor}
            onChange={this.handleChange}
            readOnly
          />
        </div>

        <div className="form-group">
          <label
            htmlFor="backgroundColor"
            className="control-label subtle-label"
          >
            Background color
          </label>{' '}
          &nbsp;
          <ColorPicker
            handleChange={color => this.set('backgroundColor', color)}
            currentVal={this.state.theme.backgroundColor}
          />
          <input
            type="text"
            name="backgroundColor"
            className="form-control"
            value={this.state.theme.backgroundColor}
            onChange={this.handleChange}
            readOnly
          />
        </div>
      </div>
    );
  }

  handleForegroundColorChange = color => this.set('foregroundColor', color);
}

export default connect(
  state => ({ app: state.App }),
  {
    updatePlatformStyle,
    addDangerNotification,
    addSuccessNotification,
  }
)(Theme);
