import * as React from 'react';

export class StateComponent<Props, State> extends React.Component<
  Props,
  State
> {
  extract = (e: any) => {
    let value: string | number | boolean | null = null;
    switch (e.target.type) {
      // handle <select>
      case 'select-one':
      case 'email':
      case 'text':
      case 'textarea':
      case 'password':
        value = e.target.value;
        break;

      case 'checkbox':
        value = e.target.checked as boolean;
        break;

      case 'number':
        value = parseInt(e.target.value);
        break;
    }

    return { name: e.target.name, value };
  };

  set = (e: any, val?: any) => {
    // when binding to custom controls, we might get name and value manually
    let { name, value } = val ? { name: e, value: val } : this.extract(e);

    if (value !== null) {
      const parts = name.split(/[.[]+/);
      this.setState(state => {
        const patch = createPatch(state, parts, value);
        return patch;
      });
    }
  };

  render() {
    return <div />;
  }
}

function createPatch(state: any, parts: string[], value: any): any {
  if (!parts || parts.length === 0) return;

  if (parts.length === 1) {
    let part = parts[0];

    if (part.endsWith(']')) {
      const index = parseInt(part.substr(0, part.length - 1));
      const p: any = state.map((v: any, i: number) =>
        i === index ? value : v
      );
      return p;
    } else {
      const p: any = {
        ...state,
        [part]: value,
      };
      return p;
    }
  }

  const part = parts[0];

  if (part.endsWith(']')) {
    const index = parseInt(part.substr(0, part.length - 1));
    const element = state[index];
    const arr = state as any[];
    return arr
      .slice(0, index)
      .concat(createPatch(element, parts.slice(1), value))
      .concat(arr.slice(index + 1));
  } else {
    const sub = state[part];
    return {
      ...state,
      [part]: createPatch(sub, parts.slice(1), value),
    };
  }
}
