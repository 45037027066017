import React, { Component, ReactNode } from 'react';

export interface AlertProps {
  type: string;
  children: ReactNode;
  handleClose: (e: any) => void;
}

// TODO: rename handleClose to onClose
const Alert = ({ type, children, handleClose }: AlertProps) => {
  return (
    <div className={`alert alert-${type} alert-dismissible`} role="alert">
      <button type="button" className="close" onClick={handleClose}>
        <span aria-hidden="true">&times;</span>
      </button>
      {children}
    </div>
  );
};

export default Alert;
