import React, { Component } from 'react';
import Modal from 'react-modal';

import ModalBase from '../FormHelpers/ModalBase';
import { AppType } from '../../enums';

class CreateApp extends ModalBase {
  constructor(props) {
    super(props, ['name', 'alias', 'appType']);

    this.validate = this.validate.bind(this);
  }

  validate() {
    const data = this.state.data;
    return {
      name: data.name ? '' : 'Must enter application name',
      alias: data.name ? '' : 'Must enter application alias',
      appType: Number(data.appType) ? '' : 'Must select application type',
      _all: '',
    };
  }

  render() {
    let appTypes: any[] = [];
    for (var i in AppType) {
      if (AppType.hasOwnProperty(i)) {
        appTypes.push({
          value: AppType[i],
          name: AppType[i] === 0 ? 'Select app type...' : i,
        });
      }
    }

    return (
      <Modal isOpen={this.props.isOpen} className="modal-dialog modal-fancy">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.hide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title" id={this.props.name + 'Label'}>
              Create new application
            </h4>
          </div>
          <div className="modal-body">
            <form id={this.props.name + 'Form'}>
              <div
                className={
                  'form-group ' + (this.state.errors.name ? 'has-error' : '')
                }
              >
                <label htmlFor="name" className="control-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Application name..."
                  name="name"
                  value={this.state.data.name}
                  onChange={this.handleChange}
                  required
                />
                <div className="help-block with-errors">
                  {this.state.errors.name}
                </div>
              </div>

              <div
                className={
                  'form-group ' + (this.state.errors.alias ? 'has-error' : '')
                }
              >
                <label htmlFor="alias" className="control-label">
                  Alias
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Application alias..."
                  name="alias"
                  value={this.state.data.alias}
                  onChange={this.handleChange}
                  required
                />
                <div className="help-block with-errors">
                  {this.state.errors.alias}
                </div>
              </div>

              <div
                className={
                  'form-group ' + (this.state.errors.appType ? 'has-error' : '')
                }
              >
                <select
                  name="appType"
                  className="form-control"
                  onChange={this.handleChange}
                >
                  {appTypes.map((appType, index) => (
                    <option key={index} value={appType.value}>
                      {appType.name}
                    </option>
                  ))}
                </select>
                <div className="help-block with-errors">
                  {this.state.errors.appType}
                </div>
              </div>

              <div
                className={
                  'form-group ' +
                  (this.state.errors.platforms ? 'has-error' : '')
                }
              >
                <div className="help-block with-errors">
                  {this.state.errors.platforms}
                </div>
              </div>

              <div className="has-error">
                <div className="help-block with-errors">
                  {this.state.errors._all}
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-fancy"
              data-dismiss="modal"
              onClick={this.props.hide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-fancy"
              onClick={this.handleCreate}
            >
              Create
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CreateApp;
