import React from 'react';
import Slider from 'react-rangeslider';

import ObjectActions from './ObjectActions';
import ColorPicker from '../ColorPicker/ColorPicker';
import * as CommonProperties from './CommonProperties';

const fonts = [
  { name: 'Times New Roman', value: 'times-new-roman' },
  { name: 'Arial', value: 'arial' },
  { name: 'Trebuchet MS', value: 'trebuchet' },
  { name: 'Bellevue Stencil', value: 'bellevue-stencil' },
  { name: 'Gotham Book', value: 'gothamrnd-book' },
  { name: 'Gotham Medium', value: 'gothamrnd-medium' },
  { name: 'Blair Light', value: 'blair-itc-light' },
];

export interface TextObjProps {
  objProps: any;
  objProps2: any;
  handleChangeEvent: () => any;
  handleClickEvent: () => any;
}

export default class TextObj extends React.Component<TextObjProps & any, any> {
  render() {
    return (
      <div className="bottom-controls">
        <ObjectActions {...this.props} />

        <div className="single-control-holder">
          <label htmlFor="text" className="textarea-label">
            Text
          </label>
          <textarea name="text" onChange={this.props.handleChangeText}>
            {this.props.textStateObj.text}
          </textarea>
        </div>

        <div className="single-control-holder">
          <label>Font</label>
          <select
            name="fontFamily"
            value={this.props.textStateObj.fontFamily}
            onChange={this.props.handleChangeText}
          >
            {fonts.map((font, index) => (
              <option key={index} value={font.value}>
                {font.name}
              </option>
            ))}
          </select>
          <select
            name="fontWeight"
            value={this.props.textStateObj.fontWeight}
            onChange={this.props.handleChangeText}
          >
            <option value="regular">Regular</option>
            <option value="bold">Bold</option>
          </select>
          <div className="bottom-properties clearfix">
            <div>
              <p>&nbsp;</p>
            </div>
            <div>
              <p>Family</p>
            </div>
            <div>
              <p>Weight</p>
            </div>
          </div>
        </div>

        <div className="single-control-holder">
          <label>Text color</label>
          <ColorPicker
            handleChange={this.props.handleChangeText}
            currentVal={this.props.text.getFill()}
          />
        </div>

        <div className="single-control-holder">
          <label>Text size</label>
          <input
            type="number"
            placeholder="Font size..."
            name="fontSize"
            value={this.props.textStateObj.fontSize}
            onChange={this.props.handleChangeText}
          />

          <button
            className="btn btn-transparent"
            name="font-size-dec"
            onClick={this.props.handleClickEvent}
          >
            <i className="fa fa-minus" />
            &nbsp;
          </button>
          <Slider
            value={Number(this.props.textStateObj.fontSize)}
            orientation="horizontal"
            onChange={value => this.props.handleChangeEvent('fontSize', value)}
            tooltip={false}
            min={10}
            max={500}
            step={1}
            className="font-size-slider"
          />
          <button
            className="btn btn-transparent"
            name="font-size-inc"
            onClick={this.props.handleClickEvent}
          >
            &nbsp;
            <i className="fa fa-plus" />{' '}
          </button>

          <div className="bottom-properties clearfix">
            <div>
              <p>&nbsp;</p>
            </div>
            <div>
              <p>Size</p>
            </div>
            <div>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>

        {false ? (
          <div className="single-control-holder">
            <label>Align</label>
            <select
              name="textAlign"
              value={this.props.textStateObj.textAlign}
              onChange={this.props.handleChangeText}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="Right">Right</option>
              <option value="Justify">Justify</option>
            </select>
          </div>
        ) : null}

        <CommonProperties.OpacityProperty {...this.props} />

        {false ? (
          <div className="single-control-holder">
            <label>Variable</label>
            <input
              type="checkbox"
              name="isVariable"
              value={this.props.textStateObj.isVariable}
              onChange={this.props.handleChangeText}
            />
            {this.props.textStateObj.isVariable ? (
              <input
                type="text"
                placeholder="Variable name"
                name="variableName"
                value={this.props.textStateObj.variable.name}
                onChange={this.props.handleChangeText}
                min="0"
                max="1"
                step="0.1"
              />
            ) : null}
            <div className="bottom-properties clearfix">
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <p>Check</p>
              </div>
              <div>
                <p>{this.props.textStateObj.isVariable ? 'Name' : ' '}</p>
              </div>
            </div>
          </div>
        ) : null}

        <hr />

        <CommonProperties.PositionProperties {...this.props} />
        <CommonProperties.SizeProperties {...this.props} />
        <CommonProperties.RotationProperties {...this.props} />
      </div>
    );
  }
}
