import * as React from 'react';

import { StateComponent } from 'Components/shared';
import { BindingContext, TextInput, Submit, Select } from '../Admin/Shared';
import {
  sendPushNotification,
  parseBadRequest,
  AppDTO,
  SendPushNotificationDto,
  getApps,
} from 'api';

type State = SendPushNotificationDto & {
  isLoading?: boolean;
  apps: AppDTO[];
};

class SendPushNotification extends StateComponent<any, State> {
  state: State = {
    appId: 1,
    title: '',
    body: '',
    isLoading: false,
    apps: [],
  };

  sendPushNotification = () => {
    sendPushNotification(this.state)
      .then(() => alert('sent'))
      .catch(e => alert(parseBadRequest(e)));
  };

  componentDidMount() {
    getApps()
      .then(r =>
        this.setState({
          isLoading: false,
          apps: r.data,
        })
      )
      .catch(e => {
        this.setState({ isLoading: false });
        alert('Cannot fetch list of apps');
      });
  }

  render() {
    return (
      <div className="card-bg" style={{ padding: '20px' }}>
        <h4>Send push notification</h4>
        <div>
          <BindingContext data={this.state} set={this.set}>
            <Select title="Application" name="appId">
              {this.state.apps.map(app => (
                <option key={app.id} value={app.id}>
                  {app.name}
                </option>
              ))}
            </Select>
            <TextInput title="Message title" name="title" />
            <TextInput title="Message body" name="body" />
            <Submit onClick={this.sendPushNotification}>Send</Submit>
          </BindingContext>
        </div>
      </div>
    );
  }
}

export default class extends React.Component {
  render() {
    return (
      <div className="container">
        <h1>Push notifications</h1>
        <SendPushNotification />
      </div>
    );
  }
}
