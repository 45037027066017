import axios from 'axios';
import {
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';

export const loadAccountInfo = accountId => ({
  type: 'ACCOUNT_LOAD',
  payload: {
    request: {
      url: `/accounts/${accountId}`,
    },
  },
});

export const editAccount = (accountId, data) => dispatch => {
  dispatch(accountEditStart());
  axios
    .put(`/api/accounts/${accountId}`, data)
    .then(r => {
      dispatch(accountEditSuccess(r.data));
      dispatch(addSuccessNotification('Account changes saved'));
      dispatch(loadAccountInfo(accountId));
    })
    .catch(e => {
      dispatch(accountEditError(e));
      dispatch(addDangerNotification('Unable to save changes'));
    });
};

export const accountEditStart = () => ({ type: 'ACCOUNT_EDIT_START' });
export const accountEditSuccess = data => ({
  type: 'ACCOUNT_EDIT_SUCCESS',
  data: data,
});
export const accountEditError = error => ({
  type: 'ACCOUNT_EDIT_ERROR',
  error: error,
});

export enum AccountUserRole {
  Admin = 0,
  User = 1,
  Device = 2,
}

export interface NewUserRequest {
  userName: string;
  password: string;
  email: string;
  role: AccountUserRole;
}

export const accountCreateUser = (accountId: number, data: NewUserRequest) => ({
  type: 'ACCOUNT_CREATE_USER',
  payload: {
    request: {
      url: `/accounts/${accountId}/user`,
      method: 'POST',
      data,
    },
  },
});
