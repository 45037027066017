import * as React from 'react';
import { connect } from 'react-redux';

import { ButtonBlock } from '../contentHelpers';

import { EditBlock, EditBlockCommands, BlockEditorProps } from './common';

interface PropsFromRedux {
  forms: any[];
  pages: any[];
}

class State {
  text = '';
  type = 'web';
  href = '';
  pageid = 0;
  form = '';
}

class Button1 extends React.Component<
  BlockEditorProps<ButtonBlock> & PropsFromRedux,
  State
> {
  state = new State();

  render() {
    const forms = this.props.forms || [];
    const pages = this.props.pages || [];

    return (
      <>
        <div className="form-group">
          <label htmlFor="text" className="control-label subtle-label">
            Text
          </label>
          <div className="input-group">
            <span className="input-group-addon" title="English">
              en
            </span>
            <input
              type="text"
              className="form-control"
              name="text"
              value={this.state.text}
              onChange={this.handleChange}
              onBlur={this.handleUpdate}
            />
          </div>
        </div>

        <div className="radio">
          <label htmlFor="">
            <input
              type="radio"
              name="type"
              value="none"
              checked={this.state.type === 'none'}
              onChange={this.handleChange}
            />{' '}
            None (hide)
          </label>
        </div>

        <div className="form-group">
          <div className="radio">
            <label htmlFor="">
              <input
                type="radio"
                name="type"
                value="web"
                checked={this.state.type === 'web'}
                onChange={this.handleChange}
              />{' '}
              Web link
            </label>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="http://..."
            name="href"
            value={this.state.href}
            onChange={this.handleChange}
            disabled={this.state.type !== 'web'}
            onBlur={this.handleUpdate}
          />
        </div>

        <div className="radio">
          <label htmlFor="">
            <input
              type="radio"
              name="type"
              value="page"
              checked={this.state.type === 'page'}
              onChange={this.handleChange}
            />{' '}
            Page link
          </label>
          <select
            name="pageid"
            value={this.state.pageid}
            className="form-control"
            disabled={this.state.type !== 'page'}
            onChange={this.handleChange}
          >
            <option value="0">Select a page...</option>
            {pages.map((page, index) => {
              return (
                <option key={index} value={page.id}>
                  {page.name} / {page.id}
                </option>
              );
            })}
          </select>
        </div>

        {forms.length !== 0 ? (
          <div className="radio">
            <label htmlFor="">
              <input
                type="radio"
                name="type"
                value="form"
                checked={this.state.type === 'form'}
                onChange={this.handleChange}
              />{' '}
              Form
            </label>
            <select
              name="form"
              value={this.state.form}
              className="form-control"
              disabled={this.state.type !== 'form'}
              onChange={this.handleChange}
            >
              <option value="">Select a form...</option>
              {forms.map((form, index) => (
                <option key={index} value={form.alias}>
                  {form.name} (alias: {form.alias})
                </option>
              ))}
            </select>
          </div>
        ) : null}
      </>
    );
  }

  componentDidMount() {
    this.init(this.props.data);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) this.init(nextProps.data);
  }

  init = (data: ButtonBlock) => {
    const type =
      data.target === 'none'
        ? 'none'
        : data.href.startsWith('pageid:')
        ? 'page'
        : data.href.startsWith('form:')
        ? 'form'
        : 'web';

    this.setState({
      type,
      text: data.text[0].text,
      href: type === 'web' ? data.href : '',
      pageid: type === 'page' ? Number(data.href.substr('pageid:'.length)) : 0,
      form: type === 'form' ? data.href.substr('form:'.length) : '',
    });
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value } as any, () => {
      if (name === 'type' || name === 'pageid' || name === 'form') {
        this.handleUpdate();
      }
    });
  };

  handleUpdate = () => {
    this.props.handleUpdate({
      ...this.props.data,
      target: this.state.type,
      href:
        this.state.type === 'none'
          ? ''
          : this.state.type === 'web'
          ? this.state.href
          : this.state.type === 'page'
          ? `pageid:${this.state.pageid}`
          : this.state.type === 'form'
          ? `form:${this.state.form}`
          : '',
      text: [
        {
          ...this.props.data.text[0],
          text: this.state.text,
        },
        ...this.props.data.text.slice(1),
      ],
    });
  };
}

export const Button = connect(state => {
  return {
    forms: state.App.forms,
    pages: state.App.pages,
  };
})(Button1);
