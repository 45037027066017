import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Container, Button } from 'ui';
import { Fetch, WithDialog } from 'Components/shared';
import {
  AppsUsersDto,
  createAppAndUsers,
  AppType,
  UserDto,
  AppDto,
  removeUserFromApp,
  addUserToApp,
  AppUserType,
  setPasswordForUser,
} from 'api';

import CreateAppUserModal from './apps-users-page/create-app-user-modal';
import { formatAlias } from './apps-users-page/formatAlias';
import AppUser from './apps-users-page/app-user';
import AddUser from './apps-users-page/add-user';
import AddUserModal from './apps-users-page/add-user-modal';
import SetPasswordModal from './apps-users-page/set-password-modal';

import './apps-users-page/apps-users-page.css';

type Props = {
  apps: AppsUsersDto;
  loadData: Function;
};

class AppsUsersPage extends PureComponent<Props> {
  render() {
    const {
      apps: { apps = [] },
    } = this.props;

    const users = apps
      .map(app => app.users)
      .selectMany(x => x)
      .filter(user => user.type === AppUserType.MyvaUser);

    return (
      <Container>
        <h1>Apps & Users</h1>

        <WithDialog>
          {(isOpen, show, hide) => (
            <>
              <Button onClick={show}>+ Create new app and user</Button>

              <CreateAppUserModal
                show={isOpen}
                onClose={hide}
                create={this.create}
              />
            </>
          )}
        </WithDialog>

        <hr />

        <h2>Apps</h2>

        {apps.map(app => (
          <div className="card-bg app" key={app.id}>
            <Link to={`/app/${app.id}`} className="app-name">
              {app.name}
            </Link>

            {app.users.map(user => (
              <AppUser
                key={user.id}
                user={user}
                onRemoveUser={() => this.onRemoveUser(app, user)}
              />
            ))}
            <WithDialog>
              {(isOpen, show, hide) => (
                <>
                  <AddUser onAddUser={show} />
                  <AddUserModal
                    show={isOpen}
                    onClose={hide}
                    onAddUser={(email, password) =>
                      this.addUser(app.id, email, password).then(() => hide())
                    }
                  />
                </>
              )}
            </WithDialog>
          </div>
        ))}

        <div className="clearfix" />

        <h2>Users</h2>
        <ul>
          {users.map(user => (
            <Fragment key={user.id}>
              {user.email}
              <WithDialog>
                {(isOpen, show, hide) => (
                  <>
                    <Button onClick={show}>Set password</Button>

                    <SetPasswordModal
                      name={user.name}
                      show={isOpen}
                      onClose={hide}
                      onSetPassword={password =>
                        this.setPassword(user.email, password).then(() =>
                          hide()
                        )
                      }
                    />
                  </>
                )}
              </WithDialog>
            </Fragment>
          ))}
        </ul>
      </Container>
    );
  }

  create = (
    name: string,
    email: string,
    appType: AppType,
    password: string
  ) => {
    const accountId = +window['userState'].accountId;
    return createAppAndUsers(accountId, {
      name,
      appType,
      accountId,
      password,
      alias: formatAlias(name),
      emails: [email],
    }).then(() => {
      this.props.loadData();
    });
  };

  addUser = (appId: number, email: string, password: string) => {
    const accountId = +window['userState'].accountId;
    return addUserToApp(accountId, appId, email, password)
      .then(() => {
        this.props.loadData();
      })
      .catch();
  };

  onRemoveUser = (app: AppDto, user: UserDto) => {
    if (confirm(`Are you sure you want to remove user ${user.name}`)) {
      const accountId = +window['userState'].accountId;
      return removeUserFromApp(accountId, app.id, user.id)
        .then(() => this.props.loadData())
        .catch();
    }
  };

  setPassword = async (email: string, password: string) => {
    const accountId = +window['userState'].accountId;
    return await setPasswordForUser(accountId, email, password);
  };
}

export default function(props) {
  const accountId = +window['userState'].accountId;
  return (
    <Fetch from={`/api/mobile/appsusers/${accountId}`}>
      {({ data, refresh }) => (
        <AppsUsersPage
          {...props}
          apps={data ? data.data : {}}
          loadData={refresh}
        />
      )}
    </Fetch>
  );
}
