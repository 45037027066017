import * as React from 'react';

export const Weather: React.SFC<{}> = () => {
  return (
    <>
      <span className="help-block">
        Weather widget will display forecast in the app.
      </span>
    </>
  );
};
