import React from 'react';
import moment from 'moment';

import { Container, Pagination } from 'ui';
import {
  getErrors,
  AccountDeviceError,
  BaseListDTO,
  DeviceLogLevel,
} from 'api';
import { Link } from 'react-router-dom';

interface Props {}

class State {
  isLoading = false;
  data = new BaseListDTO<AccountDeviceError>();
}

export default class DashboardErrors extends React.Component<Props, State> {
  state = new State();

  componentDidMount() {
    this.load(1);
  }

  load = (page: number) => {
    this.setState({ isLoading: true });
    getErrors(window['userState'].accountId, page)
      .then((r) => this.setState({ isLoading: false, data: r.data }))
      .catch(() => this.setState({ isLoading: false }));
  };

  render() {
    const {
      data: { items },
    } = this.state;

    return (
      <Container>
        <h2>Errors</h2>

        <table className="table table-shadow table-striped table-hover">
          <thead>
            <tr>
              <th>Id</th>
              <th>Device</th>
              <th>Message</th>
              <th>Exception</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {(items || []).map((item) => (
              <tr key={item.id} className={getClassNameFromLevel(item.level)}>
                <td>{item.id}</td>
                <td>
                  <Link to={`/digitalsignage/screens/${item.deviceId}/log`}>
                    {item.deviceName}
                  </Link>
                </td>
                <td>{item.message}</td>
                <td>{item.exception}</td>
                <td>{moment(item.createdOn).fromNow()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          page={this.state.data.page}
          pageCount={this.state.data.pageCount}
          onPage={this.load}
        />
      </Container>
    );
  }
}

const getClassNameFromLevel = (level: DeviceLogLevel) => {
  switch (level) {
    case DeviceLogLevel.Error:
    case DeviceLogLevel.Critical:
      return 'danger';

    case DeviceLogLevel.Warning:
      return 'warning';

    case DeviceLogLevel.Information:
      return 'info';

    default:
      return 'debug';
  }
};
