import React from 'react';
import Dropzone, { ImageFile } from 'react-dropzone';
import ReactCrop from 'react-image-crop';

import { Loading, StateComponent } from 'Components/shared';
import { MediaItemType, PlaylistOrientation } from '../../enums';
import {
  getMediaItems,
  MediaItemsList,
  MediaItemListItem,
  useMediaItem,
  ImageTarget,
  uploadMediaItem,
  CropProperties,
} from 'api';

import { Pagination, Button } from 'ui';
import { MediaItem } from './media-item';

import './media-item-picker.css';

// TODO: fix properly
// require('!style-loader!css-loader!react-image-crop/dist/ReactCrop.css');

interface Size {
  width: number;
  height: number;
}

function getDimensions(file: ImageFile) {
  return new Promise((resolve: (size: Size) => void, reject) => {
    const i = new Image();

    i.onload = () => {
      resolve({ width: i.width, height: i.height });
    };

    i.src = file.preview || '';
  });
}

interface Props {
  name: string;
  orientation: PlaylistOrientation;
  type?: string;
  handleClose: () => any;
  handleImageUploaded: (pickedImage: { id: number }) => any;
}

class State {
  isFetching = false;
  data: MediaItemsList = {
    items: [],
    page: 0,
    total: 0,
    pageSize: 20,
    pageCount: 2,
  };

  isWorking = false;
  isUploading = false;

  targetWidth = 0;
  targetHeight = 0;

  // for searching
  query = '';
  hideSmaller = false;
  showInvalidAspect = false;

  // when image is picked
  mediaToUpload: ImageFile[] = [];
  currentImageToUpload = 0;
  imagesToUpload = 0;
  uploadProgress = 0;
  totalUploadProgress = 0;
  uploadProgressText = '';
  imageCrops: (CropProperties | null)[] = [];

  // we need to know if we are showing gallery
  isShowingGallery = true;

  mediaPicked: MediaItemListItem[] = [];

  // crop area
  imagePreview: any = null;
  showCrop = false;
  crop: CropProperties | null = null;
  pixelCrop = {};
}

class MediaItemDialog extends StateComponent<Props, State> {
  state = new State();

  componentDidMount() {
    this.loadPage(1);
    if (this.props.orientation === PlaylistOrientation.Landscape) {
      this.setState({ targetWidth: 1920, targetHeight: 1080 });
    } else if (this.props.orientation === PlaylistOrientation.Portrait) {
      this.setState({ targetWidth: 1080, targetHeight: 1920 });
    } else {
      this.setState({ showInvalidAspect: true });
    }

    $('#imagePickerTabs a').click((e) => {
      e.preventDefault();
      $(this).tab('show');

      const isShowingGallery = $(this).attr('href') === '#imagepicker-gallery';
      this.setState({ isShowingGallery });
    });
  }

  loadPage = (page: number) => {
    if (this.state.isFetching) return;

    this.setState({ isFetching: true });
    getMediaItems(
      this.props.type || '',
      window['userState'].accountId,
      page,
      50,
      this.state.query
    )
      .then((r) => this.setState({ isFetching: false, data: r.data }))
      .catch(() => this.setState({ isFetching: false }));
  };

  initialCrop = (width, height) => {
    const aspect = this.state.targetWidth / this.state.targetHeight;
    const s = Math.min(
      width / this.state.targetWidth,
      height / this.state.targetHeight
    );
    return {
      aspect,
      x: 0,
      y: 0,
      width: (100 * this.state.targetWidth * s) / width,
      height: (100 * this.state.targetHeight * s) / height,
    };
  };

  handlePickFromGallery = (mediaItem: MediaItemListItem) => {
    if (mediaItem.mediaItemType === MediaItemType.Image) {
      let uri = mediaItem.thumbUri;

      try {
        const meta = JSON.parse(mediaItem.meta);
        uri = meta && meta.uri ? meta.uri : uri;
      } catch (e) {}

      if (this.props.orientation === PlaylistOrientation.None) {
        // when no orientation is specified, don't offer crop
        // TODO: maybe still offer crop but without aspect
        this.props.handleImageUploaded(mediaItem);
      } else {
        this.setState({
          mediaPicked: [mediaItem],
          imageCrops: [null],
          imagePreview: uri,
          showCrop: true,
          crop: this.initialCrop(mediaItem.width, mediaItem.height),
        });
      }
    } else {
      // non-images are not sent for cropping, just notify caller
      this.props.handleImageUploaded(mediaItem);
    }
  };

  handleFilesDrop = async (accepted: ImageFile[], rejected: ImageFile[]) => {
    if (rejected.length !== 0) {
      alert(
        'Please only drop valid files, cannot accept ' +
          rejected.map((r) => r.name).join(', ')
      );
      return;
    }

    if (accepted.length === 0) {
      alert('Drop at least one image');
      return;
    }

    // clear previews in case this is already a second drop in this lifecycle
    this.clearPreviews();

    const aspect = 1;
    const firstImage = accepted.findIndex((a) => a.type.startsWith('image/'));
    this.setState({
      mediaToUpload: accepted,
      currentImageToUpload: firstImage,
      imageCrops: accepted.map((file) => null),
      imagesToUpload: accepted.filter((a) => a.type.startsWith('image/'))
        .length,
    });

    if (firstImage > -1) {
      const { width, height } = await getDimensions(accepted[firstImage]);
      this.setState({
        imagePreview: accepted[firstImage].preview,
        showCrop: true,
        crop: this.initialCrop(width, height),
      });
    } else {
      this.setState(
        () => ({}),
        () => {
          this.uploadAll();
        }
      );
    }
  };

  handleCropChange = (crop) => this.setState({ crop });
  handleCropComplete = (crop, pixelCrop) => this.setState({ crop, pixelCrop });
  handleImageLoaded = (image) =>
    this.setState({ crop: this.initialCrop(image.width, image.height) });

  handleCancel = () => {
    if (this.state.isUploading) {
      // cannot cancel upload
    } else if (this.state.showCrop) {
      this.clearPreviews();
      this.setState({
        imagePreview: null,
        showCrop: false,
        mediaPicked: [],
        imagesToUpload: 0,
      });
    } else {
      this.clearPreviews();
      this.props.handleClose();
      $(`#${this.props.name}`).modal('hide');
    }
  };

  handleSkipCrop = () => this.setCropForCurrentImage(null);
  handleCropImage = () => this.setCropForCurrentImage(this.state.crop);

  setCropForCurrentImage = async (crop: CropProperties | null) => {
    // first remember crop for later uploads
    const imageCrops = this.state.imageCrops.map((c, i) =>
      i === this.state.currentImageToUpload ? crop : c
    );
    this.setState({ imageCrops });

    // now advance to next image if any
    // otherwise move to upload phase
    if (this.state.mediaToUpload.length > 0) {
      let i: number;
      for (
        i = this.state.currentImageToUpload + 1;
        i < this.state.mediaToUpload.length;
        ++i
      ) {
        if (this.state.mediaToUpload[i].type.startsWith('image/')) {
          const { width, height } = await getDimensions(
            this.state.mediaToUpload[i]
          );
          this.setState({
            currentImageToUpload: i,
            imagePreview: this.state.mediaToUpload[i].preview,
            showCrop: true,
            crop: this.initialCrop(width, height),
          });
          break;
        }
      }

      if (i === this.state.mediaToUpload.length) {
        this.setState({ showCrop: false }, () => this.uploadAll());
      }
    } else {
      // we only allow picking one image for now
      this.setState({ showCrop: false }, () => this.uploadAll());
    }
  };

  uploadAll = () => {
    this.setState({ isUploading: true });

    if (this.state.mediaToUpload.length > 0) this.handleUploadMedia(0);
    else if (this.state.mediaPicked.length > 0) this.handleAddExistingImage();
  };

  handleAddExistingImage = () => {
    this.setState({ isWorking: true });

    // this means user has picked an existing image, use that one!
    const index = 0;
    const mediaItem = this.state.mediaPicked[index];
    if (this.state.imageCrops[index] === null) {
      // no crop, simply use this media item
      this.props.handleImageUploaded(mediaItem);

      this.setState({ isUploading: false });
      this.clearPreviews();
      $(`#${this.props.name}`).modal('hide');
      this.props.handleClose();
    } else {
      useMediaItem(mediaItem.id, {
        crop: this.state.imageCrops[index],
        targetWidth: this.state.targetWidth,
        targetHeight: this.state.targetHeight,
        target: ImageTarget.None,
      })
        .then((r) => {
          this.props.handleImageUploaded(r.data);
          this.clearPreviews();
          $(`#${this.props.name}`).modal('hide');
          this.props.handleClose();
        })
        .catch(() => {
          alert(`Unable to upload file ${mediaItem.name}`);
          this.clearPreviews();
          $(`#${this.props.name}`).modal('hide');
          this.props.handleClose();
        });
    }
  };

  handleFilterImages = (e: any) => {
    this.setState({ query: e.target.value.toLowerCase() }, () => {
      this.loadPage(1);
    });
  };

  handleUploadMedia = (index) => {
    const media = this.state.mediaToUpload[index];

    this.setState({
      isUploading: true,
      uploadProgress: 0,
      uploadProgressText: `Uploading file ${media.name}`,
    });

    uploadMediaItem(
      window['userState'].accountId,
      media,
      this.state.imageCrops[index],
      (progress) => {
        // individual progress percentage
        const part = 100 / this.state.mediaToUpload.length;
        this.setState({
          uploadProgress: progress,
          totalUploadProgress:
            (index === 0 ? 0 : index * part) + (part * progress) / 100,
        });
      }
    )
      .then((r) => {
        this.props.handleImageUploaded(r.data);
        if (index < this.state.mediaToUpload.length - 1) {
          this.setState({});
          this.handleUploadMedia(index + 1);
        } else {
          this.setState({ isUploading: false });
          this.clearPreviews();
          $(`#${this.props.name}`).modal('hide');
          this.props.handleClose();
        }
      })
      .catch(() => {
        alert(`Unable to upload file ${media.name}`);
        if (index < this.state.mediaToUpload.length - 1) {
          this.setState({});
          this.handleUploadMedia(index + 1);
        } else {
          this.setState({ isUploading: false });
          this.clearPreviews();
          $(`#${this.props.name}`).modal('hide');
          this.props.handleClose();
        }
      });
  };

  clearPreviews = () => {
    this.state.mediaToUpload.forEach((f) => {
      if (f && f.preview) window.URL.revokeObjectURL(f.preview);
    });
  };

  render() {
    const images = this.state.data.items
      .filter(
        (image) => image.name.toLowerCase().indexOf(this.state.query) !== -1
      )
      .filter((image) => {
        if (!this.state.hideSmaller) return true;

        // remove smaller in either dimension
        if (
          image.width < this.state.targetWidth ||
          image.height < this.state.targetHeight
        ) {
          return false;
        }

        return true;
      })
      .filter((image) => {
        const imageAspect = image.width / image.height;
        const aspect = this.state.targetWidth / this.state.targetHeight;
        return (
          this.state.showInvalidAspect || Math.abs(imageAspect - aspect) < 0.01
        );
      });

    const info =
      this.props.orientation === PlaylistOrientation.Landscape
        ? '1920x1080, landscape'
        : this.props.orientation === PlaylistOrientation.Portrait
        ? '1080x1920, portrait'
        : '';

    return (
      <div
        className="modal fade modal-fullscreen-holder"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div
          className="modal-dialog modal-fancy modal-lg media-item-picker"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Add media item {info ? <small>({info})</small> : null}
              </h4>
            </div>

            <div className="modal-body">
              {this.state.isWorking ? (
                <Loading />
              ) : this.state.isUploading ? (
                <div className="col-xs-12">
                  <h4>Uploading files</h4>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={this.state.totalUploadProgress}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: `${this.state.totalUploadProgress}%` }}
                    >
                      {Math.floor(this.state.totalUploadProgress)}%
                    </div>
                  </div>

                  <h5>Uploading... {this.state.uploadProgressText}</h5>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={this.state.uploadProgress}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: `${this.state.uploadProgress}%` }}
                    >
                      {this.state.uploadProgress === 100
                        ? 'Processing...'
                        : `${this.state.uploadProgress}%`}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <ul
                    id="imagePickerTabs"
                    className={
                      'nav nav-tabs ' + (this.state.showCrop ? 'hide' : '')
                    }
                    role="tablist"
                  >
                    <li role="presentation">
                      <a
                        href="#imagepicker-upload"
                        aria-controls="style"
                        role="tab"
                        data-toggle="tab"
                      >
                        Upload
                      </a>
                    </li>
                    <li role="presentation" className="active">
                      <a
                        href="#imagepicker-gallery"
                        aria-controls="style"
                        role="tab"
                        data-toggle="tab"
                      >
                        Gallery
                      </a>
                    </li>
                  </ul>

                  <div
                    className={
                      'tab-content ' + (this.state.showCrop ? 'hide' : '')
                    }
                    id={this.props.name + 'Form'}
                    style={{ maxWidth: 'inherit' }}
                  >
                    <div
                      role="tabpanel"
                      className="tab-pane"
                      id="imagepicker-upload"
                    >
                      <h5 className="help-block">Upload new image:</h5>
                      <Dropzone
                        className="upload"
                        activeClassName="upload-accept"
                        rejectClassName="upload-reject"
                        accept="image/*,video/mp4"
                        onDrop={this.handleFilesDrop}
                      >
                        <span>+</span>
                      </Dropzone>
                      <div className="clearfix" />
                    </div>

                    <div
                      role="tabpanel"
                      className="tab-pane active tab-gallery"
                      id="imagepicker-gallery"
                    >
                      <div className="search-box">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Filter by name..."
                            name="query"
                            value={this.state.query}
                            onChange={this.handleFilterImages}
                          />
                          <span className="input-group-btn">
                            <button className="btn btn-default" type="button">
                              Go!
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="gallery">
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th>Preview</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Dimensions</th>
                              <th>Duration</th>
                              <th>Size</th>
                            </tr>
                          </thead>
                          <tbody>
                            {images.map((i) => (
                              <MediaItem
                                key={i.id}
                                image={i}
                                onClick={this.handlePickFromGallery}
                              />
                            ))}
                          </tbody>
                        </table>

                        {images.length === 0 ? (
                          <span className="text-info">
                            No images match your criteria
                          </span>
                        ) : null}

                        {this.state.isFetching ? <Loading /> : null}
                      </div>

                      <Pagination
                        onPage={this.loadPage}
                        page={this.state.data.page}
                        pageCount={this.state.data.pageCount}
                      />
                    </div>
                  </div>

                  <div
                    className={this.state.showCrop ? '' : 'hide'}
                    style={{ height: '100%' }}
                  >
                    {this.state.imagePreview ? (
                      <>
                        {this.state.imagesToUpload > 1 ? (
                          <h5>
                            Crop image{' '}
                            {`${
                              this.state.mediaToUpload[
                                this.state.currentImageToUpload
                              ].name
                            }`}{' '}
                            (
                            {`${this.state.currentImageToUpload + 1} of ${
                              this.state.imagesToUpload
                            }`}
                            )
                          </h5>
                        ) : null}
                        <ReactCrop
                          src={this.state.imagePreview}
                          crop={this.state.crop}
                          onChange={this.handleCropChange}
                          onComplete={this.handleCropComplete}
                          onImageLoaded={this.handleImageLoaded}
                        />

                        <div className="clearfix" />
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </div>

            <div className="modal-footer">
              {this.state.isShowingGallery && !this.state.showCrop ? (
                <div className="pull-left">
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="showInvalidAspect"
                        checked={this.state.showInvalidAspect}
                        onChange={this.set}
                      />{' '}
                      Show invalid aspect
                    </label>
                  </div>
                </div>
              ) : null}

              <Button onClick={this.handleCancel}>Cancel</Button>

              {this.state.mediaPicked.length + this.state.mediaToUpload.length >
                0 && !this.state.isUploading ? (
                <Button onClick={this.handleSkipCrop}>Skip crop</Button>
              ) : null}

              {this.state.showCrop ? (
                <Button onClick={this.handleCropImage}>Crop</Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MediaItemDialog;
