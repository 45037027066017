import * as React from 'react';
import { connect } from 'react-redux';
import * as Actions from './actions';

class State {
  // tslint:disable-next-line:variable-name
  ga_event = '';
  isPrivate = false;
}

class PageSettings extends React.Component<any, State> {
  state = new State();

  render() {
    const { page } = this.props;

    return (
      <>
        <div className="fancy">
          <div className="form-group">
            <label htmlFor="" className="control-label subtle-label">
              Template name
            </label>
            <div className="control-info">{this.props.template.name}</div>

            <label htmlFor="alias" className="control-label subtle-label">
              Template alias
            </label>
            <div className="control-info">{this.props.template.alias}</div>
          </div>

          {this.props.template.alias !== 'page-home' ? (
            <div className="form-group">
              <span className="help-block">
                Changing template will remove all changes made to this page.
              </span>
              <button
                className="btn btn-warning"
                onClick={this.props.handleChangeTemplate}
                disabled={
                  this.props.page &&
                  this.props.page.page &&
                  this.props.page.page.isLink
                }
              >
                Change template
              </button>
            </div>
          ) : null}

          <hr />

          <div className="form-group">
            <span className="subtle-label">Google Analytics Event</span>
            <span className="help-block">
              If you need special tracking for this page, enter an event name
              that will appear in Google Analytics.
            </span>

            <input
              type="text"
              className="form-control"
              name="ga_event"
              placeholder="Google Analytics event name"
              value={this.state.ga_event}
              onChange={e => this.setState({ ga_event: e.target.value })}
              onBlur={this.saveGaEvent}
            />
          </div>

          <div className="form-group">
            <span className="subtle-label">Private page</span>
            <span className="help-block">
              In case this page needs to become private, toggle the switch below
            </span>
            <input
              type="checkbox"
              name="isPrivate"
              checked={this.state.isPrivate}
              onChange={this.saveIsPrivate}
            />{' '}
            Is private
          </div>

          <hr />

          {!!page && !!page.page && page.page.level !== 0 && (
            <div className="form-group">
              <span id="help-btn-delete-page" className="help-block">
                Deleting page cannot be undone.
              </span>
              <button
                className="btn btn-danger"
                aria-describedby="help-btn-delete-page"
                onClick={this.handleDeletePage}
              >
                Delete page
              </button>
            </div>
          )}

          <hr />

          <div className="form-group">
            <span id="help-btn-hide-page" className="help-block">
              Hiding pages means that page will not appear in the app, but the
              data stays intact in CMS.
            </span>
            {this.props.page.page.isHidden ? (
              <button
                className="btn btn-info"
                aria-describedby="help-btn-hide-page"
                onClick={this.props.unhide}
              >
                Unhide page
              </button>
            ) : (
              <button
                className="btn btn-info"
                aria-describedby="help-btn-hide-page"
                onClick={this.props.hide}
              >
                Hide page
              </button>
            )}
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.setState({
      ga_event: this.props.page.data.settings.ga_event || '',
      isPrivate: this.props.page.data.settings.isPrivate || false,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.setState({
        ga_event: nextProps.page.data.settings.ga_event || '',
        isPrivate: nextProps.page.data.settings.isPrivate || false,
      });
    }
  }

  handleDeletePage = () => {
    if (confirm('Are you sure you want to delete this page?')) {
      this.props.dispatch(
        Actions.deletePage(
          this.props.match.params.pageid,
          this.props.match.params.id
        )
      );
    }
  };

  saveGaEvent = () => {
    this.props.saveSettings({
      ...this.props.page.data.settings,
      ga_event: this.state.ga_event,
    });
  };

  saveIsPrivate = e => {
    this.props.saveSettings({
      ...this.props.page.data.settings,
      isPrivate: e.target.checked,
    });
  };
}

export default connect(
  state => ({
    template: state.Page.template,
  }),
  (dispatch, ownProps) => ({
    hide: () => dispatch(Actions.hidePage(ownProps.page.id)),
    unhide: () => dispatch(Actions.unhidePage(ownProps.page.id)),
    saveSettings: settings => dispatch(Actions.saveSettings(settings)),
  })
)(PageSettings);
