import * as React from 'react';

export interface BlockEditorProps<T> {
  data: T;
  handleUpdate: (data: T) => any;
}

export interface EditBlockProps {
  title: string;
}

export class EditBlock extends React.Component<EditBlockProps, {}> {
  state = {
    hasError: false,
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="alert alert-danger">Unable to render editor :(</div>
      );
    }

    const { title, children } = this.props;

    return (
      <div className="edit-block">
        <h4 className="edit-block-title">{title}</h4>
        {children}
      </div>
    );
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }
}

export interface EditBlockCommandsProps {
  id: number;
  isFixed?: boolean;
  removeBlock: (index: number) => any;
  moveBlockUp: (index: number) => any;
  moveBlockDown: (index: number) => any;
}

export class EditBlockCommands extends React.PureComponent<
  EditBlockCommandsProps,
  {}
> {
  render() {
    return (
      <div className="edit-block-commands">
        <button className="btn btn-transparent" onClick={this.remove}>
          <i className="glyphicon glyphicon-remove" />
        </button>

        {!this.props.isFixed ? (
          <>
            <button className="btn btn-transparent" onClick={this.up}>
              <i className="glyphicon glyphicon-arrow-up" />
            </button>
            <button className="btn btn-transparent" onClick={this.down}>
              <i className="glyphicon glyphicon-arrow-down" />
            </button>
          </>
        ) : null}
      </div>
    );
  }

  remove = () => this.props.removeBlock(this.props.id);
  up = () => this.props.moveBlockUp(this.props.id);
  down = () => this.props.moveBlockDown(this.props.id);
}
