import React, { Component, ReactNode } from 'react';

import { isoLangs } from '../../../languages';
import { TitleBlock, LocalizedString } from '../contentHelpers';
import { EditBlock } from '../Editors/common';
import { Input } from 'ui';

interface Props {
  title: string;
  text: LocalizedString[];
  languages: string[];
  setText: (text: LocalizedString[]) => any;
}

class State {
  text: LocalizedString[] = [];
}

class LocalizeTitle extends Component<Props, State> {
  state = new State();

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.text !== this.props.text) this.init(nextProps);
  }

  init = (props: Props) => {
    const { languages } = props;
    const text = languages.map(code => {
      const block = props.text.find(d => d.lang === code);

      return {
        lang: code,
        text: block ? block.text : '',
      };
    });

    this.setState({ text });
  };

  handleTextChange = e => {
    const lang = e.target.name;
    const value = e.target.value;

    const index = this.state.text.findIndex(x => x.lang === lang);
    this.setState({
      text: this.state.text
        .slice(0, index)
        .concat(
          Object.assign({}, this.state.text[index], {
            text: value,
          })
        )
        .concat(this.state.text.slice(index + 1)),
    });
  };

  handleUpdate = () => {
    this.props.setText(this.state.text);
  };

  render() {
    const rows = this.state.text
      .map((block, index) => {
        const code = block.lang;
        const language = isoLangs.hasOwnProperty(code) ? isoLangs[code] : null;
        if (!language) return null;

        const value = null;
        return (
          <tr key={index}>
            <td>{language.name}</td>
            <td>
              <Input
                value={block.text}
                name={code}
                onChange={this.handleTextChange}
                onBlur={this.handleUpdate}
                onFocus={this.handleFocus}
              />
            </td>
          </tr>
        );
      })
      .filter(x => x);

    return (
      <EditBlock title={this.props.title}>
        <table className="table table-shadow">
          <thead>
            <tr>
              <th>Language</th>
              <th>Content</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </EditBlock>
    );
  }

  handleFocus = e => (e.target as any).select();
}

export default LocalizeTitle;
