import * as React from 'react';

import Notifications from './Notifications';

const Shell = ({ children }) => (
  <div className="app-holder">
    {children}
    <Notifications />
  </div>
);

export default Shell;
