export function pagesRange(start: number, count: number) {
  let arr: number[] = [];
  const max = 20;
  if (count < max) {
    for (var i = start; i < start + count; ++i) arr.push(i);
  } else {
    const half = Math.floor(max / 2);
    for (var i = start; i < start + half; ++i) arr.push(i);

    for (var i = start + count - half; i < start + count; ++i) arr.push(i);
  }

  return arr;
}
