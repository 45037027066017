import axios from 'axios';

import { DevicePingRequest, GuestDirectoryDeviceDto } from './types';

export function getGuestDirectoryDevices() {
  return axios.get<GuestDirectoryDeviceDto[]>('/api/guestdevice');
}

export function pingGuestDirectoryDevice(request: DevicePingRequest) {
  return axios.post<boolean>('/api/guestdevice/ping', request);
}
