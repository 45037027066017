import React from 'react';

export default function PrivacyPolicy() {
  return (
    <main className="mx-auto w-full max-w-6xl px-4 lg:p-0 text-black flex flex-col gap-4">
      <h1>Privacy policy</h1>

      <p>Last updated December 3rd, 2023</p>

      <p>
        Your privacy is critically important to us. At emguest.com and mobile
        applications (EmGuest demo) Mobendo d.o.o ("Mobendo", "we", "us", "our")
        have a few fundamental principles:
      </p>

      <ul className="principles list-disc ">
        <li>
          We don’t ask you for personal information unless we truly need it.
        </li>
        <li>
          We don’t share your personal information with anyone except other
          users you contact via EmGuest, to comply with the law, develop our
          products, or protect our rights.
        </li>
        <li>
          We don’t store personal information on our servers unless required for
          the on-going operation of one of our services.
        </li>
        <li>
          In our products, we aim to make it as simple as possible for you to
          control what’s visible to the public, seen by search engines, kept
          private, and permanently deleted.
        </li>
      </ul>

      <p>Below is our privacy policy which incorporates these goals</p>
      <p>
        If you have questions about deleting or correcting your personal data
        please contact our <a href="mailto:mobendo@mobendo.com">support team</a>
        .
      </p>
      <p>
        <a href="https://www.mobendo.com/">Mobendo d.o.o..</a> (“
        <strong>Mobendo</strong>”) operates several websites and applications
        including:
      </p>

      <ul className="list-disc">
        <li>
          <a href="https://emguest.com">emguest.com</a>
        </li>

        <li>EmGuest Demo application for Android</li>
        <li>EmGuest Demo application for iOS</li>
      </ul>

      <p>
        It is Mobendo policy to respect your privacy regarding any information
        we may collect while operating our websites.
      </p>
      <h3>Website Visitors</h3>
      <p>
        Like most website operators, Mobendo collects non-personally-identifying
        information of the sort that web browsers and servers typically make
        available, such as the browser type, language preference, referring
        site, and the date and time of each visitor request. Mobendo purpose in
        collecting non-personally identifying information is to better
        understand how Mobendo visitors use its website. From time to time,
        Mobendo may release non-personally-identifying information in the
        aggregate, e.g., by publishing a report on trends in the usage of its
        website.
      </p>
      <p>
        Mobendo also collects potentially personally-identifying information
        like Internet Protocol (IP) addresses. Mobendo does not use such
        information to identify its visitors, however, and does not disclose
        such information, other than under the same circumstances that it uses
        and discloses personally-identifying information, as described below.
      </p>
      <h3>Gathering of Personally-Identifying Information</h3>
      <p>
        Certain visitors to Mobendo websites choose to interact with Mobendo in
        ways that require Mobendo to gather personally-identifying information.
        The amount and type of information that Mobendo gathers depends on the
        nature of the interaction. For example, we ask visitors who sign up at
        <a href="https://emguest.com/">emguest.com</a> to provide an e-mail
        address, and name. Those who engage in transactions with Mobendo are
        asked to provide additional information, including as necessary the
        personal and financial information required to process those
        transactions. In each case, Mobendo collects such information only
        insofar as is necessary or appropriate to fulfill the purpose of the
        visitor’s interaction with Mobendo. Mobendo does not disclose
        personally-identifying information other than as described below. And
        visitors can always refuse to supply personally-identifying information,
        with the caveat that it may prevent them from engaging in certain
        website-related activities.
      </p>

      <h3>EmGuest demo application</h3>
      <p>Mobile applications do not gather or require any user information.</p>

      <h3>Aggregated Statistics</h3>
      <p>
        Mobendo may dcollect statistics about the behavior of visitors to its
        websites. For instance, Mobendo may monitor the most popular bands or
        clubs on the emguest.com site. Mobendo may display this information
        publicly or provide it to others. However, Mobendo does not disclose
        personally-identifying information other than as described below.
      </p>
      <h3>Protection of Certain Personally-Identifying Information</h3>
      <p>
        Mobendo discloses potentially personally-identifying and
        personally-identifying information only to those of its employees,
        contractors and affiliated organizations that (i) need to know that
        information in order to process it on Mobendo behalf or to provide
        services available at Mobendo websites, and (ii) that have agreed not to
        disclose it to others. Some of those employees, contractors and
        affiliated organizations may be located outside of your home country; by
        using Mobendo websites, you consent to the transfer of such information
        to them. Mobendo will not rent or sell potentially
        personally-identifying and personally-identifying information to anyone.
        Other than to its employees, contractors and affiliated organizations,
        as described above, Mobendo discloses potentially personally-identifying
        and personally-identifying information only in response to a subpoena,
        court order or other governmental request, or when Mobendo believes in
        good faith that disclosure is reasonably necessary to protect the
        property or rights of Mobendo, third parties or the public at large. If
        you are a registered user of an Mobendo website and have supplied your
        email address, Mobendo may occasionally send you an email to tell you
        about new features, solicit your feedback, or just keep you up to date
        with what’s going on with Mobendo and our products. We primarily use our
        various product blogs to communicate this type of information, so we
        expect to keep this type of email to a minimum. If you send us a request
        (for example via a support email or via one of our feedback mechanisms),
        we reserve the right to publish it in order to help us clarify or
        respond to your request or to help us support other users. Mobendo takes
        all measures reasonably necessary to protect against the unauthorized
        access, use, alteration or destruction of potentially
        personally-identifying and personally-identifying information.
      </p>
      <h3>Cookies</h3>
      <p>
        A cookie is a string of information that a website stores on a visitor’s
        computer, and that the visitor’s browser provides to the website each
        time the visitor returns. Mobendo uses cookies to help Mobendo identify
        and track visitors, their usage of Mobendo website, and their website
        access preferences. Mobendo visitors who do not wish to have cookies
        placed on their computers should set their browsers to refuse cookies
        before using Mobendo websites, with the drawback that certain features
        of Mobendo websites may not function properly without the aid of
        cookies.
      </p>
      <h3>Business Transfers</h3>
      <p>
        If Mobendo, or substantially all of its assets, were acquired, or in the
        unlikely event that Mobendo goes out of business or enters bankruptcy,
        user information would be one of the assets that is transferred or
        acquired by a third party. You acknowledge that such transfers may
        occur, and that any acquirer of Mobendo may continue to use your
        personal information as set forth in this policy.
      </p>

      <h3>Privacy Policy Changes</h3>
      <p>
        Although most changes are likely to be minor, Mobendo may change its
        Privacy Policy from time to time, and in Mobendo sole discretion.
        Mobendo encourages visitors to frequently check this page for any
        changes to its Privacy Policy. Your continued use of this site after any
        change in this Privacy Policy will constitute your acceptance of such
        change.
      </p>

      <hr />

      <p className="licence">
        <a
          href="https://creativecommons.org/licenses/by-sa/3.0/"
          rel="license"
          className="cc"
        >
          <img
            style={{ alignSelf: 'right', border: '0' }}
            alt="Creative Commons License"
            src="https://creativecommons.org/images/public/somerights20.png"
          />
        </a>
        Note: This Privacy Policy document is available under a Creative Commons
        Sharealike license. This document is derived from the original work by
        <a href="https://automattic.com/" target="_blank">
          Automattic Inc.
        </a>
        , San Francisco, California, authors of the world famous
        <a href="https://wordpress.com/" target="_blank">
          Wordpress.com
        </a>
        .
      </p>
    </main>
  );
}
