import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { Redirect, RouteComponentProps } from 'react-router';

import { Loading, FeatureFlag } from 'Components/shared';

import {
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
} from '../NotificationActions';

import { MediaItemRow } from './media-item-row';
import {
  MediaItemListItem,
  mediaItemScheduleClear,
  mediaItemScheduleDeactivate,
  mediaItemScheduleActivate,
  AddMediaItemToPlaylistsCommand,
  mediaItemUpdateVideoMetadata,
} from 'api';
import { useMutation } from '../../utilities/useMutation';

type Props = {
  addInfoNotification: (text: string) => void;
  addDangerNotification: (text: string) => void;
  addSuccessNotification: (text: string) => void;
} & RouteComponentProps<{ filter?: string }>;

const addMediaItemToPlaylists = useMutation<
  AddMediaItemToPlaylistsCommand,
  boolean
>(`/api/playlists/media`);

type SortBy = 'name' | 'lastModified';

class State {
  isFetching: boolean = false;

  sortBy: SortBy = 'lastModified';
  iconSortName = 'glyphicon glyphicon-sort';
  iconSortLastModified = 'glyphicon glyphicon-sort-by-attributes-alt';
  sortOrder: string = 'desc';

  presInfo: {
    presName: string;
    presWidth: number;
    presHeight: number;
    orientation: string;
  } = {
    presName: '',
    presWidth: 1920,
    presHeight: 1080,
    orientation: '',
  };
  mediaItems: any[] = [];
  itemsPerPage: number = 50;
  pageCount: number = 0;
  totalItems: number = 0;
  filterMedia: string = '';
  itemsPlaylist: any[] = [];
  total: number = 0;
  currentPage: number = 1;
  errors: {
    _all?: string;
    name?: string;
    orientation?: string;
  } = {};

  isUploading: boolean = false;
  progress: any[] = [];
  mediaUploaded?: boolean;
  mediaUploadErr?: boolean;
  uploadFileName: string = '';

  redirectTo: string = '';
}

class MediaItems extends Component<Props, State> {
  state = new State();

  componentDidMount() {
    this.loadMediaItems('');
  }

  // Handle next page
  handleNextPage = () => {
    this.setState(
      {
        currentPage:
          this.state.currentPage < this.state.pageCount
            ? this.state.currentPage + 1
            : this.state.pageCount,
      },
      () => {
        this.loadMediaItems('');
      }
    );
  };

  handleItemsPerPage = (e: any) => {
    this.setState({ itemsPerPage: e.target.value }, () => {
      this.loadMediaItems('');
    });
  };

  handlePrevPage = () => {
    this.setState(
      {
        currentPage:
          this.state.currentPage > 1 ? this.state.currentPage - 1 : 1,
      },
      () => {
        this.loadMediaItems('');
      }
    );
  };

  hanleSearchResults = (e: any) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        // when filter changes, reset to first page
        currentPage: 1,
      } as any,
      () => {
        this.loadMediaItems(this.state.filterMedia);
      }
    );
  };

  sortItemsDescending = () => {
    this.setState({ sortOrder: 'desc' }, () => {
      this.loadMediaItems('');
    });
  };

  sortItemsAscending = () => {
    this.setState({ sortOrder: 'asc' }, () => {
      this.loadMediaItems('');
    });
  };

  sortMediaItems = (sortBy: SortBy) => {
    this.setState(
      (prevState) => {
        if (sortBy === prevState.sortBy) {
          const sortOrder = prevState.sortOrder === 'desc' ? 'asc' : 'desc';

          if (sortBy === 'name') {
            return {
              sortBy,
              sortOrder,
              iconSortName:
                sortOrder === 'asc'
                  ? 'glyphicon glyphicon-sort-by-alphabet'
                  : 'glyphicon glyphicon-sort-by-alphabet-alt',
            } as any;
          }

          if (sortBy === 'lastModified') {
            return {
              sortBy,
              sortOrder,
              iconSortLastModified:
                sortOrder === 'asc'
                  ? 'glyphicon glyphicon-sort-by-attributes'
                  : 'glyphicon glyphicon-sort-by-attributes-alt',
            } as any;
          }
        } else {
          return {
            sortBy,
            sortOrder: 'asc',
            iconSortName:
              sortBy === 'name'
                ? 'glyphicon glyphicon-sort-by-alphabet'
                : 'glyphicon glyphicon-sort',
            iconSortLastModified:
              sortBy === 'lastModified'
                ? 'glyphicon glyphicon-sort-by-attributes'
                : 'glyphicon glyphicon-sort',
          } as any;
        }

        return {} as any;
      },
      () => {
        this.loadMediaItems('');
      }
    );
  };

  loadMediaItems = (search?: string) => {
    const filter = this.props.match.params.filter;
    const searchQuery = !!search ? `&search=${search}` : '';
    const filterQuery = !!filter ? `&filter=${filter}` : '';

    this.setState({ isFetching: true });
    axios
      .get(
        `/api/mediaitems/account/${window['userState'].accountId}/${this.state.currentPage}/${this.state.itemsPerPage}?sortBy=${this.state.sortBy}&sortOrder=${this.state.sortOrder}${searchQuery}${filterQuery}`
      )
      .then((r) => {
        this.setState({
          isFetching: false,
          mediaItems: r.data.items,
          pageCount: r.data.pageCount,
          total: r.data.total,
        });
      })
      .catch((e) => {
        this.props.addDangerNotification(
          'Unable to retrieve list of media items'
        );
        this.setState({
          isFetching: false,
        });
      });
  };

  handleClickCreatePres = () => {
    this.setState({
      errors: {
        name: '',
        orientation: '',
      },
    });
    const data: any = {
      name: this.state.presInfo.presName.trim(),
      orientation: this.state.presInfo.orientation,
      accountId: window['userState'].accountId,
    };

    if (!data.name) {
      this.setState({
        errors: {
          name: 'Name is required',
        },
      });
      return;
    }

    if (data.orientation === '') {
      this.setState({
        errors: {
          orientation: 'Orientation is required',
        },
      });
      return;
    }

    if (data.orientation === 'landscape') {
      data.width = 1920;
      data.height = 1080;
    } else if (data.orientation === 'portrait') {
      data.width = 1080;
      data.height = 1920;
    }

    axios
      .post('/api/presentations', data)
      .then((res) => {
        // TODO FIX this shit
        $('#newPresentation').modal('hide');
        this.setState({ redirectTo: `/digitalsignage/builder/${res.data}` });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangePres = (e: any) => {
    const oldPresInfo = this.state.presInfo;
    const propName = e.target.name;
    const propVal = e.target.value;

    switch (propName) {
      case 'pres-name':
        oldPresInfo.presName = propVal;
        break;
      case 'pres-width':
        oldPresInfo.presWidth = propVal;
        break;
      case 'pres-height':
        oldPresInfo.presHeight = propVal;
        break;
      case 'pres-orientation':
        oldPresInfo.orientation = propVal;
        break;
    }
    this.setState({
      presInfo: oldPresInfo,
    });
  };

  confirmDeleteMediaItem = (mediaItem) => {
    const mediaItemPlaylists =
      this.state.itemsPlaylist.length > 0
        ? `Playlists:\n ${this.state.itemsPlaylist.map((item) => item)}`
        : '';
    if (
      confirm(
        `Are you sure you want to delete media item ${mediaItem.name}?\n ${mediaItemPlaylists}`
      )
    ) {
      axios
        .delete(`/api/mediaitems/${mediaItem.id}`)
        .then((r) => {
          this.props.addSuccessNotification('Media item deleted!');
          this.loadMediaItems('');
        })
        .catch((e) => {
          this.props.addDangerNotification('Unable to delete media item!');
        });
    }
  };

  handleDeleteMediaItem = (mediaItem) => {
    this.setState({ itemsPlaylist: [] });
    mediaItem.used > 0
      ? axios.get(`/api/playlists/media/${mediaItem.id}`).then((r) => {
          this.setState({ itemsPlaylist: r.data.map((s) => s.name) }, () => {
            this.confirmDeleteMediaItem(mediaItem);
          });
        })
      : this.setState({ itemsPlaylist: [] }, () => {
          this.confirmDeleteMediaItem(mediaItem);
        });
  };

  handleVideoInfo = (mediaItem) => {
    axios
      .get(`/api/mediaitems/${mediaItem.id}/info`)
      .then((r) => {
        alert(r.data.info);
      })
      .catch((e) => {
        this.props.addDangerNotification('Unable to fetch video info');
      });
  };

  viewMediaItemPlaylists = (mediaItem) => {
    this.setState({ itemsPlaylist: [] });
    if (mediaItem.used > 0)
      axios.get(`/api/playlists/media/${mediaItem.id}`).then((r) => {
        this.setState({ itemsPlaylist: r.data.map((s) => s.name) }, () => {
          const mediaItemPlaylists =
            this.state.itemsPlaylist.length > 0
              ? `Playlists:\n ${this.state.itemsPlaylist.map((item) => item)}`
              : '';
          confirm(mediaItemPlaylists);
        });
      });
  };

  clearSchedule = (mediaItem: MediaItemListItem) => {
    mediaItemScheduleClear(mediaItem.id)
      .then(() => this.loadMediaItems(''))
      .catch(() => {
        this.props.addDangerNotification('Unable to remove deactivation date');
      });
  };

  setSchedule = (mediaItem: MediaItemListItem, type: string, date: number) => {
    const promise =
      type === 'activate'
        ? mediaItemScheduleActivate(mediaItem.id, date)
        : mediaItemScheduleDeactivate(mediaItem.id, date);
    return promise
      .then(() => {
        this.props.addSuccessNotification('Deactivation date set');
        this.loadMediaItems('');
      })
      .catch((e) => {
        this.props.addDangerNotification('Unable to set deactivation date');
        return Promise.reject(e);
      });
  };

  enableMediaItem = (mediaItem: MediaItemListItem) => {
    if (
      confirm('Are you sure you want to enable this item in all playlists?')
    ) {
      mediaItemScheduleActivate(mediaItem.id, 0)
        .then(() => {
          this.props.addSuccessNotification('Item activated');
          this.loadMediaItems('');
        })
        .catch((e) => {
          this.props.addDangerNotification('Unable to activate item!');
          return Promise.reject(e);
        });
    }
  };

  updateVideoMetadata = (mediaItem: MediaItemListItem) => {
    mediaItemUpdateVideoMetadata(mediaItem.id)
      .then(() => {
        this.props.addInfoNotification('Scheduled update...');
      })
      .catch((e) => {
        this.props.addDangerNotification('Unable to activate item!');
        return Promise.reject(e);
      });
  };

  disableMediaItem = (mediaItem: MediaItemListItem) => {
    if (
      confirm('Are you sure you want to disable this item in all playlists?')
    ) {
      mediaItemScheduleDeactivate(mediaItem.id, 0)
        .then(() => {
          this.props.addSuccessNotification('Item deactivated');
          this.loadMediaItems('');
        })
        .catch((e) => {
          this.props.addDangerNotification('Unable to deactivate item!');
          return Promise.reject(e);
        });
    }
  };

  addToPlaylists = async (mediaItemId: number, playlists: number[]) => {
    try {
      await addMediaItemToPlaylists({ mediaItemId, playlists });
      this.props.addSuccessNotification('Item added to all playlists');
      this.loadMediaItems(this.state.filterMedia || '');
    } catch (error) {
      this.props.addDangerNotification('Something went wrong!');
    }
  };

  handleVideoUpdate = (e, mediaItem) => {
    alert('not implemented yet');
  };

  uploadFile = (files, currentFile) => {
    if (currentFile === files.length) {
      this.setState((state: State) => ({
        isUploading: false,
        mediaUploaded: state.progress.filter((k) => k < 0).length === 0,
        mediaUploadErr: state.progress.filter((k) => k < 0).length !== 0,
      }));
      this.loadMediaItems('');
      setTimeout(() => {
        this.setState((state: State) => {
          return !state.isUploading
            ? { mediaUploaded: false, mediaUploadErr: false }
            : {};
        });
      }, 5000);
      return;
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState((state) => ({
          progress: state.progress.map((p, index) =>
            index === currentFile ? percentCompleted : p
          ),
        }));
      },
    };

    const data = new FormData();
    data.append('accountId', window['userState'].accountId);
    data.append('file', files[currentFile]);
    const name = files[currentFile].name;
    this.setState({ uploadFileName: name });
    axios
      .post('/api/mediaitems/upload', data, config)
      .then((r) => {
        this.props.addSuccessNotification(`File ${name} uploaded!`);
        this.setState((state) => ({
          progress: state.progress.map((p, index) =>
            index === currentFile ? 100 : p
          ),
        }));
        this.uploadFile(files, currentFile + 1);
      })
      .catch((e) => {
        console.dir(e);
        this.props.addDangerNotification(
          `Unable to upload file ${name}. Please refresh the page and try again.`
        );
        this.setState((state) => ({
          progress: state.progress.map((p, index) =>
            index === currentFile ? -p : p
          ),
        }));

        if (window['features'].dev) {
          debugger;
        }
      });
  };

  handleUpload = (files) => {
    console.log('Received files: ', files);

    this.setState({
      isUploading: true,
      progress: files.map((i) => 0),
      mediaUploaded: false,
      mediaUploadErr: false,
    });

    this.uploadFile(files, 0);
  };

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    let mediaItems: any[] = [];
    if (this.state.mediaItems && this.state.mediaItems.length !== 0) {
      mediaItems = this.state.mediaItems.map((mediaItem: any, index) => {
        return (
          <MediaItemRow
            key={mediaItem.id}
            mediaItem={mediaItem}
            search={this.state.filterMedia}
            onDelete={this.handleDeleteMediaItem}
            onVideoInfo={this.handleVideoInfo}
            onVideoUpdate={this.handleVideoInfo}
            onViewPlaylists={this.viewMediaItemPlaylists}
            onClearSchedule={this.clearSchedule}
            onSetSchedule={this.setSchedule}
            onEnable={this.enableMediaItem}
            onUpdateVideoMetadata={this.updateVideoMetadata}
            disableMediaItem={this.disableMediaItem}
            onAddToPlaylists={this.addToPlaylists}
          />
        );
      });
    }

    return (
      <div className="container gallery-container">
        <div className="gallery-top-options clearfix">
          <div className="col-xs-9">
            <div className="row">
              <div className="col-xs-6 col-lg-4">
                <div className="form-group has-feedback">
                  <input
                    type="text"
                    name="filterMedia"
                    onChange={this.hanleSearchResults}
                    className="form-control"
                    placeholder="Filter media"
                  />
                  <i className="glyphicon glyphicon-search form-control-feedback" />
                </div>
              </div>

              <div className="col-xs-12 col-lg-8">
                <Dropzone className="media-upload" onDrop={this.handleUpload}>
                  <div>
                    Try dropping some files here, or click to select files to
                    upload.
                  </div>
                </Dropzone>
              </div>
            </div>
          </div>

          <div className="col-xs-3">
            <div className="dropdown pull-right">
              <FeatureFlag feature="digitalsignage-newpresentation">
                <button
                  className="btn btn-fancy"
                  type="button"
                  data-toggle="modal"
                  data-target="#newPresentation"
                >
                  + New Presentation
                </button>
              </FeatureFlag>
            </div>
          </div>
          <div
            className={`col-xs-12 ${
              !this.state.isUploading &&
              !this.state.mediaUploaded &&
              !this.state.mediaUploadErr
                ? 'hide'
                : ''
            }`}
          >
            <div className="table-shadow" style={{ padding: '10px' }}>
              {this.state.isUploading ? (
                <div className="col-xs-8">
                  <br />
                  <span>Uploading file: {this.state.uploadFileName}</span>
                  <div className="progress">
                    {this.state.progress.map((p, index) => {
                      const width = 100 / this.state.progress.length;
                      if (p === 100) {
                        return (
                          <div
                            key={index}
                            className="progress-bar progress-bar-success"
                            title="hi"
                            style={{ width: `${width}%` }}
                          >
                            {p}%
                          </div>
                        );
                      }
                      if (p >= 0) {
                        return (
                          <div
                            key={index}
                            className="progress-bar progress-bar-info"
                            style={{ width: `${(width * p) / 100}%` }}
                          >
                            {p}%
                          </div>
                        );
                      }
                      if (p === 0) {
                        return <div key={index} />;
                      }

                      return (
                        <div
                          key={index}
                          className="progress-bar progress-bar-danger"
                          style={{ width: `${width}%` }}
                        >
                          {-p}%
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.state.isUploading ? (
                <div className="col-xs-4 pull-right">
                  <p style={mediaStyles.uploadProgress}>Uploading...</p>
                </div>
              ) : null}
              {this.state.mediaUploadErr ? (
                <div className="col-xs-4 pull-right">
                  <p style={mediaStyles.uploadFail}>
                    <span
                      className="glyphicon glyphicon-exclamation-sign"
                      aria-hidden="true"
                    />{' '}
                    Media upload error
                  </p>
                </div>
              ) : null}

              {this.state.mediaUploaded ? (
                <div className="col-xs-12">
                  <p style={mediaStyles.uploadSucces}>
                    <span
                      className="glyphicon glyphicon-ok"
                      aria-hidden="true"
                    />{' '}
                    Media uploaded
                  </p>
                </div>
              ) : null}

              <div className="clearfix" />
            </div>
          </div>
        </div>

        <div
          className="modal modal-fancy fade"
          id="newPresentation"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="newPresentationLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="newPresentationLabel">
                  Add new presentation
                </h4>
              </div>
              <div className="modal-body">
                <form>
                  <label className="control-label">Presentation name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Presentation name..."
                    name="pres-name"
                    value={this.state.presInfo.presName}
                    onChange={this.handleChangePres}
                  />
                  <div className="has-error">
                    <div className="help-block with-errors">
                      {this.state.errors.name}
                    </div>
                  </div>
                  <label className="control-label">Orientation</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Presentation width..."
                    name="pres-width"
                    value={this.state.presInfo.presWidth}
                    onChange={this.handleChangePres}
                    style={{ display: 'none' }}
                  />
                  <select
                    className="form-control"
                    name="pres-orientation"
                    onChange={this.handleChangePres}
                  >
                    <option value="">-- Choose orientation --</option>
                    <option value="landscape">Landscape</option>
                    <option value="portrait">Portrait</option>
                  </select>
                  <div className="has-error">
                    <div className="help-block with-errors">
                      {this.state.errors.orientation}
                    </div>
                  </div>

                  <label className="control-label" style={{ display: 'none' }}>
                    Height
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Presentation height..."
                    name="pres-height"
                    value={this.state.presInfo.presHeight}
                    onChange={this.handleChangePres}
                    style={{ display: 'none' }}
                  />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-fancy"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-fancy"
                  onClick={this.handleClickCreatePres}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12">
          <table className="table table-shadow">
            <thead>
              <tr>
                <th />
                <th>Preview</th>
                <th className="pb-0">
                  Name
                  <a
                    className="btn"
                    onClick={() => this.sortMediaItems('name')}
                  >
                    <span className={this.state.iconSortName} />
                  </a>
                </th>
                <th>Type</th>
                <th>Dimensions</th>
                <th>Size</th>
                <th className="pb-0">
                  Last Modified
                  <a
                    className="btn"
                    onClick={() => this.sortMediaItems('lastModified')}
                  >
                    <span className={this.state.iconSortLastModified} />
                  </a>
                </th>
                <th>Used</th>
                <th />
              </tr>
            </thead>
            <tbody>{mediaItems}</tbody>

            <tfoot>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td className="pt-16">Rows per page:</td>
                <td className="pt-16">
                  <select
                    name="itemsPerPage"
                    onChange={this.handleItemsPerPage}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option selected value={50}>
                      50
                    </option>
                  </select>
                </td>
                <td className="pt-16">
                  {this.state.currentPage > 1
                    ? this.state.itemsPerPage * (this.state.currentPage - 1)
                    : 1}
                  -
                  {this.state.itemsPerPage * this.state.currentPage <
                  this.state.total
                    ? this.state.itemsPerPage * this.state.currentPage
                    : this.state.total}{' '}
                  of {this.state.total}
                </td>
                {this.state.pageCount > 1 ? (
                  <td>
                    <a className="btn" onClick={this.handlePrevPage}>
                      <span className="glyphicon glyphicon-chevron-left" />
                    </a>
                    <a className="btn" onClick={this.handleNextPage}>
                      <span className="glyphicon glyphicon-chevron-right" />
                    </a>
                  </td>
                ) : (
                  <td />
                )}
              </tr>
            </tfoot>
          </table>
        </div>

        {this.state.isFetching ? <Loading /> : null}
      </div>
    );
  }
}

const mediaStyles = {
  uploadSucces: {
    color: '#3c763d',
    backgroundColor: '#dff0d8',
    border: '1px solid #d6e9c6',
    padding: '10px',
    margin: '0',
    textAlign: 'center' as 'center',
  },
  uploadFail: {
    color: '#a94442',
    backgroundColor: '#f2dede',
    border: '1px solid #ebccd1',
    padding: '10px',
    margin: '0',
    textAlign: 'center' as 'center',
  },
  uploadProgress: {
    color: '#31708f',
    backgroundColor: '#d9edf7',
    border: '1px solid #bce8f1',
    padding: '10px',
    margin: '0',
    textAlign: 'center' as 'center',
  },
};

export default connect(null, {
  addInfoNotification,
  addDangerNotification,
  addSuccessNotification,
})(MediaItems);
