import React, { FunctionComponent, useState, useEffect } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';

import {
  SimpleModal,
  Content,
  ModalProps,
  Header,
  Footer,
  Loading,
  LoadingOverlay,
} from 'Components/shared';
import { Button, Label } from 'ui';
import { useFetch } from 'utilities';
import { AppDTO, AppPageForCloneDto } from 'api';

type Props = {
  appId: number;
  appType: number;
  onLink: (pageId: number, name: string) => Promise<any>;
} & ModalProps;

const LinkPageDialog: FunctionComponent<Props> = ({
  appId,
  appType,
  onLink,
  show,
  onClose,
}) => {
  const [selectedApp, setSelectedApp] = useState<ValueType<any>>(null);
  const [selectedPage, setSelectedPage] = useState<ValueType<any>>(null);
  const [apps, setApps] = useState<AppDTO[]>([]);
  const [pages, setPages] = useState<AppPageForCloneDto[]>([]);
  const [isWorking, setIsWorking] = useState(false);

  useFetch(`/api/apps/version/2`, data => {
    setApps(data.filter(app => app.id !== appId && app.appType === appType));
  });

  useFetch(
    selectedApp ? `/api/apps/${selectedApp.value}/pagesforlink` : '',
    setPages
  );

  function link() {
    setIsWorking(true);
    onLink(selectedPage.value, selectedPage.label)
      .then(() => {
        onClose();
        setIsWorking(false);
      })
      .catch(() => {
        setIsWorking(false);
      });
  }

  return (
    <SimpleModal show={show} hide={onClose}>
      <Header>Link existing page</Header>
      <Content>
        <Label>Choose an app:</Label>
        <Select
          value={selectedApp}
          onChange={value => setSelectedApp(value)}
          options={apps.map(app => ({
            value: app.id,
            label: `${app.name} (${app.accountName || ''})`,
          }))}
          autoFocus
        />

        {!!selectedApp && (
          <>
            <Label>Choose a page:</Label>
            <Select
              value={selectedPage}
              onChange={value => setSelectedPage(value)}
              options={pages.map(page => ({
                value: page.id,
                label: page.name,
              }))}
              autoFocus
            />
          </>
        )}

        {isWorking && <LoadingOverlay />}
      </Content>
      <Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="primary"
          onClick={link}
          disabled={!selectedPage || !selectedPage.value || isWorking}
        >
          Link
        </Button>
      </Footer>
    </SimpleModal>
  );
};

export default LinkPageDialog;
