import React from 'react';
import { Link } from 'react-router-dom';

import { forgotPassword } from 'api';
import { StateComponent, Loading } from 'Components/shared';
import { Container, Label, Input, Button } from 'ui';

import { formatErrors } from 'utilities';
import EmguestLogo from './emguest-logo';

import './forgot-password-page.css';

class State {
  static NoErrors = {
    '': '',
    email: '',
  };

  isWorking = false;
  isDone = false;

  email = '';

  errors = State.NoErrors;
}

export default class ForgotPassword extends StateComponent<{}, State> {
  state = new State();

  render() {
    return (
      <Container>
        <div className="forgot-password-page shadow-md rounded bg-gray-100">
          <EmguestLogo />

          <h3>Forgot password</h3>

          {this.state.isWorking ? (
            <div style={{ height: '100px' }}>
              <Loading />
            </div>
          ) : this.state.isDone ? (
            <span className="help-block">
              An email containing reset password link has been sent.
            </span>
          ) : (
            <>
              <Label state={this.state.errors.email ? 'error' : undefined}>
                Email
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={this.set}
                />
                <span className="help-block">{this.state.errors.email}</span>
              </Label>

              <Label state={this.state.errors[''] ? 'error' : undefined}>
                <span className="help-block">{this.state.errors['']}</span>
              </Label>

              <Button onClick={this.forgotPassword}>Reset password</Button>
            </>
          )}
          <hr />

          <Link to="/account/login">Back to login</Link>
        </div>
      </Container>
    );
  }

  forgotPassword = () => {
    this.setState({ isDone: false, errors: State.NoErrors });

    if (!this.state.email) {
      this.setState(s => ({
        errors: { ...s.errors, email: 'Must enter email' },
      }));
      return;
    }

    this.setState({ isWorking: true });

    forgotPassword(this.state.email)
      .then(r => this.setState({ isWorking: false, isDone: true, email: '' }))
      .catch(e =>
        this.setState(s => ({
          isWorking: false,
          errors: {
            ...s.errors,
            ...formatErrors(s.errors, e.response.data),
          },
        }))
      );
  };
}
