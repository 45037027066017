import { DeviceLogLevel } from 'api';

export function formatDeviceLogLevel(level: DeviceLogLevel) {
  switch (level) {
    case DeviceLogLevel.Information:
      return 'info';
    case DeviceLogLevel.Warning:
      return 'warning';
    case DeviceLogLevel.Error:
      return 'error';
    case DeviceLogLevel.Critical:
      return 'critical';
  }

  return '';
}
