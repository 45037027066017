import * as React from 'react';

import { StateComponent } from 'Components/shared';

import { BlockEditorProps } from './common';
import { MapBlock, BlockType } from '../contentHelpers';
import { Label, Input } from 'ui';

type Props = BlockEditorProps<MapBlock>;
class State {
  lat = 0;
  lng = 0;
  radius = 0;
  label = '';
}

export class Map extends StateComponent<Props, State> {
  state = new State();

  mapContainer?: any;
  map?: google.maps.Map;

  render() {
    return (
      <>
        <Label>
          Latitude
          <Input
            type="number"
            name="lat"
            value={this.state.lat}
            onChange={this.set}
            onBlur={this.update}
          />
        </Label>

        <Label>
          Longitude
          <Input
            type="number"
            name="lng"
            value={this.state.lng}
            onChange={this.set}
            onBlur={this.update}
          />
        </Label>

        <Label>
          Radius
          <Input
            type="number"
            name="radius"
            value={this.state.radius}
            onChange={this.set}
            onBlur={this.update}
          />
        </Label>

        <Label>
          Point name
          <Input
            name="label"
            value={this.state.label}
            onChange={this.set}
            onBlur={this.update}
          />
        </Label>

        <a
          href="#mapBlockModal"
          role="button"
          className="btn btn-large btn-primary"
          data-toggle="modal"
        >
          Choose on map
        </a>

        <div id="mapBlockModal" className="modal fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4 className="modal-title">Select location</h4>
              </div>
              <div className="modal-body">
                <div
                  ref={(m) => (this.mapContainer = m)}
                  style={{ height: '300px' }}
                >
                  Map
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.update}
                >
                  Set location
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    const { lat, lng, radius, label } = this.props.data;
    this.setState({ lat, lng, radius, label });

    if (this.mapContainer) {
      this.map = new google.maps.Map(this.mapContainer, {
        center: { lat: 45.8150108, lng: 15.981918899999982 },
        zoom: 8,
      });

      const marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        draggable: true,
      });

      google.maps.event.addListener(this.map, 'click', (e) => {
        marker.setPosition(e.latLng);
        const lat = marker.getPosition().lat();
        const lng = marker.getPosition().lng();
        this.updateState(lat, lng);
      });
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.data !== nextProps.data) {
      const { lat, lng, radius, label } = nextProps.data;
      this.setState({ lat, lng, radius, label });
    }
  }

  updateState = (lat, lng) => {
    this.setState(
      {
        lat,
        lng,
      },
      () => {
        this.props.handleUpdate({ ...this.props.data, ...this.state });
      }
    );
  };

  update = () => {
    $('#mapBlockModal').modal('hide');
    this.props.handleUpdate({ ...this.props.data, ...this.state });
  };
}
