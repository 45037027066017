const initialAccountState = {
  isLoading: false,
  data: null,

  isSaving: false,
};

const accountReducer = (state = initialAccountState, action) => {
  switch (action.type) {
    case 'ACCOUNT_LOAD':
      return { ...state, isLoading: true };
    case 'ACCOUNT_LOAD_SUCCESS':
      return { ...state, isLoading: false, data: action.payload.data };
    case 'ACCOUNT_LOAD_FAIL':
      return { ...state, isLoading: false };

    case 'ACCOUNT_EDIT_START':
      return { ...state, isSaving: true };
    case 'ACCOUNT_EDIT_SUCCESS':
      return { ...state, isSaving: false };
    case 'ACCOUNT_EDIT_ERROR':
      return { ...state, isSaving: false };
  }

  return state;
};

export default accountReducer;
