import React from 'react';
import { UserDto, AppDto } from 'api';

type Props = {
  user: UserDto;
  onRemoveUser: (e: any) => any;
};

export default function AppUser({ user, onRemoveUser }: Props) {
  return (
    <span key={user.id} className="user">
      {user.name}

      <i
        className="glyphicon glyphicon-trash"
        style={{ color: 'red' }}
        onClick={onRemoveUser}
      />
    </span>
  );
}
