import React, { useState } from 'react';

import { GalleryImageDto, BaseListDTO } from 'api';
import { Table, Loading } from 'Components/shared';
import { Container, Pagination } from 'ui';
import { useFetch } from 'utilities';
import formatByteSize from '../utilities/formatByteSize';

export default function GuestDirectoryMediaItemsPage() {
  const [page, setPage] = useState(1);
  const [images, setImages] = useState<
    BaseListDTO<GalleryImageDto> | undefined
  >(undefined);
  useFetch(`/api/images/gallery/${page}/20`, setImages);

  if (!images) {
    return <Loading />;
  }

  return (
    <Container>
      <h1>Media items</h1>

      <Table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Size</th>
          </tr>
        </thead>
        <tbody>
          {images.items.map(image => (
            <tr key={image.id}>
              <td>
                <img src={image.thumbUri} />
              </td>
              <td>{image.name}</td>
              <td>{formatByteSize(image.size)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        onPage={page => setPage(page)}
        page={images.page}
        pageCount={images.pageCount}
      />
    </Container>
  );
}
