import * as React from 'react';
import { connect } from 'react-redux';

import { BlockType, TitleBlock, TextBlock } from './contentHelpers';

import '../../css/page-preview.css';

interface Props {
  page: any;
}

const PhoneFrame: React.SFC<{}> = props => (
  <div className="pca-hold">
      
    <div className="pca-main">
          <div className="pca-inner">{props.children}</div>
    </div>
      
    <div className="pca-sub">
          
      <div className="pca-top" />
          
      <div className="pca-mid">
              
        <div className="pca-part" />
      </div>
          
      <div className="pca-bot" />
    </div>
  </div>
);

class PagePreview extends React.Component<Props, {}> {
  render() {
    let { page } = this.props;

    return (
      <div className="page-preview">
        <h4>Preview (BETA)</h4>

        {page && page.content ? (
          <>
            <PhoneFrame>
              {page.content.map((block, index) => {
                switch (block.type as BlockType) {
                  case BlockType.title:
                    const titleBlock = block as TitleBlock;
                    return (
                      <p key={index} className="preview-title">
                        {titleBlock.text[0].text}
                      </p>
                    );

                  case BlockType.text:
                    const textBlock = block as TextBlock;
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: textBlock.text[0].text.replace(
                            /(<? *script)/gi,
                            'illegalscript'
                          ),
                        }}
                      />
                    );

                  default:
                    return (
                      <p key={index} data-type={block.type}>
                        {block.type}
                      </p>
                    );
                }
              })}
            </PhoneFrame>
          </>
        ) : null}
      </div>
    );
  }
}

export default connect(state => ({
  page: state.Page,
  theme: state.App.theme,
  templates: state.App.templates,
  languages:
    state.App.data && state.App.data.languages
      ? state.App.data.languages.split(',')
      : [],
}))(PagePreview);
