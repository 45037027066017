import React, { Component } from 'react';

const animations = {
  entry: [
    { name: 'No animation', alias: 'none', properties: [] },
    { name: 'Appear', alias: 'appear', properties: ['delay'] },
    {
      name: 'Slide In',
      alias: 'slide-in',
      properties: ['delay', 'duration', 'distance', 'direction', 'easing'],
    },
    {
      name: 'Fade In',
      alias: 'fade-in',
      properties: ['delay', 'duration', 'easing'],
    },
  ],
  exit: [
    { name: 'No animation', alias: 'none', properties: [] },
    { name: 'Disappear', alias: 'disappear', properties: ['delay'] },
    {
      name: 'Slide Out',
      alias: 'slide-out',
      properties: ['delay', 'duration', 'distance', 'direction', 'easing'],
    },
    {
      name: 'Fade Out',
      alias: 'fade-out',
      properties: ['delay', 'duration', 'easing'],
    },
  ],
  directions: [
    { name: 'none', alias: 'none' },
    { name: 'Left', alias: 'left' },
    { name: 'Right', alias: 'right' },
    { name: 'Top', alias: 'top' },
    { name: 'Bottom', alias: 'bottom' },
  ],
  easings: [
    { name: 'none', alias: 'none' },
    { name: 'Sine in', alias: 'sine-in' },
    { name: 'Sine out', alias: 'sine-out' },
    { name: 'Sine in-out', alias: 'sine-in-out' },
  ],
};

const Input = ({
  isVisible = true,
  label,
  name,
  index,
  value,
  changeAnimations,
}) => {
  return (
    <div className={'col-md-4 ' + (isVisible ? 'show' : 'hide')}>
      <label className="control-label">{label}</label>
      <input
        type="number"
        className="form-control"
        name={name}
        data-index={index}
        min="0"
        value={value}
        onChange={changeAnimations}
      />
    </div>
  );
};

const Select = ({
  isVisible = true,
  options,
  label,
  name,
  index,
  value,
  changeAnimations,
}) => {
  return (
    <div className={'col-md-4 ' + (isVisible ? 'show' : 'hide')}>
      <label className="control-label">{label}</label>
      <select
        className="form-control"
        data-index={index}
        name={name}
        value={value}
        onChange={changeAnimations}
      >
        {options.map((option, index) => (
          <option key={index} value={option.alias}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

function contains(arr, el) {
  for (var i = 0; i < arr.length; ++i) {
    if (arr[i] === el) return true;
  }

  return false;
}

class AnimationEditor extends Component<any, any> {
  render() {
    let { object } = this.props;

    let entry = object.get('animations').entry;
    let exit = object.get('animations').exit;
    let index = object.getZIndex();
    let changeAnimations = this.props.changeAnimations;

    let entryProps: any[] = [];
    for (var i = 0; i < animations.entry.length; ++i) {
      if (animations.entry[i].alias === entry.name) {
        entryProps = animations.entry[i].properties;
        break;
      }
    }

    let exitProps: any[] = [];
    for (var i = 0; i < animations.exit.length; ++i) {
      if (animations.exit[i].alias === exit.name) {
        exitProps = animations.exit[i].properties;
        break;
      }
    }

    return (
      <div
        className="modal fade"
        id={`timelineModal-${this.props.index}`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="timelineModal"
      >
        <div className="modal-dialog modal-fancy" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id="timelineModalLabel">
                Object options
              </h4>
            </div>
            <div className="modal-body clearfix">
              <div className="col-md-12">
                <label className="control-label">Entry animation</label>
                <select
                  className="form-control"
                  data-index={index}
                  name="entry-name"
                  value={entry.name}
                  onChange={changeAnimations}
                >
                  {animations.entry.map((animation, index) => (
                    <option key={index} value={animation.alias}>
                      {animation.name}
                    </option>
                  ))}
                </select>
              </div>

              <Input
                isVisible={contains(entryProps, 'delay')}
                label="Delay"
                name="entry-delay"
                value={entry.delay}
                index={index}
                changeAnimations={changeAnimations}
              />
              <Input
                isVisible={contains(entryProps, 'duration')}
                label="Duration"
                name="entry-duration"
                value={entry.duration}
                index={index}
                changeAnimations={changeAnimations}
              />
              <Input
                isVisible={contains(entryProps, 'distance')}
                label="Distance"
                name="entry-properties-distance"
                value={entry.properties.distance}
                index={index}
                changeAnimations={changeAnimations}
              />
              <Select
                isVisible={contains(entryProps, 'direction')}
                label="Direction"
                name="entry-properties-direction"
                options={animations.directions}
                value={entry.properties.direction}
                index={index}
                changeAnimations={changeAnimations}
              />
              <Select
                isVisible={contains(entryProps, 'easing')}
                label="Easing"
                name="entry-properties-easing"
                options={animations.easings}
                value={entry.properties.easing}
                index={index}
                changeAnimations={changeAnimations}
              />

              <hr className="col-md-12" />

              <div className="col-md-12">
                <label className="control-label">Exit animation</label>
                <select
                  className="form-control"
                  data-index={index}
                  name="exit-name"
                  value={exit.name}
                  onChange={changeAnimations}
                >
                  {animations.exit.map((animation, index) => (
                    <option key={index} value={animation.alias}>
                      {animation.name}
                    </option>
                  ))}
                </select>
              </div>

              <Input
                isVisible={contains(exitProps, 'delay')}
                label="Delay"
                name="exit-delay"
                value={exit.delay}
                index={index}
                changeAnimations={changeAnimations}
              />
              <Input
                isVisible={contains(exitProps, 'duration')}
                label="Duration"
                name="exit-duration"
                value={exit.duration}
                index={index}
                changeAnimations={changeAnimations}
              />
              <Input
                isVisible={contains(exitProps, 'distance')}
                label="Distance"
                name="exit-properties-distance"
                value={exit.properties.distance}
                index={index}
                changeAnimations={changeAnimations}
              />
              <Select
                isVisible={contains(exitProps, 'direction')}
                label="Direction"
                name="exit-properties-direction"
                options={animations.directions}
                value={exit.properties.direction}
                index={index}
                changeAnimations={changeAnimations}
              />
              <Select
                isVisible={contains(exitProps, 'easing')}
                label="Easing"
                name="exit-properties-easing"
                options={animations.easings}
                value={exit.properties.easing}
                index={index}
                changeAnimations={changeAnimations}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnimationEditor;
