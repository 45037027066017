import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalBase from '../FormHelpers/ModalBase';

class PageTemplateSelectorModal extends ModalBase {
  constructor(props) {
    super(props, ['template']);

    this.validate = this.validate.bind(this);
  }

  validate() {
    return {
      _all: this.state.data.template ? '' : 'Must select template!',
    };
  }

  componentDidMount() {
    const template = this.props.templates.find(
      t => t.alias === this.props.currentTemplateAlias
    );
    if (template) {
      this.set('template', template);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentTemplateAlias !== this.props.currentTemplateAlias) {
      const template = nextProps.templates.find(
        t => t.alias === nextProps.currentTemplateAlias
      );
      if (template) {
        this.set('template', template);
      }
    }
  }

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Select template
              </h4>
            </div>
            <div className="modal-body">
              <form id={this.props.name + 'Form'} className="template-selector">
                {this.props.templates
                  //.filter(template => template.alias !== 'page-home')
                  .map((template, index) => {
                    const isSelected =
                      this.state.data.template === template ? 'selected' : '';
                    return (
                      <div
                        key={index}
                        className={`template ${isSelected}`}
                        onClick={e => this.set('template', template)}
                      >
                        {template.name}
                      </div>
                    );
                  })}

                <div className="has-error">
                  <div className="help-block with-errors">
                    {this.state.errors._all}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-fancy"
                onClick={this.handleCreate}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    templates: state.App.templates,
  };
})(PageTemplateSelectorModal);
