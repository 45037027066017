import React, { PureComponent } from 'react';
import moment from 'moment';

import { getGuestDirectoryDevices, GuestDirectoryDeviceDto } from 'api';
import { Container } from 'ui';
import { withFetch, formatAppPlatforms } from 'utilities';
import { Table } from 'Components/shared';

class GuestDirectoryDevices extends PureComponent<
  {
    devices: GuestDirectoryDeviceDto[];
  },
  {}
> {
  render() {
    return (
      <Container>
        <h1>Guest Directory Devices</h1>

        <Table>
          <thead>
            <tr>
              <th>Device Id</th>
              <th>Platform</th>
              <th>Last ping</th>
              <th>App</th>
            </tr>
          </thead>
          <tbody>
            {this.props.devices.map(device => (
              <tr>
                <td>{device.deviceId}</td>
                <td>{formatAppPlatforms(device.platform)}</td>
                <td>{moment(device.lastPingedOn).fromNow()}</td>
                <td>{device.appName}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }
}

export default withFetch(async () => {
  return {
    devices: await getGuestDirectoryDevices().then(r => r.data),
  };
})(GuestDirectoryDevices);
