import React, { useState } from 'react';

import { SimpleModal, Header, Content, Footer } from '../shared/SimpleModal';
import { Label, Input, Button } from 'ui';
import { Loading } from '../shared/Loading';

type Props = {
  name: string;
  onHide: () => any;
  show: boolean;
  onRename: (name: string) => Promise<any>;
};

export function RenamePlaylistDialog(props: Props) {
  const { onHide, show } = props;
  const [name, setName] = useState(props.name);
  const [working, setIsWorking] = useState(false);

  async function save() {
    setIsWorking(true);
    try {
      await props.onRename(name);
    } catch (error) {}
    setIsWorking(false);
  }

  return (
    <SimpleModal hide={onHide} show={show}>
      <Header>Rename playlist {props.name}</Header>
      <Content>
        <Label>
          Name
          <Input
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Label>
        {working && <Loading />}
      </Content>
      <Footer>
        <Button type="default" onClick={onHide}>
          Cancel
        </Button>
        <Button type="primary" onClick={save}>
          Save
        </Button>
      </Footer>
    </SimpleModal>
  );
}
