import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { isoLangs } from '../../../languages';

class ChildrenTranslation extends Component<any, any> {
  state: any = {
    // every editable text is in the form
    // {pageid: 1, lang: 'en', text: 'Home'}
    text: [],
  };

  componentDidMount() {
    if (this.props.subpages && this.props.languages) this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.subpages !== nextProps.subpages ||
      this.props.languages !== nextProps.languages
    )
      this.init(nextProps);
  }

  init = props => {
    let { subpages, languages } = props;
    if (!subpages || !languages) return;

    let text: any[] = [];
    for (var i = 0; i < subpages.length; ++i) {
      let subpage = subpages[i];
      let title = subpage.title;

      // added when we switched from string to array for localization
      if (title.constructor !== Array) title = [];

      for (var j = 0; j < languages.length; ++j) {
        let lang = languages[j];
        let page = props.pages
          ? props.pages.find(p => p.id === subpage.id)
          : null;
        let val = page ? page.name : null;

        for (var k = 0; k < title.length; ++k) {
          if (title[k].lang === lang && title[k].text !== null) {
            val = title[k].text;
          }
        }

        text.push({
          pageid: subpage.id,
          lang: lang,
          text: val,
          original: page ? page.name : null,
        });
      }
    }

    this.setState({ text: text });
  };

  handleChange = e => {
    let index = Number(e.target.name);
    let text = this.state.text;
    this.setState({
      text: text
        .slice(0, index)
        .concat(
          Object.assign({}, text[index], {
            text: e.target.value,
          })
        )
        .concat(text.slice(index + 1)),
    });
  };

  handleUpdate = () => {
    this.props.setSubpages(
      this.props.subpages.map((subpage, index) => {
        return Object.assign({}, subpage, {
          title: this.state.text.filter(t => t.pageid === subpage.id),
        });
      })
    );
  };

  render() {
    if (this.state.text.length === 0) return null;

    let subpages = null;

    subpages = this.state.text.map((t, index) => {
      let code = t.lang;
      let language = isoLangs.hasOwnProperty(code) ? isoLangs[code] : null;

      return (
        <tr key={index}>
          {index % this.props.languages.length === 0 ? (
            <td rowSpan={this.props.languages.length}>
              <Link to={`/app/${this.props.match.params.id}/page/${t.pageid}`}>
                {t.pageid}
              </Link>
            </td>
          ) : null}
          <td>{language ? language.name : t.lang}</td>
          <td>
            <input
              type="text"
              className={
                'form-control ' + (t.original === t.text ? 'unchanged' : '')
              }
              value={t.text}
              name={index}
              onChange={this.handleChange}
              onBlur={this.handleUpdate}
              onFocus={e => (e.target as any).select()}
            />
          </td>
        </tr>
      );
    });

    return (
      <div className="edit-block">
        <h4 className="edit-block-title">
          {this.props.page.page.level === 0 ? 'picker pages' : 'Subpages'}
        </h4>
        <table className="table table-shadow">
          <thead>
            <tr>
              <th>Id</th>
              <th>Language</th>
              <th>Text</th>
            </tr>
          </thead>
          <tbody>{subpages}</tbody>
        </table>
      </div>
    );
  }
}

export default connect(state => {
  return {
    subpages: state.Page.subpages,
    pages: state.App.pages,
  };
})(ChildrenTranslation);
