export let MediaItemType = {
  Unknown: 0,
  Presentation: 1,
  Image: 2,
  Video: 3,
};

export let DevicePlatform = {
  Unknown: 0,
  WPF: 1,
  Android: 2,
  UWP: 3,
  Html: 4,
};

export let Transitions = {
  None: 0,
  Slide: 1,
};

export let AppType = {
  Unknown: 0,
  GuestDirectory: 1,
  Mobile: 2,
};

export let AppPlatforms = {
  None: 0,
  iPhone: 1,
  iPad: 2,
  Android: 4,
  AndroidTablet: 8,
  HTML5: 16,
  UWP: 32,
};

export let BackgroundType = {
  Portrait_GuestDirectory: 0,
  Landscape_GuestDirectory: 1,
  Portrait_Mobile: 2,
};

export const mediaItemTypeString = type => {
  switch (Number(type)) {
    case MediaItemType.Presentation:
      return 'Presentation';
    case MediaItemType.Image:
      return 'Image';
    case MediaItemType.Video:
      return 'Video';

    default:
      return 'Unknown';
  }
};

export const formatMediaType = type => {
  switch (type) {
    case MediaItemType.Video:
      return 'Video';
    case MediaItemType.Presentation:
      return 'Presentation';
    case MediaItemType.Image:
      return 'Image';
  }

  return 'unknown';
};

export const formatAppType = appType => {
  switch (appType) {
    case AppType.GuestDirectory:
      return 'Guest Directory';

    case AppType.Mobile:
      return 'Mobile';
  }

  return 'unknown';
};

export const formatPlatforms = platforms => {
  let p: string[] = [];
  if ((platforms & AppPlatforms.iPhone) !== 0) p.push('iPhone');
  if ((platforms & AppPlatforms.Android) !== 0) p.push('Android');
  if ((platforms & AppPlatforms.iPad) !== 0) p.push('iPad');

  if (p.length === 0) return 'none';
  else if (p.length === 1) return p[0];

  return p.reduce((prev, curr) => prev + ', ' + curr, '').substr(2);
};

export let FormRequestState = {
  Sent: 0,
  Acknowledged: 1,
  Completed: 2,
};

export const formatFormRequestState = state => {
  switch (state) {
    case FormRequestState.Sent:
      return 'Sent';
    case FormRequestState.Acknowledged:
      return 'Acknowledged';
    case FormRequestState.Completed:
      return 'Completed';
  }

  return state;
};

export let AccountUserRole = {
  Admin: 0,
  User: 1,
  Device: 2,
};

export const formatAccountUserRole = role => {
  switch (role) {
    case AccountUserRole.Admin:
      return 'Admin';

    case AccountUserRole.User:
      return 'User';

    case AccountUserRole.Device:
      return 'Device';
  }

  return role;
};

export enum PlaylistOrientation {
  Landscape = 0,
  Portrait = 1,

  None = 128,
}

export const formatPlaylistOrientation = orientation => {
  switch (orientation) {
    case PlaylistOrientation.Landscape:
      return 'Landscape';

    case PlaylistOrientation.Portrait:
      return 'Portrait';
  }

  return orientation;
};

export let PlaylistTransitions = {
  None: 0,
  SlideLeft: 1,
};
