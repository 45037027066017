import React, { useState } from 'react';
import { css, cx } from 'emotion';

import {
  ModalProps,
  Header,
  SimpleModal,
  Footer,
  Content,
} from 'Components/shared';
import { Button, Label, Input } from 'ui';

type Props = ModalProps & {
  onAddUser: (email: string, password: string) => any;
};

function isValidEmail(email: string) {
  return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
}

export default function AddUserModal({ show, onClose, onAddUser }: Props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const canCreate = isValidEmail(email);

  const addUser = () =>
    onAddUser(email, password)
      .then(() => {
        setEmail('');
        setPassword('');
        setError('');
      })
      .catch(e => {
        if (e && e.response && e.response.data) {
          setError(e.response.data);
        } else {
          setError('An error ocurred');
        }
      });

  return (
    <SimpleModal show={show} hide={onClose}>
      <Header>Add user</Header>
      <Content>
        <Label>
          Email
          <Input value={email} onChange={e => setEmail(e.target.value)} />
        </Label>

        <Label>
          Password
          <Input value={password} onChange={e => setPassword(e.target.value)} />
        </Label>
        {error && (
          <p
            className={cx(
              'bg-danger',
              css`
                padding: 10px;
              `
            )}
          >
            {error}
          </p>
        )}
      </Content>
      <Footer>
        <Button onClick={onClose}>Close</Button>
        <Button type="primary" onClick={addUser} disabled={!canCreate}>
          Create
        </Button>
      </Footer>
    </SimpleModal>
  );
}
