import React from 'react';
import moment from 'moment';
import Loadable from 'react-loadable';

import { Container } from 'ui';
import { getStats } from 'api';
import { Loading } from 'Components/shared';

const Line = Loadable({
  loader: () => import(/* webpackChunkName: "line" */ 'react-chartjs-2'),
  loading: () => <Loading />,
  render(loaded, props) {
    const Component = loaded.Line;
    return <Component {...props} />;
  },
});

interface LineChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

class State {
  data: LineChartData = {
    labels: [],
    datasets: [
      {
        label: 'Active devices',
        data: [],
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(255,99,132,1)'],
        borderWidth: 1,
      },
    ],
  };
  dataLogs: LineChartData = {
    labels: [],
    datasets: [
      {
        label: 'Logs',
        data: [],
        backgroundColor: ['rgba(0, 99, 132, 0.2)'],
        borderColor: ['rgba(0, 99, 132, 0.2)'],
        borderWidth: 1,
      },
    ],
  };
}

export default class ScreensStats extends React.Component<{}, State> {
  state = new State();

  componentDidMount() {
    getStats(window['userState'].accountId).then((r) => {
      const data = { ...this.state.data };

      data.labels = r.data.map((x) => moment(x.date).format('DD.MM.'));
      data.datasets[0].data = r.data.map((x) => x.stats.length);

      const dataLogs = { ...this.state.dataLogs };
      dataLogs.labels = r.data.map((x) => moment(x.date).format('DD.MM.'));
      dataLogs.datasets[0].data = r.data.map((x) =>
        x.stats.reduce((prev, curr) => prev + curr.logs, 0)
      );

      this.setState({ data, dataLogs });
    });
  }
  render() {
    const { data, dataLogs } = this.state;

    return (
      <Container>
        <h2>Stats</h2>
        <Line data={data} />
        <Line data={dataLogs} />
      </Container>
    );
  }
}
