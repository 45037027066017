import * as React from 'react';

import { BlockEditorProps } from './common';
import { TitleBlock, YoutubeBlock, BlockType } from '../contentHelpers';
import { Label } from 'ui';

class State {
  url = '';
  text = '';
  validationError = '';
  videoPreview = '';
}

export class Youtube extends React.Component<
  BlockEditorProps<YoutubeBlock>,
  State
> {
  state = new State();

  render() {
    return (
      <>
        <Label htmlFor="text">
          Text (appearing on the button)
          <div className="input-group">
            <span className="input-group-addon" title="English">
              en
            </span>
            <input
              type="text"
              className="form-control"
              name="text"
              value={this.state.text}
              onChange={this.handleChange}
              onBlur={this.handleUpdate}
            />
          </div>
        </Label>

        <Label htmlFor="url">
          Youtube URL
          <input
            type="text"
            className="form-control"
            value={this.state.url}
            name="url"
            onChange={this.handleChange}
            onBlur={this.handleUpdate}
          />
        </Label>

        {this.state.url !== '' ? (
          this.state.validationError !== '' ? (
            <div className="alert alert-danger">
              <strong>Not valid!</strong>
            </div>
          ) : (
            <div className="alert alert-success">
              <strong>Valid!</strong>
              {/* {this.state.videoPreview ? (
                <iframe width="560" height="315" src={this.state.videoPreview} frameBorder={0} allow="autoplay; encrypted-media" allowFullScreen />
              ) : null} */}
            </div>
          )
        ) : null}
      </>
    );
  }

  componentDidMount() {
    this.setState({
      url: this.props.data.url,
      text:
        this.props.data.text && this.props.data.text.length > 0
          ? this.props.data.text[0].text
          : '',
    });
  }

  handleChange = (e: any) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      } as any,
      () => {
        this.validateYouTubeUrl();
        this.handleUpdate();
      }
    );
  };

  validateYouTubeUrl = () => {
    const url = this.state.url;
    if (url) {
      const regExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
      const match = url.match(regExp);
      if (match) {
        this.setState({
          validationError: '',
          videoPreview: `https://www.youtube.com/embed/${
            match[2]
          }?autoplay=1&enablejsapi=1`,
        });
      } else {
        this.setState({ validationError: 'Not valid' });
      }
    }
  };

  handleUpdate = () => {
    this.props.handleUpdate({
      ...this.props.data,
      url: this.state.url,
      text: [
        {
          lang: this.props.data.text[0].lang,
          text: this.state.text,
        },
      ].concat(this.props.data.text.slice(1)),
    });
  };
}
