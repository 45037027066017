import React, { Component } from 'react';

import { BlockEditorProps } from './common';
import { TitleBlock } from '../contentHelpers';

class State {
  text = '';
}

export class Title extends Component<BlockEditorProps<TitleBlock>, State> {
  state = new State();

  componentDidMount() {
    // always select default language
    this.setState({ text: this.props.data.text[0].text });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ text: nextProps.data.text[0].text });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    } as any);

    this.props.handleUpdate({
      ...this.props.data,
      text: [
        {
          lang: this.props.data.text[0].lang,
          text: e.target.value,
        },
      ].concat(this.props.data.text.slice(1)),
    });
  };

  render() {
    return (
      <>
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-addon" title="English">
              en
            </span>
            <input
              type="text"
              className="form-control"
              value={this.state.text}
              name="text"
              onChange={this.handleChange}
            />
          </div>
        </div>
      </>
    );
  }
}
