import React from 'react';

const AppForms = ({ forms, meta, enableForm, disableForm }) => {
  const enabledForms = meta.forms || [];

  return (
    <div>
      <h5 className="edit-block-title">Forms</h5>

      <table className="table table-shadow">
        <thead>
          <tr>
            <th>Name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {forms.map((form, index) => {
            const metaForm = enabledForms.find(f => f.id === form.id);
            const isEnabled = metaForm && metaForm.isEnabled;
            return (
              <tr key={form.id}>
                <td>{form.name}</td>
                <td>
                  {isEnabled ? (
                    <button
                      className="btn btn-warning"
                      onClick={e => disableForm(form.id)}
                    >
                      Disable
                    </button>
                  ) : (
                    <button
                      className="btn btn-info"
                      onClick={e => enableForm(form.id)}
                    >
                      Enable
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AppForms;
