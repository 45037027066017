import React, { Component } from 'react';

import ScheduleControl from './ScheduleControl';

class EditSchedule extends Component<any, any> {
  state: any = {
    schedule: null,
    isValid: false,
  };

  componentDidMount() {
    this.setState({ schedule: this.props.schedule, isValid: true });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.schedule !== nextProps.schedule)
      this.setState({ schedule: nextProps.schedule, isValid: true });
  }

  handleCreate = (e) => {
    e.preventDefault();

    this.props
      .handleAction(this.props.scheduleId, this.state.schedule)
      .then((r) => {
        if (r) $(`#${this.props.name}`).modal('hide');
        this.reset();
      })
      .catch((e) => {
        var errors = e.response ? e.response.data : null;
        if (errors) {
          if (errors.error && typeof errors.error === 'string') {
            this.setState({
              errors: {
                _all: errors.error,
              },
            });
          } else {
            var newState = this.state.errors;
            for (var fieldName in errors) {
              errors[fieldName] = false;
            }

            this.setState({
              errors: newState,
            });
          }
        }
      });
  };

  reset = () => {};

  handleScheduleChange = (schedule, isValid) => {
    this.setState({ schedule: schedule, isValid: isValid });
  };

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Edit schedule
              </h4>
            </div>
            <div className="modal-body">
              <ScheduleControl
                schedule={this.state.schedule}
                onChange={this.handleScheduleChange}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-fancy"
                disabled={!this.state.isValid}
                onClick={this.handleCreate}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditSchedule;
