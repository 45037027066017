import { AppType, AppPlatforms } from '../../enums';
import * as Xamarin from '../../xamarin';

function defaultiPadGuestDirectoryStyles() {
  return [
    {
      name: 'iPad',
      styles: [
        {
          type: 'title',
          // fontSize: "24",
          // fontFamily: "Avenir",
          // fontAttributes: "none",
          // foregroundColor: null,
          // backgroundColor: null,
          horizontalTextAlignment: Xamarin.HorizontalTextAlignment.center,
          // margin: "0,0,0,0",
          // padding: "0,0,0,0",
          // opacity: 1
        },
      ],
    },
  ];
}

export default function defaultAppTheme(
  appType: any = null,
  platforms: any = null
) {
  let arr: any[] = [];

  if (appType === AppType.GuestDirectory) {
    if (platforms && AppPlatforms.iPad) {
      arr = arr.concat(defaultiPadGuestDirectoryStyles());
    }
  }

  return {
    foregroundColor: '#ffffff',
    backgroundColor: '#000000',

    fontFamily: null,
    backImage: null,
    pickerImage: null,
    logoImage: null,
    platforms: arr,
  };
}
