import React, { Component } from 'react';

import { MediaItemListItem } from 'api';
import { Button } from 'ui';

import { MediaItemType, mediaItemTypeString } from '../../enums';
import { MediaItemThumb } from '../MediaItems/media-item-thumb';
import formatDuration from '../../utilities/formatDuration';
import formatByteSize from '../../utilities/formatByteSize';
import { WithDialog } from 'Components/shared';
import { ImagePreviewModal } from '../MediaItems/image-preview-modal';
import { VideoPreviewModal } from '../MediaItems/video-preview-modal';

export const thumbs = {
  presentation: require('../../Images/media_presentation_thumb.png'),
  image: require('../../Images/media_image_thumb.png'),
  video: require('../../Images/media_video_thumb.png'),
};

const Thumb = ({ mediaItemType }) => (
  <>
    {mediaItemType === MediaItemType.Image ? (
      <div
        style={{
          backgroundImage: `url(${thumbs.image})`,
          backgroundSize: 'cover',
          width: 22,
          height: 16,
        }}
      />
    ) : mediaItemType === MediaItemType.Video ? (
      <div
        style={{
          backgroundImage: `url(${thumbs.video})`,
          backgroundSize: 'cover',
          width: 26,
          height: 20,
        }}
      />
    ) : (
      <div
        style={{
          backgroundImage: `url(${thumbs.presentation})`,
          backgroundSize: 'cover',
          width: 18,
          height: 18,
        }}
      />
    )}
  </>
);

export class MediaItem extends Component<
  {
    image: MediaItemListItem;
    onClick: (image: MediaItemListItem) => void;
  },
  { uri: string }
> {
  constructor(props) {
    super(props);

    let uri = '';
    try {
      const meta = props.image.meta;
      uri = meta ? JSON.parse(meta).uri : null;
    } catch (e) {}

    this.state = { uri };
  }

  addMediaItem = () => this.props.onClick(this.props.image);

  render() {
    const { image: mediaItem } = this.props;
    const { uri } = this.state;

    return (
      <tr>
        <td>
          <WithDialog>
            {(isOpen, show, hide) => (
              <>
                <MediaItemThumb
                  thumbUri={mediaItem.thumbUri}
                  type={mediaItem.mediaItemType}
                  mediaItemId={mediaItem.id}
                  onClick={show}
                />
                {isOpen && mediaItem.mediaItemType === MediaItemType.Image && (
                  <ImagePreviewModal
                    name={mediaItem.name}
                    uri={uri}
                    onClose={hide}
                  />
                )}
                {isOpen && mediaItem.mediaItemType === MediaItemType.Video && (
                  <VideoPreviewModal
                    name={mediaItem.name}
                    uri={uri}
                    onClose={hide}
                  />
                )}
              </>
            )}
          </WithDialog>
        </td>
        <td>{mediaItem.name}</td>
        <td>{mediaItemTypeString(mediaItem.mediaItemType)}</td>
        <td>{`${mediaItem.width} x ${mediaItem.height}`}</td>
        <td>{formatDuration(mediaItem.duration)}</td>
        <td>{formatByteSize(mediaItem.size)}</td>
        <td>
          <Button onClick={this.addMediaItem}>Add</Button>
        </td>
      </tr>
    );
  }
}
