import React from 'react';
import c from 'classnames';

type Props = {
  id: string;
  className?: string;
};

export function Dropdown(props: React.PropsWithChildren<Props>) {
  const { id, className, children, ...rest } = props;

  return (
    <div {...rest} className={c('dropdown', className)}>
      <span
        className="glyphicon glyphicon-option-vertical"
        id={id}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      />
      <ul className="dropdown-menu" aria-labelledby={id}>
        {React.Children.map(children, child => {
          return <li>{child}</li>;
        })}
      </ul>
    </div>
  );
}
