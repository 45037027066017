import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Tooltip from 'react-tooltip-lite';
import c from 'classnames';

import { DeviceForAccount } from 'api';
import { Button, Input, Label } from 'ui';

import { PlatformIcon } from './PlatformIcon';
import {
  FeatureFlag,
  SimpleModal,
  Header,
  Content,
  Footer,
} from 'Components/shared';
import { useUser, useStateLegacy } from 'utilities';

import './Screen.css';

// const channelIcon = require('../../Images/icon_card-channel.png');
// const playlistIcon = require('../../Images/icon_card-playlist.png');
// const heartIcon = require('../../Images/icon_card-heart.png');

export interface ScreenProps {
  screen: DeviceForAccount;
  version: string;
  deleteScreen: (id: number) => any;
  addPingCommand: (id: number) => any;
  addRestartCommand: (id: number) => any;
  addCrashCommand: (id: number) => any;
  addUpdateCommand: (id: number) => any;
  sendCommand: (id: number, command: string, isQuery: boolean) => any;
  renameDevice: (id: number, deviceName: string) => any;
}

class State {
  showCustom = false;
  showRename = false;
  command = '';
  name = '';
}

function getLastUpdateColor(startTime: any, endTime: any) {
  const hours = endTime.diff(startTime, 'hours');

  if (hours < 1) {
    return 'green';
  }

  if (hours > 1 && hours < 24) {
    return 'yellow';
  }

  return 'red';
}

export function Screen(props: ScreenProps) {
  const user = useUser();
  const [state, setState] = useStateLegacy<State>({
    name: props.screen.deviceName,
    showCustom: false,
    showRename: false,
    command: '',
  });

  const { screen, version } = props;
  const { deviceId } = screen;
  const endTime = moment();
  const startTime = screen.lastHeartbeat;

  const updateColor = getLastUpdateColor(startTime, endTime);

  const isOld = isScreenVersionOlderThanLatest(screen, version);

  const deleteScreen = (e) => {
    e.preventDefault();
    props.deleteScreen(deviceId);
  };
  const addPingCommand = () => props.addPingCommand(deviceId);
  const addCrashCommand = () => props.addCrashCommand(deviceId);
  const addRestartCommand = () => props.addRestartCommand(deviceId);
  const addUpdateCommand = () => props.addUpdateCommand(deviceId);

  const showCustomCommand = () => setState({ showCustom: true });
  const hideCustomCommand = () => setState({ showCustom: false });
  const sendCustomCommand = () => {
    setState({ command: '' });
    props.sendCommand(
      deviceId,
      state.command,
      state.command.startsWith('query-')
    );
    hideCustomCommand();
  };

  function queryCache() {
    props.sendCommand(deviceId, 'query-cache', true);
  }

  const showRename = () => setState({ showRename: true });
  const hideRename = () => setState({ showRename: false });
  const renameDevice = () => {
    props.renameDevice(deviceId, state.name);
    hideRename();
  };

  return (
    <>
      <div className="single-channel clearfix screen" style={{}}>
        <div className={c(updateColor, 'screen-left-border leftBorder')} />
        <div className="screen-content clearfix content">
          <div className="col-md-12 clearfix padding0">
            <div className="col-md-10 col-xs-10">
              {user.isAdministrator ? (
                <Link to={`/digitalsignage/screens/${screen.deviceId}/log`}>
                  <h5 className="contentHeading">{screen.deviceName}</h5>
                </Link>
              ) : (
                <h5 className="contentHeading">{screen.deviceName}</h5>
              )}
            </div>

            <div className="col-md-1 col-xs-1 text-right padding0">
              <div className="dropdown">
                <span
                  className="glyphicon glyphicon-option-vertical screenMenu"
                  id="dropdownMenu1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                />
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <li>
                    <a href="#" onClick={deleteScreen}>
                      Delete
                    </a>
                  </li>
                  <li>
                    <Link to={`/digitalsignage/screens/${screen.deviceId}/log`}>
                      Device log
                    </Link>
                  </li>
                  <li className="divider" />
                  <li>
                    <a onClick={addRestartCommand}>Restart</a>
                  </li>

                  {screen.playerVersion ? (
                    <FeatureFlag feature="digitalsignage-devices-commands">
                      <>
                        <li className="divider" />
                        <li>
                          <a onClick={showRename}>Rename</a>
                        </li>
                        <li>
                          <a onClick={addPingCommand}>Ping</a>
                        </li>
                        <li>
                          <a onClick={addUpdateCommand}>Update</a>
                        </li>
                        <li className="divider" />
                        <li>
                          <a onClick={queryCache}>Query cache</a>
                          <Link
                            to={`/digitalsignage/screens/${deviceId}/queries`}
                          >
                            View queries
                          </Link>
                        </li>
                        <li className="divider" />
                        <li>
                          <a onClick={showCustomCommand}>Custom</a>
                        </li>
                      </>
                    </FeatureFlag>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>

          <PlatformIcon platformId={screen.devicePlatform} />

          <div className="col-md-12 screen-info">
            {screen.playerVersion ? (
              <Tooltip
                content={
                  isOld
                    ? 'This player is running an older version EmGuest Player. Please update the player on the device.'
                    : 'This player is running the current version of EmGuest Player.'
                }
                className={isOld ? 'tooltip--error' : ''}
              >
                <p
                  className={c('contentParagraphs', {
                    'version--old': isOld,
                  })}
                >
                  <i className="fa fa-code-fork player-version" />v
                  {screen.playerVersion}{' '}
                  {isOld && (
                    <i className="version__alert glyphicon glyphicon-alert" />
                  )}
                </p>
              </Tooltip>
            ) : null}
            <p className="contentParagraphs">
              <i className="fa fa-desktop" />
              {screen.channelId ? (
                <Link to={`/digitalsignage/channels/${screen.channelId}`}>
                  {screen.channelName}
                </Link>
              ) : (
                '-no channel-'
              )}
            </p>
            <p className="contentParagraphs">
              <i className="fa fa-list" />
              {screen.playlistId ? (
                <Link to={`/digitalsignage/playlists/${screen.playlistId}`}>
                  {screen.playlistName}
                </Link>
              ) : (
                '-no playlist-'
              )}
            </p>
            <p className="contentParagraphs">
              <i className="fa fa-heartbeat" />
              {screen.lastHeartbeat === '0001-01-01T00:00:00+00:00'
                ? '-'
                : moment(screen.lastHeartbeat).fromNow()}
            </p>
          </div>
        </div>
      </div>

      <SimpleModal show={state.showRename} hide={hideRename}>
        <Header>Rename device</Header>
        <Content>
          <Label>
            Device name
            <Input
              name="name"
              value={state.name}
              onChange={(e) => setState({ name: e.target.value })}
            />
          </Label>
        </Content>
        <Footer>
          <Button onClick={hideRename}>Dismiss</Button>
          <Button type="primary" onClick={renameDevice}>
            Rename
          </Button>
        </Footer>
      </SimpleModal>

      <Modal
        isOpen={state.showCustom}
        className="modal-dialog"
        onRequestClose={hideCustomCommand}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={hideCustomCommand}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Send custom command</h4>
          </div>
          <div className="modal-body">
            <Label>
              Custom command
              <Input
                name="command"
                value={state.command}
                onChange={(e) => setState({ command: e.target.value })}
              />
            </Label>
          </div>
          <div className="modal-footer">
            <Button onClick={hideCustomCommand}>Dismiss</Button>
            <Button type="primary" onClick={sendCustomCommand}>
              Send
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
function isScreenVersionOlderThanLatest(
  screen: DeviceForAccount,
  version: string
) {
  // Don't show warning in this case
  if (!version || !screen.playerVersion) {
    return false;
  }

  // Trivial
  if (screen.playerVersion === version) {
    return false;
  }

  const official = version.split('.');
  const current = screen.playerVersion.split('.');
  if (official.length !== current.length || official.length !== 3) {
    return false;
  }

  return official[0] !== current[0] || official[1] !== current[1];
}
