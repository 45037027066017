import React, { PropsWithChildren } from 'react';

export * from './Button';
export * from './Checkbox';
export * from './Container';
export * from './icons';
export * from './Input';
export * from './Label';
export * from './MultiProgress';
export * from './Pagination';
export * from './Select';

export { useToast } from './toasts/toast-provider';

export function Section({ children }: PropsWithChildren<{}>) {
  return <section className={'table-shadow px-4 py-8'}>{children}</section>;
}

export function DefinitionItem({
  term,
  description,
}: {
  term: string;
  description: string;
}) {
  return (
    <div className="px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
      <dt className="font-medium text-gray-500">{term}</dt>
      <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-4">
        {description}
      </dd>
    </div>
  );
}
