import * as React from 'react';
import { Route, NavLink } from 'react-router-dom';

import NavBar from '../NavBar';

import PushNotifications from './PushNotifications';

const Container = ({ children }) => (
  <div style={{ height: '100%' }}>
    <NavBar title="EmGuest" link="/">
      <li>
        <NavLink exact to="/pushnotifications" activeClassName="link-active">
          Push notifications
        </NavLink>
      </li>
    </NavBar>

    {children}
  </div>
);

export default function() {
  return (
    <Container>
      <Route exact path="/pushnotifications" component={PushNotifications} />
    </Container>
  );
}
