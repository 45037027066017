import * as React from 'react';
import { Loading } from 'Components/shared';

interface SimpleTableProps {
  get: () => Promise<any>;
}

export interface SimpleTableState {
  isLoading?: boolean;
  isLoaded?: boolean;
  data?: any;
  error?: any;
}

export class SimpleTable extends React.Component<
  SimpleTableProps,
  SimpleTableState
> {
  state: SimpleTableState = {};

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props
      .get()
      .then(r =>
        this.setState({ isLoading: false, isLoaded: true, data: r.data })
      )
      .catch(e => this.setState({ isLoading: false, error: e }));
  }

  render() {
    if (this.state.isLoading) return <Loading />;
    if (this.state.error) return 'error';

    if (this.state.isLoaded && this.state.data)
      return (this.props.children as any)(this.state);
    else return 'no data';
  }
}
