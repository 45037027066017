import React, { SFC } from 'react';

import {
  SimpleModal,
  Header,
  Content,
  Footer,
  ModalProps,
} from 'Components/shared';
import { Button } from 'ui';

export type ImagePreviewModalProps = {
  name: string;
  uri: string;
} & ModalProps;

export const ImagePreviewModal: SFC<ImagePreviewModalProps> = ({
  name,
  uri,
  show,
  onClose,
}) => (
  <SimpleModal show={show} hide={onClose}>
    <Header>{name}</Header>
    <Content>
      <img width="500" className="center-block" src={uri} />
    </Content>
    <Footer>
      <Button onClick={onClose}>Close</Button>
    </Footer>
  </SimpleModal>
);
