import * as React from 'react';

export interface SelectProps {
  name?: string;
  readOnly?: boolean;
  value?: any;
  onChange?: (e?: any) => any;
  onBlur?: (e?: any) => any;
  onFocus?: (e?: any) => any;
}

export const Select: React.SFC<SelectProps> = ({ children, ...attributes }) => {
  const classes = ['form-control'];
  return (
    <select className={classes.join(' ')} {...attributes}>
      {children}
    </select>
  );
};
