import axios from 'axios';

import {
  UploadedMediaItem,
  MediaItemsList,
  SetActivationForMediaItemCommand,
  SetDeactivationForMediaItemCommand,
} from './types';
import { CropProperties } from './images';

export const uploadMediaItem = (
  accountId: number,
  file: any,
  crop: CropProperties | null,
  onProgress: (progress: number) => any
) => {
  const config = {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (onProgress) onProgress(percentCompleted);
    },
  };

  const data = new FormData();
  data.append('accountId', accountId.toString());
  data.append('file', file);
  data.append('crop', JSON.stringify(crop));

  return axios.post<UploadedMediaItem>('/api/mediaitems/upload', data, config);
};

export const useMediaItem = (mediaItemId: number, data: any) => {
  return axios.post<UploadedMediaItem>(
    `/api/mediaitems/crop/${mediaItemId}`,
    data
  );
};

export const getMediaItems = (
  type: string,
  accountId: number,
  page = 1,
  pageSize = 20,
  query = ''
) => {
  const searchQuery = query ? `?=${query}` : '';
  const url = type
    ? `/api/mediaitems/account/${accountId}/${type}/${page}/${pageSize}${searchQuery}`
    : `/api/mediaitems/account/${accountId}/${page}/${pageSize}${searchQuery}`;

  return axios.get<MediaItemsList>(url);
};

export function mediaItemScheduleActivate(id: number, date: number) {
  return axios.post<boolean>(`/api/mediaitems/${id}/schedule-activate`, {
    mediaItemId: id,
    activateOn: date,
  } as SetActivationForMediaItemCommand);
}

export function mediaItemScheduleDeactivate(id: number, date: number) {
  return axios.post<boolean>(`/api/mediaitems/${id}/schedule-deactivate`, {
    mediaItemId: id,
    deactivateOn: date,
  } as SetDeactivationForMediaItemCommand);
}

export const mediaItemScheduleClear = (id: number) => {
  return axios.post<boolean>(`/api/mediaitems/${id}/schedule-remove`);
};

export function mediaItemUpdateVideoMetadata(id: number) {
  return axios.post<boolean>(`/api/mediaitems/${id}/updatevideoinfo`);
}
