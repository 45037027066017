function calculateTotalDuration(objs) {
  return objs
    .map(obj => {
      let duration = 0;

      let animations = obj.get('animations');
      if (animations.entry.name !== 'none') {
        duration =
          parseFloat(animations.entry.delay) +
          parseFloat(animations.entry.duration);
      }
      if (animations.exit.name !== 'none') {
        duration += parseFloat(animations.exit.delay);
        duration += parseFloat(animations.exit.duration);
      } else {
        duration += 5;
      }
      return duration;
    })
    .reduce((prev, current, index) => Math.max(prev, current), 0);
}

export default calculateTotalDuration;
