import React, { SFC } from 'react';

import {
  SimpleModal,
  Header,
  Footer,
  Content,
  ModalProps,
} from 'Components/shared';
import { Button } from 'ui';

export type VideoPreviewModalProps = { name: string; uri: string } & ModalProps;

export const VideoPreviewModal: SFC<VideoPreviewModalProps> = ({
  name,
  show,
  onClose,
  uri,
}) => (
  <SimpleModal show={show} hide={onClose}>
    <Header>{name}</Header>
    <Content>
      <video width="500" controls className="center-block">
        <source src={uri} type="video/mp4" />
      </video>
    </Content>
    <Footer>
      <Button onClick={onClose}>Close</Button>
    </Footer>
  </SimpleModal>
);
