import { BaseListDTO, DeviceQuery } from 'api';
import {
  Content,
  Footer,
  Header,
  Loading,
  SimpleModal,
  Table,
  WithDialog,
} from 'Components/shared';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Container } from 'ui';
import { useQuery } from 'utilities/useQuery';

type Props = RouteComponentProps<{ id: string }>;

export function DeviceQueries(props: Props) {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { loading, data } = useQuery<BaseListDTO<DeviceQuery>>(
    `/api/dashboard/device/${id}/queries`
  );

  return (
    <Container>
      <h1>Device queries for device {id}</h1>

      {loading ? <Loading /> : null}
      {data ? (
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Query</th>
              <th>Response</th>
              <th>Is executed</th>
            </tr>
          </thead>
          <tbody>
            {(data.items || []).map((query) => (
              <tr>
                <td>{query.commandId}</td>
                <td>
                  <code>{query.query}</code>
                </td>
                <td>
                  <code>{(query.response || '').substr(0, 20)}...</code>

                  <WithDialog>
                    {(isOpen, show, hide) => (
                      <>
                        <Button onClick={show} title="View">
                          <i className="fa fa-eye"></i>
                        </Button>

                        <SimpleModal show={isOpen} hide={hide}>
                          <Header>Query response</Header>
                          <Content>
                            <pre>
                              {JSON.stringify(
                                JSON.parse(query.response),
                                null,
                                2
                              )}
                            </pre>
                          </Content>
                          <Footer>
                            <Button onClick={hide}>Close</Button>
                          </Footer>
                        </SimpleModal>
                      </>
                    )}
                  </WithDialog>
                </td>
                <td>{query.isExecuted ? '' : 'pending'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </Container>
  );
}
