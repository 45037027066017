import { isStaticBlock } from './contentHelpers';

class MobileAppState {
  isLoading = false;
  isLoaded = false;
  isPublishing = false;
  error: any = null;
  data: any = {};
  pages: any[] = [];
  templates: any[] = [];
  theme: any = {};
  forms: any[] = [];
  meta: any = {};
  languages: any[] = [];
}

export const AppReducer = (
  state: MobileAppState = new MobileAppState(),
  action
) => {
  switch (action.type) {
    case 'LOAD_APP':
      return { ...state, isLoading: true, isLoaded: false };

    case 'LOAD_APP_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.data,
        theme: action.theme,
        meta: action.meta,
        languages: action.languages,

        pages: action.data && action.data.pages ? action.data.pages : [],
        templates:
          action.data && action.data.templates ? action.data.templates : [],
        forms: action.data && action.data.forms ? action.data.forms : [],
      };

    case 'LOAD_APP_ERROR':
      return { ...state, isLoading: false, error: action.error };

    case 'APP_LANGUAGE_ADD': {
      const languages = state.languages;
      return {
        ...state,
        languages: languages.slice(0, languages.length).concat(action.language),
      };
    }

    case 'APP_LANGUAGE_REMOVE': {
      const languages = state.languages;
      const index = languages.findIndex(
        (l: any) => l.shortCode === action.language.shortCode
      );
      if (index === -1) return state;
      return {
        ...state,
        languages: languages.slice(0, index).concat(languages.slice(index + 1)),
      };
    }

    case 'APP_LANGUAGE_ENABLE': {
      const index = state.languages.indexOf(action.language);
      if (index === -1) {
        return state;
      }

      return {
        ...state,
        languages: state.languages
          .slice(0, index)
          .concat(
            Object.assign({}, action.language, {
              isEnabled: true,
            })
          )
          .concat(state.languages.slice(index + 1)),
      };
    }

    case 'APP_LANGUAGE_DISABLE': {
      const index = state.languages.indexOf(action.language);
      if (index === -1) {
        return state;
      }

      return {
        ...state,
        languages: state.languages
          .slice(0, index)
          .concat(
            Object.assign({}, action.language, {
              isEnabled: false,
            })
          )
          .concat(state.languages.slice(index + 1)),
      };
    }

    case 'APP_UPDATE_PLATFORM_STYLE': {
      const platforms = state.theme.platforms;
      const platformIndex = platforms.indexOf(action.platform);
      if (platformIndex === -1) {
        return state;
      }

      const styleIndex = action.platform.styles.indexOf(action.style);
      if (styleIndex === -1) {
        return state;
      }

      const style = Object.assign({}, action.style, {
        [action.name]: action.value,
      });

      return Object.assign({}, state, {
        theme: Object.assign({}, state.theme, {
          platforms: platforms
            .slice(0, platformIndex)
            .concat(
              Object.assign({}, action.platform, {
                styles: action.platform.styles
                  .slice(0, styleIndex)
                  .concat(style)
                  .concat(action.platform.styles.slice(styleIndex + 1)),
              })
            )
            .concat(platforms.slice(platformIndex + 1)),
        }),
      });
    }

    case 'APP_PUBLISH_START':
      return Object.assign({}, state, { isPublishing: true });

    case 'APP_PUBLISH_SUCCESS':
      return Object.assign({}, state, { isPublishing: false });

    case 'APP_PUBLISH_ERROR':
      return Object.assign({}, state, { isPublishing: false });

    case 'APP_FORM_ENABLE': {
      let metaForms = state.meta.forms || [];
      const forms = state.forms || [];
      let metaForm = metaForms.find(f => +f.id === +action.formId);
      const form = forms.find(f => f.id === action.formId);

      if (!form) return state;

      if (metaForm) {
        const index = metaForms.indexOf(metaForm);
        metaForm = Object.assign({}, metaForm, {
          isEnabled: true,
          alias: form.alias,
        });
        metaForms = metaForms
          .slice(0, index)
          .concat(metaForm)
          .concat(metaForms.slice(index + 1));
      } else {
        metaForms = metaForms.concat({
          id: action.formId,
          name: form.name,
          alias: form.alias,
          isEnabled: true,
        });
      }

      return Object.assign({}, state, {
        meta: Object.assign({}, state.meta, {
          forms: metaForms,
        }),
      });
    }

    case 'APP_FORM_DISABLE': {
      let metaForms = state.meta.forms || [];
      const forms = state.forms || [];
      let metaForm = metaForms.find(f => +f.id === +action.formId);
      const form = forms.find(f => f.id === action.formId);

      if (!form) return state;

      if (metaForm) {
        const index = metaForms.indexOf(metaForm);
        metaForm = Object.assign({}, metaForm, {
          isEnabled: false,
          alias: form.alias,
        });
        metaForms = metaForms
          .slice(0, index)
          .concat(metaForm)
          .concat(metaForms.slice(index + 1));
      } else {
        metaForms = metaForms.concat({
          id: action.formId,
          name: form.name,
          alias: form.alias,
          isEnabled: false,
        });
      }

      return Object.assign({}, state, {
        meta: Object.assign({}, state.meta, {
          forms: metaForms,
        }),
      });
    }

    default:
      return state;
  }
};

class PageState {
  isLoading = false;
  isLoaded = false;
  error: any = null;

  id = 0;
  name = '';

  // raw response for the page
  page: any = { name: '' };

  // raw data for this page including pretty much everything
  data = {
    background: {
      color: '#FFFFFF',
      landscape: '',
      portrait: '',
    },
    style: [],
    children: [],
    content: [],
    settings: {},
    'list-style': 'default',
    template: '',
  };
  // each block corresponding to layout
  content: any[] = [];
  // block describing subpage with some style/translation
  subpages: any[] = [];
  // for the selected page
  template = {
    name: '',
    alias: '',
    layout: [],
  };
}

export const PageReducer = (state = new PageState(), action) => {
  switch (action.type) {
    case 'LOAD_PAGE_STARTED':
      return Object.assign({}, state, {
        isLoading: true,
        isLoaded: false,
        error: null,
        id: action.data.id,
      });

    case 'LOAD_PAGE_SUCCESS':
      return Object.assign({}, state, action.data, {
        isLoading: false,
        isLoaded: true,
        error: null,
      });

    case 'LOAD_PAGE_ERROR':
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case 'PAGE_SET_NAME':
      return Object.assign({}, state, { name: action.name });

    case 'PAGE_CHECK_NAME':
      let name = state.name;
      if (!name.trim()) {
        name = state.page.name;
        return { ...state, name };
      }
      return state;

    case 'PAGE_SET_CONTENT':
      const { index, data } = action;
      return Object.assign({}, state, {
        content: state.content
          .slice(0, index)
          .concat(data)
          .concat(state.content.slice(index + 1)),
      });

    case 'PAGE_BLOCK_ADD': {
      const content = state.content || [];
      let i = state.content.length;
      while (i > 0) {
        if (!isStaticBlock(content[i - 1].type)) {
          break;
        }
        i -= 1;
      }

      content.splice(i, 0, action.payload);

      return Object.assign({
        ...state,
        content,
      });
    }

    case 'PAGE_BLOCK_REMOVE':
      return {
        ...state,
        content: state.content.filter(block => block.id !== action.payload),
      };

    case 'PAGE_BLOCK_MOVEUP': {
      const id = action.payload;
      const i = state.content.findIndex(b => b.id === id);
      if (i <= 0) {
        return state;
      }

      const el = state.content[i];
      const content = state.content.filter(b => b.id !== id);
      content.splice(i - 1, 0, el);
      return { ...state, content };
    }

    case 'PAGE_BLOCK_MOVEDOWN': {
      const id = action.payload;
      const i = state.content.findIndex(b => b.id === id);
      if (i === -1 || i === state.content.length - 1) {
        return state;
      }

      if (isStaticBlock(state.content[i + 1].type)) {
        return state;
      }

      const el = state.content[i];
      const content = state.content.filter(b => b.id !== id);
      content.splice(i + 1, 0, el);
      return { ...state, content };
    }

    case 'PAGE_SET_SUBPAGES':
      return Object.assign({}, state, {
        subpages: action.subpages,
      });

    case 'PAGE_SET_SUBPAGE': {
      const index = state.subpages.findIndex(
        (p: any) => p.id === action.subpage.id
      );
      if (index === -1) return state;

      return Object.assign({}, state, {
        subpages: state.subpages
          .slice(0, index)
          .concat(action.subpage)
          .concat(state.subpages.slice(index + 1)),
      });
    }

    case 'PAGE_SET_TEMPLATE': {
      return Object.assign({}, state, {
        content: action.content,
        template: action.template,
      });
    }

    case 'PAGE_SET_BACKGROUND': {
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          background: action.background,
        }),
      });
    }

    case 'PAGE_SAVE_SETTINGS': {
      return {
        ...state,
        data: {
          ...state.data,
          settings: action.settings,
        },
      };
    }

    default:
      return state;
  }
};
