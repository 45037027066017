import React, { Component } from 'react';
import ModalBase from '../FormHelpers/ModalBase';

class AddSubpageModal extends ModalBase {
  constructor(props) {
    super(props, ['name']);

    this.validate = this.validate.bind(this);
  }

  validate() {
    const data = this.state.data;
    return {
      name: data.name ? '' : 'Must enter page name',
      _all: '',
    };
  }

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.name}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.props.name + 'Label'}
      >
        <div className="modal-dialog modal-fancy" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={this.props.name + 'Label'}>
                Add sub page
              </h4>
            </div>
            <div className="modal-body">
              <form id={this.props.name + 'Form'}>
                <div
                  className={
                    'form-group ' + (this.state.errors.name ? 'has-error' : '')
                  }
                >
                  <label htmlFor="name" className="control-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Page name..."
                    name="name"
                    value={this.state.data.name}
                    onChange={this.handleChange}
                    required
                  />
                  <div className="help-block with-errors">
                    {this.state.errors.name}
                  </div>
                </div>

                <div className="has-error">
                  <div className="help-block with-errors">
                    {this.state.errors._all}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-fancy"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-fancy"
                onClick={this.handleCreate}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddSubpageModal;
